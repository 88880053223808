import Vue from "vue";
import store from "@/store";
import {ProviderModule} from "@/store/modules/provider";


export const resolveProvider = async () => {
    return ProviderModule.GetProvider()
}

export default {
    install() {
        Vue.prototype.$provider = store.state.provider;
    },
};
