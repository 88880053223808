/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'file_upload': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z"/>'
  }
})
