
import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { DeviceType, AppModule } from '@/store/modules/app'
import { SettingsModule } from '@/store/modules/settings'
import { AppMain, Navbar, Toolbar, DashboardNavbar, Sidebar } from './components'
import RightPanel from '@/components/RightPanel/index.vue'
import ResizeMixin from './mixin/resize'
import TutorialDrawer from "@/components/TutorialDrawer/index.vue";
import Statistics from "@/layout/components/Navbar/statistics.vue";

@Component({
  name: 'Layout',
  components: {
    Statistics,
    TutorialDrawer,
    Toolbar,
    AppMain,
    Navbar,
    DashboardNavbar,
    RightPanel,
    Sidebar
  }
})
export default class extends mixins(ResizeMixin) {
  get classObj() {
    return {
      hideSidebar: !this.sidebar.opened,
      openSidebar: this.sidebar.opened,
      withoutAnimation: this.sidebar.withoutAnimation,
      mobile: this.device === DeviceType.Mobile
    }
  }

  get showSettings() : boolean {
    return SettingsModule.showSettings
  }

  get fixedHeader() : boolean {
    return SettingsModule.fixedHeader
  }

  private handleClickOutside() : void {
    AppModule.CloseSideBar(false)
  }
}
