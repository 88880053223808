/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'h_mobiledata': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 11H9V7H7v10h2v-4h6v4h2V7h-2v4z"/>'
  }
})
