import {VuexModule, Module, Action, Mutation, getModule} from 'vuex-module-decorators'
import store from '@/store'
import {Marker, MarkerList} from "@/data/classes/marker"
import {MarkerElementList} from "@/data/classes/element";
import {UserModule} from "@/store/modules/user";
import {SettingsModule} from "@/store/modules/settings";

export interface IMarkerModuleState {
  active: number;
  list: MarkerList;
}


@Module({ dynamic: true, store, name: 'marker' })
class MarkerModuleClass extends VuexModule implements IMarkerModuleState {
  public active = 0;
  public list : MarkerList = new MarkerList()

  @Mutation
  private SET_ACTIVE(index: number){
    this.active = index;
  }

  @Mutation
  private REMOVE_MARKERS(){
    while (this.list.length > 0){
      this.list.splice(0, 1);
    }
  }


  @Action
  public async GetMarkers() : Promise<MarkerList> {
    // Get marker list
    if (!this.list._retrieved || this.list._licenseId !== UserModule.license){
      await this.list.get(UserModule.license);
    }

    // Return list
    return this.list;
  }

  @Action
  public async GetMarker(markerId: string) : Promise<Marker> {
    // Create new marker
    let marker = new Marker({
      _retrieved: true,
      _licenseId: UserModule.license,
      DefaultLanguage: SettingsModule.defaultLanguage,
      Languages: [SettingsModule.defaultLanguage]
    });

    if (!markerId) {
      // Add marker to list
      this.list.push(marker);
    } else {
      // Try to get locally
      marker = this.list.getById(markerId);

      // Get externally if undefined
      if (!marker){
        marker = new Marker();
        await marker.get(UserModule.license, markerId)
      } else {
        // Get elements
        marker.Elements = new MarkerElementList(marker);
        marker.Elements.setMarkerInfo(marker);
        await marker.Elements.get(marker.Id);
        window.GlobalEvents.$emit('refresh-canvas');
      }
    }

    // Set active index
    this.SET_ACTIVE(this.list.getMarkerIds().indexOf(marker));

    // Return marker
    return marker;
  }

  @Action
  public async PutMarker(marker: Marker) : Promise<MarkerList> {
    // Return marker list
    return this.list.put(marker);
  }

  @Action
  public UpdateMarkerStatus(markerId: string, status : boolean) {
    // Update marker mutation
    return this.list.update(markerId, {
      Active: status,
    });
  }


  @Action
  public async RemoveMarkerData() {
    this.REMOVE_MARKERS()
  }
}

export const MarkerModule = getModule(MarkerModuleClass)
