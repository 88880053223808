
import { Component, Prop } from 'vue-property-decorator'
import InputMixin from "../mixins/input";

@Component({
  name: 'DateInput',
  components: {},
  mixins: [InputMixin]
})
export default class DateInput extends InputMixin {
  @Prop({default: () => true }) disableDate!: (date: Date) => boolean;

}
