
import { Component, Watch, Vue } from 'vue-property-decorator'
import UpperRightMenu from "./components/UpperRightMenu.vue";
import {UserModule} from "@/store/modules/user";
import {dateShortcuts} from "@/views/statistics/components/dateShortcuts";
import {CustomerModule} from "@/store/modules/customer";
import {LicenseModule} from "@/store/modules/license";
import {MarkerModule} from "@/store/modules/marker";

@Component({
  name: 'Statistics',
  components: {UpperRightMenu},
  mixins: [],
})
export default class extends Vue {

  private querySettings: { daterange: Date[] | string[]; dateShortcuts: never[]; filters: string[] } = {
    daterange: [new Date(new Date().setDate(0)), new Date()],
    dateShortcuts: [],
    filters: []
  }

  //Watchers
  @Watch("querySettings.daterange")
  onDateRangeChange(value: string[]){
    window.GlobalEvents.$emit("set-date-range", value);
  }

  //Getters
  get impersonatingLicense(){
    return UserModule.impersonating;
  }

  get customers(){
    return CustomerModule.list;
  }
  get licenses(){
    return LicenseModule.list;
  }
  get markers(){
    return MarkerModule.list;
  }

  get options(){
    const options = [];
    if (this.$route.path === "/statistics/dashboard"){
      options.push({
        value: "MarkerId",
        label: "Markers",
        children: this.markers.map(({Id, DisplayName}) => {
          return {
            value: Id,
            label: DisplayName
          }
        }).sort((a: any, b: any)  => a.label.localeCompare(b.label))
      })
    }

    if (this.$route.path === "/statistics/markers" || this.$route.path === "/statistics/dashboard"){
      options.push({
        value: "CustomerId",
        label: "Customers",
        children: this.customers.map(({Id, Company}) => {
          return {
            value: Id,
            label: Company.Name
          }
        }).sort((a: any, b: any)  => a.label.localeCompare(b.label))
      })
    }

    if (UserModule.roles.includes("admin") && this.$route.path !== "/statistics/licenses"){
      options.push({
        value: "LicenseId",
        label: "Licenses",
        children: this.licenses.map(({Id, LicenseName, Company, LicenseOwner}) => {
          return {
            value: Id,
            label: LicenseName || Company?.Name || LicenseOwner?.Email
          }
        }).sort((a: { label: string }, b: { label: string })  => a.label.localeCompare(b.label))
      })
    }

    return options;
  }

  created(){
    this.getDateShortcuts();
    this.getOptions();

    // Set filters
    if (this.$route.query.customerId){
      this.querySettings.filters = ["CustomerId",this.$route.query.customerId as string];
    }
    if (this.$route.query.markerId){
      this.querySettings.filters = ["MarkerId",this.$route.query.markerId as string];
    }
  }

  beforeDestroy(){
    if (this.querySettings.filters[0] === "LicenseId" && this.querySettings.filters[1] !== UserModule.license){
      this.customers.get(UserModule.license);
      this.markers.get(UserModule.license);
    }
  }

  // Methods
  private getDateShortcuts(){
    this.querySettings.dateShortcuts = dateShortcuts as never[];
  }

  private async getOptions(){
    if (!this.customers._retrieved) {
      await this.customers.get(UserModule.license);
    }
    if (!this.markers._retrieved){
      await this.markers.get(UserModule.license);
    }
    if (!this.licenses._retrieved && UserModule.roles.includes("admin")){
      await this.licenses.get();
    }
  }

  private onFilterChange([key, value]: any){
    window.GlobalEvents.$emit("set-filter", {[key]: value});
  }


}
