/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'vr_video_element': {
    width: 47,
    height: 32,
    viewBox: '0 0 47 32',
    data: '<path pid="0" d="M38.213 7.31s11.809 6.266 4.325 12.947c-4.134 2.972-9.222 4.866-14.734 5.178l-.074.003v3.347c4.807-.522 12.358-2.075 16.629-6.695 9.118-9.654-6.146-14.782-6.146-14.782zM24.248 26.256l-5.503-3.736c-.71-.482-1.339-.174-1.339.683v1.995c-4.84-.583-9.18-2.348-12.84-4.996l.08.055C-2.839 13.562 8.971 7.31 8.971 7.31s-15.183 5.128-6.065 14.862c3.709 4.017 9.881 5.65 14.581 6.373v2.571c0 .857.589 1.165 1.339.683l5.463-3.736a1.02 1.02 0 00-.035-1.804l-.006-.003z"/><path pid="1" d="M28.974 0H12.037a2.544 2.544 0 00-2.544 2.544v12.773a2.557 2.557 0 002.557 2.557h16.924a2.57 2.57 0 002.557-2.557V2.543a2.558 2.558 0 00-2.557-2.544zm-8.596 15.103a6.132 6.132 0 110-12.264 6.132 6.132 0 110 12.264z"/><path pid="2" d="M20.378 3.963a5.022 5.022 0 105.061 5.021V8.97v.001a5.022 5.022 0 00-5.021-5.008h-.042.002zM23.297 9.6l-3.923 2.263a.8.8 0 01-.374.107h-.001a.696.696 0 01-.696-.696l.001-.028v.001-4.539-.027c0-.385.312-.696.696-.696h.001a.808.808 0 01.379.109l-.004-.002 3.923 2.263a.71.71 0 01.375.615v.001a.736.736 0 01-.371.627l-.004.002zM32.214 13.804l3.722 2.477V1.593L32.214 4.07v9.734zM36.673 1.593v14.728a.817.817 0 00.817-.817v-.028.001V2.423a.83.83 0 00-.816-.83h-.001z"/>'
  }
})
