/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'scatter_plot': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="7" cy="14" r="3"/><circle pid="1" cx="11" cy="6" r="3"/><circle pid="2" cx="16.6" cy="17.6" r="3"/>'
  }
})
