/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'fast_forward': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4 18l8.5-6L4 6v12zm9-12v12l8.5-6L13 6z"/>'
  }
})
