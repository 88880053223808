/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'wash': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.5 8A2.5 2.5 0 0021 5.5C21 3.83 18.5 1 18.5 1S16 3.83 16 5.5A2.5 2.5 0 0018.5 8zm-5 1c.83 0 1.5-.67 1.5-1.5 0-.84-1.5-2.5-1.5-2.5S12 6.66 12 7.5c0 .83.67 1.5 1.5 1.5zM9.12 5l-7.18 6.79c-.6.56-.94 1.35-.94 2.18V20c0 1.66 1.34 3 3 3h13.75a1.25 1.25 0 000-2.5H12v-1h7.75a1.25 1.25 0 000-2.5H12v-1h8.75a1.25 1.25 0 000-2.5H12v-1h6.75a1.25 1.25 0 000-2.5H8.86c.64-1.11 1.48-2.58 1.49-2.61a1.065 1.065 0 00-.12-1.23C10.22 6.12 9.12 5 9.12 5z"/>'
  }
})
