
import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  Checkbox,
  ColorInput,
  Content,
  DateInput,
  DateTimeInput,
  DateRangeInput,
  DateTimeRangeInput,
  ImageUpload,
  Input,
  LanguageSelect,
  List,
  ModelAnimationInput,
  MultilingualInput,
  MultilingualList,
  MultipleChoice,
  Number,
  PhoneInput,
  RatingInput,
  Select,
  SliderInput,
  SwitchInput,
  TemplateStyle,
  TemplateVariables,
  TimeInput
} from "./components";
import {getValue} from "@/globalFunctions";

interface IGeneralInputConfiguration {
  afterComponent?: Record<string, unknown>,
  appendComponent?: Record<string, unknown>,
  beforeComponent?: Record<string, unknown>,
  bindings?: { path: string, force: boolean, processor: (input: string) => string }[];
  condition?: (input: string) => boolean,
  content?: string,
  dateShortcuts?: Record<string, string>[];
  disableDate?: (date: Date) => boolean,
  disabled?: boolean | ((input?: any) => any),
  decode?: (input: any) => any,
  dynamicOptions: (input?: any) => any,
  encode?: (input: any) => any,
  filterable?: boolean;
  formValueAccess?: boolean,
  interval?: number,
  label: string,
  max?: number,
  min?: number,
  multiple?: boolean,
  name: string,
  offsetLeft?: number,
  offsetRight?: number,
  options?: Record<string, unknown>[],
  path: string,
  prefix?: string,
  prefixIcon?: string,
  prependComponent: Record<string, unknown>,
  readonly?: boolean,
  required: boolean,
  size: number,
  suffix: string,
  suffixIcon: string,
  type: string,
}

// TODO: slider, color, date, rating, component, array, language, password
@Component({
  name: 'GeneralInput',
  components: {
    Checkbox, ColorInput, Content, DateInput,DateTimeInput, DateRangeInput, DateTimeRangeInput, ImageUpload, Input, LanguageSelect, List, ModelAnimationInput, MultilingualInput, MultilingualList, MultipleChoice, Number, PhoneInput, RatingInput, Select, SliderInput, SwitchInput, TemplateStyle, TemplateVariables, TimeInput
  }
})
export default class extends Vue {
  @Prop({ default: () => {} }) private configuration! : IGeneralInputConfiguration;
  @Prop({ default: () => [] }) private errors! : { path: string, code: string }[];
  @Prop({ default: "form" }) private formId? : string;
  @Prop({ default: () => {} }) private formValues! : any;

  private value: any = null;
  private language = "EN";

  // COMPUTED
  get valueModel(){
    // Get value from form values
    const value = getValue(this.formValues, this.config.path);

    // Decode value
    const decodedValue = this.config.decode ? this.config.decode(value) : value;

    // Set value
    this.value = decodedValue;

    // Return value
    return this.value;
  }
  set valueModel(value: any){
    // Store value locally
    this.value = value;

    // Do not write
    if (!(this.config.type.indexOf("list") >= 0 && typeof value !== "object")){
      // Encode value
      const encodedValue = this.config.encode ? this.config.encode(value) : value;

      // Emit to form
      window.FormEvents.$emit("input-" + this.formId, {
        path: this.config.path + (this.config.type.indexOf("multilingual_text") >= 0 ? "." + this.language : ""),
        value: encodedValue,
      });

      // Emit bindings
      if (this.config.bindings){
        this.config.bindings.forEach((binding) => {
          window.FormEvents.$emit("input-" + this.formId, {
            path: binding.path + (this.config.type.indexOf("multilingual_text") >= 0 ? "." + this.language : ""),
            value: binding.processor ? binding.processor(encodedValue) : encodedValue,
          });
        });
      }
    }
  }

  get condition(){
    return this.config.condition ? this.config.condition(this.formValues) : true;
  }

  get config(){
    return {
      afterComponent: this.configuration.afterComponent,
      appendComponent: this.configuration.appendComponent,
      beforeComponent: this.configuration.beforeComponent,
      bindings: this.configuration.bindings,
      condition: this.configuration.condition,
      content: this.configuration.content,
      dateShortcuts: this.configuration.dateShortcuts,
      disabled: typeof this.configuration.disabled === "function" ? this.configuration.disabled(this.formValues) : this.configuration.disabled,
      decode: this.configuration.decode,
      disableDate: this.configuration.disableDate,
      dynamicOptions: this.configuration.dynamicOptions,
      encode: this.configuration.encode,
      filterable: this.configuration.filterable,
      formValues: this.configuration.formValueAccess ? this.formValues : null,
      interval: this.configuration.interval,
      label: this.configuration.label,
      min: this.configuration.min,
      max: this.configuration.max,
      multiple: this.configuration.multiple,
      name: this.configuration.name,
      offsetLeft: this.configuration.offsetLeft,
      offsetRight: this.configuration.offsetRight,
      options: this.configuration.options,
      path: this.configuration.path,
      prefix: this.configuration.prefix,
      prefixIcon: this.configuration.prefixIcon,
      prependComponent: this.configuration.prependComponent,
      readonly: this.configuration.readonly,
      required: this.configuration.required,
      size: this.configuration.size,
      suffix: this.configuration.suffix,
      suffixIcon: this.configuration.suffixIcon,
      type: this.configuration.type,
    }
  }

  get error(){
    return this.errors.filter(({path}: {path: string}) => this.config.path === path).map(({code}:{code: string}) => code)[0];
  }

  get inputs(){
    return {
      "animation": ModelAnimationInput,
      "checkbox": Checkbox,
      "color": ColorInput,
      "content": Content,
      "date": DateInput,
      "datetime": DateTimeInput,
      "daterange": DateRangeInput,
      "datetimerange": DateTimeRangeInput,
      "email": Input,
      "image": ImageUpload,
      "select": Select,
      "password": Input,
      "slider": SliderInput,
      "switch": SwitchInput,
      "language": LanguageSelect,
      "list": List,
      "multilingual_list": MultilingualList,
      "multilingual_text": MultilingualInput,
      "multilingual_textarea": MultilingualInput,
      "multiple_choice": MultipleChoice,
      "number": Number,
      "rating": RatingInput,
      "style": TemplateStyle,
      "tel": PhoneInput,
      "text": Input,
      "textarea": Input,
      "variables": TemplateVariables,
      "time": TimeInput
    }
  }


  created(){
    // Get value
    const value = getValue(this.formValues, this.config.path);

    // Decode value
    this.value = this.config.decode ? this.config.decode(value) : value;

    // Set value globally
    window.FormEvents.$on("set-value", ({path, value}: any) => {
      if (this.config.path === path){
        this.value = this.config.decode ? this.config.decode(value) : value;
      }
    })
  }


  // METHODS
}
