/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'element_contact': {
    width: 896,
    height: 1024,
    viewBox: '0 0 896 1024',
    data: '<path pid="0" d="M128.008 0v1024h768.005V0H128.008zM512.01 256.329c70.509 0 127.673 57.164 127.673 127.673S582.519 511.675 512.01 511.675s-127.673-57.164-127.673-127.673 57.164-127.673 127.673-127.673zm191.991 511.676H319.998v-64.004c0-70.697 57.311-128.008 128.008-128.008h128.008c70.697 0 128.008 57.311 128.008 128.008v64.004zM0 64.004h96.006v191.991H0V64.004zM0 319.999h96.006V511.99H0V319.999zM0 575.993h96.006v191.991H0V575.993zM0 832.009h96.006V1024H0V832.009z"/>'
  }
})
