/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'upload': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5 20h14v-2H5v2zm0-10h4v6h6v-6h4l-7-7-7 7z"/>'
  }
})
