import request from '@/utils/request'
import {UserModule} from "@/store/modules/user";

export const getCustomers = (licenseId?: string, params?: any) =>
    request({
        url: 'license/' + (licenseId ? licenseId : UserModule.license) + '/customer',
        method: 'get',
        params
    })

export const getCustomer = (licenseId?: string, customerId?: string, params?: any) =>
    request({
        url: 'license/' + (licenseId ? licenseId : UserModule.license) + '/customer/' + customerId,
        method: 'get',
        params
    })

export const createCustomer = (licenseId?: string, data?: any) =>
    request({
        url: 'license/' + (licenseId ? licenseId : UserModule.license) + '/customer',
        method: 'post',
        data: data
    })

export const updateCustomer = (licenseId?: string, customerId?: string, data?: any) =>
    request({
        url: 'license/' + (licenseId ? licenseId : UserModule.license) + '/customer/' + customerId,
        method: 'put',
        data
    })

export const deleteCustomer = (licenseId?: string, customerId?: string) =>
    request({
        url: 'license/' + (licenseId ? licenseId : UserModule.license) + '/customer/' + customerId,
        method: 'delete'
    })
