/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'vpn_key_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.83 18H21v-4h2v-4H12.83l8 8zm-1.05 4.61l1.41-1.41L2.81 2.81 1.39 4.22l2.59 2.59A6.012 6.012 0 001 12c0 3.31 2.69 6 6 6 2.21 0 4.15-1.2 5.18-2.99l7.6 7.6zM8.99 11.82c.01.06.01.12.01.18 0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2c.06 0 .12 0 .18.01l1.81 1.81z"/>'
  }
})
