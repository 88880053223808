/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'audio_element': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" _fill="#231f20" d="M27.888 28.8H4.096a1.393 1.393 0 01-1.392-1.392V3.616c0-.768.624-1.392 1.392-1.392h23.792c.768 0 1.392.624 1.392 1.392v23.792c0 .768-.624 1.392-1.392 1.392zM5.424 26.08H26.56V4.944H5.424V26.08z"/><path pid="1" d="M22.541 8.57a.315.315 0 00-.451 0l-.531.531a.336.336 0 00-.01.458c1.45 1.603 2.23 3.507 2.23 5.722.003 2.221-.778 4.134-2.237 5.747a.333.333 0 00.01.458l.531.531a.315.315 0 00.451 0c3.578-3.715 3.523-9.802.006-13.446zm-7.376.409l-.928.87c-.864.819-1.734 1.635-2.595 2.458a.569.569 0 01-.426.176c-.726-.006-1.45-.006-2.176-.006-.384.003-.765.003-1.149.003h-.019v5.6c.038.016.08.026.125.026H9.04c.717 0 1.43 0 2.144-.003a.453.453 0 01.346.134c1.174 1.091 2.349 2.179 3.523 3.267.058.054.115.102.173.157.096.086.176.01.176-.166V9.079c-.003-.176-.109-.221-.237-.099zm5.376 1.584a.322.322 0 00-.454.003l-.518.518a.337.337 0 00-.01.461c.918 1.056 1.411 2.301 1.411 3.744 0 1.446-.502 2.698-1.421 3.757a.34.34 0 00.01.461l.525.525a.31.31 0 00.448-.003c2.618-2.787 2.307-7.123.01-9.466zm-1.975 1.997a.306.306 0 00-.445-.003l-.538.541a.35.35 0 00-.016.464c.384.49.589 1.069.589 1.728 0 .669-.214 1.248-.602 1.741a.35.35 0 00.016.464l.538.538c.125.125.33.128.445-.003 1.373-1.533 1.328-3.994.013-5.469zm-11.859-.083v5.603a.137.137 0 00.077.026h.637v-5.629c-.234.003-.47.003-.704.003l-.01-.003z"/>'
  }
})
