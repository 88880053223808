import {getProvider} from "../api/provider";
import {createRandomToken, filterUndefined} from "@/globalFunctions";


export class Provider implements IProvider {
    _retrieved = false;
    _retrieving = false;

    Archived = false;
    EmailConfiguration = new EmailConfiguration()
    EnableEcommerce = false;
    Id: string = createRandomToken();
    Platform = new Platform();


    /**
     * Constructor
     * @param {unknown} obj data
     */
    constructor(obj?: any ) {
        if (obj) {
            this.set(obj);
        }
    }


    /**
     * Set provider data
     * @param {string} data - the provider data
     * @return {Provider} The provider object
     */
    set = (data: any) : Provider => {
        // Assign data
        Object.assign(this, data);

        // Set favicon
        let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = this.Platform.Images.FavIcon.Url + (this.Platform.Images.FavIcon.Url.indexOf('?') >= 0 ?'&' : '?') +'v=5';


        // Return provider object
        return this;
    }


    /**
     * Get the provider
     * @param {string} id - the provider Id
     * @return {Provider} The provider object
     */
    get = async (id?: string) : Promise<Provider> => {
        // Set ID
        if (id){
            this.Id = id;
        }

        // Get data
        const {data} = await getProvider(this.Id);

        // Set data
        this.set(data);

        // Retrieved
        this._retrieved = true;
        this._retrieving = false;

        // Return provider object
        return this;
    }


    /**
     * Convert to JSON
     * @return {IProvider} JSON data
     */
    toJSON = () : IProvider => {
        return filterUndefined({
            Archived: this.Archived,
            EmailConfiguration: this.EmailConfiguration.toJSON ? this.EmailConfiguration.toJSON() : this.EmailConfiguration,
            Id: this.Id,
            Platform: this.Platform.toJSON ? this.Platform.toJSON() : this.Platform,
            EnableEcommerce: this.EnableEcommerce
        }) as IProvider;
    }


}

export class EmailConfiguration implements IProviderEmailConfiguration {
    Base = {
        Email: "no-reply@veewar.com",
        Name: "VEEWAR",
    };
    Images = {
        Image: {
            Extension: "png",
            Height: 170,
            Name: "image_VEEWAR-logo_dark-background.png",
            Size: 23579,
            Style: "width: 40%; float:left;",
            Url: "https://storage.googleapis.com/dev-veewar.appspot.com/reseller/veewar/images/logo.png?GoogleAccessId=firebase-adminsdk-3f0ay%40dev-veewar.iam.gserviceaccount.com&Expires=16725225600&Signature=BDY3XRGessyv53FL8S%2Fi1pEGA1sy53BpWxfjpf7OKzmEmuQch9QsqWO4%2FOQ%2F%2FbRKO3adfrPzLNYP77De1d4xYi4xZrAZxtaBa93sSkWD8aCi6dc7sNwD%2B1dMBooG6QZjqVKXGyE2E687vNR7st%2F%2F1VqmXRJe4QxYGGFrSRwoMUp3ynUYaeuDKxOSejylzYvw9oWcjsB8fIk5vw8u%2BMqaGwA40gc1M2Qud%2BSfBuWpS0qhJl2z3n015%2BIpfB6UuWkMrrqbPSv3dkzMVdrTXhwMSSN6qM05SWldYI5%2Ftyy0c5Rnoa0%2B4vPqAiU4tUxhuXoFqyjiCxTeDtxGan8clGBgHw%3D%3D",
            UrlPath: "reseller/veewar/images/logo.png",
            Width: 1019
        },
        ImageSignature: {
            Extension: "png",
            Height: 442,
            Name: null,
            Size: 47035,
            Style: "float:left; max-width: 150px;",
            Url: "https://storage.googleapis.com/dev-veewar.appspot.com/reseller/veewar/images/signature.png?GoogleAccessId=firebase-adminsdk-3f0ay%40dev-veewar.iam.gserviceaccount.com&Expires=16725225600&Signature=OTXsN9cENJL9Xorizz6r%2FziskJOWj6joccuLEK3JiQTek2vp9zj846R3opfW56IVfzauMXJMgumWHaQFFC4NuYKIHGDbFkIPdeaGDSjE684AUImhPHpgZF6uxAAun6R9IiqAnX116H%2FG%2F9eFH3kcLFDB%2FJKItXVSu1%2Fr3XqemviBFEN4Bj4yDR2%2BalOzNlDaXpfbYCXm6NExnuNyYJ3IBj3m8%2BRDDLhqoE2a1tFjow4MfL8aiD6r9PHH%2FJ%2FlHYPLWCHaYBj5cog%2B2pfSdsuUhGFxmRK6Q3TYYE51dqaRyXAY3kLY51qQEVlrSGhZJ%2F%2F4GeXmS8INH6yq%2FivMv2RpnA%3D%3D",
            UrlPath: "reseller/veewar/images/signature.png",
            Width: 629
        }
    };
    Variables = {
        Domain: "https://veewar.com/",
        CmsDomain: "https://cms.veewar.com/",
        SalesEmail: "sales@veewar.com",
        TwitterUrl: "https://www.twitter.com",
        FacebookUrl: "https://www.facebook.com",
        InstagramUrl: "https://www.facebook.com",
        WebsiteUrl: "https://www.veewar.com",
        YouTubeUrl: "https://www.youtube.com",
        LinkedInUrl: "https://www.linkedin.com",
        LoginUrl: "https://cms.veewar.com/login",
        HomePageUrl: "https://veewar.com",
        TutorialUrl: "https://veewar.com/tutorials",
        SenderName: "Sigrid Bakker",
        SenderFunction: "AR Consultant",
        SenderEmail: "info@veewar.com",
        SenderCompanyName: "VEEWAR",
        SenderPhoneNumber: "",
        FullLicensePlatform: "VEEWAR",
        FullLicenseCompany: "MultiMediaMarkers",
        FullLicenseAddress: "",
        FullLicenseZIP: "9606RD",
        FullLicenseCity: "Kropswolde",
        FullLicenseCountry: "Nederland",
        FullLicenseHomepage: "https://veewar.com/",
        FullLicenseMail: "info@veewar.com"
    }


    /**
     * Convert to JSON
     * @return {IProviderEmailConfiguration} JSON data
     */
    toJSON = () : IProviderEmailConfiguration => {
        return filterUndefined({
            Base: this.Base,
            Images: this.Images,
            Variables: this.Variables,
        }) as IProviderEmailConfiguration;
    }
}

export class Platform implements IProviderPlatform {
    Colors = {
        danger: "#f44336",
        info: "#299ED9",
        primary: "#253546",
        rose:"#e91e63",
        success: "#4caf50",
        warning: "#F57B00"
    };
    Company = {
        City: "Hoogezand",
        ClientCode: "",
        Country: "Nederland",
        Email: "info@veewar.com",
        HouseNumber: "201",
        Name: "MultiMediaMarkers",
        Notes: "",
        Phone: "+31 50 203 19 10",
        PostalCode: "9600 AE",
        Street: "Postbus",
    };
    Domains = ["veewar.nl", "veewar.com"];
    Endpoints = {
        Company: "https://multimediamarkers.com",
        Contact: "https://multimediamarkers.com/contact",
        Home: "https://veewar.com",
        PasswordReset: "https://cms.veewar.com/reset",
        Register: "https://cms.veewar.com/register",
        Terms: "https://www.veewar.com/algemene-voorwaarden",
        Tutorials: "https://cms.veewar.com/tutorials",
        Verify: "https://cms.veewar.com/verify",
    };
    Images = {
        FavIcon: {
            Extension: "ico",
            Height: 256,
            Name: null,
            Size: 115817,
            Url: "https://storage.googleapis.com/dev-veewar.appspot.com/reseller/veewar/images/favicon.ico?GoogleAccessId=firebase-adminsdk-3f0ay%40dev-veewar.iam.gserviceaccount.com&Expires=16725225600&Signature=TcGAOKDuEDW0qsJkzfAuPVHAm3qVDuoZOVvDScGfTA6dTpTSO09XmezAwxTG8PJsIuYQRqFYb9IyKOX1b3uNGMVAYBrwfyhRsYXRLAuXAJgIDzM8sJRqZ12RwJKTjy5ea%2FdTZIuI7nCcqgd59mF8GrkyFMWex654OtMX0IgpRFYRMILS%2Fn3Wfer11Bb45DUF2F%2Fj38ZKNFtMVhh9bwZFXqRpyLXVLdv%2Fkb6sMRc8DiAXKSjmi5Ymfk2PYMEviyaIsEXgEUb4JMyRdEzvbu89xTlHJFEyybYMpF81NmqA5AdDaVhUbQx1CQPLL9QFzrYsHd8TZkTO5XML5Y1zd2Bhrw%3D%3D",
            UrlPath: "reseller/veewar/images/favicon.ico",
            Width: 629
        },
        Icon: {
            Extension: "png",
            Height: 339,
            Name: null,
            Size: 13467,
            Url: "https://storage.googleapis.com/dev-veewar.appspot.com/reseller/veewar/images/icon.png?GoogleAccessId=firebase-adminsdk-3f0ay%40dev-veewar.iam.gserviceaccount.com&Expires=16725225600&Signature=WCL%2FwZZRtXZQxLO1wdLWMR8aPeDQ7mbhKFyjHweKwfLPFDVpcPaeVUuH%2BCdZpG4BZWmHwkSQY%2BEGRuJpjkHWi97sgHLY9VuBLTpLkguFw3UWm83KmhrMNGmZK9GCFCSnzuSuLq5OmSTzBBGqNmRHjV2iWaG%2Fh%2F3P0BnpCZuvNui0k%2FuYFO4Ax2W3JJTim3tuoW4%2FgLLMkBdEYMW2%2BtzHPzdxk1lnqsIww7MyIV1Z9cpDiAaly%2BeM150YCZfEtOwfRxxNUXcnMFIPgXzVqzEJ6R4R2JEcWXlpG91uIfu4ODnJgwT0dHksRGYv26eTEHCgtAXTwphev%2BG1fHlcWXU6dg%3D%3D",
            UrlPath: "reseller/veewar/images/icon.png",
            Width: 353
        },
        LoginBackground: {
            Extension: "jpg",
            Height: 729,
            Name: null,
            Size: 82699,
            Url: "https://storage.googleapis.com/dev-veewar.appspot.com/reseller/veewar/images/login_background.jpg?GoogleAccessId=firebase-adminsdk-3f0ay%40dev-veewar.iam.gserviceaccount.com&Expires=16725225600&Signature=PIDo1SclObgVjBgbaNIiGFRBGVD%2BFqcAvEvceTl4pMpYx1w1q5GAxhgAmmNnTGQORVKPO6rbbQEXK4NnGiVLQKw51U4vFFZO6l%2FD6FbxrEZfi6t%2BMZ8X1nva4rpxj1nx1w7%2B3bg5k04MkeQFVHc53tP6iPQLSuOxXC1jmUciA6NitOJ0oEsuUrK7u9mUOLUsspphcAD%2By9211%2B60Ky%2BhQnyZXwnSpDeMkhf%2F%2FJgUgJ7zPld72U2f%2BwJlLfTJtym9pcJ2yXUfZsl2eYZ4JfemOWf6XWPIPWJpjBKsDiv9RK5QNTiXOi946%2BxznXhb6BBVbPBbqLA%2FyixaEg9K2oo4XA%3D%3D",
            UrlPath: "reseller/veewar/images/login_background.jpg",
            Width: 1659
        },
        Logo: {
            Extension: "png",
            Height: 170,
            Name: null,
            Size: 23579,
            Url: "https://storage.googleapis.com/dev-veewar.appspot.com/reseller/veewar/images/cms_logo.png?GoogleAccessId=firebase-adminsdk-3f0ay%40dev-veewar.iam.gserviceaccount.com&Expires=16725225600&Signature=c59PioCMKAeCvQWZZFx75XjJ7WZtI5ggePWH%2FbvgG0lp9E7u%2BsxheZN%2BMIcxb%2Fj3kpGCZ%2BCiDfSGjRNoD97H7O7B%2FniV6o1D3kp0hbww6u98AlT9Gin3jZ%2BJ5WUZCJhJm%2FAOmV0c8tCPLA8Jj1RnUDQIxesogaes7iVCItbIvcCXRfMU%2FHYO8VGGpCLxP0tj2aR67NTSSbsyUZVOgRIImbOvnv%2BVdkgmVAE8vGK246zZg%2B4hFiNXKkD6%2FfpDUprHe3ibi2QA7LVwI%2FaeHCvLbUs4qIec6Vyy5oMRg6tK8%2BMr0nf8M8RsKzTdbVjRgnNJSpMhmPT4n2sE0C7nW9wCwg%3D%3D",
            UrlPath: "reseller/veewar/images/cms_logo.png",
            Width: 1019
        },
        RegisterBackground: {
            Extension: "jpg",
            Height: 729,
            Name: null,
            Size: 82699,
            Url: "https://storage.googleapis.com/dev-veewar.appspot.com/reseller/veewar/images/register_background.jpg?GoogleAccessId=firebase-adminsdk-3f0ay%40dev-veewar.iam.gserviceaccount.com&Expires=16725225600&Signature=E%2Fww9OpOSw1VYCcqJhQsAmWjYVYn%2BwGM71H2CoEozTYi7FEPPoeqsXYcaw59MXnSLHWrkwVSJY1euOdmjNvJJRMvYBfteOgT8cnaqMYfQPals4oL8D5Ke0DW9sh9i8mKRk4igb8fwT6ifOTMiXdHYcixyRGPMLozhaAuQ99ENFsg6q0oCYj2xNBIJta%2F%2BBimyYn6kQaeFkjacnYxfhj5A0xeX3DTfo6Ny%2FbCUPLbZyi7mni9leWplN3lb2e%2BDOLlCU3MUClyMTHrzMlwxYiIyMi3K06BNv1%2FudJ2DCd3Au6LTdQ5jjHKFyW2GiYWFFy8TXQaTx8nFWxIOUnS2sA93g%3D%3D",
            UrlPath: "reseller/veewar/images/register_background.jpg",
            Width: 1659
        },
    };
    Settings = {
        HelpEnabled: true
    }


    /**
     * Convert to JSON
     * @return {IProviderPlatform} JSON data
     */
    toJSON = () : IProviderPlatform => {
        return filterUndefined({
            Colors: this.Colors,
            Company: this.Company,
            Domains: this.Domains,
            Endpoints: this.Endpoints,
            Images: this.Images,
            Settings: this.Settings,
        }) as IProviderPlatform;
    }
}
