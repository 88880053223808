import {VuexModule, Module, Action, getModule} from 'vuex-module-decorators'
import store from '@/store'
import {License, LicenseList} from "@/data/classes/license"


export interface ILicenseModuleState {
  active: number;
  list: LicenseList;
}


@Module({ dynamic: true, store, name: 'license' })
class LicenseModuleClass extends VuexModule implements ILicenseModuleState {
  public active = 0;
  public list : LicenseList = new LicenseList()


  @Action
  public async GetLicenses(admin = false) : Promise<LicenseList> {
    // Get license list
    await this.list.get(admin);

    // Return list
    return this.list;
  }

  @Action
  public async GetLicense(licenseId: string) : Promise<License> {
    // Return new license
    let license = new License();
    if (!licenseId) {
      // Add license to list
      this.list.push(license);

      // Return license
      return license;
    }

    // Get locally
    license = this.list.find((license) => license.Id === licenseId);

    // Get externally if undefined
    if (!license){
      license = new License();
      await license.get(licenseId);
    }

    // Return license
    return license;
  }

}

export const LicenseModule = getModule(LicenseModuleClass)
