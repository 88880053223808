/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'expand_less': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"/>'
  }
})
