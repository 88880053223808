import Vue from 'vue'
import {sendSlackMessage} from "@/data/api/slack";

const GlobalEventBus = new Vue();

// Global events
let resizeTimeout: ReturnType<typeof setTimeout> | undefined;
window.addEventListener("resize", () => {
  // Resize start
  if (resizeTimeout === undefined){
    GlobalEventBus.$emit("window-resize-start");
  } else {
    clearTimeout(resizeTimeout);
  }

  // Set time out
  resizeTimeout = setTimeout(() => {
    GlobalEventBus.$emit("window-resize-end");
    resizeTimeout = undefined;
  }, 500);
});

// Global listeners
GlobalEventBus.$on("notify", (variables: any) => {
  Vue.prototype.$notify(variables);
})

GlobalEventBus.$on("open-chat", () => {
  if (window.environment === "cms"){
    // Append
    const script = document.createElement('script');
    script.src = "https://code.tidio.co/zxkgrretvcscqzk1cw5te4cqivmkhtmg.js";
    document.head.appendChild(script);
    GlobalEventBus.$off("open-chat");
  }
});

//Send slack message
GlobalEventBus.$on("send-slack-message", (options: any) => {
  sendSlackMessage(options);
});


export default GlobalEventBus;
