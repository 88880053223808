/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'keyboard_control_key': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5 12l1.41 1.41L12 7.83l5.59 5.58L19 12l-7-7z"/>'
  }
})
