import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

/* Layout */
import Layout from '@/layout/index.vue'

/* Router modules */

Vue.use(VueRouter)

/*
  Note: sub-menu only appear when children.length>=1
  Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
*/

/*
  name:'router-name'             the name field is required when using <keep-alive>, it should also match its component's name property
                                 detail see : https://vuejs.org/v2/guide/components-dynamic-async.html#keep-alive-with-Dynamic-Components
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    roles: ['admin', 'editor']   will control the page roles (allow setting multiple roles)
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    hidden: true                 if true, this route will not show in the sidebar (default is false)
    alwaysShow: true             if true, will always show the root menu (default is false)
                                 if false, hide the root menu when has less or equal than one children route
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    noCache: true                if true, the page will not be cached (default is false)
    affix: true                  if true, the tag will affix in the tags-view
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
*/

/**
  ConstantRoutes
  a base page that does not have permission requirements
  all roles can be accessed
*/
export const constantRoutes: RouteConfig[] = [
  {
    path: '/redirect',
    component: Layout,
    meta: { hidden: true },
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import(/* webpackChunkName: "redirect" */ '@/views/template/redirect/index.vue')
      }
    ]
  },
  {
    path: '/catalogus',
    component: () => import(/* webpackChunkName: "catalogus" */ '@/views/ecommerce/Catalogus.vue' as any) as any,
    meta: { hidden: true }
  },
  {
    path: '/payment-failed',
    component: () => import(/* webpackChunkName: "catalogus" */ '@/views/ecommerce/PaymentFailed.vue' as any) as any,
    meta: { hidden: true }
  },
  {
    path: '/payment-success',
    component: () => import(/* webpackChunkName: "catalogus" */ '@/views/ecommerce/PaymentSuccess.vue' as any) as any,
    meta: { hidden: true }
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/authentication/login/index.vue'),
    meta: { hidden: true }
  },
  {
    path: '/mobile',
    component: () => import(/* webpackChunkName: "mobile" */ '@/views/mobile/index.vue'),
    meta: { hidden: true }
  },
  {
    path: '/demo',
    component: () => import(/* webpackChunkName: "demo" */ '@/views/authentication/demo/index.vue'),
    meta: { hidden: true }
  },
  {
    path: '/register',
    component: () => import(/* webpackChunkName: "register" */ '@/views/authentication/register/index.vue'),
    meta: { hidden: true }
  },
  {
    path: '/verify',
    component: () => import(/* webpackChunkName: "register" */ '@/views/authentication/verify/index.vue'),
    meta: { hidden: true }
  },
  {
    path: '/password-reset',
    component: () => import(/* webpackChunkName: "register" */ '@/views/authentication/password-reset/index.vue'),
    meta: { hidden: true }
  },
  {
    path: '/change-email',
    component: () => import(/* webpackChunkName: "register" */ '@/views/authentication/verify/index.vue'),
    meta: { hidden: true }
  },
  {
    path: '/auth-redirect',
    component: () => import(/* webpackChunkName: "auth-redirect" */ '@/views/authentication/login/auth-redirect.vue'),
    meta: { hidden: true }
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/template/error-page/404.vue'),
    meta: { hidden: true }
  },
  {
    path: '/401',
    component: () => import(/* webpackChunkName: "401" */ '@/views/template/error-page/401.vue'),
    meta: { hidden: true }
  },
  {
    path: '/app',
    component: () => import(/* webpackChunkName: "appRedirect" */ '@/views/redirect/app.vue'),
    meta: { hidden: true }
  },
  {
    path: '/user',
    component: Layout,
    redirect: '/user/profile',
    meta: {
      title: 'user',
      icon: 'person',
      divider: true
    },
    children: [
      {
        path: 'profile',
        component: () => import(/* webpackChunkName: "user-profile" */ '@/views/user/profile/index.vue'),
        name: 'UserProfile',
        meta: {
          title: 'profile',
          closeOnRedirect: true,
        }
      },
      {
        path: 'licenses',
        component: () => import(/* webpackChunkName: "user-licenses" */ '@/views/user/licenses/index.vue'),
        name: 'UserLicense',
        meta: {
          title: 'licenses',
          closeOnRedirect: true
        }
      },
      {
        path: 'licenses/:licenseId',
        component: () => import(/* webpackChunkName: "user-license-info" */ '@/views/user/licenses/info.vue'),
        name: 'LicenseInfo',
        meta: {
          hidden: true,
          title: 'licenseInfo',
          closeOnRedirect: true,
          navbar: {
            buttons: [
              {
                command: "backToLicenses",
                label: "dashboard.navbar.back-to-licenses",
                type: "info",
              }
            ]
          }
        }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard/grid',
  },
  {
    path: '/dashboard',
    component: Layout,
    redirect: '/dashboard/grid',
    children: [
      {
        path: '/dashboard/:view',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/index.vue'),
        name: 'Dashboard',
        meta: {
          view: "grid",
          title: 'dashboard',
          icon: 'view_list',
          navbar: 'dashboard',
        }
      }
    ]
  },
  {
    path: '/profile',
    redirect: '/user/licenses',
    meta: { hidden: true },
  },
  {
    path: '/sandbox',
    component: Layout,
    meta: { hidden: true },
    children: [{
      path: 'index',
      component: () => import(/* webpackChunkName: "dashboard" */ '@/views/empty.vue'),
    }]
  }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
*/
export const asyncRoutes: RouteConfig[] = [
  {
    path: '/marker',
    component: Layout,
    redirect: '/marker/editor',
    meta: {
      name: "sidebar_create_marker",
      title: 'new-marker',
      icon: 'add'
    },
    children: [
      {
        path: '/marker/editor',
        component: () => import(/* webpackChunkName: "marker-create" */ '@/views/marker/editor/index.vue'),
        name: 'Editor',
        meta: {
          title: 'new-marker',
          icon: 'add',
          navbar: 'toolbar',
          closeOnRedirect: true,
          noCache: true,
          returnPage: '/dashboard',
          breadcrumb: false
        }
      },
      {
        path: '/marker/editor/:markerId',
        component: () => import(/* webpackChunkName: "marker-create" */ '@/views/marker/editor/index.vue'),
        name: 'Editor',
        meta: {
          hidden: true,
          noCache: true,
          navbar: 'toolbar',
          closeOnRedirect: true,
          breadcrumb: false,
          returnPage: '/dashboard',
        }
      },
    ]
  },
  {
    path: '/statistics',
    component: Layout,
    redirect: '/statistics/dashboard',
    meta: {
      title: 'statistics',
      icon: 'bar_chart'
    },
    children: [
      {
        path: 'dashboard',
        component: () => import(/* webpackChunkName: "statistics-dashboard" */ '@/views/statistics/index.vue'),
        name: 'StatisticsDashboard',
        meta: {
          title: 'dashboard',
          navbar: "statistics",
          closeOnRedirect: true,
          breadcrumb: false,
          noCache: true,
        }
      },
      {
        path: 'markers',
        component: () => import(/* webpackChunkName: "marker-create" */ '@/views/statistics/markers.vue'),
        name: 'StatisticsMarkerDashboard',
        meta: {
          title: 'statistics-markers',
          navbar: "statistics",
          closeOnRedirect: true,
          breadcrumb: false,
          noCache: true,
        }
      },
      {
        path: 'customers',
        component: () => import(/* webpackChunkName: "marker-create" */ '@/views/statistics/customers.vue'),
        name: 'StatisticsCustomerDashboard',
        meta: {
          title: 'statistics-customers',
          navbar: "statistics",
          closeOnRedirect: true,
          breadcrumb: false,
          noCache: true,
        }
      },
      {
        path: 'licenses',
        component: () => import(/* webpackChunkName: "marker-create" */ '@/views/statistics/licenses.vue'),
        name: 'StatisticsLicenseDashboard',
        meta: {
          roles: ['admin'],
          title: 'statistics-licenses',
          navbar: "statistics",
          closeOnRedirect: true,
          breadcrumb: false,
          noCache: true,
        }
      },
    ]
  },
  {
    path: '/customers',
    component: Layout,
    redirect: '/customers/list',
    meta: {
      title: 'customers',
      icon: 'people'
    },
    children: [
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "marker-create" */ '@/views/customers/list.vue'),
        name: 'CustomerList',
        meta: {
          title: 'customers',
          icon: 'people',
          closeOnRedirect: true,
          navbar: {
            buttons: [
              {
                command: "newCustomer",
                label: "dashboard.navbar.new-customer",
                type: "info",
              }
            ]
          }
        }
      },
      {
        path: 'edit/:customerId',
        component: () => import(/* webpackChunkName: "marker-create" */ '@/views/customers/edit.vue'),
        name: 'CustomerEdit',
        meta: {
          title: 'customer-edit',
          icon: 'people',
          hidden: true,
          closeOnRedirect: true,
          returnPage: "/customers/list"
        }
      },
    ]
  },
  {
    path: '/library',
    component: Layout,
    redirect: '/library/list',
    meta: {
      title: 'library',
      icon: 'category',
    },
    children: [
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "library" */ '@/views/library/index.vue'),
        name: 'Library',
        meta: {
          title: 'library',
          icon: 'category',
          navbar: {
            buttons: [
              {
                command: "newLibraryItem",
                label: "dashboard.navbar.new-library-item",
                type: "info",
              },
              {
                roles: ['admin'],
                command: "newLibraryCategory",
                label: "dashboard.navbar.new-library-category",
                type: "info",
              }
            ]
          }
        }
      },
    ]
  },
  {
    path: '/helpdesk',
    component: Layout,
    meta: {
      title: 'helpdesk',
      icon: 'help',
    },
    children: [
      {
        path: 'tutorials',
        component: () => import(/* webpackChunkName: "open-tutorials" */ '@/views/helpdesk/tutorials.vue'),
        name: 'Tutorials',
        meta: {
          title: 'tutorials',
        }
      },
      {
        path: 'tour',
        component: () => import(/* webpackChunkName: "start-tour" */ '@/views/helpdesk/tour.vue'),
        name: 'Tour',
        meta: {
          title: 'tour',
        }
      }
    ]
  },
  {
    path: '/admin',
    component: Layout,
    redirect: '/admin/dashboard',
    meta: {
      title: 'admin',
      icon: 'admin_panel_settings',
      roles: ['admin'], // you can set roles in root nav
      alwaysShow: true // will always show the root menu
    },
    children: [
      {
        path: 'dashboard',
        component: () => import(/* webpackChunkName: "admin-dashboard" */ '@/views/admin/index.vue'),
        name: 'Dashboard',
        meta: {
          title: 'admin-dashboard',
          roles: ['admin'],
        }
      },
      {
        path: 'users',
        component: () => import(/* webpackChunkName: "permission-role" */ '@/views/admin/permission/users.vue'),
        name: 'UserList',
        meta: {
          title: 'users',
          roles: ['admin'],
        }
      },
      {
        path: 'licenses',
        component: () => import(/* webpackChunkName: "license-list" */ '@/views/admin/licenses/list.vue'),
        name: 'AdminLicenseList',
        meta: {
          title: 'licenses',
          closeOnRedirect: false,
          navbar: {
            buttons: [
              {
                roles: ['admin'],
                command: "newLicense",
                label: "dashboard.navbar.new-license",
                type: "info",
              }
            ]
          }
        }
      },
      {
        path: 'licenses/:licenseId',
        component: () => import(/* webpackChunkName: "license-edit" */ '@/views/admin/licenses/edit.vue'),
        name: 'AdminLicenseEdit',
        meta: {
          title: 'licenseEdit',
          closeOnRedirect: true,
          hidden: true,
          returnPage: "/admin/licenses"
        }
      },
      {
        path: 'tutorials',
        component: () => import(/* webpackChunkName: "tutorial-list" */ '@/views/admin/tutorials/list.vue'),
        name: 'AdminTutorials',
        meta: {
          title: 'tutorials',
          closeOnRedirect: false,
          navbar: {
            buttons: [
              {
                roles: ['admin'],
                command: "newTutorial",
                label: "dashboard.navbar.new-tutorial",
                type: "info",
              }
            ]
          }
        }
      },
      {
        path: 'tutorials/:tutorialId',
        component: () => import(/* webpackChunkName: "tutorial-edit" */ '@/views/admin/tutorials/edit.vue'),
        name: 'AdminTutorialEdit',
        meta: {
          title: 'edit-tutorial',
          hidden: true,
          closeOnRedirect: true,
          returnPage: "/admin/tutorials"
        }
      },
      {
        path: 'tutorials/:tutorialId/sections',
        component: () => import(/* webpackChunkName: "tutorial-section-list" */ '@/views/admin/tutorials/sections/list.vue'),
        name: 'AdminTutorialSections',
        meta: {
          title: 'tutorial-sections',
          hidden: true,
          closeOnRedirect: true,
          navbar: {
            buttons: [
              {
                roles: ['admin'],
                command: "newTutorialSection",
                label: "dashboard.navbar.new-tutorial-section",
                type: "info",
              }
            ]
          }
        }
      },
      {
        path: 'tutorials/:tutorialId/sections/:tutorialSectionId',
        component: () => import(/* webpackChunkName: "tutorial-section-edit" */ '@/views/admin/tutorials/sections/edit.vue'),
        name: 'AdminTutorialSectionEdit',
        meta: {
          title: 'edit-tutorial-section',
          hidden: true,
          closeOnRedirect: true,
          returnPage: "/admin/tutorials/:tutorialId/sections"
        }
      },
    ]
  },
  {
    path: '/ecommerce',
    component: Layout,
    redirect: '/ecommerce/dashboard',
    meta: {
      title: 'ecommerce',
      icon: 'storefront',
      roles: ['admin'], // you can set roles in root nav
      alwaysShow: true // will always show the root menu
    },
    children: [
      {
        path: 'prospects',
        component: () => import(/* webpackChunkName: "prospect-list" */ '@/views/admin/prospects/list.vue'),
        name: 'AdminProspectList',
        meta: {
          title: 'prospects',
          closeOnRedirect: false
        }
      },
      {
        path: 'prospects/:prospectId',
        component: () => import(/* webpackChunkName: "prospect-info" */ '@/views/admin/prospects/info.vue'),
        name: 'AdminProspectInfo',
        meta: {
          title: 'prospect',
          closeOnRedirect: true,
          hidden: true,
          returnPage: "/admin/prospects"
        }
      },
      {
        path: 'products',
        component: () => import(/* webpackChunkName: "product-list" */ '@/views/admin/products/list.vue'),
        name: 'AdminProductList',
        meta: {
          title: 'products',
          closeOnRedirect: false,
          navbar: {
            buttons: [
              {
                roles: ['admin'],
                command: "newProduct",
                label: "dashboard.navbar.new-product",
                type: "info",
              },
              {
                roles: ['admin'],
                command: "toCatalogus",
                label: "dashboard.navbar.to-catalogus",
                type: "info-border",
              },
              {
                roles: ['admin'],
                command: "refreshProducts",
                label: "dashboard.navbar.refresh-products",
                type: "danger",
              }
            ]
          }
        }
      },
      {
        path: 'products/:productId',
        component: () => import(/* webpackChunkName: "product-edit" */ '@/views/admin/products/edit.vue'),
        name: 'AdminProductEdit',
        meta: {
          title: 'productEdit',
          closeOnRedirect: true,
          hidden: true,
          returnPage: "/ecommerce/products"
        }
      },
      {
        path: 'coupons',
        component: () => import(/* webpackChunkName: "coupon-list" */ '@/views/admin/coupons/list.vue'),
        name: 'AdminCouponList',
        meta: {
          title: 'coupons',
          closeOnRedirect: false,
          navbar: {
            buttons: [
              {
                roles: ['admin'],
                command: "newCoupon",
                label: "dashboard.navbar.new-coupon",
                type: "info",
              },
            ]
          }
        }
      },
      {
        path: 'coupons/:couponId',
        component: () => import(/* webpackChunkName: "coupon-edit" */ '@/views/admin/coupons/edit.vue'),
        name: 'AdminCouponEdit',
        meta: {
          title: 'couponEdit',
          closeOnRedirect: true,
          hidden: true,
          returnPage: "/ecommerce/coupons"
        }
      },
    ]
  },
  {
    path: '/tests',
    component: Layout,
    redirect: '/tests/scans',
    meta: {
      title: 'tests',
      icon: 'bug',
      roles: ['admin'], // you can set roles in root nav
      alwaysShow: true // will always show the root menu
    },
    children: [
      {
        path: 'scans',
        component: () => import(/* webpackChunkName: "test-scans" */ '@/views/admin/tests/scans.vue'),
        name: 'TestScans',
        meta: {
          title: 'test-scans',
          roles: ['admin'],
        }
      },
      {
        path: 'emails',
        component: () => import(/* webpackChunkName: "test-emails" */ '@/views/admin/tests/emails.vue'),
        name: 'TestEmails',
        meta: {
          title: 'test-emails',
          roles: ['admin'],
        }
      },
      {
        path: 'subscriptions',
        component: () => import(/* webpackChunkName: "test-subscriptions" */ '@/views/admin/tests/subscriptions.vue'),
        name: 'TestSubscriptions',
        meta: {
          title: 'test-subscriptions',
          roles: ['admin'],
        }
      },
    ]
  },
  {
    path: '*',
    redirect: '/404',
    meta: { hidden: true }
  }
]



const createRouter = () => new VueRouter({
  // mode: 'history',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  base: process.env.BASE_URL,
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher // reset router
}

export default router
