/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'category': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2l-5.5 9h11z"/><circle pid="1" cx="17.5" cy="17.5" r="4.5"/><path pid="2" d="M3 13.5h8v8H3z"/>'
  }
})
