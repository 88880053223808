
import { Component, Prop } from 'vue-property-decorator'
import InputMixin from "../mixins/input";

@Component({
  name: 'Input',
  components: {},
  mixins: [InputMixin]
})
export default class Input extends InputMixin {
  @Prop({ default: 0 }) private min?: string; // textarea, text, email, password,
  @Prop({ default: undefined }) private max?: string; // textarea, text, email, password,

}
