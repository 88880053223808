
import {Component, Prop, PropSync, Vue, Watch} from 'vue-property-decorator'
import GeneralInput from "@/components/GeneralInput/index.vue";
import {createRandomToken, getValue, setValue} from "@/globalFunctions";

@Component({
  name: 'GeneralForm',
  components: {
    GeneralInput,
  }
})
export default class extends Vue {
  @Prop({ default: false }) private accessible: boolean;
  @Prop({ default: "form" }) private formId: string;
  @Prop({ default: () => []}) private tabs: Record<string, any>[];
  @Prop({ default: false }) private skipValidation: boolean;
  @Prop({ default: () => { return {}; }}) private value: Record<string, any>;
  @Prop({ default: () => { return {}; }}) private validateTabChange?: () => boolean | void;
  @PropSync("activeTabKey", {default: ""}) private activeTab: string;

  // Variables
  private errors: Record<string,{ path: string, code: string }[]> = {};
  public lastChange = 0;
  private filteredTabs: Record<string, any>[];
  private updateKey = createRandomToken();

  // Computed
  get formValues(){
    return this.value;
  }
  set formValues(value){
    this.$emit("input", value);
  }

  // Watchers
  @Watch('activeTab')
  private onActiveNameChange(value: string) {
    this.$emit("tab-change", value);
  }

  //Methods
  public nextTab(){
    const tabIndex = this.filteredTabs.findIndex((tab) => tab.key === this.activeTab);
    const valid = this.validateTab(tabIndex);

    if (valid){
      window.GlobalEvents.$emit("general-form-next-tab");
      if (tabIndex === this.filteredTabs.length - 1){
        this.submit();
      } else {
        this.activeTab = this.filteredTabs[tabIndex + 1].key;
      }

    }
  }
  public prevTab(){
    const tabIndex = this.filteredTabs.findIndex((tab) => tab.key === this.activeTab);
    if (tabIndex > 0){
      window.GlobalEvents.$emit("general-form-prev-tab");
      this.activeTab = this.filteredTabs[tabIndex - 1].key;
    }
  }

  public beforeLeave(newTabKey: string) {
    if (this.skipValidation || this.accessible){
      return true;
    }

    // Validate all previous tabs
    const newTabIndex = this.filteredTabs.findIndex((tab) => tab.key === newTabKey);
    for(let x=0; x<newTabIndex; x++){
      const valid = this.validateTab(x);
      if (!valid){
        return false;
      }
    }

    // Validate
    return true;
  }

  public validate(){
    // Assume valid
    let valid = true;

    //Validate all tabs
    for (const tabIndex in this.filteredTabs){
      // Validate tab
      valid = this.validateTab(parseInt(tabIndex));

      if (!valid){
        return false;
      }
    }

    return valid;
  }
  private validateTab(tabIndex?: number){
    // Get tab index
    if (tabIndex === undefined){
      tabIndex = this.filteredTabs.findIndex((tab) => tab.key === this.activeTab);
    }

    // Get tab
    const tab = this.filteredTabs[tabIndex];

    // Failsafe
    if (!this.errors[tab.key]){
      this.$set(this.errors, tab.key, []);
    }

    // Get all inputs of the current tab
    if (tab.sections){
      const inputs = tab.sections.map((x: any) => x.inputs).reduce((list: any[], inputs: any) => {
        list.push(...inputs);
        return list;
      }, []);

      this.errors[tab.key] = [];
      for (const input of inputs){
        this.errors[tab.key].push(...this.validateInput(input));
      }
    }

    // Custom tab validation
    if (tab.validate !== undefined){
      this.errors[tab.key].push(...tab.validate(this.formValues));
    }

    // Emit errors
    if (this.errors[tab.key].length > 0){
      // Go to invalidated tab
      this.activeTab = tab.key;

      // Emit event
      window.FormEvents.$emit("tab-invalidated", {
        formId: this.formId,
        tabKey: tab.key,
        errors: this.errors[tab.key]
      });
    }

    // Validated if no errors
    return this.errors[tab.key].length === 0;
  }
  private validatePath(path: string){
    // For all tabs
    for (const tabIndex in this.filteredTabs){
      // Get all inputs of the current tab associated to the given path
      const tab = this.filteredTabs[tabIndex];
      if (tab.sections){
        const inputs = tab.sections.map((x: any) => x.inputs).reduce((list: any[], inputs: any) => {
          list.push(...inputs);
          return list;
        }, []).filter((input: any) => input.path === path);

        // Failsafe
        if (!this.errors[tab.key]){
          this.$set(this.errors, tab.key, []);
        }

        // Remove associated errors
        this.errors[tab.key] = this.errors[tab.key].filter((error: {path: string}) => error.path !== path);

        // Re-validate
        for (const input of inputs){
          this.errors[tab.key].push(...this.validateInput(input));
        }
      }
    }
  }
  private validateInput(input: any) {
    const errors: { path: string, code: string }[] = [];

    // Only validate visible fields
    if (input.condition){
      if (!input.condition(this.formValues)){
        return errors;
      }
    }

    // Get input value
    const value = getValue(this.formValues, input.path);

    // Check required values
    if (input.required && (value === undefined || value === null || value === "")){
      errors.push({
        path: input.path,
        code: input.errorCodes?.required || "missing-required-value",
      });
    } else if (input.required && input.type === "checkbox" && !value){
      errors.push({
        path: input.path,
        code: input.errorCodes?.required || "checkbox-is-required",
      });
    }

    // Custom validation
    if (input.validate){
      const error = input.validate(value, this.formValues);
      if (error){
        errors.push(error);
      }
    }

    // TODO: Apply validation rules for url, email, et cetera
    if (input.type === "tel" || input.rules && input.rules?.includes("tel")){
      if (value){
        if (value.length < 8){
          errors.push({
            path: input.path,
            code: input.errorCodes?.phonenumber || "incorrect-phonenumber",
          });
        }
      }
    }
    if (input.type === "email" || input.rules && input.rules?.includes("email")){
      const validateEmail = (email: string) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      };

      if (!value){
        errors.push({
          path: input.path,
          code: input.errorCodes?.email || "incorrect-email",
        });
      } else if (!validateEmail(value)){
        errors.push({
          path: input.path,
          code: input.errorCodes?.email || "incorrect-email",
        });
      }
    }
    if (input.type === "url" || input.rules && input.rules?.includes('url')){
      let url;
      try {
        url = new URL(value);
        console.info(url);
      } catch (_) {
        errors.push({
          path: input.path,
          code: input.errorCodes?.email || "incorrect-url",
        });
      }
    }

    return errors;
  }

  private filterTabs(){
    // Filter tabs
    this.filteredTabs = this.tabs.filter((tab) => tab.condition ? tab.condition(this.formValues) : true);
  }

  private handleInput = ({path, value}: {path: string, value: any}) => {
    if (!this.formValues){
      this.formValues = {};
    }
    // Value has changed
    this.lastChange = Date.now();
    setValue(this.formValues, "_changed", Date.now());

    // Set value
    setValue(this.formValues, path, value);

    // Output value
    this.$emit("input", this.formValues);

    // Re-validate path
    this.validatePath(path);

    // Filter tabs
    this.filterTabs();

    // Update
    this.updateKey = createRandomToken();
  }

  private submit(){
    this.$emit("submit", this.formValues);
    window.GlobalEvents.$emit("general-form-submit");
  }


  // Hooks
  created() {
    // Filter tabs
    this.filterTabs();

    // Set active tab
    this.activeTab = this.$route.query.tab || this.filteredTabs[0].key || "general";

    // Subscribe to form events of specific language if provided
    window.FormEvents.$on("input-" + this.formId, this.handleInput);
  }

  destroy() {
    // Unsubscribe
    window.FormEvents.$off("input-" + this.formId, this.handleInput);
  }
}
