
import { Component, Vue } from 'vue-property-decorator'
import UpperRightMenu from "./components/UpperRightMenu.vue";
import {UserModule} from "@/store/modules/user";

@Component({
  name: 'Navbar',
  components: {UpperRightMenu}
})
export default class extends Vue {

  get impersonatingLicense() : boolean {
    return UserModule.impersonating;
  }

  private stopImpersonating() : void {
    UserModule.StopImpersonating();
  }

  private emit(event: string) : void {
    window.EditorEvents.$emit(event);
  }

  goToNewMarker() : void {
    if (this.$router.currentRoute.path !== "/marker/editor"){
      this.$router.push("/marker/editor");
    } else {
      window.location.reload();
    }
  }
}
