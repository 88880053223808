/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'element_3D': {
    width: 888,
    height: 1024,
    viewBox: '0 0 888 1024',
    data: '<path pid="0" d="M444.156 0l-409.6 199.68 409.6 200.947 409.6-200.947L444.156 0zM0 819.2L415.987 1024V449.276L0 245.76V819.2zM472.324 449.276v574.707l415.987-204.8V245.761L472.324 449.277z"/>'
  }
})
