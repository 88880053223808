import {
    convertDate,
    createRandomToken,
    filterUndefined,
    getImageSize,
    getObjectSize,
    getVideoSize
} from "@/globalFunctions";
import {createMarkerElement, deleteMarkerElement, getMarkerElements, updateMarkerElement} from "@/data/api/element";
import {Marker} from "@/data/classes/marker";

export class MarkerElementList extends Array implements Array<MarkerElementGroup> {
    public _markerId: string;
    public _markerDefaultLanguage: string;
    public _markerLanguages: string[];
    public _markerSize: {
        PhysicalSizeX: number;
        PhysicalSizeY: number;
        ImageSize: {
            X: number;
            Y: number;
        };
    }
    public _changed = 0;
    public _retrieved = false;

    constructor(marker: Marker) {
        super();

        this.setMarkerInfo(marker);
    }

    public activate = (groupId: string) => {
        for (const markerElementGroup of this){
            markerElementGroup._active = markerElementGroup._groupId === groupId;
        }
    }

    public new = (type: string, canvas?: ICanvasInformation) : MarkerElementGroup => {
        // Create new group
        const markerElementGroup = new MarkerElementGroup({
            _markerId: this._markerId,
            _markerDefaultLanguage: this._markerDefaultLanguage,
            _markerLanguages: this._markerLanguages,
            _markerSize: this._markerSize,
        });

        // New element
        markerElementGroup.new({
            Type: type,
            Index: this.length
        }, canvas);

        // Push to array
        this.push(markerElementGroup);

        return markerElementGroup;
    }

    public empty = () : void => {
        while(this.length > 0){
            this.pop();
        }
    }

    public clean = () : void => {
        // Remove unchanged elements
        let index = this.findIndex((markerElementGroup) => {
            return markerElementGroup._new && markerElementGroup._changed === 0;
        });
        while(index >= 0){
            this.splice(index, 1);
            index = this.findIndex((markerElementGroup) => markerElementGroup._new && markerElementGroup._changed === 0);
        }
    }

    public get = async (markerId: string) : Promise<MarkerElementList> => {
        // Empty array
        this.empty();

        // Get data
        const {data} = await getMarkerElements(markerId);

        // Convert to elements
        for (const markerElementData of data){
            // Find group
            const markerElementGroupIndex = this.findIndex((markerElementGroup) => markerElementGroup._groupId === markerElementData.GroupId);
            let markerElementGroup;
            if (markerElementGroupIndex >= 0){
                markerElementGroup = this[markerElementGroupIndex];
            } else {
                markerElementGroup = new MarkerElementGroup({
                    _markerId: this._markerId,
                    _markerDefaultLanguage: this._markerDefaultLanguage,
                    _markerLanguages: this._markerLanguages,
                    _markerSize: this._markerSize,
                    _groupId: markerElementData.GroupId,
                    _new: false
                });
            }

            // Create marker element
            const markerElement = new MarkerElement({
                ...markerElementData,
                _new: false
            });
            markerElement._new = false;
            markerElementGroup.set(markerElement, false);
            markerElementGroup._new = false;

            // Set marker size
            markerElementGroup.setMarkerSize(this._markerSize);

            // Push element
            this.push(markerElementGroup);
        }

        // Retrieved
        this._changed = 0;
        this._retrieved = true;

        // Return elements
        return this;
    }

    public set = (markerElement: MarkerElement) => {
        // Changes were made
        this.hasChanged();
        markerElement.hasChanged();

        // Find group index
        const index = this.findIndex((markerElementGroup) => markerElementGroup._groupId === markerElement.GroupId);
        if (index >= 0){
            // Set marker element
            this[index].set(markerElement);
        } else {
            // New marker element group
            const markerElementGroup = new MarkerElementGroup({
                _markerId: this._markerId,
                _markerLanguages: this._markerLanguages,
                _markerSize: this._markerSize,
                _groupId: markerElement.GroupId,
                _new: markerElement._new
            });

            // Push to list
            this.push(markerElementGroup);
        }
    }

    public delete = async (elementGroupIds?: string[]) : Promise<boolean> => {
        let result = true;
        if (!elementGroupIds){
            elementGroupIds = this.map((markerElementGroup) => markerElementGroup._groupId);
        }

        for (const groupId of elementGroupIds){
            const markerElementGroupIndex = this.findIndex((markerElementGroup) => markerElementGroup._groupId === groupId);
            const markerElementGroup = this[markerElementGroupIndex];

            // If new
            if (markerElementGroup){
                // Is deleted
                markerElementGroup._deleted = true;

                if (!markerElementGroup._new){
                    // Delete from FireStore
                    const deleted = await markerElementGroup.delete().catch(() => {
                        result = false;
                        markerElementGroup._deleted = false;
                    });

                    // If successfull
                    if (deleted){
                        // Delete from list
                        this.splice(markerElementGroupIndex, 1);
                    } else {
                        result = false;
                    }
                } else {
                    // Delete from list
                    this.splice(markerElementGroupIndex, 1);
                }
            }
        }

        return result;
    }

    public update = async (markerId: string) => {
        // Collect elements
        const markerElementGroups = this as MarkerElement[];

        // Update elements
        let result = true
        for (const markerElementGroup of markerElementGroups){
            await markerElementGroup.update(markerId).catch(() => {
                result = false;
            });
        }

        // Changed
        this._changed = 0;

        return result;
    }

    public getGroup = (groupId: string) => {
        return this.find((markerElementGroup) => markerElementGroup._groupId === groupId);
    }

    public getByType = (type: string) => {
        return this.filter((markerElementGroup) => markerElementGroup.getProperty("Type") === type);
    }

    public setMarkerInfo = (marker: Marker) => {
        this._markerId = marker.Id || "";
        this._markerDefaultLanguage = marker.DefaultLanguage || "EN";
        this._markerLanguages = marker.Languages || [];
        this._markerSize = {
            PhysicalSizeX: marker.PhysicalSizeX,
            PhysicalSizeY: marker.PhysicalSizeY,
            ImageSize: marker.ImageSize,
        };
    }

    public setCanvas = (canvas: ICanvasInformation) => {
        for (const markerElementGroup of this){
            markerElementGroup.setCanvasInformation(canvas);
        }
    }

    public setDefaultLanguage = (language: string) => {
        // Set language
        this._markerDefaultLanguage = language;
        for (const markerElementGroup of this){
            markerElementGroup.setDefaultLanguage(language);
        }
    }

    public addLanguage = (language: string) => {

        // Add language
        if (this._markerLanguages.indexOf(language) < 0){
            this._markerLanguages.push(language);
        } else {
            return;
        }

        for (const markerElementGroup of this){
            // Add language
            markerElementGroup.addLanguage(language);
        }
    }

    public removeLanguage = (language: string) => {
        // Remove language
        if (this._markerLanguages.indexOf(language) >= 0){
            this._markerLanguages.splice(this._markerLanguages.indexOf(language), 1);
        } else {
            return;
        }

        for (const markerElementGroup of this){
            // Add language
            markerElementGroup.removeLanguage(language);
        }
    }

    public removedFromCanvas = () => {
        this.forEach((markerElementGroup) => {
            markerElementGroup.hasBeenRemovedFromCanvas();
        });
    }

    public hasChanged = (value?: number) => {
        this._changed = value || Date.now();
    }
}

export class MarkerElementGroup implements IMarkerElementGroup {
    // Index signature
    [key: string]: any;

    public _canvasInformation: ICanvasInformation;
    public _deleted = false;
    public _groupId = createRandomToken();
    public _isFlat = false;
    public _markerId: string;
    public _markerDefaultLanguage: string;
    public _markerLanguages: string[];
    public _markerSize: {
        PhysicalSizeX: number;
        PhysicalSizeY: number;
        ImageSize: {
            X: number;
            Y: number;
        };
    }

    public _new = true;
    public _changed = 0;

    DE?: MarkerElement = new MarkerElement();
    EN?: MarkerElement = new MarkerElement();
    FR?: MarkerElement = new MarkerElement();
    NL?: MarkerElement = new MarkerElement();

    constructor(obj?: any){
        if (obj){
            Object.assign(this, obj);
        }
    }

    new(data: any, canvas?: ICanvasInformation){

        for (const language of this._markerLanguages){
            // Create new marker element
            this[language] = new MarkerElement({
                ...data,
                GroupId: this._groupId,
                Id: createRandomToken(),
                Language: language,
                Type: data.Type,
            }).toElementType();

            // Set marker size
            this[language].setMarkerSize(this._markerSize);

            // Set default values
            this[language].setDefaults();
        }

        // Set canvas
        if (canvas){
            this.setCanvasInformation(canvas);
        }

    }

    getByLanguage(language: string){
        return (this as any)[language];
    }

    getById(id: string){
        // Get entry
        const entry = Object.entries(this).find((entry) => entry[1].Id === id);

        // Return entry
        if (entry){
            return entry[1];
        }

        return undefined;
    }

    toList(){
        return this._markerLanguages.map((language) => this[language]);
    }

    set(markerElement: MarkerElement, changed = true){
        // Get Element type
        this[markerElement.Language].set({
            Type: markerElement.Type,
            _changed: changed ? Date.now() : 0,
        });
        this[markerElement.Language] = this[markerElement.Language].toElementType();

        // Set element
        this[markerElement.Language].set({
            ...markerElement,
            GroupId: this._groupId,
            _changed: changed ? Date.now() : 0,
        });
        this._changed = changed ? Date.now() : 0;

        // If not ready but element has content, reload spatial transformation
        if (!markerElement._canvas.ready && markerElement._hasContent){
             markerElement.setSpatialTransformation();
        }

        // TODO: Update other elements based on this data
    }

    apply(data: any){
        if (data.Scale){
            this.setScale(data.Scale, true, this._markerDefaultLanguage);
        }
        if (data.Position){
            this.setPosition(data.Position);
        }
        if (data.Rotation){
            this.setRotation(data.Rotation);
        }
    }

    getProperty(property: string, language?: string){
        return this[language || this._markerDefaultLanguage][property];
    }

    /**
     * Update the element group
     * @return {void} None
     */
    async update() : Promise<any[]> {
        if (this._deleted){
            return [];
        }

        // Update elements
        const promises = []
        for (const language of this._markerLanguages){
            // Update element
            console.log(language);
            promises.push(this[language].update(this._markerId));
        }

        // Element is no longer new
        this._new = false;

        return promises;
    }

    /**
     * Delete the element group
     * @return {void} None
     */
    async delete(){
        // Delete elements
        let success = true;
        for (const language of this._markerLanguages){
            // Delete element
            const result = await this[language].delete(this._markerId);

            if (result !== true){
                success = false;
            }
        }

        return success;
    }


    setDefaultLanguage(language: string){
        // Set default
        this._markerDefaultLanguage = language;
    }

    addLanguage(language: string){
        if (!this._markerLanguages.includes(language)){
            // Add to languages
            this._markerLanguages.push(language);
        }

        // Get copy of default
        const markerElementCopy = this[this._markerDefaultLanguage].copy();

        // Change language
        markerElementCopy.Language = language;

        // New ID
        markerElementCopy.Id = createRandomToken();

        // Set new element
        this[language] = markerElementCopy;
    }

    removeLanguage(language: string){
        // Remove language
        this._markerLanguages.splice(this._markerLanguages.indexOf(language), 1);

        // Delete element
        this[language].delete().then(() => {
            delete this[language];
        });
    }


    /**
     * Set marker size
     * @return {void} None
     */
    public setMarkerSize(
        marker: { PhysicalSizeX: number, PhysicalSizeY: number, ImageSize: { X: number,Y: number }}
    ) : void {
        // Set in group
        this._markerSize = {
            PhysicalSizeX: marker.PhysicalSizeX,
            PhysicalSizeY: marker.PhysicalSizeY,
            ImageSize: marker.ImageSize,
        };

        // Set to elements
        for (const language of this._markerLanguages){
            this[language].setMarkerSize(this._markerSize);
        }
    }

    setCanvasInformation(canvas: ICanvasInformation){
        // Set to elements
        this._canvasInformation = canvas;
        for (const language of this._markerLanguages){
            this[language].setCanvasSize(canvas);
            this[language].setCanvasPosition(canvas);
        }
    }

    setCanvasSize(canvas: ICanvasInformation, size?: {width: number, height: number}){
        this._canvasInformation = canvas;
        for (const language of this._markerLanguages){
            this[language].setCanvasSize(canvas, size);
        }
    }

    setCanvasPosition(canvas: ICanvasInformation, position?: {x: number, y: number}){
        this._canvasInformation = canvas;
        for (const language of this._markerLanguages){
            this[language].setCanvasPosition(canvas, position);
        }
    }

    async setSpatialTransformation(canvas?: ICanvasInformation){
        for (const language of this._markerLanguages){
            await this[language].setSpatialTransformation(canvas);
        }
        return true;
    }


    resetPosition(){
        for (const language of this._markerLanguages){
            this[language]._hasPosition = false;
        }
        this.setPosition();
    }
    setPosition(position?: { X: number, Y: number; Z: number; }, changed = true){
        for (const language of this._markerLanguages){
            this[language].setPosition(position, changed);
        }
    }

    resetRotation(){
        for (const language of this._markerLanguages){
            this[language]._hasRotation = false;
        }
        this.setRotation();
    }
    setRotation(rotation?: { W: number; X: number, Y: number; Z: number; }, changed = true){
        for (const language of this._markerLanguages){
            this[language].setRotation(rotation, changed);
        }
    }


    resetScale(){
        for (const language of this._markerLanguages){
            this[language]._hasScale = false;
        }
        this.setScale();
        this.setCanvasPosition(this._canvasInformation);
    }
    setScale(scale?: { X: number, Y: number; Z: number; }, changed = true, language?: string){
        for (const markerLanguage of this._markerLanguages){
            this[markerLanguage].setScale(scale, changed);
        }

        if (language && scale){
            // Set scale
            const {pixelWidth} = this[language]._canvas;

            // Calculate relative scale for other
            for (const markerLanguage of this._markerLanguages.filter((x) => x !== language)) {
                // Get multiplier
                const multiplier = pixelWidth/this[markerLanguage]._canvas.pixelWidth;

                // Set scale
                if (!isNaN(multiplier)){
                    this[markerLanguage].setScale({
                        X: scale.X * multiplier,
                        Y: scale.Y * multiplier,
                        Z: scale.Z * multiplier,
                    }, changed);
                }
            }
        }

    }

    resetAspectRatio(){
        // Set new scale
        const scale = this.getProperty("Scale");
        this.setScale({
            X: scale.X * 1,
            Y: scale.X * 1,
            Z: scale.X * 1
        }, true, this._markerDefaultLanguage);
        this.setCanvasPosition(this._canvasInformation);
    }

    hasBeenAddedToCanvas(language: string){
        // Other languages have been removed
        for (const language of this._markerLanguages){
            this[language].hasBeenRemovedFromCanvas();
        }

        // This language has been added
        this[language].hasBeenAddedToCanvas();
    }

    hasBeenRemovedFromCanvas(){
        for (const language of this._markerLanguages){
            this[language].hasBeenRemovedFromCanvas();
        }
    }

    async getSize(){
        for (const language of this._markerLanguages){
            await this[language].getSize();
        }
    }
}

export class MarkerElement implements IMarkerElement {
    _active = false;
    _new = true;
    _changed = 0;
    _canvas: {
        added: boolean,
        pixelWidth?: number,
        pixelHeight?: number,
        position?: {
            x: number,
            y: number,
        },
        ready: boolean,
        type: string,
    }
    _canvasInformation: ICanvasInformation;
    _hasContent = false;
    _hasPosition = false;
    _hasRotation = false;
    _hasScale = false;
    _hasSize = false;
    _isFlat = true; // TODO: Determine when an element is flat
    _markerSize: {
        PhysicalSizeX: number;
        PhysicalSizeY: number;
        ImageSize: {
            X: number;
            Y: number;
        };
    }


    Action?: IMarkerElementAction;
    Animation = {
        Delay: 0,
        Ease: "ease-in",
        OnScan: false,
        Speed: 1,
        Type: "fade-in",
    };
    Archived = false;
    ArchivedBy?: string;
    ArchivedDateTime?: Date;
    BaseType = "";
    ButtonStyle?: IMarkerElementButtonStyle;
    ContentType = "file";
    CreatedDateTime = new Date();
    CreatedBy?: string;
    CreatedByEmail?: string;
    Contact?: IMarkerElementContactInfo;
    DocumentUpdatedDateTime = new Date();
    DocumentUpdatedBy?: string;
    DocumentUpdatedByEmail?: string;
    Event?: IMarkerElementEventInfo;
    File?: string;
    FileExtension?: string;
    FileSize?: number;
    FileUrl?: string;
    GroupId: string = createRandomToken();
    Id = "new";
    Images: IMarkerElementImage[];
    ImageSize?: {
        X: number;
        Y: number;
    };
    ImageSizeX?: number; // Deprecated
    ImageSizeY?: number; // Deprecated
    Index = 0;
    Language = "EN";
    LibraryItemId?: string;
    LocaleSync = {
        Action: false,
        Animation: true,
        Content: false,
        Languages: ["EN"],
        Position: true,
        Rotation: true,
        Size: true,
    };
    MediaUpdatedBy?: string;
    MediaUpdatedDateTime = new Date();
    ModelAnimations?: {
        List: any[];
        OnClick: {
            Loop: boolean;
            Order: any[];
        };
        OnStart: {
            Loop: boolean;
            Order: any[];
        };
        Triggers: string[]
    };
    ObjectSize?: {
        X: number;
        Y: number;
        Z: number;
    };
    ObjectSizeX?: number; // Deprecated
    ObjectSizeY?: number; // Deprecated
    ObjectSizeZ?: number; // Deprecated
    OriginalFilename = "";
    Position: {
        X: number,
        Y: number,
        Z: number,
    };
    PositionX?: number; // Deprecated
    PositionY?: number; // Deprecated
    PositionZ?: number; // Deprecated
    Rotation: {
        W: number,
        X: number,
        Y: number,
        Z: number,
    };
    RotationW?: number; // Deprecated
    RotationX?: number; // Deprecated
    RotationY?: number; // Deprecated
    RotationZ?: number; // Deprecated
    Scale: {
        X: number,
        Y: number,
        Z: number,
    };
    ScaleX?: number; // Deprecated
    ScaleY?: number; // Deprecated
    ScaleZ?: number; // Deprecated
    Settings?: IMarkerElementSettings;
    StorageLocation?: string;
    Template?: IMarkerElementTemplate;
    ThumbnailPath?: string;
    ThumbnailUrl?: string;
    Type = "";
    VideoSize?: {
        X: number;
        Y: number;
    };
    VideoSizeX?: number; // Deprecated
    VideoSizeY?: number; // Deprecated
    UserInteraction?: {
        Position: string;
        Rotation: string;
        Scale: string;
    }
    YouTubeEmbedded?: string;
    YouTubeId?: string;
    YouTubeInfo?: any;

    constructor(obj?: any){
        if (obj){
            this.set(obj);
        }
    }

    /**
     * Set data
     * @return {MarkerElement} Instance
     */
    public set(data: any) : MarkerElement {
        // Convert dates
        Object.keys(data).forEach(key => {
            if (key.indexOf("DateTime") >= 0){
                data[key] = convertDate(data[key]);
            }
        });

        // Assign data
        Object.assign(this, data);

        // Set properties
        if (this.FileUrl || this.YouTubeId){
            this._hasContent = true;
        }

        // Determine if element has size
        this.hasSize();

        // Determine if element has position
        this._hasPosition = !!this.Position;

        // Determine if element has rotation
        this._hasRotation = !!this.Rotation;

        // Determine if element has scale
        this._hasScale = (!!data.Scale && data.Scale.X !== null && data.Scale.X !== 0);

        // Set canvas properties
        this._canvas = {
            added: false,
            ready: this._hasPosition && this._hasRotation && this._hasScale && this._hasContent,
            type: (this.Type === "VrVideo" || this.Type === "VrImage") ? "vr" : "ar"
        };

        if (this.Type === "Object3D"){
            this._isFlat = false;
        }

        // Return instance
        return this;
    }

    /**
     * Set marker size
     * @return {void} None
     */
    public setMarkerSize(
        marker: { PhysicalSizeX: number, PhysicalSizeY: number, ImageSize: { X: number,Y: number }}
    ) : void {
        this._markerSize = {
            PhysicalSizeX: marker.PhysicalSizeX,
            PhysicalSizeY: marker.PhysicalSizeY,
            ImageSize: marker.ImageSize,
        };
    }

    public async update(markerId: string){
        // Update or create
        const data = this.toJSON();

        let result;
        console.log(this.Language, this.Id, this._new, markerId);
        if (this._new){
            result = await createMarkerElement(markerId, data);
        } else {
            result = await updateMarkerElement(markerId, this.Id, data)
        }

        // Assign object
        Object.assign(this, result.data);

        // No changes
        this._changed = 0;
        this._new = false;
        this._canvas.added = false;

        return result;
    }

    /**
     * Delete marker element
     * @return {void} None
     */
    public async delete(markerId: string) : Promise<boolean | any> {
        // Get result
        const result = await deleteMarkerElement(markerId, this.Id);

        return (result as any).status === "success" ? true : result;
    }

    /**
     * Convert to JSON
     * @return {IMarkerElement} JSON data
     */
    public toJSON() : IMarkerElement {

        return filterUndefined({
            Action: this.Action,
            Animation: this.Animation,
            Archived: this.Archived || false,
            ArchivedBy: this.ArchivedBy,
            ArchivedDateTime: convertDate(this.ArchivedDateTime),
            BaseType: this.BaseType,
            ButtonStyle: this.ButtonStyle,
            Contact: this.Type === "Contact" ? this.Contact : undefined,
            ContentType: this.ContentType,
            CreatedBy: this.CreatedBy,
            CreatedDateTime: convertDate(this.CreatedDateTime),
            DocumentUpdatedBy: this.DocumentUpdatedBy,
            DocumentUpdatedDateTime: convertDate(this.DocumentUpdatedDateTime),
            Event: this.Type === "Event" ? this.Event : undefined,
            FileExtension: this.FileExtension,
            FileSize: this.FileSize,
            FileUrl: this.FileUrl,
            GroupId: this.GroupId,
            Id: this.Id,
            ImageSize: (() => {
                if (this.ImageSize?.X && this.ImageSize?.Y) {
                    return {
                        X: parseInt(this.ImageSize.X.toString()),
                        Y: parseInt(this.ImageSize.Y.toString()),
                    };
                } else {
                    return undefined;
                }
            })(),
            Images: this.Images,
            Index: this.Index,
            Language: this.Language,
            LibraryItemId: this.LibraryItemId,
            LocaleSync: this.LocaleSync,
            MediaUpdatedBy: this.MediaUpdatedBy,
            MediaUpdatedDateTime: convertDate(this.MediaUpdatedDateTime),
            ModelAnimations: this.ModelAnimations,
            ObjectSize: (() => {
                if (this.ObjectSize?.X && this.ObjectSize?.Y && this.ObjectSize?.Z) {
                    return {
                        X: parseFloat(this.ObjectSize.X.toString()),
                        Y: parseFloat(this.ObjectSize.Y.toString()),
                        Z: parseFloat(this.ObjectSize.Z.toString()),
                    };
                } else {
                    return undefined;
                }
            })(),
            OriginalFilename: this.OriginalFilename,
            Position: this.Position,
            Rotation: this.Rotation,
            Scale: this.Scale,
            Settings: this.Settings,
            StorageLocation: this.StorageLocation,
            Template: this.Template,
            ThumbnailPath: this.ThumbnailPath,
            ThumbnailUrl: this.ThumbnailUrl,
            Type: this.Type,
            VideoSize: (() => {
                if (this.VideoSize?.X && this.VideoSize?.Y) {
                    return {
                        X: parseInt(this.VideoSize.X.toString()),
                        Y: parseInt(this.VideoSize.Y.toString()),
                    };
                } else {
                    return undefined;
                }
            })(),
            UserInteraction: this.UserInteraction,
            YouTubeEmbedded: this.Type === "YouTubeVideo" ? this.YouTubeEmbedded : undefined,
            YouTubeId: this.Type === "YouTubeVideo" ? this.YouTubeId : undefined,
            YouTubeInfo: this.Type === "YouTubeVideo" ? this.YouTubeInfo : undefined,
        }) as IMarkerElement;
    }

    copy(){
        return new MarkerElement(this).toElementType();
    }

    toElementType() : MarkerElement {
        let element;
        switch (this.Type) {
            case "Audio":
                element = new AudioElement(this.toJSON());
                break;
            case "Button":
                element = new ButtonElement(this.toJSON());
                break;
            case "Carousel":
                element = new CarouselElement(this.toJSON());
                break;
            case "Contact":
                element = new ContactElement(this.toJSON());
                break;
            case "CustomButton":
                element = new CustomButtonElement(this.toJSON());
                break;
            case "Event":
                element = new EventElement(this.toJSON());
                break;
            case "Image":
                element = new ImageElement(this.toJSON());
                break;
            case "Object3D":
                element = new Object3DElement(this.toJSON());
                break;
            case "Video":
                element = new VideoElement(this.toJSON());
                break;
            case "YouTubeVideo":
                element = new YouTubeVideoElement(this.toJSON());
                break;
            case "VrImage":
                element = new VrImageElement(this.toJSON());
                break;
            case "VrVideo":
                element = new VrVideoElement(this.toJSON());
                break;
            default:
                element = new MarkerElement(this.toJSON());
                break;
        }

        // Copy data
        element.Position = this.Position;
        element.Rotation = this.Rotation;
        element.Scale = this.Scale;

        return element;
    }

    validateContent(){
        //Content is of file type, but there is no file
        if (this.ContentType !== "template" && !this.FileUrl){
            return false;
        }

        //Content is template, but no libraryItemId is provided
        if (this.ContentType === "template" && !this.LibraryItemId){
            return false;
        }

        return true;
    }

    validateAction(){
        return true;
    }

    validateContact(){
        return true;
    }

    hasChanged(){
        this._changed = Date.now();
    }

    hasSize(){
        // Determine if element has size
        if (
            this.BaseType === "image" && this.ImageSize?.X && this.ImageSize?.Y ||
            this.BaseType === "video" && this.VideoSize?.X && this.VideoSize?.Y ||
            this.BaseType === "3d" && !!this.ObjectSize?.X && !!this.ObjectSize?.Y && !!this.ObjectSize?.Z ||
            this.BaseType === "other"
        ){
            this._hasSize = true;
        } else {
            this._hasSize = false;
        }

        return this._hasSize
    }

    hasBeenAddedToCanvas(){
        this._canvas.added = true;
    }

    hasBeenRemovedFromCanvas(){
        if (!this._canvas){
            this._canvas = {
                added: false,
                ready: this._hasPosition && this._hasRotation && this._hasScale && this._hasContent,
                type: (this.Type === "VrVideo" || this.Type === "VrImage") ? "vr" : "ar"
            };
        } else {
            this._canvas.added = false;
        }
    }

    hasContent(){
        this._hasContent = true;

        // Check size
        if (
            this.BaseType === "audio" && this.VideoSize?.X && this.VideoSize?.Y ||
            this.BaseType === "image" && this.ImageSize?.X && this.ImageSize?.Y ||
            this.BaseType === "video" && this.VideoSize?.X && this.VideoSize?.Y ||
            this.BaseType === "3d" && this.ObjectSize?.X && this.ObjectSize?.Y && this.ObjectSize?.Z
        ){
            this._hasSize = true;
            this.setScale();
        }
    }

    isReadyForCanvas(){
        if (!this._canvas){
            this._canvas = {
                added: false,
                ready: (this._hasPosition && this._hasRotation && this._hasScale && this._hasContent),
                type: (this.Type === "VrVideo" || this.Type === "VrImage") ? "vr" : "ar"
            }
        } else {
            this._canvas.ready = (this._hasPosition && this._hasRotation && this._hasScale && this._hasContent);
        }

        return this._canvas.ready;
    }

    public async getSize() : Promise<void> {
        // Get size if not set
        if (this.FileUrl){
            switch (this.BaseType) {
                case "audio":
                    this.ImageSize = { X: 500, Y: 500 };
                    break;
                case "image":
                    this.ImageSize = await getImageSize(this.FileUrl);
                    break;
                case "video":
                    this.VideoSize = await getVideoSize(this.FileUrl);
                    break;
                case "3d":
                    this.ObjectSize = await getObjectSize(this.FileUrl);
                    break;
            }
        } else if (this.Type === "YouTubeVideo"){
            this.VideoSize = { X: this.YouTubeInfo.width, Y: this.YouTubeInfo.height }
        } else {
            throw Error("No file url specified");
        }
    }


    /**
     * Reload element
     * @return {MarkerElement} Instance
     */
    public async setSpatialTransformation(canvas?: ICanvasInformation) : Promise<MarkerElement> {
        // Get size if not set
        if (!this.hasSize()){
            await this.getSize();
        }

        // Set canvas information
        if (canvas){
            this._canvasInformation = canvas;
        }

        // Load object coordinates
        this.setPosition();
        this.setRotation();
        this.setScale();

        // Set canvas variables
        this._canvas = {
            added: false,
            ready: this._hasPosition && this._hasRotation && this._hasScale && this._hasContent,
            type: (this.Type === "VrVideo" || this.Type === "VrImage") ? "vr" : "ar"
        };

        // Return instance
        return this;
    }

    setPosition(position?: { X: number, Y: number; Z: number; }, changed = true){
        // Update position
        if (position){
            this.Position = position;
            this._hasPosition = true;

            // Set canvas position
            if (this._canvasInformation){
                this.setCanvasPosition();
            }

            // Changes were made
            if (changed){
                this.hasChanged();
            }
        }

        // Fallback position
        if (!this._hasPosition){
            if (this.BaseType === "3d"){
                this.Position = {
                    X: 0,
                    Y: 0,
                    Z: 0
                }
            } else {
                this.Position = {
                    X: 0,
                    Y: this.Index * 0.0001,
                    Z: 0
                }
            }

            // Position is set
            this._hasPosition = true;

            // Changes made
            this.hasChanged();

            // Set canvas position
            if (this._canvasInformation){
                this.setCanvasPosition();
            }
        }
    }

    setRotation(rotation?: { W: number; X: number, Y: number; Z: number; }, changed = true){
        // Update position
        if (rotation){
            this.Rotation = rotation;
            this._hasRotation = true;

            // Changes were made
            if (changed){
                this.hasChanged();
            }
        }

        // Set position
        if (!this._hasRotation){
            if (this.BaseType === "3d"){
                this.Rotation = {
                    W: 0,
                    X: 0,
                    Y: 0,
                    Z: 0
                }
            } else {
                this.Rotation = {
                    W: 0,
                    X: -90,
                    Y: 0,
                    Z: 0
                }
            }

            // We have rotation
            this._hasRotation = true;

            // Changes made
            this.hasChanged();
        }
    }

    setScale(
        scale?: { X: number, Y: number; Z: number; },
        changed = true
    ){
        if (scale) {
            this.Scale = scale;
            this._hasScale = true;

            // Set canvas size
            if (this._canvasInformation){
                this.setCanvasSize();
            }

            // Changes were made
            if (changed){
                this.hasChanged();
            }
        }

        // Set scale
        if (!this._hasScale){
            this.Scale = {X: 1, Y: 1, Z: 1};
            const {marker} = this._canvasInformation;
            if (this.BaseType === "3d"){
                //Set 3D equal to half of the marker
                const size = Math.max(this.ObjectSize?.X || 0, this.ObjectSize?.Y || 0, this.ObjectSize?.Z || 0);
                const physicalSize = Math.min(marker.physicalWidth, marker.physicalHeight);
                this.Scale.X = physicalSize/size * (0.5)/1.15;
                this.Scale.Y = physicalSize/size * (0.5)/1.15;
                this.Scale.Z = physicalSize/size * (0.5)/1.15;
            }
            else if (this.BaseType === "video") {
                this.Scale.X = marker.physicalWidth  * ( (this.VideoSize?.X || 0 ) / marker.imageSizeX ) * (100 / (this.VideoSize?.X || 0));
                this.Scale.Y = marker.physicalHeight * ( ( this.VideoSize?.Y || 0 ) / marker.imageSizeY ) * (100 / (this.VideoSize?.Y || 0));

                if (this.Type === "YouTubeVideo"){
                    this.Scale.X = this.Scale.X * 2.5;
                    this.Scale.Y = this.Scale.Y * 2.5;
                }
            }
            else if (this.BaseType === "image"){
                this.Scale.X = marker.physicalWidth * (( this.ImageSize?.X || 0 ) / marker.imageSizeX ) * (100 / (this.ImageSize?.X || 0));
                this.Scale.Y = marker.physicalHeight * (( this.ImageSize?.Y || 0 ) / marker.imageSizeY) * (100 / (this.ImageSize?.Y || 0));
            }
            else if (this.BaseType === "audio"){
                this.Scale.X = marker.physicalWidth * (100 / marker.imageSizeX );
                this.Scale.Y = marker.physicalHeight * (100 / marker.imageSizeY);
            }
            else {
                // Equal to halve of the marker
                this.Scale.X = marker.physicalWidth  * .5;
                this.Scale.Y = marker.physicalHeight * .5;
            }

            // Now we have scale
            this._hasScale = true;

            // Set canvas size
            if (this._canvasInformation){
                this.setCanvasSize();
            }

            // Changes made
            this.hasChanged();
        }
    }

    setCanvasSize(canvas?: ICanvasInformation, size?: {width: number, height: number}){
        // Store/retrieve canvas information
        if (canvas){
            this._canvasInformation = canvas;
        } else {
            canvas = this._canvasInformation;
        }

        if (size){
            this._canvas.pixelWidth = size.width;
            this._canvas.pixelHeight = size.height;
        } else {
            if (this.BaseType === "3d"){
                this._canvas.pixelWidth = 1.15*(this.Scale?.X || 0)*canvas.marker.pixelHeight*((this.ObjectSize?.X || 0)/canvas.marker.physicalWidth);
                this._canvas.pixelHeight = 1.15*(this.Scale?.Z || 0)*canvas.marker.pixelHeight*((this.ObjectSize?.Z || 0)/canvas.marker.physicalHeight);
            } else if (this.BaseType === "video" && this._canvas.type !== "vr"){
                this._canvas.pixelWidth = canvas.marker.pixelWidth*((this.Scale?.X || 0)/canvas.marker.physicalWidth)/(100/(this.VideoSize?.X || 0));
                this._canvas.pixelHeight = canvas.marker.pixelHeight*((this.Scale?.Y || 0)/canvas.marker.physicalHeight)/(100/(this.VideoSize?.Y || 0));
            } else if (this.BaseType === "image" && this._canvas.type !== "vr") {
                this._canvas.pixelWidth = canvas.marker.pixelWidth*((this.Scale?.X || 0)/canvas.marker.physicalWidth)/(100/(this.ImageSize?.X || 0));
                this._canvas.pixelHeight = canvas.marker.pixelHeight*((this.Scale?.Y || 0)/canvas.marker.physicalHeight)/(100/(this.ImageSize?.Y || 0));
            } else if (this.BaseType === "audio") {
                this._canvas.pixelWidth = canvas.marker.pixelWidth*((this.Scale?.X || 0)/canvas.marker.physicalWidth)/(100/(this.ImageSize?.X || 1000));
                this._canvas.pixelHeight = canvas.marker.pixelHeight*((this.Scale?.Y || 0)/canvas.marker.physicalHeight)/(100/(this.ImageSize?.Y || 1000));
            } else {
                this._canvas.pixelWidth = 100;
                this._canvas.pixelHeight = 100;
            }
        }
    }

    setCanvasPosition(canvas?: ICanvasInformation, position?: {x: number, y: number}){
        // Store/retrieve canvas information
        if (canvas){
            this._canvasInformation = canvas;
        } else {
            canvas = this._canvasInformation;
        }

        // Set canvas position
        if (!this._canvas.pixelWidth || !this._canvas.pixelHeight){
            this.setCanvasSize(canvas);
        }

        if (!this.Position){
            this.setPosition();
        }

        if (this._canvas.pixelWidth && this._canvas.pixelHeight){
            if (position){
                this._canvas.position = position;
            } else {
                this._canvas.position = {
                    x: canvas.marker.pixelWidth * (this.Position.X / canvas.marker.physicalWidth) - ( this._canvas.pixelWidth / 2),
                    y: canvas.marker.pixelHeight * (this.Position.Z / canvas.marker.physicalHeight) - ( this._canvas.pixelHeight / 2)
                }
            }
        }
    }
}

export class ArElement extends MarkerElement {
    _canvas = {
        added: false,
        ready: false,
        type: "ar"
    }

    Canvas = {
        Type: "AR"
    };
}

export class AudioElement extends ArElement {
    BaseType = "audio";
    ButtonStyle: {
        BackgroundColor: {
            R: number;
            G: number;
            B: number;
            A: number;
        };
        BorderColor: {
            R: number;
            G: number;
            B: number;
            A: number;
        };
        IconColor: {
            R: number;
            G: number;
            B: number;
            A: number;
        };
    };
    ContentType = "file";
    Settings: IMarkerElementSettings = {
        AfterRecognition: "autostart",
        AfterFinished: "loop",
        CameraLeavesMarker: "stop",
        PlayDelayed: 0,
        Hidden: false,
        FixToScreen: false
    };
    Type = "Audio";

    setDefaults(){
        this.BaseType = "audio";
        this.ButtonStyle = {
            BackgroundColor: {
                R: 255,
                G: 255,
                B: 255,
                A: 1,
            },
            BorderColor: {
                R: 0,
                G: 0,
                B: 0,
                A: 1,
            },
            IconColor: {
                R: 0,
                G: 0,
                B: 0,
                A: 1
            },
        };
        this.ContentType = "file";
        this.Settings = {
            AfterRecognition: "autostart",
            AfterFinished: "loop",
            CameraLeavesMarker: "stop",
            PlayDelayed: 0,
            Hidden: false,
            FixToScreen: false
        }
        this.Type = "Audio";
    }
}

export class ButtonElement extends ArElement {
    Action = {
        Animation: {
            ElementGroupId: null,
            Loop: false,
            Order: []
        },
        Application: null,
        Email: null,
        InAppBrowser: false,
        Phone: null,
        Type: null,
        Url: null
    };
    BaseType = "image";
    ContentType = "file";
    Type = "Button";

    setDefaults(){
        this.BaseType = "image";
        this.ContentType = "file";
        this.Type = "Button";
    }

    validateAction(){
        if (!this.Action.Type){
            return false;
        } else {
            if (this.Action.Type === "application" && !this.Action.Application){
                return false
            } else if (this.Action.Type === "email" && !this.Action.Email){
                return false
            } else if ((this.Action.Type === "phone" || this.Action.Type === "sms") && !this.Action.Phone){
                return false
            } else if (this.Action.Type === "url" && !this.Action.Url){
                return false
            }
        }

        return true;
    }
}

export class CarouselElement extends ArElement {
    BaseType = "image";
    ContentType = "file";
    Images: IMarkerElementImage[];
    Type = "Carousel";

    setDefaults(){
        this.BaseType = "image";
        this.ContentType = "file";
        this.Images = [];
        this.Type = "Carousel";
    }

    validateContent(): boolean {
        //Carousel without images
        if (this.Images.length === 0){
            return false;
        }

        return true;
    }
}

export class ContactElement extends ArElement {
    BaseType = "image";
    ContentType = "file";
    Contact : IMarkerElementContactInfo = {
        AddressLine: "",
        BirthDay: "01/01/2022",
        City: "",
        CompanyName: "",
        Country: "",
        Email: "",
        FirstName: "",
        Infix: "",
        LastName: "",
        Phone: "",
        PostalCode: "",
        Prefix: "",
        Role: "",
        Sex: "",
        Url: "",
    };
    Type = "Contact";

    setDefaults(){
        this.BaseType = "image";
        this.ContentType = "file";
        this.Contact = {};
        this.Type = "Contact";
    }

    validateContact(){
        return !!this.Action?.Contact?.LastName;
    }
}

export class CustomButtonElement extends ArElement {
    Action = {
        Animation: {
            ElementGroupId: null,
            Loop: false,
            Order: []
        },
        Application: null,
        Email: null,
        InAppBrowser: false,
        Phone: null,
        Type: null,
        Url: null
    };
    BaseType = "image";
    ContentType = "template";
    Type = "CustomButton";

    setDefaults(){
        this.BaseType = "image";
        this.ContentType = "template";
        this.Type = "CustomButton";
    }

    validateAction(){
        if (!this.Action.Type){
            return false;
        } else {
            if (this.Action.Type === "application" && !this.Action.Application){
                return false
            } else if (this.Action.Type === "email" && !this.Action.Email){
                return false
            } else if ((this.Action.Type === "phone" || this.Action.Type === "sms") && !this.Action.Phone){
                return false
            } else if (this.Action.Type === "url" && !this.Action.Url){
                return false
            }
        }

        return true;
    }
}

export class EventElement extends ArElement {
    BaseType = "image";
    ContentType = 'template';
    Event : IMarkerElementEventInfo;
    Type = "Event";

    setDefaults(){
        this.BaseType = "image";
        this.ContentType = "template";
        this.Event = {
            TimeZoneOffset: new Date().getTimezoneOffset()
        };
        this.Type = "Event";
    }
}

export class ImageElement extends ArElement {
    BaseType = "image";
    ContentType = "file";
    Type = "Image";

    setDefaults(){
        this.BaseType = "image";
        this.ContentType = "file";
        this.Type = "Image";
    }
}

export class Object3DElement extends ArElement {
    BaseType = "3d";
    ContentType = "file";
    ModelAnimations = {
        List: [],
        OnClick: {
            Loop: false,
            Order: [],
        },
        OnStart: {
            Loop: false,
            Order: [],
        },
        Triggers: [],
    }
    Type = "Object3D";
    UserInteraction = {
        Position: "movable",
        Rotation: "rotatable",
        Scale: "scalable"
    }

    setDefaults(){
        this.BaseType = "3d";
        this.ContentType = "file";
        this.Type = "Object3D";
        this.UserInteraction = {
            Position: "movable",
            Rotation: "rotatable",
            Scale: "scalable"
        }
    }

}

export class VideoElement extends ArElement {
    BaseType = "video";
    ContentType = "file";
    Type = "Video";
    Settings = {
        AfterRecognition: "autostart",
        AfterVideoFinished: "loop",
        AlphaColor: "rgba(0,177,64,1)",
        AlphaColorDelta: 0.3,
        CameraLeavesMarker: "stop",
        IsAlpha: false,
        Rotation: 0
    }

    setDefaults(){
        this.BaseType = "video";
        this.ContentType = "file";
        this.Settings = {
            AfterRecognition: "autostart",
            AfterVideoFinished: "loop",
            AlphaColor: "rgba(0,177,64,1)",
            AlphaColorDelta: 0.3,
            CameraLeavesMarker: "stop",
            IsAlpha: false,
            Rotation: 0
        }
        this.Type = "Video";
    }
}

export class YouTubeVideoElement extends ArElement {
    BaseType = "video";
    ContentType = "external";
    Settings = {
        AfterRecognition: "autostart",
        AfterVideoFinished: "loop",
        AlphaColor: "rgba(0,177,64,1)",
        AlphaColorDelta: 0.3,
        CameraLeavesMarker: "stop",
        IsAlpha: false,
        Rotation: 0
    }
    Type = "YouTubeVideo";
    YouTubeInfo: {
        embedded: string;
        id: string;
        info?: unknown;
        input?: string;
        requesting?: boolean;
        url: string;
        width: number;
        height: number;
    };
    YouTubeId = "";
    YouTubeEmbedded = "";

    setDefaults(){
        this.BaseType = "video";
        this.ContentType = "external";
        this.Settings = {
            AfterRecognition: "autostart",
            AfterVideoFinished: "loop",
            AlphaColor: "rgba(0,177,64,1)",
            AlphaColorDelta: 0.3,
            CameraLeavesMarker: "stop",
            IsAlpha: false,
            Rotation: 0
        }
        this.Type = "YouTubeVideo";
    }
}


export class VrElement extends MarkerElement {
    _canvas = {
        added: false,
        ready: false,
        type: "vr",
    }

    Canvas = {
        Type: "VR"
    }
}

export class VrImageElement extends VrElement {
    BaseType = "image";
    ContentType = "file";
    Type = "VrImage";

    setDefaults(){
        this.BaseType = "image";
        this.ContentType = "file";
        this.Type = "VrImage";
    }
}

export class VrVideoElement extends VrElement {
    BaseType = "video";
    ContentType = "file";
    Type = "VrVideo";

    setDefaults(){
        this.BaseType = "video";
        this.ContentType = "file";
        this.Type = "VrVideo";
    }
}

