/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'chevron_left': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>'
  }
})
