/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'keyboard_double_arrow_right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.41 6L5 7.41 9.58 12 5 16.59 6.41 18l6-6z"/><path pid="1" d="M13 6l-1.41 1.41L16.17 12l-4.58 4.59L13 18l6-6z"/>'
  }
})
