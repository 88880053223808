import {VuexModule, Module, Action, getModule} from 'vuex-module-decorators'
import store from '@/store'
import {Customer, CustomerList} from "@/data/classes/customer"
import {UserModule} from "@/store/modules/user";

export interface ICustomerModuleState {
  active: number;
  list: CustomerList;
}


@Module({ dynamic: true, store, name: 'customer' })
class CustomerModuleClass extends VuexModule implements ICustomerModuleState {
  public active = 0;
  public list : CustomerList = new CustomerList()


  @Action
  public async GetCustomers() : Promise<CustomerList> {
    // Get customer list
    await this.list.get(UserModule.license);

    // Return list
    return this.list;
  }

  @Action
  public async GetCustomer(customerId: string) : Promise<Customer> {
    // Return new customer
    let customer = new Customer({ _parentLicenseId: UserModule.license });
    if (!customerId || customerId === 'new') {
      // Add customer to list
      this.list.push(customer);

      // Return customer
      return customer;
    }

    // Get locally
    customer = this.list.find((c) => c.Id === customerId);

    // Get externally if undefined
    if (!customer){
      customer = new Customer();
      await customer.get(customerId, UserModule.license)
    }

    // Return customer
    return customer;
  }

  @Action
  public async SaveCustomer(customer: Customer) : Promise<Customer> {
    // Update customer
    await customer.update();

    // Reinsert
    if (customer.Id){
      const index = this.list.findIndex(c => c.Id === customer.Id);
      if (index >= 0){
        this.list[index] = customer;
      } else {
        this.list.push(customer);
      }
    }

    // Return customer
    return customer;
  }


}

export const CustomerModule = getModule(CustomerModuleClass)
