/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'element_event': {
    width: 960,
    height: 1024,
    viewBox: '0 0 960 1024',
    data: '<path pid="0" d="M319.999 384.003h128.008v128.008H319.999V384.003zm192.011 0h128.008v128.008H512.01V384.003zm191.991 0h128.008v128.008H704.001V384.003zM128.008 768.005h128.008v128.008H128.008V768.005zm191.991 0h128.008v128.008H319.999V768.005zm192.011 0h128.008v128.008H512.01V768.005zM319.999 575.993h128.008v128.008H319.999V575.993zm192.011 0h128.008v128.008H512.01V575.993zm191.991 0h128.008v128.008H704.001V575.993zm-575.993 0h128.008v128.008H128.008V575.993zM832.009 0v64.004H704.001V0H255.994v64.004H128.007V0H-.001v1024h959.996V0H832.008zm64.004 959.996H64.004V255.995h832.009v704.001z"/>'
  }
})
