
import { Component, Vue } from 'vue-property-decorator'
import {sendEvent} from "@/firebase/config";
import {Marker} from "@/data/classes/marker";


@Component({
  name: 'TestMarkerDialog',
  components: {},
  mixins: []
})
export default class TestMarkerDialog extends Vue {

  private visible  = false;

  private marker: Marker = new Marker();

  get appUrl(){
    return window.origin + "/#/app?app=veewar&prospectId=" + window.UserScoring.hashedEmail;
  }


  created(){
    this.setEventListeners();
  }

  // METHODS
  public close(){
    // Hide
    this.visible = false;

    // Analytics
    window.GlobalEvents.$emit("test-marker-dialog-closed");
    sendEvent("test_marker_modal_closed");
  }
  public async open(marker: Marker){
    // Set marker
    this.marker = marker;

    // Open intro-form
    this.visible = true;

    // Analytics
    window.GlobalEvents.$emit("test-marker-dialog-opened");
    sendEvent("test_marker_modal_opened");

    // Close assistance
    window.GlobalEvents.$emit("close-assistance-dialog");
  }

  private setEventListeners(){
    window.GlobalEvents.$on("open-test-marker-dialog", this.open);
  }
}
