/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'delete_all': {
    width: 16,
    height: 16,
    viewBox: '0 0 89.94 90',
    data: '<path pid="0" d="M0 68.66h68.66V0H0zm54.45-18.45l-4.24 4.24-15.88-15.88-15.88 15.88-4.24-4.24 15.88-15.88-15.88-15.88 4.24-4.24 15.88 15.88 15.88-15.88 4.24 4.24-15.88 15.88 15.88 15.88zm25.21-36.15h-6v59.66H14v6h65.66V14.06zm4.28 10.28V84H24.27v6h65.67V24.34z" fill-rule="evenodd"/>'
  }
})
