// @ts-ignore
import {v4 as createUniqueId} from "uuid";
import {UserModule} from "@/store/modules/user";
import {DatabaseModule} from "@/store/modules/database";

export const convertDates = (data : Record<string, unknown>) : unknown => {
  return {
    ...data,
    CreatedDateTime: convertDate(data.CreatedDateTime),
    DocumentUpdatedDateTime: convertDate(data.DocumentUpdatedDateTime),
  };
};

export const convertDate = (date : unknown) : Date | undefined => {
  if (date instanceof Date) {
    return date;
  } else if (date instanceof Timestamp) {
    return date.toDate();
  } else if (typeof date === "string") {
    return new Date(date.toString());
  } else if (date){
    if ((date as {_seconds: number})._seconds) {
      return new Date((date as {_seconds: number})._seconds * 1000);
    } else if ((date as {seconds: number}).seconds) {
      return new Date((date as {seconds: number}).seconds * 1000);
    }
  }
  return undefined;
};

export const createRandomToken = () : string => {
  return createUniqueId();
};

export const replaceRouteVariables = (path: string) : string => {
  if (path){
    const parts = path.split("/");
    for (const index in parts){
      if (parts[index].indexOf(":") === 0){
        parts[index] = router.currentRoute.params[parts[index].substring(1, parts[index].length)];
      }
    }
    return parts.join("/");
  }
  return path;
}

export const dataUrlToFile = async (dataUrl: string, filename: string) : Promise<File> => {
  const arr = dataUrl.split(',');
  if (arr && arr[0]){
    const mime = arr[0].match(/:(.*?);/);

    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }

    if (mime){
      return new File([u8arr], filename, {type:mime[0]});
    }
  }

  throw Error("File could not be created");
}

export const toRGBA = (obj: {R?: number, r?: number, G?:number, g?: number, B?:number, b?: number, A?: number, a?: number}): string => {
  if (!obj){
    return "rgba(0,0,0,1)";
  }

  return "rgba("+(obj.R || obj.r || 0)+","+(obj.G || obj.g || 0)+","+(obj.B || obj.b || 0)+","+(obj.A || obj.a || 1)+")";
}

export const rgbaToObject = (string: any) : {R: number, G: number, B: number, A: number} => {
  if (string instanceof Object){
    const obj = string as any;
    return {
      R: parseInt(obj.R || obj.r || 0),
      G: parseInt(obj.G || obj.g || 0),
      B: parseInt(obj.B || obj.b || 0),
      A: parseFloat(obj.A || obj.a || 0)
    }
  } else if (string.indexOf("rgba") >= 0) {
    const rgba = string.substr(5, string.length - 6).split(",");
    return {
      R: parseInt(rgba[0]),
      G: parseInt(rgba[1]),
      B: parseInt(rgba[2]),
      A: parseFloat(rgba[3])
    }
  } else {
    const rgb = string.substr(4, string.length - 5).split(",");
    return {
      R: parseInt(rgb[0]),
      G: parseInt(rgb[1]),
      B: parseInt(rgb[2]),
      A: 1
    }
  }
}

export const getLocalDataUrl = async (url: string) : Promise<string | false> => {
  if (url.indexOf("data:") >= 0 && url.indexOf("base64") >= 0){
    return url;
  }

  try {
    let path = url.split("?").shift();
    if (path){
      if (path.indexOf("appspot.com/o") >= 0){
        path = path.split("appspot.com/o").pop();
      } else if (path.indexOf("appspot.com") >= 0){
        path = path.split("appspot.com").pop();
      }
      if (!path){
        return false;
      }

      // Replace spaces
      while (path.indexOf("%2F") >= 0 || path.indexOf("%20") >= 0 || path.indexOf("//") >= 0){
        path = path.replace("%2F", "/");
        path = path.replace("%20", "/");
        path = path.replace("//", "/");
      }

      // Get object
      const object = await DatabaseModule.Get(path).catch((error: any) => {
        throw Error(error)
      }) as any;

      // Return data url or get path
      if (object){
        if (object.dataUrl){
          return object.dataUrl;
        }
      } else if (path){
        // Download
        await DatabaseModule.Download({filepath: path}).catch((error: any) => {
          throw Error(error)
        });

        // Get object
        const object = await DatabaseModule.Get(path).catch((error: any) => {
          throw Error(error)
        }) as any;
        if (object){
          if (object.dataUrl){
            return object.dataUrl;
          }
        }
      }
    }
  }
  catch (e) {
    console.warn(e);
  }

  return false;
}

export const getVideoSize = async (url: string): Promise<{ X: number, Y: number }> => {
  return new Promise(resolve => {
    const video = document.createElement('video');
    video.addEventListener( "loadedmetadata", () => {
      resolve({
        X: video.videoWidth,
        Y: video.videoHeight
      });
    }, false);
    video.src = url;
  });
}

export const getImageSize = async (url: string): Promise<{ X: number, Y: number }> => {
  return new Promise(resolve => {
    const img = new Image();
    img.onload = () => {
      resolve({
        X: img.width,
        Y: img.height,
      });
    }
    img.src = url;
  });
}

import * as THREE from "three"
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import {GLTF} from "three/examples/jsm/loaders/GLTFLoader";
import html2canvas from "html2canvas";
import i18n from "@/lang";
import firebase from "firebase/compat";
import Timestamp = firebase.firestore.Timestamp;
import router from "@/router";
export const getObjectSize = async (url: string): Promise<{ X: number, Y: number, Z: number } | undefined> => {
  return new Promise(resolve => {
    const loader = new GLTFLoader();
    loader.load( url,
        ( object: GLTF ) => {
          // Get bounding box
          const box = new THREE.Box3().setFromObject( object.scene );
          const sizeVector = new THREE.Vector3();
          box.getSize(sizeVector);

          // Return size
          resolve({
            X: sizeVector.x,
            Y: sizeVector.y,
            Z: sizeVector.z
          });
        },
        () => {},
        () => {
          resolve(undefined);
        }
    );

  });
}
export const getModelAnimations = async (url?: string): Promise<any> => {
  if (!url){
    return [];
  }
  
  return new Promise(resolve => {
    const loader = new GLTFLoader();
    loader.load( url,
        ( object: GLTF ) => {
          // Return size
          resolve(object.animations.map((animation) => {
            return {
              name: animation.name,
              duration: animation.duration,
              uuid: animation.uuid
            }
          }));
        },
        () => {},
        () => {
          resolve(undefined);
        }
    );

  });
}

export const getAudioImage = async (buttonStyle?: IMarkerElementButtonStyle) => {
  // Get variables
  const backgroundColor = buttonStyle ? toRGBA(buttonStyle.BackgroundColor) : "#fff";
  const borderColor = buttonStyle ? toRGBA(buttonStyle.BorderColor) : "#000";
  const iconColor = buttonStyle ? toRGBA(buttonStyle.IconColor) : "#000";

  // Build HTML
  const html = '<div' +
      '        style="background-color: ' + borderColor + '; width: 500px; height: 500px; border-radius: 100%;"' +
      '    >' +
      '      <div style="background-color: ' + backgroundColor + '; position: absolute; width: 400px; height:400px; left: 50%; top: 50%; transform: translateX(-50%) translateY(-50%); border-radius: 100%;">' +
      '        <div style="border-color: transparent transparent transparent ' + iconColor + '; border-style: solid; position: absolute; width: 150px; height:200px; left: 50%; top: 50%; transform: translateX(-40%) translateY(-50%); box-sizing: border-box; border-width: 100px 0 100px 150px;">' +
      '        </div>' +
      '      </div>' +
      '    </div>'

  // Create element
  const div = document.createElement('div');
  div.innerHTML = html;
  div.style.width = "500px";
  div.style.height = "500px";
  div.style.position = "relative";
  document.body.appendChild(div);

  return await html2canvas(div, {
    useCORS: true,
    backgroundColor: null,
    scale: 1
  }).then((canvas: HTMLCanvasElement) => {
    return canvas.toDataURL("image/png");
  }).finally(() => {
    document.body.removeChild(div);
  });
}

export const getTemplateImage = async (template: IMarkerElementTemplate, data: any, language: string): Promise<string | null> => {
  // Create html
  const html = (() => {
    if (!template.Processed){
      return null;
    }

    let html = JSON.parse(JSON.stringify(template.Html));

    //Replace variables
    template.Variables.forEach((variable: IMarkerElementTemplateVariable) => {
      if (variable.Binding !== "none" && variable.Binding !== undefined){
        //Get value
        let value = getValue(data, variable.Binding);

        //Process value
        if (variable.Binding.indexOf("Date") >= 0){
          value = convertDate(value)?.toLocaleDateString();
        }

        //Replace value
        html = html.replace("{{" + variable.Key + "}}", value);
      } else if (variable.International && variable.Locale[language]){
        //Replace value
        html = html.replace("{{" + variable.Key + "}}", variable.Locale[language]);
      } else {
        //Replace value
        html = html.replace("{{" + variable.Key + "}}", i18n.t(variable.Value));
      }
    });


    //Replace style variables
    template.Style.forEach((style: any, index: number) => {
      if (style){
        //Find tag original
        const tag = template.Tags[index].OriginalContent;
        if (!tag){
          return;
        }

        //Find style attr
        const styleAttr = (tag.match(new RegExp('\\bstyle(?:=(["\'])[^"]*\\1)?', 'gms')) as RegExpMatchArray)[0];

        //Create new style attr
        let newStyleAttr = 'style="';
        Object.keys(style).forEach(key => {
          newStyleAttr += key + ': ' + style[key].Value + (style[key].Type === 'size' ? "px" : "") + '; ';
        });
        newStyleAttr += '"';

        //New tag
        const newTag = tag.replace(styleAttr, newStyleAttr).replace(";;",";");

        //Replace tag
        html = html.replace(tag, newTag);
      }
    });

    return html;
  })();

  // Create iframe
  const iframe = document.createElement('iframe');
  iframe.style.width = '500px';
  iframe.style.height = '500px';
  document.body.appendChild(iframe);

  //Write html to iframe
  const doc = iframe.contentWindow?.document;

  if (doc){
    doc.open();
    doc.write('<link rel="stylesheet" href="https://storage.googleapis.com/dev-xrpresenter.appspot.com/assets/fonts/fonts.css" type="text/css" media="all" /><div style="position: absolute; top:50%; left: 50%; transform: translateY(-50%) translateX(-50%); padding: 0; margin: 0;" id="wrapper">' + html + '</div>');
    doc.close();

    // Resize the inner wrapper
    const margin = 50;
    const wrapper = doc.getElementById("wrapper");
    if (wrapper){
      const child = wrapper.children[0] as HTMLElement;
      wrapper.style.height = child.style.height + child.style.borderTopWidth + child.style.borderBottomWidth + child.style.marginTop + child.style.marginBottom + child.style.paddingTop + child.style.paddingBottom;
      wrapper.style.width = child.style.width + child.style.borderLeftWidth + child.style.borderRightWidth + child.style.marginLeft + child.style.marginRight + child.style.paddingLeft + child.style.paddingRight;
      wrapper.style.zoom = Math.min(iframe.clientWidth/(wrapper.clientWidth + margin),iframe.clientHeight/(wrapper.clientHeight + margin)).toString();

      // Create image
      return html2canvas(wrapper, {
        useCORS: true,
        backgroundColor: null,
        scale: 2
      }).then((canvas: HTMLCanvasElement) => {
        const myImage = canvas.toDataURL("image/png");
        // let downloadLink = document.createElement("a");
        // downloadLink.href = myImage as string;
        // downloadLink.download = "template_" + new Date().toISOString() + ".png";
        // downloadLink.click();

        //Return data
        return Promise.resolve(myImage);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    }
  }

  return null;
}

// eslint-disable-next-line
export const filterUndefined = (data : any) : any => {
  if (!data){
    return {};
  }

  if (data instanceof Object) {
    Object.keys(data).forEach((key) => {
      if (data[key] === undefined) {
        delete data[key];
      } else if (data[key] instanceof Object) {
        data[key] = filterUndefined(data[key]);
      }
    });
  }

  return data;
};

export const flattenObject = (ob: any) => {
  const toReturn = {} as any;

  for (const i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if ((typeof ob[i]) == 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}

export const openTutorial = (tutorialId: string) => {
  window.GlobalEvents.$emit("close-intro-dialog", false);
  window.GlobalEvents.$emit("open-tutorial", tutorialId);
}

export const startIntroVideo = () => {
  window.GlobalEvents.$emit("close-intro-dialog", false);
  window.GlobalEvents.$emit("open-video-dialog", "intro");
}

export const startTour = (tourId: string) => {
  window.GlobalEvents.$emit("close-intro-dialog");
  window.GlobalEvents.$emit("start-tour", tourId);
}

export const setLocale = (locale: string) => {
  //Safe
  if (locale.toUpperCase().includes("NL")){
    locale = "nl-NL";
  } else if (locale.toUpperCase().includes("EN")){
    locale = "en-US";
  } else if (locale.toUpperCase().includes("DE")){
    locale = "de-DE";
  } else if (locale.toUpperCase().includes("FR")){
    locale = "fr-FR";
  }

  //Locale
  i18n.locale = locale;

  //Set locally
  localStorage.setItem('localePreference', locale);

  //Emit event
  window.GlobalEvents.$emit("locale-changed");
}

export const setValue = (obj: any, path: string, value: any) => {
  const a = path.split('.')
  let o = obj
  while (a.length - 1) {
    const n = a.shift()
    if (n){
      if (!o) o = {}
      if (!(n in o)) o[n] = {}
      o = o[n]
    }
  }

  if (!o) o = {}

  o[a[0]] = value

  return o;
}

export const getValue = (obj: any, path: string) => {
  path = path?.replace(/\[(\w+)\]/g, '.$1')
  path = path?.replace(/^\./, '')
  if (!path){
    return;
  }

  const a = path.split('.')
  let o = obj
  while (a.length) {
    const n = a.shift()
    if (n){
      if (!o) return;
      if (!(n in o)) return;
      o = o[n];
    }
  }
  return o
}

export const fileToDataUrl = async (file: File | Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      if (event.target?.result){
        return resolve(event.target?.result.toString());
      } else {
        return reject(false);
      }
    }
    reader.onerror = (event) => {
      return reject(event);
    }
  })
}


export const goToFireStore = ({type, licenseId, markerId, markerElementId, resellerId, bugReportId, customerId, libraryItemId, libraryItemType, productId, prospectId, tutorialId, tutorialSectionId, userId, couponId}: any): void => {
  //Set title
  const project = window.project;

  //Go to
  if (type === "marker"){
    window.open("https://console.firebase.google.com/u/0/project/"+project+"/firestore/data/~2Flicenses~2F"+ (licenseId || UserModule.license) +"~2Fmarkers~2F" + markerId, "_blank");
  } else if (type === "markerElement"){
    window.open("https://console.firebase.google.com/u/0/project/"+project+"/firestore/data/~2Flicenses~2F"+(licenseId || UserModule.license)+"~2Fmarkers~2F"+ markerId+"~2Felements~2F"+ markerElementId, "_blank");
  } else if (type === "license"){
    window.open("https://console.firebase.google.com/u/0/project/"+project+"/firestore/data/~2Flicenses~2F"+(licenseId || UserModule.license), "_blank");
  } else if (type === "user"){
    window.open("https://console.firebase.google.com/u/0/project/"+project+"/firestore/data/~2Fusers~2F"+ userId, "_blank");
  } else if (type === "product"){
    window.open("https://console.firebase.google.com/u/0/project/"+project+"/firestore/data/~2Fproducts~2F"+ productId, "_blank");
  } else if (type === "reseller"){
    window.open("https://console.firebase.google.com/u/0/project/"+project+"/firestore/data/~2Fresellers~2F"+ resellerId, "_blank");
  } else if (type === "customer"){
    window.open("https://console.firebase.google.com/u/0/project/"+project+"/firestore/data/~2Flicenses~2F"+(licenseId || UserModule.license)+"~2Fcustomers~2F"+customerId, "_blank");
  } else if (type === "bug-report"){
    window.open("https://console.firebase.google.com/u/0/project/"+project+"/firestore/data/~2Fbug_reports~2F"+ bugReportId, "_blank");
  } else if (type === "library-item"){
    window.open("https://console.firebase.google.com/u/0/project/"+project+"/firestore/data/~2Flibrary~2F" + libraryItemType + "~2Flibrary_items~2F"+libraryItemId, "_blank");
  } else if (type === "tutorial"){
    window.open("https://console.firebase.google.com/u/0/project/"+project+"/firestore/data/~2Ftutorials~2F" + tutorialId, "_blank");
  } else if (type === "tutorial-section"){
    window.open("https://console.firebase.google.com/u/0/project/"+project+"/firestore/data/~2Ftutorials~2F" + tutorialId + "~2Fsections~2F" + tutorialSectionId, "_blank");
  } else if (type === "prospect"){
    window.open("https://console.firebase.google.com/u/0/project/"+project+"/firestore/data/~2Fprospects~2F" + prospectId, "_blank");
  } else if (type === "coupon"){
    window.open("https://console.firebase.google.com/u/0/project/"+project+"/firestore/data/~2Fcoupons~2F" + couponId, "_blank");
  }
}

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}
export function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text);
}

export const sha256 : any = (ascii: any) => {
  function rightRotate(value: any, amount: any) {
    return (value>>>amount) | (value<<(32 - amount));
  }

  const mathPow = Math.pow;
  const maxWord = mathPow(2, 32);
  const lengthProperty = 'length'
  let i, j; // Used as a counter across the whole file
  let result = ''

  const words: any = [];
  const asciiBitLength = ascii[lengthProperty]*8;

  //* caching results is optional - remove/add slash from front of this line to toggle
  // Initial hash value: first 32 bits of the fractional parts of the square roots of the first 8 primes
  // (we actually calculate the first 64, but extra values are just ignored)
  let hash = sha256.h = sha256.h || [];
  // Round constants: first 32 bits of the fractional parts of the cube roots of the first 64 primes
  const k = sha256.k = sha256.k || [];
  let primeCounter = k[lengthProperty];
  /*/
  var hash = [], k = [];
  var primeCounter = 0;
  //*/

  const isComposite: any = {};
  for (let candidate = 2; primeCounter < 64; candidate++) {
    if (!isComposite[candidate]) {
      for (i = 0; i < 313; i += candidate) {
        isComposite[i] = candidate;
      }
      hash[primeCounter] = (mathPow(candidate, .5)*maxWord)|0;
      k[primeCounter++] = (mathPow(candidate, 1/3)*maxWord)|0;
    }
  }

  ascii += '\x80' // Append Ƈ' bit (plus zero padding)
  while (ascii[lengthProperty]%64 - 56) ascii += '\x00' // More zero padding
  for (i = 0; i < ascii[lengthProperty]; i++) {
    j = ascii.charCodeAt(i);
    if (j>>8) return; // ASCII check: only accept characters in range 0-255
    words[i>>2] |= j << ((3 - i)%4)*8;
  }
  words[words[lengthProperty]] = ((asciiBitLength/maxWord)|0);
  words[words[lengthProperty]] = (asciiBitLength)

  // process each chunk
  for (j = 0; j < words[lengthProperty];) {
    const w = words.slice(j, j += 16); // The message is expanded into 64 words as part of the iteration
    const oldHash = hash;
    // This is now the undefinedworking hash", often labelled as variables a...g
    // (we have to truncate as well, otherwise extra entries at the end accumulate
    hash = hash.slice(0, 8);

    for (i = 0; i < 64; i++) {
      // Expand the message into 64 words
      // Used below if
      const w15 = w[i - 15], w2 = w[i - 2];

      // Iterate
      const a = hash[0], e = hash[4];
      const temp1 = hash[7]
          + (rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) // S1
          + ((e&hash[5])^((~e)&hash[6])) // ch
          + k[i]
          // Expand the message schedule if needed
          + (w[i] = (i < 16) ? w[i] : (
                  w[i - 16]
                  + (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15>>>3)) // s0
                  + w[i - 7]
                  + (rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2>>>10)) // s1
              )|0
          );
      // This is only used once, so *could* be moved below, but it only saves 4 bytes and makes things unreadble
      const temp2 = (rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) // S0
          + ((a&hash[1])^(a&hash[2])^(hash[1]&hash[2])); // maj

      hash = [(temp1 + temp2)|0].concat(hash); // We don't bother trimming off the extra ones, they're harmless as long as we're truncating when we do the slice()
      hash[4] = (hash[4] + temp1)|0;
    }

    for (i = 0; i < 8; i++) {
      hash[i] = (hash[i] + oldHash[i])|0;
    }
  }

  for (i = 0; i < 8; i++) {
    for (j = 3; j + 1; j--) {
      const b = (hash[i]>>(j*8))&255;
      result += ((b < 16) ? 0 : '') + b.toString(16);
    }
  }
  return result;
};
