/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'bookmark_remove': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 7h-6V5h6v2zm-2 3.9A5.002 5.002 0 0114 3H7c-1.1 0-2 .9-2 2v16l7-3 7 3V10.9z"/>'
  }
})
