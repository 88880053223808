
import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import { PermissionModule } from '@/store/modules/permission'
import { SettingsModule } from '@/store/modules/settings'
import SidebarItem from './SidebarItem.vue'
import SidebarLogo from './SidebarLogo.vue'
import variables from '@/styles/_variables.scss'
import Hamburger from '@/components/Hamburger/index.vue'
import { UserModule } from '@/store/modules/user'
import {logout} from "@/data/api/users";

@Component({
  name: 'SideBar',
  components: {
    SidebarItem,
    SidebarLogo,
    Hamburger
  }
})
export default class extends Vue {
  get sidebar() {
    return AppModule.sidebar
  }

  get routes() {
    return PermissionModule.routes
  }

  get showLogo() {
    return SettingsModule.showSidebarLogo
  }

  get showBuyButton(){
    return !window.location.href.includes('veeew') && (UserModule.roles.includes("trial") && !UserModule.roles.includes("user") || UserModule.user.Email === "demo-licentie@veewar.com");
  }

  get menuActiveTextColor() {
    if (SettingsModule.sidebarTextTheme) {
      return SettingsModule.theme
    } else {
      return variables.menuActiveText
    }
  }

  get variables() {
    return variables
  }

  get activeMenu() {
    const route = this.$route
    const { meta, path } = route
    // if set path, the sidebar will highlight the path you set
    if (meta.activeMenu) {
      return meta.activeMenu
    }
    return path
  }

  get isCollapse() {
    return !this.sidebar.opened
  }

  private toggleSideBar() {
    window.GlobalEvents.$emit("window-resize-start");
    AppModule.ToggleSideBar(false)
    setTimeout(() => {
      window.GlobalEvents.$emit("window-resize-end");
    }, 500);
  }

  private async goToCatalogus() {
    // Log out
    await logout('/catalogus');

    // To catalogus
    return this.$router.push('/catalogus');
  }
}
