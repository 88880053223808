
import {Component, Prop, Watch, Vue} from 'vue-property-decorator'

import * as THREE from "three"
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import {GLTF, GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {Light, Object3D} from "three";
import {createRandomToken, getLocalDataUrl} from "@/globalFunctions";
import EmptyState from "@/components/EmptyState/index.vue";
import {MessageBox} from "element-ui";


// TODO: Add environments
@Component({
  name: 'ObjectViewer',
  components: {
    EmptyState
  },
  mixins: []
})
export default class Canvas3D extends Vue {
  @Prop({default: true}) private autoRotate: boolean;
  @Prop({default: false}) private enableWireframe: boolean;
  @Prop({default: false}) private plain: boolean;
  @Prop({default: true}) private advancedSettings: boolean;
  @Prop({default: true}) private transparent: boolean;
  @Prop({default: "free"}) private view: string;
  @Prop({default: ""}) private url!: string;

  // Variables
  private animations: any[] = [];
  private animationPlaying = "";
  private animationFrameRequest: number | null = null;
  private camera: THREE.PerspectiveCamera;
  private clip: THREE.AnimationAction;
  private clock: THREE.Clock;
  private controls: OrbitControls;
  private error = false;
  private instanceId = 'object-' + createRandomToken();
  private lights: any[];
  private loading = true;
  private mixer: THREE.AnimationMixer;
  private object: any;
  private progress = 0;
  private renderer: THREE.WebGLRenderer;
  private scene: THREE.Scene;
  private thumbnails: any[] = [];
  private viewSettings = {
    backgroundColor: "rgb(238, 238, 238)",
    environment: null,
    capturingImage: false,
    level: "top",
    rotate: true,
    ratio: "full",
    wireframe: false,
  }

  // Computed
  get enableButtons(){
    return !this.plain;
  }

  // Watchers
  @Watch("viewSettings.backgroundColor")
  onBackgroundColorChanged(rgb: string | null){
    if (rgb){
      this.transparent = false;
    } else {
      this.transparent = true;
    }
    this.setBackgroundColor(rgb || "rgb(238,238,238)");
  }

  // Methods
  private addLights(){
    // Get bounding box
    const boundingBox = new THREE.Box3();
    boundingBox.setFromObject(this.object);
    const size = new THREE.Vector3();
    boundingBox.getSize(size);

    // Collect lights
    let lights: (THREE.PointLight | THREE.HemisphereLight)[] = [];
    lights[ 0 ] = new THREE.PointLight( 0xffffff, 1, 0 );
    lights[ 1 ] = new THREE.PointLight( 0xffffff, 1, 0 );
    lights[ 2 ] = new THREE.PointLight( 0xffffff, 1, 0 );
    lights[ 3 ] = new THREE.PointLight( 0xffffff, 1, 0 );


    let dist = 5
    lights[ 0 ].position.set( dist * size.x,  dist * size.z, dist * size.z );
    lights[ 1 ].position.set( -dist * size.x,  dist * size.x, dist * size.z );
    lights[ 2 ].position.set( dist * size.x,  0, -dist * size.z);
    lights[ 3 ].position.set( -dist * size.x,  0, -dist * size.z);

    //Hemisphere
    lights.push(new THREE.HemisphereLight( 0xffffbb, 0x080820, .1 ));

    // Add lights to scene
    this.lights = lights;
    this.lights.forEach(light => {
      this.scene.add(light);
    });
  }

  addWireframe(object: Object3D){
    object.traverse((node: any) => {
      if (!node.isMesh) return;
      node.material.wireframe = true;
    });
  }

  removeWireframe(object: Object3D){
    object.traverse((node: any) => {
      if (!node.isMesh) return;
      node.material.wireframe = false;
    });
  }

  private animate() {
    // Perform animation frame request
    this.animationFrameRequest = window.requestAnimationFrame(this.animate);

    // Start rendering
    this.renderer.render(this.scene, this.camera);

    // Update controls
    if (this.controls){
      this.controls.update();
    }

    // Animation
    if (this.mixer){
      this.mixer.update(this.clock.getDelta());
    } else if (this.animations.length > 0){
      // Instantiate mixer
      const object = this.scene.getObjectByName("Object3D");
      if (object){
        this.clock = new THREE.Clock();
        this.mixer = new THREE.AnimationMixer(object);
        this.clip = this.mixer.clipAction(this.animations[0]);
      }
    }
  }

  private applySetting(setting: string) {
    switch (setting) {
      case "enableWireframe":
        this.addWireframe(this.scene.getObjectByName("Object3D") || new Object3D());
        this.viewSettings.wireframe = true;
        break;
      case "disableWireframe":
        this.removeWireframe(this.scene.getObjectByName("Object3D") || new Object3D());
        this.viewSettings.wireframe = false;
        break;
      case "setBackground":
        // Open color picker
        (document.querySelector("#color-picker .el-color-picker__trigger") as HTMLElement).click();
        break;
      case "enableRotate":
        this.controls.autoRotate = true;
        this.viewSettings.rotate = true;
        break;
      case "disableRotate":
        this.controls.autoRotate = false;
        this.viewSettings.rotate = false;
        break;
      case "createThumbnails":
        MessageBox({
          title: this.$t('confirmations.create-thumbnails.title').toString(),
          message: this.$t('confirmations.create-thumbnails.text').toString(),
          showCancelButton: true,
          confirmButtonClass: "el-button--warning",
          cancelButtonClass: "el-button--info",
          confirmButtonText: this.$t('confirmations.create-thumbnails.confirmation-button').toString(),
          cancelButtonText: this.$t('confirmations.create-thumbnails.cancel-button').toString(),
        }).then(() => {
          this.createThumbnails(true);
        }).catch(() => {
          return false;
        });
        break;
      case "toggleHeightLevel":
        if (this.viewSettings.level === "top"){
          this.viewSettings.level = "bottom";
        } else if (this.viewSettings.level === "level"){
          this.viewSettings.level = "top";
        } else if (this.viewSettings.level === "bottom"){
          this.viewSettings.level = "level";
        }

        // Refit
        this.fitCamera(this.viewSettings.level, this.viewSettings.rotate);
        break;
      case "toggleRatio":
        if (this.viewSettings.ratio === "full"){
          this.viewSettings.ratio = "1:1";
        } else if (this.viewSettings.ratio === "1:1"){
          this.viewSettings.ratio = "4:3";
        } else if (this.viewSettings.ratio === "4:3"){
          this.viewSettings.ratio = "16:9";
        } else if (this.viewSettings.ratio === "16:9"){
          this.viewSettings.ratio = "full";
        }

        // Loading
        this.loading = true;

        // Hide object
        const object = this.scene.getObjectByName("Object3D");
        if (object){
          object.visible = false;
        }

        // Reload
        setTimeout(() => {
          this.load();
        }, 100)
        break;
    }
  }

  private async beforeLoad(){
    // Reset
    this.reset();

    // Get viewport
    const viewport = this.$refs.viewport as Element;
    if (!viewport){
      return new Promise(resolve => {
        setTimeout(() => {
          this.beforeLoad().then(resolve);
        }, 500);
      })
    }


    // Get container
    const container = this.$refs.container as HTMLElement;
    if (!container){
      return new Promise(resolve => {
        setTimeout(() => {
          this.beforeLoad().then(resolve);
        }, 500);
      })
    }

    // Set width and height
    container.style.width = viewport.clientWidth + "px";
    container.style.height = viewport.clientHeight + "px";

    //Init renderer
    this.renderer = new THREE.WebGLRenderer( { alpha: true, preserveDrawingBuffer:true });
    this.renderer.setSize(container.clientWidth, container.clientHeight);
    this.renderer.setClearColor( 0xffffff, 0);

    //Create scene
    this.scene = new THREE.Scene();
    this.setBackgroundColor(this.viewSettings.backgroundColor);

    //Add camera
    this.camera = new THREE.PerspectiveCamera(
        45,
        container.clientWidth / container.clientHeight,
        0.1,
        10000
    )


    //Build canvas
    this.renderer.domElement.classList.remove("visible");
    container.innerHTML = "";
    container.appendChild(this.renderer.domElement)

    //Start animating
    this.animate();
  }

  private async captureImage(notify = true, type = "default-view", download = false){
    // Start
    this.viewSettings.capturingImage = true;

    // Prevent double capturing
    await window.timeout(1000);

    // Get image
    const dataUrl = await this.createImage().catch(() => {
      if (notify){
        this.$notify({
          title: this.$t('notifications.object-viewer-captured-image.error.title').toString(),
          message: this.$t('notifications.object-viewer-captured-image.error.message').toString(),
          type: 'error'
        });
      }
    });

    // Emit
    this.$emit("captured-image", dataUrl);
    window.GlobalEvents.$emit("object-viewer-captured-image", {dataUrl, type});

    // Download automatically
    if (download){
      let downloadLink = document.createElement("a");
      downloadLink.href = dataUrl as string;
      downloadLink.download = type + ".png";
      downloadLink.click();
    }

    // Save locally
    this.thumbnails.push({
      type: type,
      dataUrl: dataUrl
    });

    // Notify
    if (notify){
      this.$notify({
        title: this.$t('notifications.object-viewer-captured-image.success.title').toString(),
        message: this.$t('notifications.object-viewer-captured-image.success.message').toString(),
        type: 'success',
        onClick: () => {
          let downloadLink = document.createElement("a");
          downloadLink.href = dataUrl as string;
          downloadLink.download = type + ".png";
          downloadLink.click();
        }
      });
    }

    // Done
    this.viewSettings.capturingImage = false;
  }

  private async createImage(){
    return new Promise((resolve, reject) => {
      try {
        resolve(this.renderer.domElement.toDataURL("image/png"));
      } catch (e) {
        reject(e);
      }
    });
  }

  private async createThumbnails(download = false){
    // Disable rotation
    this.fitCamera("top", false);

    // Get object
    const object = this.scene.getObjectByName("Object3D");
    if (object){
      for (const level of ["top", "level", "bottom"]){
        // Front view
        object.rotation.set(0, 0, 0);
        this.fitCamera(level, false);
        await this.captureImage(false, level + "-front-view.png", download);

        // Back view
        object.rotation.set(0, Math.PI, 0);
        this.fitCamera(level, false);
        await this.captureImage(false, level + "-back-view.png", download);

        // Left view
        object.rotation.set(0, Math.PI / 2, 0);
        this.fitCamera(level, false);
        await this.captureImage(false, level + "-left-view.png", download);

        // Left front view
        object.rotation.set(0, Math.PI / 4, 0);
        this.fitCamera(level, false);
        await this.captureImage(false, level + "-left-front-view.png", download);

        // Right view
        object.rotation.set(0, -1 * Math.PI / 2, 0);
        this.fitCamera(level, false);
        await this.captureImage(false, level + "-right-view.png", download);

        // Right view
        object.rotation.set(0, -1 * Math.PI / 4, 0);
        this.fitCamera(level, false);
        await this.captureImage(false, level + "-right-front-view.png", download);
      }

      // To top
      this.fitCamera("top", false);

      // Top view
      object.rotation.set(Math.PI / 2, 0, 0);
      this.fitCamera("level", false);
      await this.captureImage(false, "top-view.png", download);

      // Bottom view
      object.rotation.set(-1 * Math.PI / 2, 0, 0);
      this.fitCamera("level", false);
      await this.captureImage(false, "bottom-view.png", download);

      //Reset
      object.rotation.set(0, 0, 0);
    }


    // Restore
    this.fitCamera(this.viewSettings.level, this.viewSettings.rotate);

    // Get thumbnails
    window.GlobalEvents.$emit("object-viewer-thumbnails-created", this.thumbnails)
    return this.thumbnails;
  }

  private async createThumbnail(type = "front-view"){
    // Disable rotation
    this.fitCamera("top", false);

    // Get object
    const object = this.scene.getObjectByName("Object3D");
    if (object){
      switch (type) {
        case "front-view":
          // Front view
          object.rotation.set(0, 0, 0);
          this.fitCamera("level", false);
          await this.captureImage(false, "front-view");
          break;
        case "rear-view":
          object.rotation.set(0, Math.PI, 0);
          this.fitCamera("level", false);
          await this.captureImage(false, "rear-view");
          break;
        case "cross-view":
          object.rotation.set(0, Math.PI / 4, 0);
          this.fitCamera("top", false);
          await this.captureImage(false, "cross-view");
          break;
        default:
          object.rotation.set(0, 0, 0);
          this.fitCamera("top", false);
          await this.captureImage(false, "front-view");
      }

      //Reset
      object.rotation.set(0, 0, 0);
    }


    // Restore
    this.fitCamera(this.viewSettings.level, this.viewSettings.rotate);

    // Get thumbnails
    window.GlobalEvents.$emit("object-viewer-thumbnails-created", this.thumbnails)
    return this.thumbnails;
  }

  private fitCamera(position = "top", autoRotate = true){
    // Get bounding box
    const object = this.scene.getObjectByName("Object3D")
    if (!object){
      throw Error("Object not found");
    }

    // Get size
    const boundingBox = new THREE.Box3().setFromObject(object)
    const size = new THREE.Vector3();
    boundingBox.getSize(size);

    // Set camera at a distance
    let new_width = Math.max(size.x,size.y,size.z)*1.5;
    let new_height = new_width/this.camera.aspect;
    let vFOV = THREE.MathUtils.degToRad( this.camera.fov ); // convert vertical fov to radians
    let new_dist = new_height/(2 * Math.tan( vFOV / 2 ));
    this.camera.position.set(0, new_dist, new_dist);

    // get the max side of the bounding box (fits to width OR height as needed )
    const maxDim = Math.max(size.x, size.y, size.z);
    let dist = maxDim / (2 * Math.tan(this.camera.fov * Math.PI / 360));
    const positionY = position === "top" ? 1.5 * size.y : (position === "bottom" ? -1.5 * size.y : 0);
    this.camera.position.set(0, positionY, dist * 1.5); // fudge factor so you can see the boundaries

    //Get center
    const center = new THREE.Vector3();
    boundingBox.getCenter(center)

    // Create controls
    if (this.view === "free"){
      this.controls = new OrbitControls( this.camera, this.renderer.domElement );
      this.controls.target = center;
      if (this.autoRotate && autoRotate){
        this.controls.autoRotate = true;
        this.controls.autoRotateSpeed = 2;
      }
    }
  }

  private async initializeObject(url: string){
    // Get scene
    const {scene, animations} = await this.loadObject(url);

    // Save animations
    this.animations = animations;

    // Set object
    this.object = scene;

    // Set data
    this.object.name = "Object3D";

    //Remove lights from object
    this.removeLights(this.object);

    //Add to scene
    this.scene.add(this.object);

    // Fit camera
    if (this.view === "top"){
      this.object.rotation.set(Math.PI / 2, 0, 0);
      this.fitCamera("level", false);
    } else {
      this.fitCamera();
    }

    // Add lights
    this.addLights();
  }

  private isWebGLAvailable(){
    try {
      const canvas = document.createElement( 'canvas' );
      return !! ( window.WebGLRenderingContext && ( canvas.getContext( 'webgl' ) || canvas.getContext( 'experimental-webgl' ) ) );
    } catch ( e ) {
      return false;
    }
  }

  private reload(){
    // Reset settings
    this.viewSettings = {
      backgroundColor: "rgb(238, 238, 238)",
      environment: null,
      capturingImage: false,
      level: "top",
      rotate: true,
      ratio: "full",
      wireframe: false,
    }

    // Load
    this.load();
  }

  private async load(){
    // Start loading
    this.loading = true;

    // Initiate
    await this.beforeLoad();

    // Add object
    await this.initializeObject(this.url);

    // Stop loading
    this.loading = false;
    this.$emit("load");

    // Show canvas
    this.showCanvas();
  }

  showCanvas(){
    // Get container
    const container = this.$refs.container as Element;
    const canvas = container.querySelector("canvas");
    if (canvas){
      this.renderer.domElement.classList.add("visible");
    }
  }

  setBackgroundColor(rgb: string){
    const {r, g, b} = (() => {
      if (rgb !== null){
        const matches = rgb.match(/\d+/g);
        if (matches){
          let [r,g,b] = matches;
          return {r,g,b}
        }
      }

      // Default
      return {r: 238, g: 238, b: 238};
    })();

    //Set background color
    let color = new THREE.Color();
    color.setRGB(parseFloat(r.toString())/255, parseFloat(g.toString())/255, parseFloat(b.toString())/255);
    if (this.transparent){
      this.scene.background = null;
    } else {
      this.scene.background = color;
    }
  }

  private getLocalDataUrl = getLocalDataUrl;

  private async loadObject(url: string) : Promise<{ scene: any; animations: any[]; }> {
    let loader = new GLTFLoader();

    // Get local url
    const localUrl = await this.getLocalDataUrl(url);

    //Load
    return new Promise((resolve, reject) => {
      loader.load( localUrl ? localUrl : url,
          ( object: GLTF ) => {
            resolve(object);
          },
          async (e: ProgressEvent) => {
            this.progress = e.loaded/e.total;
          },
          ( error: ErrorEvent ) => {
            console.error(error);

            // We have an error
            this.error = true;

            // Emit
            this.$emit("error", error);

            // Reject
            reject(error);
          }
      );
    });
  }

  private playAnimation(uuid: string){
    // Stop current clip
    this.clip.stop();

    // Stop playing
    if (this.animationPlaying === uuid){
      this.animationPlaying = "";
      return;
    }

    // Get index
    const index = this.animations.findIndex((x) => x.uuid === uuid);
    if (index >= 0){
      // Select new clip
      this.clip = this.mixer.clipAction(this.animations[index]);

      // Play new clip
      this.clip.play();

      // Playing animation
      this.animationPlaying = uuid;
    }
  }

  private reset(){
    // Get container
    const container = this.$refs.container as Element;

    // Remove canvas
    if (container){
      container.innerHTML = "";
    }
  }

  private removeLights(parentObject: Object3D | Light){
    if (parentObject.children){
      if (parentObject.children.length > 0){
        parentObject.children.forEach((childObject: Object3D | Light, index: number) => {
          //Remove lights
          if ("intensity" in childObject){
            parentObject.children.splice(index, 1);
          }

          //Iterate
          this.removeLights(childObject);
        });
      }
    }
  }

  private setEventListeners(){
    // Resize
    window.GlobalEvents.$on('window-resize-start', this.onWindowResizeStart);
    window.GlobalEvents.$on('window-resize-end', this.onWindowResizeEnd);
    window.GlobalEvents.$on('object-viewer-create-thumbnails', this.createThumbnails);
    window.GlobalEvents.$on('object-viewer-create-thumbnail', this.createThumbnail);
  }

  private onWindowResizeStart(){
    // Start loading
    this.loading = true;

    // Remove canvas
    this.reset();
  }

  private onWindowResizeEnd(){
    // Reload canvas
    this.load();
  }

  mounted(){
    if ( this.isWebGLAvailable() ) {
      // Set event listeners
      this.setEventListeners();

      // Load
      this.load();
    } else {
      // Notify user
      this.$notify({
        title: this.$t('notifications.web-gl-not-available.title').toString(),
        message: this.$t('notifications.web-gl-not-available.message').toString(),
        type: 'error'
      });
    }
  }

  beforeDestroy(){
    window.GlobalEvents.$off('window-resize-start');
    window.GlobalEvents.$off('window-resize-end');
    window.GlobalEvents.$off('object-viewer-create-thumbnails');
    window.GlobalEvents.$off('object-viewer-create-thumbnail');
  }
}
