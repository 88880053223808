
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'SidebarLogo'
})
export default class extends Vue {
  @Prop({ required: true }) private collapse!: boolean

  get iconUrl() {
    return (this as any).$provider?.Platform?.Images?.Icon?.Url || (this as any).$provider?.Platform?.Images?.IconUrl;
  }
}
