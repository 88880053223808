/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'output': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17 17l5-5-5-5-1.41 1.41L18.17 11H9v2h9.17l-2.58 2.59z"/><path pid="1" d="M19 19H5V5h14v2h2V5a2 2 0 00-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14a2 2 0 002-2v-2h-2v2z"/>'
  }
})
