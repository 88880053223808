import Vue from 'vue'
import VueI18n from 'vue-i18n'

import { getLanguage } from '@/utils/cookies'


// User defined lang
import deLocale from './de-DE.json'
import enLocale from './en-US.json'
import frLocale from './fr-FR.json'
import nlLocale from './nl-NL.json'

Vue.use(VueI18n)

const messages = {
  de: {
    ...deLocale,
    el: {
      colorpicker: {
        confirm: 'OK',
        clear: 'Leeren'
      },
      datepicker: {
        now: 'Jetzt',
        today: 'Heute',
        cancel: 'Abbrechen',
        clear: 'Leeren',
        confirm: 'OK',
        selectDate: 'Datum wählen',
        selectTime: 'Uhrzeit wählen',
        startDate: 'Startdatum',
        startTime: 'Startzeit',
        endDate: 'Enddatum',
        endTime: 'Endzeit',
        prevYear: 'Letztes Jahr',
        nextYear: 'Nächtes Jahr',
        prevMonth: 'Letzter Monat',
        nextMonth: 'Nächster Monat',
        day: 'Tag',
        week: 'Woche',
        month: 'Monat',
        year: '',
        month1: 'Januar',
        month2: 'Februar',
        month3: 'März',
        month4: 'April',
        month5: 'Mai',
        month6: 'Juni',
        month7: 'Juli',
        month8: 'August',
        month9: 'September',
        month10: 'Oktober',
        month11: 'November',
        month12: 'Dezember',
        weeks: {
          sun: 'So',
          mon: 'Mo',
          tue: 'Di',
          wed: 'Mi',
          thu: 'Do',
          fri: 'Fr',
          sat: 'Sa'
        },
        months: {
          jan: 'Jan',
          feb: 'Feb',
          mar: 'Mär',
          apr: 'Apr',
          may: 'Mai',
          jun: 'Jun',
          jul: 'Jul',
          aug: 'Aug',
          sep: 'Sep',
          oct: 'Okt',
          nov: 'Nov',
          dec: 'Dez'
        }
      },
      select: {
        loading: 'Lädt.',
        noMatch: 'Nichts gefunden.',
        noData: 'Keine Daten',
        placeholder: 'Daten wählen'
      },
      cascader: {
        noMatch: 'Nichts gefunden.',
        loading: 'Lädt.',
        placeholder: 'Daten wählen',
        noData: 'Keine Daten'
      },
      pagination: {
        goto: 'Gehe zu',
        pagesize: ' pro Seite',
        total: 'Gesamt {total}',
        pageClassifier: ''
      },
      messagebox: {
        confirm: 'OK',
        cancel: 'Abbrechen',
        error: 'Fehler'
      },
      upload: {
        deleteTip: 'Klicke löschen zum entfernen',
        delete: 'Löschen',
        preview: 'Vorschau',
        continue: 'Fortsetzen'
      },
      table: {
        emptyText: 'Keine Daten',
        confirmFilter: 'Anwenden',
        resetFilter: 'Zurücksetzen',
        clearFilter: 'Alles ',
        sumText: 'Summe'
      },
      tree: {
        emptyText: 'Keine Einträge'
      },
      transfer: {
        noMatch: 'Nichts gefunden.',
        noData: 'Keine Einträge',
        titles: ['Liste 1', 'Liste 2'],
        filterPlaceholder: 'Einträge filtern',
        noCheckedFormat: '{total} Einträge',
        hasCheckedFormat: '{checked}/{total} ausgewählt'
      },
      image: {
        error: 'FAILED' // to be translated
      },
      pageHeader: {
        title: 'Back' // to be translated
      },
      popconfirm: {
        confirmButtonText: 'Yes', // to be translated
        cancelButtonText: 'No' // to be translated
      }
    }
  },
  en: {
    ...enLocale,
    el: {
      colorpicker: {
        confirm: 'OK',
        clear: 'Clear'
      },
      datepicker: {
        now: 'Now',
        today: 'Today',
        cancel: 'Cancel',
        clear: 'Clear',
        confirm: 'OK',
        selectDate: 'Select date',
        selectTime: 'Select time',
        startDate: 'Start Date',
        startTime: 'Start Time',
        endDate: 'End Date',
        endTime: 'End Time',
        prevYear: 'Previous Year',
        nextYear: 'Next Year',
        prevMonth: 'Previous Month',
        nextMonth: 'Next Month',
        year: '',
        month1: 'January',
        month2: 'February',
        month3: 'March',
        month4: 'April',
        month5: 'May',
        month6: 'June',
        month7: 'July',
        month8: 'August',
        month9: 'September',
        month10: 'October',
        month11: 'November',
        month12: 'December',
        week: 'week',
        weeks: {
          sun: 'Sun',
          mon: 'Mon',
          tue: 'Tue',
          wed: 'Wed',
          thu: 'Thu',
          fri: 'Fri',
          sat: 'Sat'
        },
        months: {
          jan: 'Jan',
          feb: 'Feb',
          mar: 'Mar',
          apr: 'Apr',
          may: 'May',
          jun: 'Jun',
          jul: 'Jul',
          aug: 'Aug',
          sep: 'Sep',
          oct: 'Oct',
          nov: 'Nov',
          dec: 'Dec'
        }
      },
      select: {
        loading: 'Loading',
        noMatch: 'No matching data',
        noData: 'No data',
        placeholder: 'Select'
      },
      cascader: {
        noMatch: 'No matching data',
        loading: 'Loading',
        placeholder: 'Select',
        noData: 'No data'
      },
      pagination: {
        goto: 'Go to',
        pagesize: '/page',
        total: 'Total {total}',
        pageClassifier: ''
      },
      messagebox: {
        title: 'Message',
        confirm: 'OK',
        cancel: 'Cancel',
        error: 'Illegal input'
      },
      upload: {
        deleteTip: 'press delete to remove',
        delete: 'Delete',
        preview: 'Preview',
        continue: 'Continue'
      },
      table: {
        emptyText: 'No Data',
        confirmFilter: 'Confirm',
        resetFilter: 'Reset',
        clearFilter: 'All',
        sumText: 'Sum'
      },
      tree: {
        emptyText: 'No Data'
      },
      transfer: {
        noMatch: 'No matching data',
        noData: 'No data',
        titles: ['List 1', 'List 2'], // to be translated
        filterPlaceholder: 'Enter keyword', // to be translated
        noCheckedFormat: '{total} items', // to be translated
        hasCheckedFormat: '{checked}/{total} checked' // to be translated
      },
      image: {
        error: 'FAILED'
      },
      pageHeader: {
        title: 'Back' // to be translated
      },
      popconfirm: {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }
    }
  },
  fr: {
    ...frLocale,
    el: {
      colorpicker: {
        confirm: 'OK',
        clear: 'Effacer'
      },
      datepicker: {
        now: 'Maintenant',
        today: 'Auj.',
        cancel: 'Annuler',
        clear: 'Effacer',
        confirm: 'OK',
        selectDate: 'Choisir date',
        selectTime: 'Choisir horaire',
        startDate: 'Date début',
        startTime: 'Horaire début',
        endDate: 'Date fin',
        endTime: 'Horaire fin',
        prevYear: 'Année précédente',
        nextYear: 'Année suivante',
        prevMonth: 'Mois précédent',
        nextMonth: 'Mois suivant',
        year: '', // In french, like in english, we don't say "Année" after the year number.
        month1: 'Janvier',
        month2: 'Février',
        month3: 'Mars',
        month4: 'Avril',
        month5: 'Mai',
        month6: 'Juin',
        month7: 'Juillet',
        month8: 'Août',
        month9: 'Septembre',
        month10: 'Octobre',
        month11: 'Novembre',
        month12: 'Décembre',
        // week: 'Semaine',
        weeks: {
          sun: 'Dim',
          mon: 'Lun',
          tue: 'Mar',
          wed: 'Mer',
          thu: 'Jeu',
          fri: 'Ven',
          sat: 'Sam'
        },
        months: {
          jan: 'Jan',
          feb: 'Fév',
          mar: 'Mar',
          apr: 'Avr',
          may: 'Mai',
          jun: 'Jun',
          jul: 'Jul',
          aug: 'Aoû',
          sep: 'Sep',
          oct: 'Oct',
          nov: 'Nov',
          dec: 'Déc'
        }
      },
      select: {
        loading: 'Chargement',
        noMatch: 'Aucune correspondance',
        noData: 'Aucune donnée',
        placeholder: 'Choisir'
      },
      cascader: {
        noMatch: 'Aucune correspondance',
        loading: 'Chargement',
        placeholder: 'Choisir',
        noData: 'Aucune donnée'
      },
      pagination: {
        goto: 'Aller à',
        pagesize: '/page',
        total: 'Total {total}',
        pageClassifier: ''
      },
      messagebox: {
        confirm: 'Confirmer',
        cancel: 'Annuler',
        error: 'Erreur'
      },
      upload: {
        deleteTip: 'Cliquer sur supprimer pour retirer le fichier',
        delete: 'Supprimer',
        preview: 'Aperçu',
        continue: 'Continuer'
      },
      table: {
        emptyText: 'Aucune donnée',
        confirmFilter: 'Confirmer',
        resetFilter: 'Réinitialiser',
        clearFilter: 'Tous',
        sumText: 'Somme'
      },
      tree: {
        emptyText: 'Aucune donnée'
      },
      transfer: {
        noMatch: 'Aucune correspondance',
        noData: 'Aucune donnée',
        titles: ['Liste 1', 'Liste 2'],
        filterPlaceholder: 'Entrer un mot clef',
        noCheckedFormat: '{total} elements',
        hasCheckedFormat: '{checked}/{total} coché(s)'
      },
      image: {
        error: 'FAILED' // to be translated
      },
      pageHeader: {
        title: 'Back' // to be translated
      },
      popconfirm: {
        confirmButtonText: 'Yes', // to be translated
        cancelButtonText: 'No' // to be translated
      }
    }
  },
  nl: {
    ...nlLocale,
    el: {
      colorpicker: {
        confirm: 'Bevestig',
        clear: 'Wissen'
      },
      datepicker: {
        now: 'Nu',
        today: 'Vandaag',
        cancel: 'Annuleren',
        clear: 'Legen',
        confirm: 'Bevestig',
        selectDate: 'Selecteer datum',
        selectTime: 'Selecteer tijd',
        startDate: 'Startdatum',
        startTime: 'Starttijd',
        endDate: 'Einddatum',
        endTime: 'Eindtijd',
        prevYear: 'Vorig jaar',
        nextYear: 'Volgend jaar',
        prevMonth: 'Vorige maand',
        nextMonth: 'Volgende maand',
        year: '',
        month1: 'januari',
        month2: 'februari',
        month3: 'maart',
        month4: 'april',
        month5: 'mei',
        month6: 'juni',
        month7: 'juli',
        month8: 'augustus',
        month9: 'september',
        month10: 'oktober',
        month11: 'november',
        month12: 'december',
        // week: 'week',
        weeks: {
          sun: 'Zo',
          mon: 'Ma',
          tue: 'Di',
          wed: 'Wo',
          thu: 'Do',
          fri: 'Vr',
          sat: 'Za'
        },
        months: {
          jan: 'jan',
          feb: 'feb',
          mar: 'maa',
          apr: 'apr',
          may: 'mei',
          jun: 'jun',
          jul: 'jul',
          aug: 'aug',
          sep: 'sep',
          oct: 'okt',
          nov: 'nov',
          dec: 'dec'
        }
      },
      select: {
        loading: 'Laden',
        noMatch: 'Geen overeenkomende resultaten',
        noData: 'Geen data',
        placeholder: 'Selecteer'
      },
      cascader: {
        noMatch: 'Geen overeenkomende resultaten',
        loading: 'Laden',
        placeholder: 'Selecteer',
        noData: 'Geen data'
      },
      pagination: {
        goto: 'Ga naar',
        pagesize: '/pagina',
        total: 'Totaal {total}',
        pageClassifier: ''
      },
      messagebox: {
        title: 'Bericht',
        confirm: 'Bevestig',
        cancel: 'Annuleren',
        error: 'Ongeldige invoer'
      },
      upload: {
        deleteTip: 'Kies verwijder om te wissen',
        delete: 'Verwijder',
        preview: 'Voorbeeld',
        continue: 'Doorgaan'
      },
      table: {
        emptyText: 'Geen data',
        confirmFilter: 'Bevestigen',
        resetFilter: 'Reset',
        clearFilter: 'Alles',
        sumText: 'Som'
      },
      tree: {
        emptyText: 'Geen data'
      },
      transfer: {
        noMatch: 'Geen overeenkomende resultaten',
        noData: 'Geen data',
        titles: ['Lijst 1', 'Lijst 2'],
        filterPlaceholder: 'Geef zoekwoerd',
        noCheckedFormat: '{total} items',
        hasCheckedFormat: '{checked}/{total} geselecteerd'
      },
      image: {
        error: 'FAILED' // to be translated
      },
      pageHeader: {
        title: 'Back' // to be translated
      },
      popconfirm: {
        confirmButtonText: 'Yes', // to be translated
        cancelButtonText: 'No' // to be translated
      }
    }
  },
}

export const getLocale = () => {
  const cookieLanguage = getLanguage()
  if (cookieLanguage) {
    document.documentElement.lang = cookieLanguage
    return cookieLanguage
  }

  const language = navigator.language.toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      document.documentElement.lang = locale
      return locale
    }
  }

  // Default language is english
  return 'en'
}

const i18n = new VueI18n({
  locale: getLocale(),
  messages
})

export default i18n
