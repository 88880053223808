// Tabs
export const tabs : any[] = [
    {
        key: "basic",
        sections: [
            {
                inputs: [
                    {
                        label: 'dashboard.bugs.full-name',
                        name: 'input_bug_report_full_name',
                        type: 'text',
                        required: true,
                        path: "FullName",
                        size: 50
                    },
                    {
                        label: 'dashboard.bugs.company-name',
                        name: 'input_bug_report_company_name',
                        type: 'text',
                        required: true,
                        path: "CompanyName",
                        size: 50
                    },
                    {
                        label: 'dashboard.bugs.email',
                        name: 'input_bug_report_email',
                        type: 'text',
                        required: true,
                        path: "Email",
                        size: 50
                    },
                    {
                        label: 'dashboard.bugs.phone',
                        name: 'input_bug_report_phone',
                        type: 'text',
                        required: true,
                        path: "Phone",
                        size: 50
                    },
                    {
                        label: 'dashboard.bugs.description',
                        name: 'input_bug_report_description',
                        type: 'textarea',
                        required: true,
                        path: "Description",
                        size: 100
                    }
                ]
            }
        ],
    }
]

