import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from "vue-property-decorator";


@Component
export default class InputMixin extends Vue {
    @Prop({ default: null }) private appendComponent: any;
    @Prop({ default: () => { return {};}}) private attributes: any;
    @Prop({ default: false }) private disabled: boolean;
    @Prop({ default: undefined }) private error?: string;
    @Prop({default: null}) public formValues: any;
    @Prop({ default: "" }) private label! : string;
    @Prop({ default: "input" }) private name! : string;
    @Prop({ default: "" }) private placeholder: string;
    @Prop({ default: null }) private prependComponent: any;
    @Prop({ default: "" }) private prefix: string;
    @Prop({ default: "" }) private prefixIcon: string;
    @Prop({ default: false }) private readonly: boolean;
    @Prop({ default: "" }) private suffix: string;
    @Prop({ default: "" }) private suffixIcon: string;
    @Prop({ default: null }) private value! : unknown;

    get hasValue(): boolean {
        return this.valueModel !== undefined && this.valueModel !== null && this.valueModel !== "";
    }

    // Model
    get valueModel(): any {
        return this.value;
    }
    set valueModel(value: any){
        // Emit
        this.$emit('input', value);
        window.FormEvents.$emit("change", value);
    }


    get props(){
        return {
            attributes: this.attributes,
            appendComponent: this.appendComponent,
            prepend_component: this.prependComponent,
            error: this.error,
            formValues: this.formValues,
            label: this.label,
            disabled: this.disabled,
            placeholder: this.placeholder,
            prefix: this.prefix,
            prefixIcon: this.prefixIcon,
            readonly: this.readonly,
            suffix: this.suffix,
            suffixIcon: this.suffixIcon,
        }
    }
}
