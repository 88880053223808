/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'custom_button_element': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M25.952 10.57L15.453 21.072l-4.771-.022-.026-4.774L21.155 5.777l4.797 4.794zm1.795-6.589c-1.325-1.325-2.966-1.83-3.667-1.13l-1.891 1.891 4.797 4.794 1.891-1.891c.701-.701.195-2.339-1.13-3.664z"/><path pid="1" d="M15.952 2.534H4.173c-.806 0-1.462.653-1.462 1.462v23.558c0 .806.653 1.462 1.462 1.462h23.558c.806 0 1.462-.653 1.462-1.462V15.775h-2.65v10.602c0-.006.003-.01.01-.01H5.35c.006 0 .01.003.01.01V5.174c0 .006-.003.01-.01.01h10.602v-2.65z"/>'
  }
})
