import request from '@/utils/request'

export const getTutorials = async (params?: any) =>
    request({
        url: 'tutorial',
        method: 'get',
        params
    });


export const getTutorial = (id: string) =>
    request({
        url: 'tutorial/' + id,
        method: 'get'
    })

export const getTutorialByLink = (link: string, language?: string) =>
    request({
        url: 'tutorial-link/' + link + "/" + language,
        method: 'get'
    })


export const createTutorial = (data: ITutorial) =>
    request({
        url: 'tutorial',
        method: 'post',
        data: data
    })

export const updateTutorial = (id: string, data: ITutorial) =>
    request({
        url: 'tutorial/' + id,
        method: 'put',
        data
    })

export const deleteTutorial = (id: string) =>
    request({
        url: 'tutorial/' + id,
        method: 'delete'
    })

export const getTutorialSections = (tutorialId: string) =>
    request({
        url: 'tutorial/' + tutorialId + '/section',
        method: 'get'
    })

export const getTutorialSection = (tutorialId: string, id: string) =>
    request({
        url: 'tutorial/' + tutorialId + '/section/' + id,
        method: 'get'
    })


export const createTutorialSection = (tutorialId: string, data: ITutorialSection) =>
    request({
        url: 'tutorial/' + tutorialId + '/section',
        method: 'post',
        data: data
    })

export const updateTutorialSection = (tutorialId: string, id: string, data: ITutorialSection) =>
    request({
        url: 'tutorial/' + tutorialId + '/section/' + id,
        method: 'put',
        data
    })

export const deleteTutorialSection = (tutorialId: string, id: string) =>
    request({
        url: 'tutorial/' + tutorialId + '/section/' + id,
        method: 'delete'
    })
