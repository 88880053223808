
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import request from "@/utils/request";
import {UserModule} from "@/store/modules/user";
import {uploadFile} from "@/firebase/functions";
import {createRandomToken, getImageSize, getModelAnimations, getObjectSize, getVideoSize} from "@/globalFunctions";
import FileViewer from "@/components/FileViewer/index.vue";
import {SettingsModule} from "@/store/modules/settings";
import MultipleFileViewer from "@/components/FileViewer/multiple.vue";

@Component({
  name: 'FileUpload',
  components: {MultipleFileViewer, FileViewer}
})
export default class extends Vue {
  @Prop({default: true}) private editable: boolean;
  @Prop({default: null}) private endpoint: string | null;
  @Prop({ default: undefined }) private error?: string;
  @Prop({default: () => []}) private fileTypes: string[];
  @Prop({default: null }) private fileName: string;
  @Prop({default: "image"}) private fileType: string;
  @Prop({default: false}) private immediateUpload: boolean;
  @Prop({default: false}) private disabled: boolean;
  @Prop({default: () => { return {};}}) private metadata: Record<string, unknown>;
  @Prop({default: false}) private multiple: boolean;
  @Prop({default: ""}) private folder: string;
  @Prop({default: true}) private showIcon: boolean;
  @Prop({default: false}) private advancedSettings: boolean;
  @Prop({default: ""}) private value: string | string[];

  @PropSync("files", { type: Array, default: () => [] }) private syncedFiles: any[];
  @PropSync("index", { type: Number, default: 0 }) private syncedIndex: number;

  public loading = false;

  private fileExtension: string;
  private fileSize: number;
  private url = "";
  private originalFilename: string;
  private retrievedId?: string;
  private uploadProgress = 0;

  get allowedFileTypes(){
    if (this.fileTypes.length > 0){
      return this.fileTypes;
    }
    if (this.fileType){
      return SettingsModule.fileTypes[this.fileType];
    }
    return ["png", "jpeg", "jpg"];
  }

  get allowedFileSize(){
    if (this.fileType){
      return SettingsModule.fileSizes[this.fileType];
    }
    return null;
  }

  get fileUrl(){
    return this.value;
  }

  set fileUrl(value){
    this.$emit("input", value);
  }

  get isDisabled() {
    if (this.disabled){
      return true;
    }

    // Disable if multiple files and fileurl is chosen
    if (this.multiple){
      return this.syncedFiles.length > 0;
    }

    // Disable if not editable and image or any of 3d, video and audio
    if (this.fileType === "image"){
      return !this.editable && !!this.fileUrl
    } else {
      return !!this.fileUrl;
    }
  }

  private async uploadFile({file} : {file: File}){
    // Start loading
    this.loading = true;

    // Set filetypes
    this.originalFilename = file.name;
    this.fileSize = file.size;
    this.fileExtension = file.name.split(".").pop() || "";


    if (this.allowedFileSize !== null && (this.fileSize/(1024*1024)) > this.allowedFileSize){
      // Emit
      this.$emit("on-error", {
        code: "file-size-not-allowed",
        message: "Filesize is not allowed",
      });

      // Notify user
      this.$notify({
        title: this.$t('notifications.file-size-not-allowed.title').toString(),
        message: this.$t('notifications.file-size-not-allowed.message').toString(),
        type: 'error'
      });

      this.loading = false;

      throw Error("Filetype is not allowed");
    }


    if (this.allowedFileTypes.indexOf(this.fileExtension) < 0){
      // Emit
      this.$emit("on-error", {
        code: "file-type-not-allowed",
        message: "Filetype is not allowed",
      });

      // Notify user
      this.$notify({
        title: this.$t('notifications.file-type-not-allowed.' + this.allowedFileTypes.join('-') + '.title').toString(),
        message: this.$t('notifications.file-type-not-allowed.' + this.allowedFileTypes.join('-') + '.message').toString(),
        type: 'error'
      });

      this.loading = false;

      throw Error("Filetype is not allowed");
    }

    // File selected
    window.GlobalEvents.$emit("upload-file-selected");

    // Parse file
    const fileData = await this.getFileData(file, this.fileType);
    const contentType = this.base64MimeType(fileData.Base64);

    // Additional data
    const metadata = {
      ImageSize: fileData.ImageSize,
      FileExtension: this.fileExtension,
      FileSize: this.fileSize,
      OriginalFilename: this.originalFilename,
      ContentType: contentType,
      UserId: UserModule.uid,
      LicenseId: UserModule.license,
    }

    // Filename
    const fileName = this.fileName ? this.fileName : createRandomToken();

    // Upload file
    let storageLocation;
    if (this.endpoint){
      // Using an API endpoint
      const {data} : any = await request({
        url: this.endpoint,
        method: 'post',
        data: {
          ...this.metadata,
          ...metadata,
          File: fileData.Base64,
        }
      }).catch((error: Error) => {
        console.error(error);

        // Emit
        this.$emit("on-error", error);
      });

      // Storage location
      storageLocation = data.StorageLocation;

      // Retrieve Id
      this.retrievedId = data.Id;

      // Set url
      this.url = data.FileUrl + (data.FileUrl.indexOf("?") >= 0 ? "&" : "?") +"time=" + Date.now();
      this.fileUrl = this.url;
    } else {
      // Upload file using Firebase Storage
      const url = await uploadFile(this.folder + fileName, file,{
        ...this.metadata,
        ...metadata
      }, (progress: any) => {
        this.uploadProgress = progress;
      }).catch((error: Error) => {
        console.error(error);

        // Emit
        this.$emit("on-error", error);
      });

      // Set url
      this.url = url + ((url as string).indexOf("?") >= 0 ? "&" : "?") +"time=" + Date.now();
      this.fileUrl = this.url;
    }

    // Collect data
    const data = {
      Id: this.retrievedId,
      FileExtension: this.fileExtension,
      FileSize: this.fileSize,
      FileUrl: this.url,
      ImageSize: fileData.ImageSize,
      MediaUpdatedBy: UserModule.uid,
      MediaUpdatedDateTime: new Date(),
      ObjectSize: fileData.ObjectSize,
      OriginalFilename: this.originalFilename,
      PhysicalSizeX: fileData.PhysicalSizeX,
      PhysicalSizeY: fileData.PhysicalSizeY,
      StorageLocation: storageLocation || this.folder + fileName,
      ModelAnimations: fileData.ModelAnimations,
      VideoSize: fileData.VideoSize,
    };

    // Emit
    this.$emit("on-success", data);

    // File selected
    window.GlobalEvents.$emit("upload-file-uploaded");

    // Add to files
    if (this.multiple){
      this.syncedFiles.push(data);
    }

    //End loading
    this.uploadProgress = 0;
    this.loading = false;
  }

  private base64MimeType = (encoded: string) : string => {
    let result = null;

    if (typeof encoded !== "string") {
      return "";
    }

    const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

    if (mime && mime.length) {
      result = mime[1];
    }

    return result || "";
  };

  private getFileData = async (file : File, fileType: string) : Promise<any> => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async function () {

        if (fileType === "image"){
          if (reader.result){
            const imageSize = await getImageSize(reader.result as string);
            resolve({
              Base64: reader.result,
              ImageSize: imageSize,
              PhysicalSizeX: imageSize.X*0.026458333*0.01,
              PhysicalSizeY: imageSize.Y*0.026458333*0.01,
            });
          }
        } else if (fileType === "video"){
          resolve({
            Base64: reader.result,
            VideoSize: await getVideoSize(reader.result as string)
          });

        } else if (fileType === "audio"){
          resolve({
            Base64: reader.result
          });
        } else if (fileType === "3d"){
          resolve({
            Base64: reader.result,
            ObjectSize: await getObjectSize(reader.result as string),
            ModelAnimations: {
              List: await getModelAnimations(reader.result as string),
              OnClick: {
                Loop: false,
                Order: []
              },
              OnStart: {
                Loop: false,
                Order: []
              },
              Triggers: []
            }
          });
        }

      };
      reader.onerror = function (error) {
        reject(error);
      };
    })
  }

  private removeFile = (index?: number) => {
    if (!this.multiple){
      this.fileUrl = "";
    } else if (index !== undefined){
      // Delete file
      this.syncedFiles.splice(index, 1);
    }
  }
}
