/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'element_images': {
    width: 1212,
    height: 1024,
    viewBox: '0 0 1212 1024',
    data: '<path pid="0" d="M569.194 308.138c-56.524 0-100.926 45.742-100.926 100.926 0 56.524 45.742 100.926 100.926 100.926 56.524 0 100.926-45.742 100.926-100.926 1.34-55.163-44.423-100.926-100.926-100.926z"/><path pid="1" d="M1085.904 131.868h-6.72v-6.72C1079.163 56.524 1022.66 0 954.036 0H125.147C56.523 0-.001 55.163-.001 125.148v641.858c0 68.624 56.524 125.148 125.148 125.148h6.72v6.72c0 68.624 56.524 125.148 125.148 125.148h830.228c68.624 0 125.148-56.524 125.148-125.148V255.656c-1.361-68.624-57.863-123.787-126.487-123.787zM131.868 255.655v565.153h-6.72c-30.942 0-55.163-24.221-55.163-55.163V123.787c0-30.942 24.221-55.163 55.163-55.163h830.228c30.942 0 55.163 24.221 55.163 55.163v6.72H257.015c-69.984 1.361-125.148 56.524-125.148 125.148zm1009.199 543.632L900.213 485.769c-17.501-22.882-43.063-36.322-68.624-36.322-26.922 0-51.143 13.461-68.624 36.322L567.854 741.424l-92.845-121.107c-17.501-24.221-43.063-36.322-68.624-36.322-26.922 0-51.143 13.461-68.624 36.322l-135.908 177.61V255.655c0-30.942 24.221-55.163 55.163-55.163h830.228c30.942 0 55.163 24.221 55.163 55.163v543.612l-1.34.021z"/>'
  }
})
