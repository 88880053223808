import {Provider} from "@/data/classes/provider";
import Vue from "vue";
import GlobalEventBus from "@/utils/globalEventBus";
import {UserScoring} from "@/utils/user-scoring";

// Declaration
declare global {
    interface Window {
        isDemo: boolean;
        isTrial: boolean;
        FormEvents: any;
        FormWrapperEvents: any;
        EditorEvents: any;
        GlobalEvents: any;
        provider: Provider;
        timeout: (time: number) => Promise<void>;
        environment: string;
        timestamps: {markerSaved: number; markerChanged: number; },
        UserScoring: UserScoring
        apiUrl: string;
        project: string;
    }
    interface XMLHttpRequest { origOpen: any; }
    interface Vue { $provider: any, $functions: any, $user: any}
    interface String {
        hashCode: any;
    }
}

String.prototype.hashCode = function() {
    let hash = 0,
        i, chr;
    if (this.length === 0) return hash;
    for (i = 0; i < this.length; i++) {
        chr = this.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}



// Register window variables
Object.assign(window, {
    isDemo: false,
    isTrial: false,
    FormEvents: new Vue(),
    FormWrapperEvents: new Vue(),
    EditorEvents: new Vue(),
    GlobalEvents: GlobalEventBus,
    environment: window.location.origin.split("/").pop()?.split(":").shift()?.split(".vee").shift()?.split("-vee").shift(),
    provider: {},
    timestamps: {},
    timeout: async (s: number) => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(true);
            }, s);
        });
    },
    UserScoring: new UserScoring()
});
