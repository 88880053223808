/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'vertical_shades': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20 19V3H4v16H2v2h20v-2h-2zm-10 0V5h4v14h-4z"/>'
  }
})
