import i18n from "@/lang";

export const dateShortcuts = [
    {
        text: i18n.t('element-ui.date-picker.ranges.half-year'),
        onClick(picker: any){
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 182);
            picker.$emit('pick', [start, end]);
        }
    },{
        text: i18n.t('element-ui.date-picker.ranges.year'),
        onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 365);
            picker.$emit('pick', [start, end]);
        }
    },{
        text: i18n.t('element-ui.date-picker.ranges.two-year'),
        onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 365 * 2);
            picker.$emit('pick', [start, end]);
        }
    },{
        text: i18n.t('element-ui.date-picker.ranges.five-year'),
        onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 365 * 5);
            picker.$emit('pick', [start, end]);
        }
    }]
