import {convertDate, filterUndefined} from "@/globalFunctions";
import {getUserProfile, updateUserProfile} from "@/data/api/users";

/**
 * User
 */
export class User implements IUser {
    _new = true;
    _changed = 0;

    Active = true;
    Address?: {
        AddressLine?: string;
        City?: string;
        Country?: string;
        PostalCode?: string;
    };
    Archived = false;
    ArchivedBy?: string;
    ArchivedDateTime = new Date();
    BirthDay?: string;
    CreatedDateTime = new Date();
    CreatedBy?: string;
    Company?: ICompanyInfo | string;
    CompanyRole?: string;
    Claims?: Record<string, boolean>;
    DefaultLocale = "EN";
    DefaultLicense?: string;
    DocumentUpdatedDateTime = new Date();
    DocumentUpdatedBy?: string;
    Email?: string;
    EmailVerified = false;
    FirstName?: string;
    GeneralConditionsAccepted = false;
    Id = "";
    IntroFinished = false;
    Infix?: string;
    LastName?: string;
    Licenses: string[] = [];
    LastSignInDateTime: Date = new Date();
    Phone?: string;
    PlatformId = "veewar";
    ResellerId: string | null = null;
    Roles = ["user"];
    Sex?: string;
    StripeId?: string;
    VerificationCode = "";

    /**
     * Constructs the class
     * @param {any} obj - Object data
     */
    constructor(obj?: any) {
        if (obj) {
            this.set(obj);
        }
    }

    /**
     * Get user
     * @return {User} User instance
     */
    public async get(id: string) : Promise<User> {
        // Get data
        const {data}  = await getUserProfile(id);

        // Set data
        return this.set(data);
    }

    /**
     * Set library category data
     * @return {User} Instance
     */
    public set(data: any) : User {
        // Assign data
        Object.assign(this, data);

        if (typeof this.Company === "string"){
            this.Company = {
                Name: this.Company
            }
        }

        // Return instance
        return this;
    }

    /**
     * Update user data
     * @return {any} Result
     */
    public async update(){
        // Update or create
        const data = this.toJSON();
        const result = await updateUserProfile(this.Id, data);

        // Assign resulting data
        Object.assign(this, result.data);

        // No changes
        this._changed = 0;
        this._new = false;

        // Return instance
        return result;
    }

    /**
     * Intro is finisihed
     * @return {any} Result
     */
    public async finishedIntro(){
        // Intro is finished
        this.IntroFinished = true;

        // Return instance
        return this.update();
    }


    /**
     * Convert to JSON
     * @return {ILibraryCategory} JSON data
     */
    public toJSON() : IUser {
        return filterUndefined({
            Active: this.Active,
            Address: this.Address,
            BirthDay: this.BirthDay,
            CreatedDateTime: convertDate(this.CreatedDateTime),
            Company: this.Company,
            CompanyRole: this.CompanyRole,
            Claims: this.Claims,
            DefaultLocale: this.DefaultLocale,
            DefaultLicense: this.DefaultLicense,
            DocumentUpdatedDateTime: convertDate(this.DocumentUpdatedDateTime),
            Email: this.Email,
            EmailVerified: this.EmailVerified,
            FirstName: this.FirstName,
            GeneralConditionsAccepted: this.GeneralConditionsAccepted,
            Id: this.Id,
            IntroFinished: this.IntroFinished,
            Infix: this.Infix,
            LastName: this.LastName,
            LastSignInDateTime: convertDate(this.LastSignInDateTime),
            Licenses: this.Licenses,
            Phone: this.Phone,
            PlatformId: this.PlatformId,
            ResellerId: this.ResellerId,
            Sex: this.Sex,
            StripeId: this.StripeId,
            VerificationCode: this.VerificationCode,
        }) as IUser;
    }




    /**
     * Determines if the user has access to any of the roles
     * @return {boolean} Whether access is permitted
     */
    public hasAccess(roles: string[]) : boolean {
        let accessAllowed = false;
        roles.forEach((role) => {
           if (this.Roles.indexOf(role) >= 0) {
               accessAllowed = true;
           }
        });
        return accessAllowed;
    }
}
