import {listenToDoc, sendEvent} from "@/firebase/config";
import {createRandomToken, sha256} from "@/globalFunctions";
import router from "@/router";
import {Route} from "vue-router";
import {updateProspect} from "@/data/api/prospect";
import i18n from "@/lang";

export class UserScoring {

    appInstalled = false;
    clicks = 0;
    date: string = new Date().getFullYear().toString() + "-" + (new Date().getMonth()+1).toString() + "-" + new Date().getDate().toString();
    demographic = {
        country: null,
        region: null,
        device: null,
        browser: null,
        ipAddress: null,
        company: null
    }
    email = "";
    events: { [key: string]: boolean } = {
        "generate_lead": false,
        "start_demo": false,
        "intro_modal_closed": false,
        "demo_marker_view": false,
        "marker_creation_started": false,
        "marker_created": false,
        "element_creation_started": false,
        "element_created": false,
        "marker_activated": false,
        "marker_deactivated": false,
        "test_marker_modal_opened": false,
        "test_marker_modal_view": false,
        "catalogus_viewed": false,
        "begin_checkout": false,
        "purchase_failed": false,
        "purchase": false,
    }
    firstInteraction = 0;
    hashedEmail = "";
    lastInteraction = 0;
    lastUpdate = 0;
    listeners: {[key: string]: any} = {};
    log: {
        event: string;
        timestamp: number;
        params?: any
    }[] = [];
    markers: {[key: string]: any} = {};
    pages: string[] = [];
    prospect = false;
    sessionId = createRandomToken();
    sessionStart = Date.now();



    constructor() {
        // Get log
        const log = sessionStorage.getItem("us_eventLog");
        if (log){
            this.log = JSON.parse(log);

            // Check events
            Object.keys(this.events).forEach(key => {
                this.events[key] = this.log.filter(({event}: any) => key === event).length > 0;
            });

            if (this.events["start_demo"]){
                this.prospect = true;
            }
        }

        // Get email
        const hashedEmail = localStorage.getItem("us_hashedEmail");
        if (hashedEmail){
            this.hashedEmail = hashedEmail;
        }

        // Get clicks
        const clicks = localStorage.getItem("us_clicks");
        this.clicks = 0;
        if (clicks){
            this.clicks = parseInt(clicks);
        }
        if (isNaN(this.clicks)){
            this.clicks = 0;
        }

        // Get pages
        const pages = localStorage.getItem("us_pages");
        if (pages){
            this.pages = JSON.parse(pages) || [];
        }

        // Get marker IDs
        const markers = localStorage.getItem("us_markers");
        if (markers){
            this.markers = JSON.parse(markers) || {};
            Object.keys(this.markers).forEach((key) => {
                delete this.markers[key].unsubscribe;
            });
        }

        // Get session Id
        const sessionId = sessionStorage.getItem("us_sessionId");
        if (sessionId){
            this.sessionId = sessionId;
        } else {
            sessionStorage.setItem("us_sessionId", this.sessionId);
        }

        // Get session start
        const sessionStart = sessionStorage.getItem("us_sessionStart");
        if (sessionStart){
            this.sessionStart = parseInt(sessionStart);
        } else {
            sessionStorage.setItem("us_sessionStart", this.sessionStart.toString());
        }

        // Get first interaction
        const firstInteraction = sessionStorage.getItem("us_firstInteraction");
        if (firstInteraction){
            this.firstInteraction = parseInt(firstInteraction);
        }

        // Get last interaction
        const lastInteraction = sessionStorage.getItem("us_lastInteraction");
        if (lastInteraction){
            this.lastInteraction = parseInt(lastInteraction);
        }


        // Get pages
        const demographic = localStorage.getItem("us_demographic");
        if (demographic){
            this.demographic = JSON.parse(demographic) || {};
        } else {
            this.getDemographic();
        }

        // Set event listeners
        this.setEventListeners();
    }


    getMarkers(){
        const obj: {[key: string]: any} = {};
        Object.entries(this.markers).forEach(([key, info]: any) => {
            if (!obj[key]){
                obj[key] = {};
            }
            obj[key].Scans = info.scans;
            obj[key].LastScanned = info.scanned;
            obj[key].CloudScans = info.cloudScans;
            obj[key].LastCloudScanned = info.cloudScanned;
            obj[key].Clicks = info.clicks;
            obj[key].LastClicked = info.clicked;
            obj[key].LastInteraction = info.updated;
            obj[key].Events = info.Events;
        });
        return obj;
    }

    getStatus(){
        let status = "idle";
        if (this.events["demo_marker_view"] || this.events["editor_marker_view"]){
            status = "viewing";
        }
        if (this.events["marker_creation_started"]){
            status = "creating_marker";
        }
        if (this.events["marker_created"]){
            status = "created_marker";
        }
        if (this.events["element_creation_started"]){
            status = "creating_elements";
        }
        if (this.events["element_created"]){
            status = "created_elements";
        }
        if (this.events["test_marker_modal_opened"]){
            status = "testing_marker";
        }
        if (this.events["test_marker_modal_viewed"]){
            status = "tested_marker";
        }
        if (this.events["marker_activated"]){
            status = "activated_marker";
        }
        if (this.events["marker_scan"]){
            status = "scanned_marker";
        }
        if (this.events["begin_checkout"]){
            status = "started_checkout";
        }
        if (this.events["purchase"]){
            status = "customer";
        }
        if (this.events["purchase_failed"]){
            status = "failed_checkout";
        }

        return status;
    }

    async getDemographic(){
        // Get demographics
        const info: any = await fetch('https://api.ipregistry.co/?key=k87umaabtxatm4nw')
            .then(function (response) {
                return response.json();
            });

        // Get demographics of interest
        this.demographic = {
            browser: info.user_agent.name || null,
            company: info?.company?.name || null,
            country: info.location.country.name || null,
            device: info.user_agent.device.name || null,
            ipAddress: info.ip,
            region: info.location.region.name || null,
        }

        // Save to local storage
        localStorage.setItem("us_demographic", JSON.stringify(this.demographic));
    }

    onHandleClick() {
        // Add to click tally
        this.clicks++;
        localStorage.setItem("us_clicks", this.clicks.toString());

        // Set interaction
        if (!this.firstInteraction){
            this.firstInteraction = Date.now();
            sessionStorage.setItem("us_firstInteraction", this.lastInteraction.toString());
        }
        this.lastInteraction = Date.now();
        sessionStorage.setItem("us_lastInteraction", this.lastInteraction.toString());

        // Update at most every minute
        if (Date.now() - this.lastUpdate > 60*1000 && this.lastInteraction > this.lastUpdate){
            this.update();
        }
    }

    onRouteChange(to: Route, _: Route, next: any){
        if (this){
            // Add path
            if (!this.pages.includes(to.path)){
                this.pages.push(to.path);
                localStorage.setItem("us_pages", JSON.stringify(this.pages));
            }

            // Add marker IDs
            if (to.params.markerId && !Object.keys(this.markers).includes(to.params.markerId)){
                this.markers[to.params.markerId] = {
                    opened: true,
                    events: 0,
                    clicks: 0,
                    scans: 0,
                    cloudScans: 0
                };
                localStorage.setItem("us_markers", JSON.stringify(this.markers));
            } else if (to.params.markerId){
                this.markers[to.params.markerId].opened = true;
            }
        }

        // Go to next page
        next();
    }

    send(event: string, params?: any, update = true){
        // Add to log
        this.log.push({
            event,
            params,
            timestamp: Date.now()
        });

        // Save log
        sessionStorage.setItem("us_eventLog", JSON.stringify(this.log));

        // Check events
        this.events[event] = true;

        // If prospect
        if (event === "start_demo"){
            this.prospect = true;
        }
        if (event === "intro_modal_closed"){
            if (params.app_installed){
                this.appInstalled = true;
            }
        }

        // Update
        if (update){
            return this.update();
        }
        return;
    }

    setEmail(email: string) {
        // Set email
        this.email = email;
        localStorage.setItem("email", email);
        this.hashedEmail = sha256(this.email);
        localStorage.setItem("us_hashedEmail", this.hashedEmail);

        // Lead generated
        if (!this.events["generate_lead"]){
            sendEvent("generate_lead");
        }
    }

    setEventListeners(){
        document.addEventListener("click", () => {
            this.onHandleClick();
        });
        router.beforeEach((to: Route, _: Route, next: any) => {
            this.onRouteChange(to, _, next);
        });
        // document.addEventListener("visibilitychange", () => {
        //     if (document.visibilityState === 'hidden') {// Update at most every minute
        //         this.send("window_hidden", {}, false);
        //     } else if (document.visibilityState === 'visible'){
        //         this.send("window_visible", {}, false);
        //     }
        // });
    }

    async update(){
        // Wait at least 5 seconds between updates
        if (Date.now() - this.lastUpdate < 5*1000){
            await window.timeout(5*1000 + Math.random()*10*1000);
        }

        // Save to FireStore if prospect
        if (this.prospect && this.hashedEmail){

            // Update
            updateProspect(this.hashedEmail,{
                AppInstalled: this.appInstalled,
                Clicks: this.clicks,
                Email: this.email,
                Events: this.events,
                Status: this.getStatus(),
                LastInteraction: this.lastInteraction,
                Language: i18n.locale?.split("-")[0]?.toUpperCase() || "EN",
                Session: {
                    [this.sessionId]: {
                        Browser: this.demographic.browser,
                        Date: this.date,
                        Device: this.demographic.device,
                        Start: this.sessionStart,
                        End: Date.now()+5*1000,
                        FirstInteraction: this.firstInteraction,
                        IpAddress: this.demographic.ipAddress,
                        LastInteraction: this.lastInteraction,
                        Log: this.log,

                    }
                },
                Demographic: {
                    Browser: this.demographic.browser,
                    Company: this.demographic.company,
                    Country: this.demographic.country,
                    Device: this.demographic.device,
                    IpAddress: this.demographic.ipAddress,
                    Region: this.demographic.region
                },
                Markers: this.getMarkers(),
                Pages: this.pages,
                PlatformId: window.provider.Id
            });
            this.lastUpdate = Date.now();

            // Update once every 5 minutes
            setTimeout(() => {
                // Update at most every minute
                if (Date.now() - this.lastUpdate > 60*1000 && this.lastInteraction > this.lastUpdate){
                    this.update();
                }
            }, 5*60*1000);
        }
    }

    async addScanListener(markerId: string){

        // Create listener
        if (!this.markers[markerId]){
            this.markers[markerId] = {
                opened: false,
                events: 0,
                clicks: 0,
                scans: 0,
                cloudScans: 0
            };
        }

        if (!this.markers[markerId].unsubscribe){
            //Create listener
            this.markers[markerId].initialized = null;
            const {unsubscribe} = await listenToDoc("statistics", markerId, (data: any) => {

                // Update local marker statistics
                if (!this.markers[markerId].initialized){
                    this.markers[markerId].initialized = Date.now();
                } else {
                    this.appInstalled = true;
                    this.markers[markerId].updated = Date.now();
                    this.markers[markerId].events++;
                    if (data.LastEvent === "V"){
                        this.markers[markerId].scans++;
                        this.markers[markerId].scanned = Date.now();
                        this.send("marker_scan", { marker_scan_type: "local", marker_id: markerId }, false);
                    } else if (data.LastEvent === "S"){
                        this.markers[markerId].cloudScans++;
                        this.markers[markerId].cloudScanned = Date.now();
                        this.send("marker_scan", { marker_scan_type: "cloud", marker_id: markerId }, false);
                    } else if (data.LastEvent === "C"){
                        this.markers[markerId].clicks++;
                        this.markers[markerId].clicked = Date.now();
                        this.send("marker_click", { marker_id: markerId }, false);
                    }
                }

                // Save to local storage
                localStorage.setItem("us_markers", JSON.stringify(this.markers));

                // Delete listener after a maximum of 5 events to prevent excessive usage
                if (this.markers[markerId].events > 5){
                    this.removeScanListener(markerId);
                    this.update();
                }
            });


            this.markers[markerId].unsubscribe = unsubscribe;
        }
    }

    removeScanListener(markerId: string){
        // Unsubscribe from listener
        if (this.markers[markerId]){
            if (this.markers[markerId].unsubscribe){
                // Unsubscribe
                this.markers[markerId].unsubscribe();

                // Delete listener
                delete this.markers[markerId].unsubscribe;
            }
        }
    }

}
