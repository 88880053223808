/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'tapas': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22 10V1h-8v9c0 1.86 1.28 3.41 3 3.86V21h-2v2h6v-2h-2v-7.14c1.72-.45 3-2 3-3.86zm-2-7v3h-4V3h4zm-7.5 8.5A2.5 2.5 0 0110 14H8v9H6v-9H4a2.5 2.5 0 010-5h2V8H4a2.5 2.5 0 010-5h2V1h2v2h2a2.5 2.5 0 010 5H8v1h2a2.5 2.5 0 012.5 2.5z"/>'
  }
})
