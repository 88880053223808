/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'south_west': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 19v-2H8.41L20 5.41 18.59 4 7 15.59V9H5v10h10z"/>'
  }
})
