import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'
import elementVariables from '@/styles/element-variables.scss'
import defaultSettings from '@/settings'
import {setLocale} from "@/globalFunctions";

export interface ISettingsState {
  theme: string
  fixedHeader: boolean
  showSettings: boolean
  showSidebarLogo: boolean
  sidebarTextTheme: boolean
  languages: Record<string,string>[]
  defaultLanguage: string
  fileTypes: Record<string, string[]>
}

@Module({ dynamic: true, store, name: 'settings' })
class Settings extends VuexModule implements ISettingsState {
  public theme = elementVariables.theme
  public fixedHeader = defaultSettings.fixedHeader
  public showSettings = defaultSettings.showSettings
  public showSidebarLogo = defaultSettings.showSidebarLogo
  public sidebarTextTheme = defaultSettings.sidebarTextTheme
  public languages = defaultSettings.languages
  public defaultLanguage = defaultSettings.defaultLanguage
  public fileTypes: Record<string, string[]> = {
    marker: ["jpg", "jpeg"],
    '3d': ["gltf", "glb"],
    audio: ["mp3"],
    image: ["jpg", "jpeg", "png"],
    video: ["mp4"],
  }
  public fileSizes: Record<string, number> = {
    marker: 2,
    '3d': 50,
    audio: 5,
    image: 5,
    video: 20,
  }

  @Mutation
  private CHANGE_SETTING(payload: { key: string, value: any }) {
    const { key, value } = payload
    if (Object.prototype.hasOwnProperty.call(this, key)) {
      (this as any)[key] = value
    }
  }

  @Action
  public ChangeSetting(payload: { key: string, value: any}) {
    this.CHANGE_SETTING(payload)
  }

  @Action
  public SetDefaultLanguage(defaultLanguage: string) {
    // Failsafe
    let languageAvailable = false;
    const languages = this.languages.map(({key}) => key.toLowerCase());
    for (const language of languages){
      if (defaultLanguage.toLowerCase().indexOf(language) >= 0 && !languageAvailable){
        defaultLanguage = language.toUpperCase();
        languageAvailable = true;
      }
    }

    // Set language
    if (languageAvailable){
      this.CHANGE_SETTING({key: "defaultLanguage", value: defaultLanguage});
      setLocale(defaultLanguage);
    }
  }
}

export const SettingsModule = getModule(Settings)
