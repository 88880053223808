
import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import { UserModule } from '@/store/modules/user'
import BugReportForm from "@/components/BugReportDialog/index.vue";

@Component({
  name: 'UpperRightMenu',
  components: {BugReportForm}
})
export default class extends Vue {

  private dropdownVisible = false;

  get isDemo(){
    return window.isDemo;
  }

  get sidebar() {
    return AppModule.sidebar
  }

  private async logout() {
    await UserModule.LogOut()
    this.$router.push(`/login?redirect=${this.$route.fullPath}`).catch(err => {
      console.warn(err)
    })
  }

  private openBugReportDialog(){
    if (this.$refs.bugReportDialog){
      (this.$refs.bugReportDialog as any).open();
      window.GlobalEvents.$emit("open-bug-report-dialog");
    }
  }

  private runTour(tourId: string){
    window.GlobalEvents.$emit("start-tour", tourId);
  }

  private openIntroDialog(){
    window.GlobalEvents.$emit("open-intro-dialog");
  }


  handleVisibleChange(){
    this.dropdownVisible = !this.dropdownVisible;
    if (this.dropdownVisible){
      window.GlobalEvents.$emit("dropdown-menu-opened");
    } else {
      window.GlobalEvents.$emit("dropdown-menu-closed");
    }
  }
}
