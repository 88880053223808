
import {Component, Prop, PropSync} from 'vue-property-decorator'
import InputMixin from "../mixins/input";

@Component({
  name: 'MultilingualInput',
  components: {},
  mixins: [InputMixin]
})
export default class MultilingualInput extends InputMixin {
  @Prop({ default: "text" }) private type! : string; // multilingual_text, multilingual_textarea
  @PropSync("language", {default: "EN"}) private syncedLanguage: string;

  get inputType(){
    return this.type.split('_')[1] || "text";
  }

  created(){
    this.syncedLanguage = this.$i18n.locale.split("-")[0].toUpperCase();
  }
}
