/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'warning_amber': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2z"/><path pid="1" d="M13 16h-2v2h2zm0-6h-2v5h2z"/>'
  }
})
