
import { Component, Vue } from 'vue-property-decorator'
import {UserModule} from "@/store/modules/user";
import router from "@/router";

@Component({
  name: 'AssistanceDialog',
  components: {},
  mixins: []
})
export default class AssistanceDialog extends Vue {

  private visible  = false;
  private hovering  = false;
  pos1=0;pos2=0;pos3=0;pos4=0;

  private popup = "4";
  private popupsOpened: any = {}
  private popupActions: any = {
    "1": {
      showQrCode: false,
      condition: () => {
        return router.currentRoute.path.includes("/dashboard");
      },
      left: () => {
        window.GlobalEvents.$emit("open-tutorial", "create-marker");
      },
      right: () => {
        window.GlobalEvents.$emit("start-tour", "create-marker");
      }
    },
    "2": {
      condition: () => {
        return router.currentRoute.path.includes("dashboard") || router.currentRoute.path.includes("editor");
      },
      showQrCode: false,
      left: () => {
        window.GlobalEvents.$emit("open-tutorial", "editor");
      },
      right: () => {
        window.GlobalEvents.$emit("start-tour", "create-elements");
      }
    },
    "3": {
      condition: () => {
        return router.currentRoute.path.includes("dashboard") || router.currentRoute.path.includes("editor");
      },
      showQrCode: true,
      left: () => {
        window.GlobalEvents.$emit("open-tutorial", "editor");
      },
      right: () => {
        window.GlobalEvents.$emit("start-tour", "test-activate-marker");
      }
    },
    "4": {
      showQrCode: true,
      left: () => {
        window.GlobalEvents.$emit("open-tutorial", "bug-report");
      },
      right: () => {
        window.GlobalEvents.$emit("open-bug-report-dialog");
      }
    },
  }


  private lastActivity = Date.now();
  timeout: any;

  isTouring = false;
  introFinished = false;
  videoFinished = true;

  created(){
    this.setEventListeners();
  }

  get listItems(){
    let listItems = [], x = 1;
    while(this.$t("assistance-dialog.popup-" + this.popup + ".list.list-item-"+x) !== "assistance-dialog.popup-" + this.popup + ".list.list-item-"+x){
      listItems.push(this.$t("assistance-dialog.popup-" + this.popup + ".list.list-item-"+x).toString());
      x++;
    }
    return listItems;
  }

  get appUrl(){
    return window.origin + "/#/app?app=veewar&prospectId=" + window.UserScoring.hashedEmail;
  }

  get showQrCode(){
    return this.popupActions[this.popup.toString()].showQrCode;
  }

  // METHODS
  public close(){
    // Hide
    this.visible = false;

  }
  public async open(){
    // Open assistance-dialog
    this.visible = true;
    this.popupsOpened[this.popup.toString()] = true;
  }

  onClick(){
    // Record last activity
    this.lastActivity = Date.now();

    // Remove timeout
    if (this.timeout){
      window.clearTimeout(this.timeout);
    }

    if (this.isTouring || !this.introFinished || !this.videoFinished){
      return;
    }

    if (!this.visible){

      // Select correct popup based on UserScoring
      this.selectPopup();

      // Create timeout
      this.timeout = setTimeout(() => {
        if (this.isTouring || !this.introFinished || !this.videoFinished){
          return;
        }

        if (this.popupActions[this.popup.toString()].condition){
          if (this.popupActions[this.popup.toString()].condition() === false){
            return;
          }
        }

        // Show popup
        this.visible = true;
        this.popupsOpened[this.popup.toString()] = true;
      }, this.popupsOpened[this.popup.toString()] ? 2*60*1000 : 20*1000);
    }
  }

  selectPopup(){
    const events = window.UserScoring.events;
    if (!events.marker_created){
      this.popup = "1";
    } else if (!events.element_created){
      this.popup = "2";
    } else if (!events.test_marker_modal_viewed || !events.marker_activated){
      this.popup = "3";
    } else if (!events.marker_scan){
      this.popup = "4";
    } else {
      // Disable assistance dialog
      document.removeEventListener("click", this.onClick);
    }
  }

  doAction(button: string){
    this.popupActions[this.popup][button]();
    this.close();
  }

  private setEventListeners(){
    window.GlobalEvents.$on("open-assistance-dialog", this.open);
    window.GlobalEvents.$on("close-assistance-dialog", this.close);
    window.GlobalEvents.$on("logout", () => {
      window.clearTimeout(this.timeout);
      document.removeEventListener("click", this.onClick);
    });
    window.GlobalEvents.$on("tour-started", () => {
      this.isTouring = true;
      this.visible = false;
    });
    window.GlobalEvents.$on("tour-stopped", () => {
      this.isTouring = false;
      this.visible = false;
    });
    window.GlobalEvents.$on("tour-stopped", () => {
      this.isTouring = false;
      this.visible = false;
    });
    window.GlobalEvents.$on("logout", () => {
      this.visible = false;
    });
    window.GlobalEvents.$on("intro-dialog-opened", () => {
      this.introFinished = false;
    });
    window.GlobalEvents.$on("intro-dialog-closed", () => {
      this.introFinished = true;
    });
    window.GlobalEvents.$on("video-dialog-opened", () => {
      this.videoFinished = false;
    });
    window.GlobalEvents.$on("video-dialog-closed", () => {
      this.videoFinished = true;
    });
    window.GlobalEvents.$on("user-logged-in", () => {
      if (UserModule.user.Email === "demo-licentie@veewar.com"){
        document.addEventListener("click", this.onClick);
      }
    });
  }

  // Draggable
  dragMouseDown(e: any) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    this.pos3 = e.clientX;
    this.pos4 = e.clientY;
    document.onmouseup = this.closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = this.elementDrag;
  }

  elementDrag(e: any) {
    e = e || window.event;
    e.preventDefault();

    // calculate the new cursor position:
    this.pos1 = this.pos3 - e.clientX;
    this.pos2 = this.pos4 - e.clientY;
    this.pos3 = e.clientX;
    this.pos4 = e.clientY;

    // set the element's new position:
    let element = this.$refs.parent as HTMLElement;
    element.style.top = (element.offsetTop - this.pos2) + "px";
    element.style.left = (element.offsetLeft - this.pos1) + "px";
  }

  closeDragElement() {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
  }
}
