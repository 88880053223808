
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'EmptyState'
})
export default class extends Vue {
  @Prop({ default: "" }) private icon! : string;
  @Prop({ default: "" }) private title! : string;
  @Prop({ default: "" }) private description! : string;
  @Prop({ default: "" }) private buttonText? : string;
}
