import {Component, Vue, Watch} from 'vue-property-decorator'
import {AppModule, DeviceType} from '@/store/modules/app'

@Component({
  name: 'ResizeMixin'
})
export default class extends Vue {

  mobileWhitelist = ["/register", "/catalogus", "/payment-success", "/payment-error"]

  get device() {
    return AppModule.device
  }

  get sidebar() {
    return AppModule.sidebar
  }

  @Watch('$route', {deep: true})
  private onRouteChange() {
    if (this.device === DeviceType.Mobile && this.sidebar.opened) {
      AppModule.CloseSideBar(false)
    }
    if (this.device === DeviceType.Mobile && !this.mobileWhitelist.includes(this.$route.path)){
      this.$router.push("/mobile");
    }
  }

  beforeMount() {
    window.addEventListener('resize', this.resizeHandler)
  }

  mounted() {
    this.load()
  }

  async load(){
    const isMobile = this.isMobile()
    if (isMobile) {
      AppModule.ToggleDevice(DeviceType.Mobile)
      AppModule.CloseSideBar(true)
      document.body.classList.add("mobile")
      if (!this.mobileWhitelist.includes(this.$route.path)){
        return this.$router.push("/mobile");
      }
    } else {
      document.body.classList.add("desktop")
    }
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler)
  }

  private isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  }

  private resizeHandler() {
    if (!document.hidden) {
      const isMobile = this.isMobile()
      AppModule.ToggleDevice(isMobile ? DeviceType.Mobile : DeviceType.Desktop)
      if (isMobile) {
        AppModule.CloseSideBar(true)
      }
    }
  }
}
