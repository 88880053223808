/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'calendar_view_day': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 17h18v2H3zm0-7h18v5H3zm0-4h18v2H3z"/>'
  }
})
