/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'keyboard_double_arrow_down': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 6.41L16.59 5 12 9.58 7.41 5 6 6.41l6 6z"/><path pid="1" d="M18 13l-1.41-1.41L12 16.17l-4.59-4.58L6 13l6 6z"/>'
  }
})
