/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'e_mobiledata': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16 9V7H8v10h8v-2h-6v-2h6v-2h-6V9h6z"/>'
  }
})
