
import { Component, Prop } from 'vue-property-decorator'
import InputMixin from "../mixins/input";

@Component({
  name: 'Input',
  components: {},
  mixins: [InputMixin]
})
export default class Input extends InputMixin {
  @Prop({default: 0}) private min: number;
  @Prop({default: 100}) private max: number;
  @Prop({default: 1}) private interval: number;
}
