/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'fitbit': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.89 13.89c1.04 0 1.89-.85 1.89-1.89s-.85-1.89-1.89-1.89S18 10.96 18 12s.85 1.89 1.89 1.89zm-4.24-.21c.93 0 1.68-.75 1.68-1.68s-.75-1.68-1.68-1.68c-.93 0-1.68.75-1.68 1.68s.75 1.68 1.68 1.68zm0-4.26c.93 0 1.68-.75 1.68-1.68 0-.93-.75-1.68-1.68-1.68-.93 0-1.68.75-1.68 1.68 0 .93.75 1.68 1.68 1.68zm0 8.51c.93 0 1.68-.75 1.68-1.68 0-.93-.75-1.68-1.68-1.68-.93 0-1.68.75-1.68 1.68 0 .92.75 1.68 1.68 1.68zm-4.24-4.46c.81 0 1.47-.66 1.47-1.47s-.66-1.47-1.47-1.47c-.81 0-1.47.66-1.47 1.47s.65 1.47 1.47 1.47zm0-4.26c.81 0 1.47-.66 1.47-1.47s-.66-1.47-1.47-1.47c-.81 0-1.47.66-1.47 1.47s.65 1.47 1.47 1.47zm0 8.52c.81 0 1.47-.66 1.47-1.47 0-.81-.66-1.47-1.47-1.47-.81 0-1.47.66-1.47 1.47-.01.81.65 1.47 1.47 1.47zm0 4.27c.81 0 1.47-.66 1.47-1.47 0-.81-.66-1.47-1.47-1.47-.81 0-1.47.66-1.47 1.47-.01.81.65 1.47 1.47 1.47zm0-17.06c.81 0 1.47-.66 1.47-1.47S12.22 2 11.41 2c-.81 0-1.47.66-1.47 1.47s.65 1.47 1.47 1.47zm-4.25 8.32c.7 0 1.26-.57 1.26-1.26s-.57-1.26-1.26-1.26a1.261 1.261 0 000 2.52zm0 4.25a1.261 1.261 0 000-2.52 1.261 1.261 0 000 2.52zm0-8.49a1.261 1.261 0 000-2.52 1.261 1.261 0 000 2.52zm-3.87 4.03a1.05 1.05 0 100-2.1 1.05 1.05 0 000 2.1z"/>'
  }
})
