/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'sync_alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 12l4-4-4-4v3H3v2h15zM6 12l-4 4 4 4v-3h15v-2H6z"/>'
  }
})
