
import {Component, Watch} from 'vue-property-decorator'
import InputMixin from "../mixins/input";

@Component({
  name: 'Input',
  components: {},
  mixins: [InputMixin]
})
export default class Input extends InputMixin {

  private size = 100;
  private order = "px";

  @Watch("size")
  onSizeChange(){
    this.valueModel = this.size + this.order;
  }

  @Watch("order")
  onOrderChange(){
    this.valueModel = this.size + this.order;
  }

  created(){
    if (this.valueModel){
      this.order = this.valueModel.indexOf('%') >= 0 ? '%' : (this.valueModel.indexOf('px') >= 0 ? 'px' : 'em');
      this.size = this.valueModel.replace(this.order, "");
    }
  }
}
