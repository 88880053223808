
import { Component, Prop, Watch } from 'vue-property-decorator'
import InputMixin from "../mixins/input";

@Component({
  name: 'Input',
  components: {},
  mixins: [InputMixin]
})
export default class Input extends InputMixin {
  @Prop({ default: "text" }) private type! : string; // textarea, text, email, password,

  countries = [
    {
      "code": "93",
      "country": "Afghanistan",
      "countryCode": "AF"
    },
    {
      "code": "355",
      "country": "Albania",
      "countryCode": "AL"
    },
    {
      "code": "213",
      "country": "Algeria",
      "countryCode": "DZ"
    },
    {
      "code": "1-684",
      "country": "American Samoa",
      "countryCode": "AS"
    },
    {
      "code": "376",
      "country": "Andorra",
      "countryCode": "AD"
    },
    {
      "code": "244",
      "country": "Angola",
      "countryCode": "AO"
    },
    {
      "code": "1-264",
      "country": "Anguilla",
      "countryCode": "AI"
    },
    {
      "code": "672",
      "country": "Antarctica",
      "countryCode": "AQ"
    },
    {
      "code": "1-268",
      "country": "Antigua and Barbuda",
      "countryCode": "AG"
    },
    {
      "code": "54",
      "country": "Argentina",
      "countryCode": "AR"
    },
    {
      "code": "374",
      "country": "Armenia",
      "countryCode": "AM"
    },
    {
      "code": "297",
      "country": "Aruba",
      "countryCode": "AW"
    },
    {
      "code": "61",
      "country": "Australia",
      "countryCode": "AU"
    },
    {
      "code": "43",
      "country": "Austria",
      "countryCode": "AT"
    },
    {
      "code": "994",
      "country": "Azerbaijan",
      "countryCode": "AZ"
    },
    {
      "code": "1-242",
      "country": "Bahamas",
      "countryCode": "BS"
    },
    {
      "code": "973",
      "country": "Bahrain",
      "countryCode": "BH"
    },
    {
      "code": "880",
      "country": "Bangladesh",
      "countryCode": "BD"
    },
    {
      "code": "1-246",
      "country": "Barbados",
      "countryCode": "BB"
    },
    {
      "code": "375",
      "country": "Belarus",
      "countryCode": "BY"
    },
    {
      "code": "32",
      "country": "Belgium",
      "countryCode": "BE"
    },
    {
      "code": "501",
      "country": "Belize",
      "countryCode": "BZ"
    },
    {
      "code": "229",
      "country": "Benin",
      "countryCode": "BJ"
    },
    {
      "code": "1-441",
      "country": "Bermuda",
      "countryCode": "BM"
    },
    {
      "code": "975",
      "country": "Bhutan",
      "countryCode": "BT"
    },
    {
      "code": "591",
      "country": "Bolivia",
      "countryCode": "BO"
    },
    {
      "code": "387",
      "country": "Bosnia and Herzegovina",
      "countryCode": "BA"
    },
    {
      "code": "267",
      "country": "Botswana",
      "countryCode": "BW"
    },
    {
      "code": "55",
      "country": "Brazil",
      "countryCode": "BR"
    },
    {
      "code": "246",
      "country": "British Indian Ocean Territory",
      "countryCode": "IO"
    },
    {
      "code": "1-284",
      "country": "British Virgin Islands",
      "countryCode": "VG"
    },
    {
      "code": "673",
      "country": "Brunei",
      "countryCode": "BN"
    },
    {
      "code": "359",
      "country": "Bulgaria",
      "countryCode": "BG"
    },
    {
      "code": "226",
      "country": "Burkina Faso",
      "countryCode": "BF"
    },
    {
      "code": "257",
      "country": "Burundi",
      "countryCode": "BI"
    },
    {
      "code": "855",
      "country": "Cambodia",
      "countryCode": "KH"
    },
    {
      "code": "237",
      "country": "Cameroon",
      "countryCode": "CM"
    },
    {
      "code": "1",
      "country": "Canada",
      "countryCode": "CA"
    },
    {
      "code": "238",
      "country": "Cape Verde",
      "countryCode": "CV"
    },
    {
      "code": "1-345",
      "country": "Cayman Islands",
      "countryCode": "KY"
    },
    {
      "code": "236",
      "country": "Central African Republic",
      "countryCode": "CF"
    },
    {
      "code": "235",
      "country": "Chad",
      "countryCode": "TD"
    },
    {
      "code": "56",
      "country": "Chile",
      "countryCode": "CL"
    },
    {
      "code": "86",
      "country": "China",
      "countryCode": "CN"
    },
    {
      "code": "61",
      "country": "Christmas Island",
      "countryCode": "CX"
    },
    {
      "code": "61",
      "country": "Cocos Islands",
      "countryCode": "CC"
    },
    {
      "code": "57",
      "country": "Colombia",
      "countryCode": "CO"
    },
    {
      "code": "269",
      "country": "Comoros",
      "countryCode": "KM"
    },
    {
      "code": "682",
      "country": "Cook Islands",
      "countryCode": "CK"
    },
    {
      "code": "506",
      "country": "Costa Rica",
      "countryCode": "CR"
    },
    {
      "code": "385",
      "country": "Croatia",
      "countryCode": "HR"
    },
    {
      "code": "53",
      "country": "Cuba",
      "countryCode": "CU"
    },
    {
      "code": "599",
      "country": "Curacao",
      "countryCode": "CW"
    },
    {
      "code": "357",
      "country": "Cyprus",
      "countryCode": "CY"
    },
    {
      "code": "420",
      "country": "Czech Republic",
      "countryCode": "CZ"
    },
    {
      "code": "243",
      "country": "Democratic Republic of the Congo",
      "countryCode": "CD"
    },
    {
      "code": "45",
      "country": "Denmark",
      "countryCode": "DK"
    },
    {
      "code": "253",
      "country": "Djibouti",
      "countryCode": "DJ"
    },
    {
      "code": "1-767",
      "country": "Dominica",
      "countryCode": "DM"
    },
    {
      "code": "1-809, 1-829, 1-849",
      "country": "Dominican Republic",
      "countryCode": "DO"
    },
    {
      "code": "670",
      "country": "East Timor",
      "countryCode": "TL"
    },
    {
      "code": "593",
      "country": "Ecuador",
      "countryCode": "EC"
    },
    {
      "code": "20",
      "country": "Egypt",
      "countryCode": "EG"
    },
    {
      "code": "503",
      "country": "El Salvador",
      "countryCode": "SV"
    },
    {
      "code": "240",
      "country": "Equatorial Guinea",
      "countryCode": "GQ"
    },
    {
      "code": "291",
      "country": "Eritrea",
      "countryCode": "ER"
    },
    {
      "code": "372",
      "country": "Estonia",
      "countryCode": "EE"
    },
    {
      "code": "251",
      "country": "Ethiopia",
      "countryCode": "ET"
    },
    {
      "code": "500",
      "country": "Falkland Islands",
      "countryCode": "FK"
    },
    {
      "code": "298",
      "country": "Faroe Islands",
      "countryCode": "FO"
    },
    {
      "code": "679",
      "country": "Fiji",
      "countryCode": "FJ"
    },
    {
      "code": "358",
      "country": "Finland",
      "countryCode": "FI"
    },
    {
      "code": "33",
      "country": "France",
      "countryCode": "FR"
    },
    {
      "code": "689",
      "country": "French Polynesia",
      "countryCode": "PF"
    },
    {
      "code": "241",
      "country": "Gabon",
      "countryCode": "GA"
    },
    {
      "code": "220",
      "country": "Gambia",
      "countryCode": "GM"
    },
    {
      "code": "995",
      "country": "Georgia",
      "countryCode": "GE"
    },
    {
      "code": "49",
      "country": "Germany",
      "countryCode": "DE"
    },
    {
      "code": "233",
      "country": "Ghana",
      "countryCode": "GH"
    },
    {
      "code": "350",
      "country": "Gibraltar",
      "countryCode": "GI"
    },
    {
      "code": "30",
      "country": "Greece",
      "countryCode": "GR"
    },
    {
      "code": "299",
      "country": "Greenland",
      "countryCode": "GL"
    },
    {
      "code": "1-473",
      "country": "Grenada",
      "countryCode": "GD"
    },
    {
      "code": "1-671",
      "country": "Guam",
      "countryCode": "GU"
    },
    {
      "code": "502",
      "country": "Guatemala",
      "countryCode": "GT"
    },
    {
      "code": "44-1481",
      "country": "Guernsey",
      "countryCode": "GG"
    },
    {
      "code": "224",
      "country": "Guinea",
      "countryCode": "GN"
    },
    {
      "code": "245",
      "country": "Guinea-Bissau",
      "countryCode": "GW"
    },
    {
      "code": "592",
      "country": "Guyana",
      "countryCode": "GY"
    },
    {
      "code": "509",
      "country": "Haiti",
      "countryCode": "HT"
    },
    {
      "code": "504",
      "country": "Honduras",
      "countryCode": "HN"
    },
    {
      "code": "852",
      "country": "Hong Kong",
      "countryCode": "HK"
    },
    {
      "code": "36",
      "country": "Hungary",
      "countryCode": "HU"
    },
    {
      "code": "354",
      "country": "Iceland",
      "countryCode": "IS"
    },
    {
      "code": "91",
      "country": "India",
      "countryCode": "IN"
    },
    {
      "code": "62",
      "country": "Indonesia",
      "countryCode": "ID"
    },
    {
      "code": "98",
      "country": "Iran",
      "countryCode": "IR"
    },
    {
      "code": "964",
      "country": "Iraq",
      "countryCode": "IQ"
    },
    {
      "code": "353",
      "country": "Ireland",
      "countryCode": "IE"
    },
    {
      "code": "44-1624",
      "country": "Isle of Man",
      "countryCode": "IM"
    },
    {
      "code": "972",
      "country": "Israel",
      "countryCode": "IL"
    },
    {
      "code": "39",
      "country": "Italy",
      "countryCode": "IT"
    },
    {
      "code": "225",
      "country": "Ivory Coast",
      "countryCode": "CI"
    },
    {
      "code": "1-876",
      "country": "Jamaica",
      "countryCode": "JM"
    },
    {
      "code": "81",
      "country": "Japan",
      "countryCode": "JP"
    },
    {
      "code": "44-1534",
      "country": "Jersey",
      "countryCode": "JE"
    },
    {
      "code": "962",
      "country": "Jordan",
      "countryCode": "JO"
    },
    {
      "code": "7",
      "country": "Kazakhstan",
      "countryCode": "KZ"
    },
    {
      "code": "254",
      "country": "Kenya",
      "countryCode": "KE"
    },
    {
      "code": "686",
      "country": "Kiribati",
      "countryCode": "KI"
    },
    {
      "code": "383",
      "country": "Kosovo",
      "countryCode": "XK"
    },
    {
      "code": "965",
      "country": "Kuwait",
      "countryCode": "KW"
    },
    {
      "code": "996",
      "country": "Kyrgyzstan",
      "countryCode": "KG"
    },
    {
      "code": "856",
      "country": "Laos",
      "countryCode": "LA"
    },
    {
      "code": "371",
      "country": "Latvia",
      "countryCode": "LV"
    },
    {
      "code": "961",
      "country": "Lebanon",
      "countryCode": "LB"
    },
    {
      "code": "266",
      "country": "Lesotho",
      "countryCode": "LS"
    },
    {
      "code": "231",
      "country": "Liberia",
      "countryCode": "LR"
    },
    {
      "code": "218",
      "country": "Libya",
      "countryCode": "LY"
    },
    {
      "code": "423",
      "country": "Liechtenstein",
      "countryCode": "LI"
    },
    {
      "code": "370",
      "country": "Lithuania",
      "countryCode": "LT"
    },
    {
      "code": "352",
      "country": "Luxembourg",
      "countryCode": "LU"
    },
    {
      "code": "853",
      "country": "Macau",
      "countryCode": "MO"
    },
    {
      "code": "389",
      "country": "Macedonia",
      "countryCode": "MK"
    },
    {
      "code": "261",
      "country": "Madagascar",
      "countryCode": "MG"
    },
    {
      "code": "265",
      "country": "Malawi",
      "countryCode": "MW"
    },
    {
      "code": "60",
      "country": "Malaysia",
      "countryCode": "MY"
    },
    {
      "code": "960",
      "country": "Maldives",
      "countryCode": "MV"
    },
    {
      "code": "223",
      "country": "Mali",
      "countryCode": "ML"
    },
    {
      "code": "356",
      "country": "Malta",
      "countryCode": "MT"
    },
    {
      "code": "692",
      "country": "Marshall Islands",
      "countryCode": "MH"
    },
    {
      "code": "222",
      "country": "Mauritania",
      "countryCode": "MR"
    },
    {
      "code": "230",
      "country": "Mauritius",
      "countryCode": "MU"
    },
    {
      "code": "262",
      "country": "Mayotte",
      "countryCode": "YT"
    },
    {
      "code": "52",
      "country": "Mexico",
      "countryCode": "MX"
    },
    {
      "code": "691",
      "country": "Micronesia",
      "countryCode": "FM"
    },
    {
      "code": "373",
      "country": "Moldova",
      "countryCode": "MD"
    },
    {
      "code": "377",
      "country": "Monaco",
      "countryCode": "MC"
    },
    {
      "code": "976",
      "country": "Mongolia",
      "countryCode": "MN"
    },
    {
      "code": "382",
      "country": "Montenegro",
      "countryCode": "ME"
    },
    {
      "code": "1-664",
      "country": "Montserrat",
      "countryCode": "MS"
    },
    {
      "code": "212",
      "country": "Morocco",
      "countryCode": "MA"
    },
    {
      "code": "258",
      "country": "Mozambique",
      "countryCode": "MZ"
    },
    {
      "code": "95",
      "country": "Myanmar",
      "countryCode": "MM"
    },
    {
      "code": "264",
      "country": "Namibia",
      "countryCode": "NA"
    },
    {
      "code": "674",
      "country": "Nauru",
      "countryCode": "NR"
    },
    {
      "code": "977",
      "country": "Nepal",
      "countryCode": "NP"
    },
    {
      "code": "31",
      "country": "Netherlands",
      "countryCode": "NL"
    },
    {
      "code": "599",
      "country": "Netherlands Antilles",
      "countryCode": "AN"
    },
    {
      "code": "687",
      "country": "New Caledonia",
      "countryCode": "NC"
    },
    {
      "code": "64",
      "country": "New Zealand",
      "countryCode": "NZ"
    },
    {
      "code": "505",
      "country": "Nicaragua",
      "countryCode": "NI"
    },
    {
      "code": "227",
      "country": "Niger",
      "countryCode": "NE"
    },
    {
      "code": "234",
      "country": "Nigeria",
      "countryCode": "NG"
    },
    {
      "code": "683",
      "country": "Niue",
      "countryCode": "NU"
    },
    {
      "code": "850",
      "country": "North Korea",
      "countryCode": "KP"
    },
    {
      "code": "1-670",
      "country": "Northern Mariana Islands",
      "countryCode": "MP"
    },
    {
      "code": "47",
      "country": "Norway",
      "countryCode": "NO"
    },
    {
      "code": "968",
      "country": "Oman",
      "countryCode": "OM"
    },
    {
      "code": "92",
      "country": "Pakistan",
      "countryCode": "PK"
    },
    {
      "code": "680",
      "country": "Palau",
      "countryCode": "PW"
    },
    {
      "code": "970",
      "country": "Palestine",
      "countryCode": "PS"
    },
    {
      "code": "507",
      "country": "Panama",
      "countryCode": "PA"
    },
    {
      "code": "675",
      "country": "Papua New Guinea",
      "countryCode": "PG"
    },
    {
      "code": "595",
      "country": "Paraguay",
      "countryCode": "PY"
    },
    {
      "code": "51",
      "country": "Peru",
      "countryCode": "PE"
    },
    {
      "code": "63",
      "country": "Philippines",
      "countryCode": "PH"
    },
    {
      "code": "64",
      "country": "Pitcairn",
      "countryCode": "PN"
    },
    {
      "code": "48",
      "country": "Poland",
      "countryCode": "PL"
    },
    {
      "code": "351",
      "country": "Portugal",
      "countryCode": "PT"
    },
    {
      "code": "1-787, 1-939",
      "country": "Puerto Rico",
      "countryCode": "PR"
    },
    {
      "code": "974",
      "country": "Qatar",
      "countryCode": "QA"
    },
    {
      "code": "242",
      "country": "Republic of the Congo",
      "countryCode": "CG"
    },
    {
      "code": "262",
      "country": "Reunion",
      "countryCode": "RE"
    },
    {
      "code": "40",
      "country": "Romania",
      "countryCode": "RO"
    },
    {
      "code": "7",
      "country": "Russia",
      "countryCode": "RU"
    },
    {
      "code": "250",
      "country": "Rwanda",
      "countryCode": "RW"
    },
    {
      "code": "590",
      "country": "Saint Barthelemy",
      "countryCode": "BL"
    },
    {
      "code": "290",
      "country": "Saint Helena",
      "countryCode": "SH"
    },
    {
      "code": "1-869",
      "country": "Saint Kitts and Nevis",
      "countryCode": "KN"
    },
    {
      "code": "1-758",
      "country": "Saint Lucia",
      "countryCode": "LC"
    },
    {
      "code": "590",
      "country": "Saint Martin",
      "countryCode": "MF"
    },
    {
      "code": "508",
      "country": "Saint Pierre and Miquelon",
      "countryCode": "PM"
    },
    {
      "code": "1-784",
      "country": "Saint Vincent and the Grenadines",
      "countryCode": "VC"
    },
    {
      "code": "685",
      "country": "Samoa",
      "countryCode": "WS"
    },
    {
      "code": "378",
      "country": "San Marino",
      "countryCode": "SM"
    },
    {
      "code": "239",
      "country": "Sao Tome and Principe",
      "countryCode": "ST"
    },
    {
      "code": "966",
      "country": "Saudi Arabia",
      "countryCode": "SA"
    },
    {
      "code": "221",
      "country": "Senegal",
      "countryCode": "SN"
    },
    {
      "code": "381",
      "country": "Serbia",
      "countryCode": "RS"
    },
    {
      "code": "248",
      "country": "Seychelles",
      "countryCode": "SC"
    },
    {
      "code": "232",
      "country": "Sierra Leone",
      "countryCode": "SL"
    },
    {
      "code": "65",
      "country": "Singapore",
      "countryCode": "SG"
    },
    {
      "code": "1-721",
      "country": "Sint Maarten",
      "countryCode": "SX"
    },
    {
      "code": "421",
      "country": "Slovakia",
      "countryCode": "SK"
    },
    {
      "code": "386",
      "country": "Slovenia",
      "countryCode": "SI"
    },
    {
      "code": "677",
      "country": "Solomon Islands",
      "countryCode": "SB"
    },
    {
      "code": "252",
      "country": "Somalia",
      "countryCode": "SO"
    },
    {
      "code": "27",
      "country": "South Africa",
      "countryCode": "ZA"
    },
    {
      "code": "82",
      "country": "South Korea",
      "countryCode": "KR"
    },
    {
      "code": "211",
      "country": "South Sudan",
      "countryCode": "SS"
    },
    {
      "code": "34",
      "country": "Spain",
      "countryCode": "ES"
    },
    {
      "code": "94",
      "country": "Sri Lanka",
      "countryCode": "LK"
    },
    {
      "code": "249",
      "country": "Sudan",
      "countryCode": "SD"
    },
    {
      "code": "597",
      "country": "Suriname",
      "countryCode": "SR"
    },
    {
      "code": "47",
      "country": "Svalbard and Jan Mayen",
      "countryCode": "SJ"
    },
    {
      "code": "268",
      "country": "Swaziland",
      "countryCode": "SZ"
    },
    {
      "code": "46",
      "country": "Sweden",
      "countryCode": "SE"
    },
    {
      "code": "41",
      "country": "Switzerland",
      "countryCode": "CH"
    },
    {
      "code": "963",
      "country": "Syria",
      "countryCode": "SY"
    },
    {
      "code": "886",
      "country": "Taiwan",
      "countryCode": "TW"
    },
    {
      "code": "992",
      "country": "Tajikistan",
      "countryCode": "TJ"
    },
    {
      "code": "255",
      "country": "Tanzania",
      "countryCode": "TZ"
    },
    {
      "code": "66",
      "country": "Thailand",
      "countryCode": "TH"
    },
    {
      "code": "228",
      "country": "Togo",
      "countryCode": "TG"
    },
    {
      "code": "690",
      "country": "Tokelau",
      "countryCode": "TK"
    },
    {
      "code": "676",
      "country": "Tonga",
      "countryCode": "TO"
    },
    {
      "code": "1-868",
      "country": "Trinidad and Tobago",
      "countryCode": "TT"
    },
    {
      "code": "216",
      "country": "Tunisia",
      "countryCode": "TN"
    },
    {
      "code": "90",
      "country": "Turkey",
      "countryCode": "TR"
    },
    {
      "code": "993",
      "country": "Turkmenistan",
      "countryCode": "TM"
    },
    {
      "code": "1-649",
      "country": "Turks and Caicos Islands",
      "countryCode": "TC"
    },
    {
      "code": "688",
      "country": "Tuvalu",
      "countryCode": "TV"
    },
    {
      "code": "1-340",
      "country": "U.S. Virgin Islands",
      "countryCode": "VI"
    },
    {
      "code": "256",
      "country": "Uganda",
      "countryCode": "UG"
    },
    {
      "code": "380",
      "country": "Ukraine",
      "countryCode": "UA"
    },
    {
      "code": "971",
      "country": "United Arab Emirates",
      "countryCode": "AE"
    },
    {
      "code": "44",
      "country": "United Kingdom",
      "countryCode": "GB"
    },
    {
      "code": "1",
      "country": "United States",
      "countryCode": "US"
    },
    {
      "code": "598",
      "country": "Uruguay",
      "countryCode": "UY"
    },
    {
      "code": "998",
      "country": "Uzbekistan",
      "countryCode": "UZ"
    },
    {
      "code": "678",
      "country": "Vanuatu",
      "countryCode": "VU"
    },
    {
      "code": "379",
      "country": "Vatican",
      "countryCode": "VA"
    },
    {
      "code": "58",
      "country": "Venezuela",
      "countryCode": "VE"
    },
    {
      "code": "84",
      "country": "Vietnam",
      "countryCode": "VN"
    },
    {
      "code": "681",
      "country": "Wallis and Futuna",
      "countryCode": "WF"
    },
    {
      "code": "212",
      "country": "Western Sahara",
      "countryCode": "EH"
    },
    {
      "code": "967",
      "country": "Yemen",
      "countryCode": "YE"
    },
    {
      "code": "260",
      "country": "Zambia",
      "countryCode": "ZM"
    },
    {
      "code": "263",
      "country": "Zimbabwe",
      "countryCode": "ZW"
    },
  ];

  countryCode = "31";
  phonenumber = "";

  @Watch("countryCode")
  onCountryCodeChange(value: string){
    this.countryCode = value.replace(/\D/g,'');
    this.valueModel = "+" + value + " " + this.phonenumber;
  }

  @Watch("phonenumber")
  onPhonenumberChange(value: string){
    this.phonenumber = value.replace(/\D/g,'').substr(0, 12);
    this.valueModel = "+" + this.countryCode + " " + value;
  }

  created(){
    this.countryCode = this.valueModel.split(" ").shift().replace("+","");
    this.phonenumber = this.valueModel.split(this.countryCode).pop().split(" ").join("").replace("+","");
  }
}
