/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'plagiarism': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm1.04 17.45l-1.88-1.88c-1.33.71-3.01.53-4.13-.59a3.495 3.495 0 010-4.95 3.495 3.495 0 014.95 0 3.48 3.48 0 01.59 4.13l1.88 1.88-1.41 1.41zM13 9V3.5L18.5 9H13z"/><circle pid="1" cx="11.5" cy="14.5" r="1.5"/>'
  }
})
