/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'image_element': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M29.018 4.704H2.983c-.797 0-1.45.653-1.45 1.45v19.69c0 .797.653 1.45 1.45 1.45h26.038c.797 0 1.45-.653 1.45-1.45V6.154c0-.797-.653-1.45-1.453-1.45zm-1.45 2.902v11.168l-5.658-5.149a1.434 1.434 0 00-2.032.074l-5.802 6.128-3.155-3.446c-.253-.291-.653-.47-1.053-.47s-.797.144-1.053.435l-4.384 4.64V7.607h23.136zM14.333 24.432H5.159l4.678-4.931 4.496 4.931zm3.917 0L16 22l5.005-5.258 6.563 5.984v1.706H18.25z"/><path pid="1" d="M14.115 17.107c2.394 0 4.317-1.923 4.317-4.317s-1.923-4.317-4.317-4.317-4.317 1.923-4.317 4.317c0 2.358 1.958 4.317 4.317 4.317zm0-5.731c.762 0 1.414.618 1.414 1.414s-.618 1.414-1.414 1.414c-.797 0-1.414-.618-1.414-1.414s.653-1.414 1.414-1.414z"/>'
  }
})
