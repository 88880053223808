/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'vertical_align_top': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8 11h3v10h2V11h3l-4-4-4 4zM4 3v2h16V3H4z"/>'
  }
})
