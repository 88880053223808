import axios from 'axios'
import i18n from "@/lang";

if (!window.environment){
    window.environment = window.location.origin.split("/").pop()?.split(":").shift()?.split(".vee").shift()?.split("-vee").shift() || "";
}
console.log("Mode:", window.environment);

const baseUrls : { [key: string]: string } = {
    localhost: "https://us-central1-dev-veewar.cloudfunctions.net/api",
    dev: "https://us-central1-dev-veewar.cloudfunctions.net/api",
    test: "https://us-central1-test-veewar.cloudfunctions.net/api",
    acceptance: "https://us-central1-acceptance-veewar.cloudfunctions.net/api",
    cms: "https://us-central1-veewar-cms.cloudfunctions.net/api",
    "veewar-cms.web.app": "https://us-central1-veewar-cms.cloudfunctions.net/api",
}
const projects: { [key: string]: string } = {
    localhost: "dev-veewar",
    dev: "dev-veewar",
    test: "test-veewar",
    acceptance: "acceptance-veewar",
    cms: "veewar-cms",
    "veewar-cms.web.app": "veewar-cms"
}
if (!window.project){
    window.project = projects[window.environment || "dev"];
}
if(!window.apiUrl){
    window.apiUrl = baseUrls[window.environment || "dev"] || "https://us-central1-veewar-cms.cloudfunctions.net/api";
}

const config = {
    baseURL: baseUrls[window.environment || "dev"] || "https://us-central1-veewar-cms.cloudfunctions.net/api", // url = base url + request url
    timeout: 540000,
    withCredentials: true,
    crossDomain: true,
    keepAlive: true
}
const service = axios.create(config)



// Request interceptors
service.interceptors.request.use(
  async (config) => {
    // Add X-Access-Token header to every request, you can add other custom headers here
    if (sessionStorage.getItem("token")) {
        config.headers["Authorization"] = "Bearer " + sessionStorage.getItem("token");
    }
      config.headers["Access-Control-Allow-Origin"] = window.location.origin;
    return config
  },
  (error) => {
    console.error(error);
    Promise.reject(error)
  }
)

// Response interceptors
service.interceptors.response.use(
  (response) => {
        // Get data
        const res = response.data;
        console.log(res);

        // Handle
        if (res.status === "success" || res.status === 200){
            return res;
        } else {
            return Promise.reject(response)
        }
  },
  (error) => {
        console.error(error);
        if (!error.response){
          window.GlobalEvents.$emit("notify", {
              type: "error",
              title: i18n.t("notifications.unknown-error.title"),
              message: i18n.t("notifications.unknown-error.message")
          });
        } else if (error.code === 401) {
            console.log(error);
            //window.location.reload();
        } else if (notificationErrorCodes.includes(error.response.data.code)){
            window.GlobalEvents.$emit("notify", {
                type: "error",
                title: i18n.t("notifications." + error.response.data.code + ".title"),
                message: i18n.t("notifications." + error.response.data.code + ".message")
            })
        }

        return Promise.reject(error)
  }
)

const notificationErrorCodes: string[] = []

export default service
