/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'element_3d-animation_2': {
    width: 1015,
    height: 1024,
    viewBox: '0 0 1015 1024',
    data: '<path pid="0" d="M613.206 486.35l-105.72-59.221-105.72 59.221 105.72 59.221 105.72-59.221zM0 267.132h89.037v89.037H0v-89.037zM0 0h89.037v89.037H0V0zM528.621 704.131l105.72-61.314V522.302l-105.72 61.314v120.515zM0 400.698h89.037v89.037H0v-89.037zM0 133.587h89.037v89.037H0v-89.037zM0 934.963h89.037V1024H0v-89.037zM0 801.397h89.037v89.037H0v-89.037zM0 534.285h89.037v89.037H0v-89.037zM0 667.831h89.037v89.037H0v-89.037zM925.913 400.698h89.037v89.037h-89.037v-89.037zM925.913 267.132h89.037v89.037h-89.037v-89.037zM925.913 133.587h89.037v89.037h-89.037v-89.037zM925.913 534.285h89.037v89.037h-89.037v-89.037zM925.913 0h89.037v89.037h-89.037V0zM486.329 583.616l-105.72-61.314v120.515l105.72 61.314V583.616zM925.913 667.831h89.037v89.037h-89.037v-89.037z"/><path pid="1" d="M127.595 1024h761.218V.021H127.595V1024zm66.937-355.821l52.881-29.611-19.022 69.769 109.968-63.428v-158.58c0-8.454 4.227-14.795 10.588-19.022l137.444-78.244V264.3l-50.747 50.747v-61.314l71.903-71.903 71.903 71.903v61.314L528.703 264.3v124.763l137.444 78.244c6.341 4.227 10.588 10.588 10.588 19.022v156.467l109.968 63.428-19.084-69.748 52.881 29.611 27.477 97.266-97.266 27.477-52.881-29.611 69.769-19.022-109.968-63.428-137.444 78.244c-6.341 4.227-14.795 4.227-21.136 0l-139.558-76.109-109.968 63.428 67.676 16.929-52.881 29.611-97.266-27.477 27.477-95.214zM925.913 934.963h89.037V1024h-89.037v-89.037zM925.913 801.397h89.037v89.037h-89.037v-89.037z"/>'
  }
})
