/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'element_image': {
    width: 1297,
    height: 1024,
    viewBox: '0 0 1297 1024',
    data: '<path pid="0" d="M1194.667 0H102.392C46.076 0 0 46.075 0 102.392v853.333C0 993.276 30.724 1024 68.275 1024h1160.529c37.551 0 68.275-30.724 68.275-68.275V102.392C1297.058 46.076 1250.983 0 1194.667 0zM102.392 68.275h1092.254c18.765 0 34.137 15.352 34.137 34.137v657.074L936.959 469.338c-27.31-27.31-69.971-27.31-97.281 0L713.39 595.626l-252.597-256.01c-27.31-27.31-69.971-27.31-97.281 0L68.275 634.874V102.392c0-18.765 15.352-34.117 34.117-34.117zm0 887.45c-18.765 0-34.137-15.352-34.137-34.137V732.156l343.03-343.03 254.293 254.293c27.31 27.31 69.971 27.31 97.281 0l126.288-126.288 339.657 339.616v64.861c0 18.765-15.352 34.137-34.137 34.137l-1092.275-.021z"/><path pid="1" d="M1047.896 354.988c63.144 0 114.35-51.206 114.35-114.35s-51.206-114.35-114.35-114.35-114.35 51.206-114.35 114.35c1.697 63.144 51.206 114.35 114.35 114.35zm0-158.729c25.593 0 46.075 20.482 46.075 46.075s-20.482 46.075-46.075 46.075c-25.593 0-46.075-20.482-46.075-46.075 1.697-25.593 20.462-46.075 46.075-46.075z"/>'
  }
})
