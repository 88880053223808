import Vue from 'vue'
import Component from 'vue-class-component'
import {Marker, MarkerList} from "@/data/classes/marker";
import {UserModule} from "@/store/modules/user";
import {MarkerModule} from "@/store/modules/marker";


@Component
export class MarkerMixin extends Vue {

    public markerQuery = {
        cursor: null,
        page: 1,
        limit: 6,
        rowSize: 5,
        filters: {
            archived: false,
            sort: "DocumentUpdatedDateTime-desc",
            customerId: null,
            active: null,
            search: ""
        },
        total: 0,
    }

    get markers(){
        return this.$store.state.marker.list as MarkerList;
    }

    get queriedMarkers(){
        // Sort markers
        const [sortKey, sortDirection] = this.markerQuery.filters.sort.split('-');
        let queriedMarkers = this.markers.sort((a,b) => {
            if (sortDirection === "asc"){
                return a[sortKey] - b[sortKey];
            } else {
                return b[sortKey] - a[sortKey];
            }
        }) as Marker[];

        // Filter markers
        queriedMarkers = queriedMarkers.filter((marker) => {
            let include = true;
            if (!this.markerQuery.filters.archived && marker.Archived){
                include = false;
            }

            if (this.markerQuery.filters.customerId && marker.CustomerId !== this.markerQuery.filters.customerId){
                include = false;
            }

            if (this.markerQuery.filters.active && marker.Active !== this.markerQuery.filters.active){
                include = false;
            }

            if (this.markerQuery.filters.search){
                const searchTerm = this.markerQuery.filters.search;
                if (marker.Id.indexOf(searchTerm) < 0 && marker.DisplayName.indexOf(searchTerm) < 0){
                    include = false;
                }
            }

            return include;
        });

        // Scroll event
        const overview = document.querySelector(".marker-overview");
        if (overview){
            overview.scrollTop = 500;
            overview.scrollTo({top:0, behavior: "smooth"});
        }

        return queriedMarkers;
    }

    get paginatedMarkers(){
        // Get queried markers
        let paginatedMarkers = this.queriedMarkers;

        // Paginate markers
        const startIndex = (this.markerQuery.page - 1) * this.markerQuery.limit;
        const endIndex = this.markerQuery.page * this.markerQuery.limit;
        paginatedMarkers = paginatedMarkers.slice(startIndex, endIndex);

        return paginatedMarkers;
    }

    get selectedMarkers(){
        return this.markers.filter((marker) => marker._selected);
    }

    public async loadMarkers(): Promise<any> {
        if (!UserModule.license){
            await window.timeout(1000);
            return this.loadMarkers();
        }

        // Start loading
        const loading = this.$loading({
            lock: true,
            target: '#dashboard',
        });
        await MarkerModule.GetMarkers().finally(() => {
            loading?.close();
        })
        // Set
        this.markerQuery.total = this.markers.length;

        // Markers loaded
        window.GlobalEvents.$emit("markers-loaded");
    }

}
