
import { Component, Prop } from 'vue-property-decorator'
import InputMixin from "../mixins/input";
import {createRandomToken} from "@/globalFunctions";

@Component({
  name: 'Input',
  components: {},
  mixins: [InputMixin]
})
export default class Input extends InputMixin {
  @Prop({ default: "text" }) private type! : string; // textarea, text, email, password,

  private id = createRandomToken();

  openColorPicker(){
    // Open color picker
    (document.querySelector("#color-picker-" + this.id + "  .el-color-picker__trigger") as HTMLElement).click();
  }
}
