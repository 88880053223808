import { VuexModule, Module, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'
import {downloadFile, getFileLastModified} from "@/firebase/functions";
import {fileToDataUrl} from "@/globalFunctions";

export interface IDatabaseModuleState {
  paths: Record<string, unknown>;
}

@Module({ dynamic: true, store, name: 'database' })
class Database extends VuexModule implements IDatabaseModuleState {
  dbVersion = 1;
  paths: Record<string, unknown>;

  @Action
  public async OpenDatabase(callback: (files: any[]) => void) {
    // Create/open database
    const request = indexedDB.open("FileDatabase", this.dbVersion);

    // For future use. Currently only in latest Firefox versions
    request.onupgradeneeded = (event: any) => {
      // Create database
      const db = event.target.result;

      db.onerror = (event: any) => {
        console.error("Error creating/accessing IndexedDB database", event);
        return Promise.reject();
      };

      // Create an objectStore for this database
      db.createObjectStore("Files");

      // Create transaction
      const transaction = event.target.transaction;

      if (transaction){
        // Wait for transaction to be completed
        transaction.oncomplete = () => {
          // Callback
          callback(db.transaction(["Files"], "readwrite").objectStore("Files"));
        }
      } else {
        // Start the transaction
        callback(db.transaction(["Files"], "readwrite").objectStore("Files"));
      }
    };

    request.onsuccess = (event: any) => {
      // Create database
      const db = event.target.result;

      db.onerror = (event: any) => {
        console.error("Error creating/accessing IndexedDB database", event);
      };

      // Create transaction
      const transaction = event.target.transaction;

      if (transaction){
        // Wait for transaction to be completed
        transaction.oncomplete = () => {
          // Callback
          callback(db.transaction(["Files"], "readwrite").objectStore("Files"));
        }
      } else {
        callback(db.transaction(["Files"], "readwrite").objectStore("Files"));
      }
    }
  }

  @Action
  public async Get(filepath: string) {
    return new Promise((resolve, reject) => {
      this.OpenDatabase((objectStore: any) => {
        // Get file
        const request = objectStore.get(filepath);

        // Handlers
        request.onerror = (event: any) => {
          reject(event);
        };
        request.onsuccess = () => {
          resolve(request.result);
        };
      });
    })
  }

  @Action
  public async Download({filepath, file, metadata}: {filepath: string, file?: File | Blob | string | null, metadata?: any}) {
    // Download blob from filepath if not given
    if (!file){
      file = await downloadFile(filepath) as Blob;
    }

    // Convert to data url
    const dataUrl = typeof file === "string" ? file : await fileToDataUrl(file);

    // Put in the database
    return this.OpenDatabase((objectStore: any) => {
      // Put file
      objectStore.put({
        path: filepath,
        dataUrl: dataUrl,
        lastModified: Date.now(),
        metadata
      }, filepath);

      // Return value
      return true;
    });
  }

  @Action
  public async Delete(filepath: string) {
    // Open the database
    return this.OpenDatabase((objectStore: any) => {
      // Delete file
      return objectStore.delete(filepath);
    });
  }

  @Action public async Update(){
    // List all files and check if we need to download
    return this.OpenDatabase((objectStore: any) => {
      objectStore.getAll().onsuccess = async (event: any) => {
        for (const object of event.target.result){
          const lastModified = await getFileLastModified(object.path).catch((error) => {
            // Delete the object locally if it does not exists in the storage
            if (error === "storage/object-not-found"){
              this.Delete(object.path);
            }
          });

          // If the file was modified, update it locally
          if (lastModified){
            if (lastModified > object.lastModified){
              await this.Download({filepath: object.path, file: null, metadata: object.metadata});
            }
          }
        }
      };
    });
  }

}

export const DatabaseModule = getModule(Database)
