/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'element_audio': {
    width: 896,
    height: 1024,
    viewBox: '0 0 896 1024',
    data: '<path pid="0" d="M853.808 229.085c-22.21-30.299-53.175-65.714-87.175-99.715s-69.416-64.966-99.694-87.175C615.345 4.368 590.328.001 575.999.001H80.001C35.893.001 0 35.894 0 80.002V944c0 44.108 35.893 80.001 80.001 80.001h736.001c44.108 0 80.001-35.893 80.001-80.001V320.005c0-14.328-4.367-39.345-42.194-90.919zm-132.427-54.464c30.694 30.694 54.797 58.394 72.577 81.373h-153.95v-153.95c22.979 17.78 50.679 41.882 81.373 72.577zm110.613 769.378c0 8.672-7.32 15.992-15.992 15.992H80.001c-8.672 0-15.992-7.32-15.992-15.992V80.001c0-8.672 7.32-15.992 15.992-15.992h495.998v223.99c0 17.676 14.328 32.005 32.005 32.005h223.99v623.995z"/><path pid="1" d="M692.288 391.25a32.047 32.047 0 00-26.556-6.634l-320.004 64.009c-14.952 2.995-25.724 16.117-25.724 31.381V716.89c-18.841-8.173-40.676-12.873-64.009-12.873-70.685 0-127.997 42.985-127.997 95.993 0 53.029 57.313 95.993 127.997 95.993s127.997-42.985 127.997-95.993V570.239l255.995-51.199v133.841c-18.841-8.173-40.676-12.873-64.009-12.873-70.685 0-127.997 42.985-127.997 95.993 0 53.029 57.313 95.993 127.997 95.993s127.997-42.985 127.997-95.993V415.997a31.869 31.869 0 00-11.687-24.747z"/>'
  }
})
