/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'display_settings': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20 3H4c-1.11 0-2 .89-2 2v12a2 2 0 002 2h4v2h8v-2h4c1.1 0 2-.9 2-2V5a2 2 0 00-2-2zm0 14H4V5h16v12z"/><path pid="1" d="M6 8.25h8v1.5H6zm10.5 1.5H18v-1.5h-1.5V7H15v4h1.5zm-6.5 2.5h8v1.5h-8zM7.5 15H9v-4H7.5v1.25H6v1.5h1.5z"/>'
  }
})
