/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'screenshot_monitor': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20 3H4c-1.11 0-2 .89-2 2v12a2 2 0 002 2h4v2h8v-2h4c1.1 0 2-.9 2-2V5a2 2 0 00-2-2zm0 14H4V5h16v12z"/><path pid="1" d="M6.5 7.5H9V6H5v4h1.5zM19 12h-1.5v2.5H15V16h4z"/>'
  }
})
