/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'carousel_element': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M9.574 3.547A1.053 1.053 0 008.63 4.6v3.165H5.465a.661.661 0 00-.109 0 1.053 1.053 0 00-.944 1.053v3.165H1.244a.661.661 0 00-.109 0 1.053 1.053 0 00-.944 1.053V24.29a.661.661 0 000 .109v2.704c0 .582.47 1.053 1.053 1.053h21.101c.582 0 1.053-.47 1.053-1.053v-.624-1.482-1.056h3.162c.582 0 1.053-.47 1.053-1.053v-3.165h3.171c.582 0 1.053-.47 1.053-1.053V4.603c0-.582-.47-1.053-1.053-1.053H9.683c-.035-.003-.074-.003-.109-.003zm1.165 2.112h18.992v11.958h-2.112V8.823v-.099a1.055 1.055 0 00-1.053-.957H10.739V5.658zM6.518 9.876H25.51v8.794a.661.661 0 000 .109v3.056h-2.112v-8.794-.099a1.055 1.055 0 00-1.053-.957H6.518V9.876zm-4.22 4.221H21.29v8.461l-3.494-3.264a1.077 1.077 0 00-1.44 0l-4.077 3.702-5.069-4.429a1.05 1.05 0 00-.781-.253 1.082 1.082 0 00-.451.144l-3.68 2.208v-6.57zm10.198.704c-1.542 0-2.813 1.27-2.813 2.813s1.27 2.813 2.813 2.813 2.813-1.27 2.813-2.813a2.827 2.827 0 00-2.813-2.813zm0 2.112c.4 0 .704.301.704.704 0 .4-.301.704-.704.704s-.704-.301-.704-.704c0-.403.304-.704.704-.704zM6.397 20.67l4.31 3.76-1.792 1.626H2.297v-1.683-1.242l4.099-2.461zm10.662.848l4.234 3.946v.595h-9.226c0-.003 4.992-4.541 4.992-4.541z"/>'
  }
})
