/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow_forward_ios': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.23 20.23L8 22l10-10L8 2 6.23 3.77 14.46 12z"/>'
  }
})
