/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'kebab_dining': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.75 8H11v5H7.75v1h.75a2.5 2.5 0 010 5h-.75v4h-1.5v-4H5.5a2.5 2.5 0 010-5h.75v-1H3V8h3.25V7H5.5a2.5 2.5 0 010-5h.75V1h1.5v1h.75a2.5 2.5 0 010 5h-.75v1zm10-1h.75a2.5 2.5 0 000-5h-.75V1h-1.5v1h-.75a2.5 2.5 0 000 5h.75v1H13v5h3.25v1h-.75a2.5 2.5 0 000 5h.75v4h1.5v-4h.75a2.5 2.5 0 000-5h-.75v-1H21V8h-3.25V7z"/>'
  }
})
