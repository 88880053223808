/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'pest_control_rodent': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.31 17.38l-2.39-2.13c.52-2.36-1.36-4.25-3.42-4.25-1.16 0-3.5.9-3.5 3.5 0 .97.39 1.84 1.03 2.47l-.71.71A4.457 4.457 0 0111 14.5c0-1.7.96-3.17 2.35-3.93a5 5 0 00-2.28-.57c-2.38 0-4.37 1.65-4.91 3.87A2.997 2.997 0 014 11c0-1.66 1.34-3 3-3h2.5a2.5 2.5 0 000-5H8c-.55 0-1 .45-1 1s.45 1 1 1h1.5c.28 0 .5.22.5.5s-.22.5-.5.5H7a5.002 5.002 0 00-1 9.9v.03C6 18.73 8.27 21 11.07 21h8.86c1.87 0 2.81-2.34 1.38-3.62zM18 19c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"/>'
  }
})
