/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'auto_awesome_motion': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14 2H4a2 2 0 00-2 2v10h2V4h10V2zm4 4H8a2 2 0 00-2 2v10h2V8h10V6zm2 4h-8a2 2 0 00-2 2v8a2 2 0 002 2h8c1.1 0 2-.9 2-2v-8c0-1.1-.9-2-2-2z"/>'
  }
})
