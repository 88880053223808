
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import html2canvas from "html2canvas";
import {toRGBA} from "@/globalFunctions";

@Component({
  name: 'AudioViewer',
  components: {}
})
export default class extends Vue {
  @Prop({default: null }) src: string;
  @Prop({default: () => { return {};}}) private metadata: Record<string, any>;
  @Prop({default: true }) controls: boolean;

  private fontSize = "150px";

  private playing = false;
  private sound: HTMLAudioElement;
  private progress = 0;

  @Watch('metadata.ButtonStyle', {deep: true})
  onButtonStyleChange(){
    this.updateThumbnail();
  }

  get backgroundColor(){
    return this.metadata?.ButtonStyle?.BackgroundColor ? toRGBA(this.metadata?.ButtonStyle?.BackgroundColor) : '#fff';
  }

  get borderColor(){
    return this.metadata?.ButtonStyle?.BorderColor ? toRGBA(this.metadata?.ButtonStyle?.BorderColor) : "#000";
  }

  get iconColor(){
    return this.metadata?.ButtonStyle?.IconColor ? toRGBA(this.metadata?.ButtonStyle?.IconColor) : "#000";
  }

  private toggle(){
    if (this.playing){
      this.pause();
    } else {
      this.play();
    }
  }

  play(){
    if (!this.sound){
      //Initiate
      this.sound = new Audio(this.src);

      //Load
      this.sound.load();

      //Add event on timeupdate
      this.sound?.removeEventListener('timeupdate', () => { return null; });
      this.sound.addEventListener('timeupdate', () => {
        this.progress = (this.sound.currentTime/this.sound.duration) * 100;

        if (this.progress === 100){
          this.playing = false;
          this.progress = 0;
          this.sound.currentTime = 0;
        }
      });
    }

    //Play sound
    this.sound.play()
    this.playing = true;
  }

  pause(){
    //Pause audio
    this.sound?.pause();
    this.playing = false;
  }

  private format(progress: number){
    if (this.sound){
      const currentTime = (progress/100) * this.sound.duration;
      const minutes = Math.floor(currentTime / 60)
      const seconds = Math.round(currentTime - minutes * 60);

      if (isNaN(minutes) || isNaN(seconds)){
        return "00:00";
      }

      return (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
    } else {
      return "00:00";
    }
  }

  private async createImage(): Promise<string>{
    let div = document.getElementById("audio-button");
    if (div){
      return html2canvas(div, {
        useCORS: true,
        backgroundColor: null,
        scale: 2
      }).then((canvas: HTMLCanvasElement) => {
        let myImage = canvas.toDataURL("image/png");
        //Return data
        return Promise.resolve(myImage);
      }).catch((err: Error) => {
        return Promise.reject(err);
      });
    } else {
      return Promise.reject("Content not found");
    }
  }

  private updateThumbnail(){
    setTimeout(async () => {
      // Create image
      const image = await this.createImage();

      // Emit
      window.GlobalEvents.$emit("audio-viewer-captured-image", image);
    }, 500);
  }

  onWindowResizeEnd(){
    this.fontSize = (this.$refs.wrapper as Element).clientWidth * .6 + "px"
  }

  mounted(){
    if (this.$refs.wrapper){
      this.fontSize = (this.$refs.wrapper as Element).clientWidth * .6 + "px"
      window.GlobalEvents.$on("window-resize-end", this.onWindowResizeEnd);
    }

    // Create audio thumbnail
    this.updateThumbnail();
  }

  beforeDestroy(){
    window.GlobalEvents.$off("window-resize-end", this.onWindowResizeEnd);
    this.pause();
  }
}
