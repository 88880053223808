/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'railway_alert': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23 8a7 7 0 00-11.95-4.95A33.8 33.8 0 009 3c-4.42 0-8 .5-8 4v10.5A3.5 3.5 0 004.5 21L3 22.5v.5h12v-.5L13.5 21a3.5 3.5 0 003.5-3.5v-2.58A7 7 0 0023 8zM3 12V7h6.08a6.96 6.96 0 001.18 5H3zm6 7c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm7.71-6.06l-.2.03L16 13l-.47-.02-.16-.02-.29-.04-.2-.04-.22-.06a1.55 1.55 0 01-.23-.07l-.13-.05A4.99 4.99 0 0111.1 7c.04-.19.09-.37.15-.54l.05-.14.15-.38.07-.15.2-.36.07-.12.3-.42.02-.02c.24-.3.52-.57.82-.81l.01-.01.46-.32.03-.02A5.25 5.25 0 0116 3a5 5 0 01.71 9.94zM15 4h2v5h-2zm0 6h2v2h-2z"/>'
  }
})
