/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'element_custom_button': {
    width: 1024,
    height: 1024,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M643.652 285.902l63.669 50.64 38.68-43.913-63.007-52.306z"/><path pid="1" d="M853.326 0H170.674C76.42 0 0 76.42 0 170.674v682.674c0 94.254 76.42 170.674 170.674 170.674h682.674c94.254 0 170.674-76.42 170.674-170.674V170.653C1023.979 76.399 947.581.001 853.327.001zm-61.661 304.12L385.111 765.736a20.793 20.793 0 01-6.322 4.891l-121.358 61.021a20.907 20.907 0 01-22.192-2.114 20.963 20.963 0 01-7.689-20.931l28.428-134.173a21.227 21.227 0 014.635-9.355l404.141-467.682c7.454-8.629 20.461-9.697 29.218-2.413l95.365 79.196c4.378 3.61 7.048 8.821 7.497 14.481.449 5.639-1.431 11.213-5.169 15.463z"/><path pid="2" d="M295.833 688.355l-18.283 86.287 78.812-39.641 324.795-368.75-63.391-50.405z"/>'
  }
})
