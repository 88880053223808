interface ISettings {
  title: string // Overrides the default title
  showSettings: boolean // Controls settings panel display
  showSidebarLogo: boolean // Controls siderbar logo display
  fixedHeader: boolean // If true, will fix the header component
  sidebarTextTheme: boolean // If true, will change active text color for sidebar based on theme
  devServerPort: number // Port number for webpack-dev-server
  mockServerPort: number // Port number for mock server
  languages: Record<string, string>[]
  defaultLanguage: string
}

// You can customize below settings :)
const settings: ISettings = {
  title: 'VEEWAR',
  showSettings: true,
  fixedHeader: false,
  showSidebarLogo: true,
  sidebarTextTheme: true,
  devServerPort: 9527,
  mockServerPort: 9528,
  languages: [{
    key: "NL",
    label: "languages.dutch"
  },{
    key: "EN",
    label: "languages.english"
  },{
    key: "FR",
    label: "languages.french"
  },{
    key: "DE",
    label: "languages.german"
  }],
  defaultLanguage: "NL"
}

export default settings
