/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'airlines': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13 4L2 20h17l3-16h-9zm1.5 10a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"/>'
  }
})
