
import {Component, Prop} from 'vue-property-decorator'
import InputMixin from "../mixins/input";

@Component({
  name: 'Select',
  components: {},
  mixins: [InputMixin]
})
export default class Select extends InputMixin {
  @Prop({ default: false }) private filterable? : boolean;
  @Prop({ default: false }) private multiple! : boolean;
  @Prop({ default: null }) private optionGroups! : Record<string, unknown>[];
  @Prop({ default: () => [] }) private options! : Record<string, unknown>[];
  @Prop({ default: null }) private dynamicOptions: (input?: any) => any;

  private loadedOptions: Record<string, unknown>[] = [];
  private retrieved = false;
  private retrieving = false;

  created(){
    // Set loaded options
    this.loadedOptions = this.options;
    if (!this.dynamicOptions){
      this.retrieved = true;
    } else {
      this.getDynamicOptions();
    }
  }

  async getDynamicOptions(){
    if (!this.retrieved && !this.retrieving && this.dynamicOptions){
      this.retrieving = true;
      this.loadedOptions = await this.dynamicOptions();
      this.retrieved = true;
    }
  }

}
