
import { Component, Watch } from 'vue-property-decorator'
import InputMixin from "../mixins/input";

@Component({
  name: 'List',
  components: {},
  mixins: [InputMixin]
})
export default class List extends InputMixin {
  items: any[] = [];

  @Watch("items", {deep: true})
  onItemsChanged(value: any){
    this.$emit("input", value.map((item: any) => item.value))
  }

  @Watch("valueModel", {deep: true})
  onValueModelChanged(value: any){
    if (this.items.length === 0 && value.length > 0){
      this.items = value ? value.map((value: any, index: number) => {
        return {
          value,
          index
        }
      }) : [];
    }
  }

  addItem(){
    this.items.push({
      value: "",
      index: this.items.length
    });
  }

  removeItem(index: number){
    this.items.splice(index, 1);
  }
}
