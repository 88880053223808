import request from '@/utils/request'

export const getBugReports = (params?: any) =>
    request({
        url: 'bug-report',
        method: 'get',
        params
    })

export const getBugReport = (bugReportId?: string, params?: any) =>
    request({
        url: 'bug-report/' + bugReportId,
        method: 'get',
        params
    })

export const createBugReport = (data?: any) =>
    request({
        url: 'bug-report',
        method: 'post',
        data: data
    })

export const updateBugReport = (bugReportId?: string, data?: any) =>
    request({
        url: 'bug-report/' + bugReportId,
        method: 'put',
        data
    })

export const deleteBugReport = (bugReportId: string) =>
    request({
        url: 'bug-report/' + bugReportId,
        method: 'delete'
    })
