/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'spatial_audio_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="10" cy="9" r="4"/><path pid="1" d="M16.39 15.56C14.71 14.7 12.53 14 10 14s-4.71.7-6.39 1.56A2.97 2.97 0 002 18.22V21h16v-2.78c0-1.12-.61-2.15-1.61-2.66zM20.36 1l-1.41 1.41a7.007 7.007 0 010 9.9l1.41 1.41a8.98 8.98 0 000-12.72z"/><path pid="2" d="M17.54 10.9a5.003 5.003 0 000-7.07l-1.41 1.41a3 3 0 010 4.24l1.41 1.42z"/>'
  }
})
