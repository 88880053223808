import request from '@/utils/request'
import {Marker} from '@/data/classes/marker'
import {UserModule} from "@/store/modules/user";

export const getMarkers = async (licenseId?: string, params?: any) =>
    request({
        url: 'license/' + (licenseId ? licenseId : UserModule.license) + '/marker',
        method: 'get',
        params
    });


export const getMarker = (licenseId?: string, id?: string) =>
    request({
        url: 'license/' + (licenseId ? licenseId : UserModule.license) + '/marker/' + id,
        method: 'get'
    })

export const getVuforiaData = (licenseId?: string, id?: string) =>
    request({
        url: 'license/' + (licenseId ? licenseId : UserModule.license) + '/marker/' + id + '/vuforia',
        method: 'get'
    })

export const createMarker = (licenseId?: string, marker?: Marker) =>
    request({
        url: 'license/' + (licenseId ? licenseId : UserModule.license) + '/marker',
        method: 'post',
        data: marker
    })

export const updateMarker = (licenseId?: string, id?: string, data?: any) =>
    request({
        url: 'license/' + (licenseId ? licenseId : UserModule.license) + '/marker/' + id,
        method: 'put',
        data
    })

export const deleteMarker = (licenseId?: string, id?: string) =>
    request({
        url: 'license/' + (licenseId ? licenseId : UserModule.license) + '/marker/' + id,
        method: 'delete'
    })
