
import { Component, Vue, Watch } from 'vue-property-decorator'
import {Tutorial, TutorialList, TutorialSectionList} from "@/data/classes/tutorial";
import {sendEvent} from "@/firebase/config";

@Component({
  name: 'TutorialDrawer',
  components: {
  }
})
export default class extends Vue {
  // Variables
  private visible = false;
  private retrieving = false;
  private link = "";
  private view: "overview" | "tutorial" = "overview";

  private tutorial = new Tutorial();
  private tutorials = new TutorialList();
  private tutorialSections = new TutorialSectionList();

  get language(){
    return this.$i18n.locale.split("-")[0];
  }

  // Watchers
  @Watch("langauge")
  onLanguageChange(){
    this.goToOverview();
  }

  // Methods
  public async openTutorial(link?: string, ){
    if (this.retrieving){
      return;
    }

    // Analytics
    sendEvent("help_opened", {
      tutorial_link: link
    });

    // Open drawer
    this.retrieving = true;
    this.visible = true;
    const loading = this.$loading({
      target: "#tutorial-drawer .el-drawer",
      lock: true
    });

    try {
      if (link){
        // Set link
        this.link = link;

        // Get tutorial
        await this.tutorial.getByLink(link, this.language)
        await this.tutorialSections.get(this.tutorial.Id);

        // Set view
        this.view = "tutorial";

        // Go to link
        this.scrollToSection();
      } else {
        // Go to overview
        await this.goToOverview();
      }
    }
    catch (e) {
      console.error(e);
    }

    this.retrieving = false;
    loading?.close();
  }

  private scrollToSection(){
    //Get index of section
    let index = this.tutorialSections.findIndex((x) => {
      return x.Link ? x.Link.indexOf(this.link) >= 0 : false;
    });
    if (index < 0){
      return false;
    }

    //Check if refs are available
    if (!this.$refs.tutorialSection || !this.$refs.tutorial){
      setTimeout(this.scrollToSection, 100);
    } else {
      const htmlElement = (this.$refs.tutorialSection as any)[index] as any;

      //Scroll to section
      (this.$refs.tutorial as HTMLElement).scrollTop = htmlElement.offsetTop - 300;

      //Highlight
      htmlElement.style.backgroundColor = "#999";
      setTimeout(() => {
        htmlElement.style.backgroundColor = (index%2 === 0 ? '#FFFFFF' : '#F1F1F1');
      },2000);
    }
  }

  public async goToTutorial(tutorialId: string){
    const loading = this.$loading({
      target: "#tutorial-drawer .el-drawer",
      lock: true
    });

    // Get tutorial
    await this.tutorial.get(tutorialId);
    await this.tutorialSections.get(tutorialId);
    this.view = "tutorial";

    loading?.close();
  }

  private async goToOverview(){
    const loading = this.$loading({
      target: "#tutorial-drawer .el-drawer",
      lock: true
    });

    // Get tutorials
    if (this.tutorials.length === 0){
      await this.tutorials.get({
        language: this.language
      });
    }

    // Set view
    this.view = "overview";


    loading?.close();
    return true;
  }

  mounted(){
    this.setEventListeners();
  }

  beforeDestroy(){
    this.removeEventListeners();
  }

  private setEventListeners(){
    window.GlobalEvents.$on("open-tutorial", this.openTutorial);
  }

  private removeEventListeners(){
    window.GlobalEvents.$off("open-tutorial", this.openTutorial);
  }
}
