
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Content',
  components: {},
  mixins: []
})
export default class Content extends Vue {
  @Prop({ default: "" }) private content! : string;

}
