/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'mark_unread_chat_alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="19" cy="3" r="3"/><path pid="1" d="M6 8V6h9.03a4.906 4.906 0 01-.92-4H4.01a2 2 0 00-2 2L2 22l4-4h14c1.1 0 2-.9 2-2V6.97C21.16 7.61 20.13 8 19 8H6zm8 6H6v-2h8v2zm4-3H6V9h12v2z"/>'
  }
})
