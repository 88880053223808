/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'element_youtube_video': {
    width: 1303,
    height: 1024,
    viewBox: '0 0 1303 1024',
    data: '<path pid="0" d="M438.55 512.753h426.29L651.695 280.036 438.55 512.753zM634.919 745.04h35.703c47.748 0 47.748 0 47.748-47.318v-184.97H587.171v184.97c0 47.318 0 47.318 47.748 47.318zM0 950.227V75.493C0 34.197 33.338.86 74.418.86h1154.339c41.08 0 74.418 33.338 74.418 74.418v874.734c0 41.08-33.338 74.418-74.418 74.418H74.418C33.338 1024.645 0 991.308 0 950.227zm92.485-20.217h92.485v-92.485H92.485v92.485zm0-184.97h92.485v-92.485H92.485v92.485zm0-187.12h92.485v-92.485H92.485v92.485zm0-184.97h92.485v-92.485H92.485v92.485zm0-187.12h92.485V93.345H92.485v92.485zM1118.42 930.01h92.485v-92.485h-92.485v92.485zm0-184.97h92.485v-92.485h-92.485v92.485zm0-187.12h92.485v-92.485h-92.485v92.485zm0-184.97h92.485v-92.485h-92.485v92.485zm0-187.12h92.485V93.345h-92.485v92.485z"/><path pid="1" d="M354.883 185.83h600.076v637.93H354.883V185.83z"/><path pid="2" _fill="#fff" d="M838.6 294.446c-119.37-19.787-241.106-19.787-360.26 0-68.181 14.195-110.767 58.287-110.767 115.714v204.112c0 58.287 42.586 101.733 111.842 116.574 59.362 9.894 119.37 15.056 179.593 15.056 60.438 0 120.66-4.947 180.238-14.841 67.535-12.905 111.412-58.287 111.412-116.574V410.375c0-57.857-43.876-103.239-112.057-115.929zm-84.526 231.427l-145.825 87.538c-2.366 1.29-4.947 2.151-7.528 1.936-2.581 0-4.947-.645-7.313-1.721-4.732-2.796-7.528-7.958-7.313-13.335V425.43c0-5.162 2.796-10.109 7.313-12.905 4.732-2.581 10.324-2.581 14.841 0l145.825 87.538c6.883 4.517 9.033 13.55 4.517 20.433-1.075 1.721-2.796 3.441-4.517 4.517v.86z"/>'
  }
})
