import {UserModule} from "@/store/modules/user";
import {convertDate, filterUndefined, getImageSize} from "@/globalFunctions";
import {getFileUrl} from "@/firebase/functions";
import {deleteMarker, getMarker, getMarkers, getVuforiaData, updateMarker} from "@/data/api/marker";
import {MarkerElementList} from "./element"

export class MarkerList extends Array implements Array<Marker> {
    _retrieved = false;
    _retrieving = false;
    _loading = false;
    _first = "";
    _last = "";
    _licenseId = "";

    constructor(){
        super();
    }

    private _get = (markerId:string) => {
        const index = this.findIndex((marker) => marker.Id === markerId);
        return this[index];
    }

    private _exists = (markerId:string) => {
        return !!this._get(markerId);
    }

    public getMarkerIds = () => {
        return this.map((marker) => marker.Id);
    }

    private empty = () => {
        while(this.length > 0){
            this.splice(0, 1);
        }
    }

    public activate = async(markerIds?: string[]) => {
        const selectedMarkers = this.filter((marker) => markerIds ? markerIds.includes(marker.Id) : marker._selected);

        const results: any[] = [];
        for (const marker of selectedMarkers){
            results.push({
                id: marker.Id,
                name: marker.DisplayName,
                result: await marker.update(
                    UserModule.license,
                    {
                        Active: true
                    }
                ).then(({marker}: any) => {
                    return marker;
                }).catch((error: any) => {
                    return error.response;
                })
            });
        }

        // Save to list
        const successIds = results.filter(({result}) => result.status === "success").map(({id}:any) => id);
        if (successIds){
            for (const markerId of successIds){
                const index = this.findIndex((marker) => marker.Id === markerId);
                this[index].Active = true;
            }
        }

        return {
            success: results.filter(({result}) => result.status === "success").map(({name}) => name),
            error: results.filter(({result}) => result.status === "error").map(({name, result}) => {
                return {
                    name,
                    code: result.code,
                }
            }),
        }
    }

    public deactivate = async(markerIds?: string[]) => {
        const selectedMarkers = this.filter((marker) => markerIds ? markerIds.includes(marker.Id) : marker._selected);

        const results = [];
        for (const marker of selectedMarkers){
            results.push({
                id: marker.Id,
                name: marker.DisplayName,
                result: await marker.update(
                    UserModule.license,
                    {
                        Active: false
                    }
                ).then(({marker}: any) => {
                    return marker;
                }).catch((error: any) => {
                    return error.response;
                })
            });
        }

        // Save to list
        const successIds = results.filter(({result}) => result.status === "success").map(({id}:any) => id);
        if (successIds){
            for (const markerId of successIds){
                const index = this.findIndex((marker) => marker.Id === markerId);
                this[index].Active = false;
            }
        }

        return {
            success: results.filter(({result}) => result.status === "success").map(({name}:any) => name),
            error: results.filter(({result}) => result.status === "error").map(({name, result}) => {
                return {
                    name,
                    code: result.code,
                }
            }),
        }
    }

    public deselect = () => {
        this.forEach((marker) => {
            marker._selected = false;
        });
    }

    public get = async (licenseId?: string, params?: any) => {
        // Prevent double loading
        if (this._retrieving){
            return false;
        }

        // Empty list
        this.empty();

        // Set license
        if (licenseId){
            this._licenseId = licenseId;
        }

        // Set variables
        this._retrieving = true;
        this._loading = true;

        // Get data
        const {data} = await getMarkers(this._licenseId, params);

        // Push
        for (const markerData of data) {
            if (!this._exists(markerData.Id)){
                // Instantiate marker
                const marker = new Marker();

                // Set data
                marker.set(markerData);

                // Marker is retrieved
                marker._licenseId = this._licenseId;
                marker._retrieved = true;
                marker._new = false;

                // Push to list
                this.push(marker);
            }
        }

        // Set first and last ID
        this._first = data[0]?.Id;
        this._last = data[data.length-1]?.Id;

        // List retrieved
        this._retrieved = true;
        this._retrieving = false;
        this._loading = false;

        // Return marker list
        return this;
    }

    public getById = (markerId: string): Marker => {
        return this._get(markerId);
    }

    public put = (marker: Marker): MarkerList => {
        const index = this.findIndex((m) => m.Id === marker.Id);
        if (index >= 0){
            this[index].set(marker);
        } else {
            this.push(marker);
        }
        return this;
    }

    public update = async (markerId:string, data?:any) => {
        // Get specific marker
        const marker = this._get(markerId) as Marker;

        return marker.update(this._licenseId, data);
    }

    public remove = async (markerId?: string) => {
        if (markerId){
            const index = this.findIndex((marker) => {
                return marker.Id === markerId
            });
            if (index >= 0){
                return this[index].delete().then(() => {
                    this.splice(index, 1);
                });
            }
        }
    }
}

export class Marker implements IMarker {
    _new = true;
    _retrieved = false;
    _selected = false;
    _changed = 0;
    _licenseId = "";

    Active = false;
    Archived = false;
    CreatedBy = UserModule.uid;
    CreatedDateTime = new Date();
    CustomerId = "none";
    DefaultLanguage = "EN";
    DisplayName = "";
    DocumentUpdatedBy = UserModule.uid;
    DocumentUpdatedDateTime = new Date();
    Elements = new MarkerElementList(this);
    ElementsUpdatedBy = UserModule.uid;
    ElementsUpdatedDateTime = new Date();
    File?: string;
    FileExtension = "jpg";
    FileSize?: number;
    FileUrl?: string;
    Id = "new";
    ImageExtension?: string;
    ImageSize = {
        X: 0,
        Y: 0
    };
    ImageUpdatedBy = UserModule.uid;
    ImageUpdatedDateTime = new Date();
    Languages = ["EN"]
    Local?: boolean;
    Locked = false;
    License = "";
    OriginalFilename?: string;
    PhysicalSizeX = 0;
    PhysicalSizeY = 0;
    ProjectId?: string;
    SpecifySize = true;
    Statistics?: {
        Date: Date | string;
        ThisMonth: IMarkerStatisticObject;
        Today: IMarkerStatisticObject;
        Total: IMarkerStatisticObject;
    };
    StorageLocation?: string;
    ThumbnailPath?: string;
    TestCode?: string;
    Vuforia?: IVuforiaData | null = null;

    /**
     * Constructor
     * @param {unknown} obj data
     */
    constructor(obj?: any ) {
        if (obj) {
            this.set(obj);
        }
    }

    /**
     * Convert to JSON
     * @return {IMarker} JSON data
     */
    toJSON = () : IMarker => {
        return filterUndefined({
            Active: this.Active,
            Archived: this.Archived,
            CreatedBy: this.CreatedBy,
            CreatedDateTime: convertDate(this.CreatedDateTime),
            CustomerId: this.CustomerId,
            DefaultLanguage: this.DefaultLanguage,
            DisplayName: this.DisplayName,
            DocumentUpdatedBy: this.DocumentUpdatedBy,
            DocumentUpdatedDateTime: convertDate(this.DocumentUpdatedDateTime),
            ElementsUpdatedBy: this.ElementsUpdatedBy,
            ElementsUpdatedDateTime: convertDate(this.ElementsUpdatedDateTime),
            FileExtension: this.FileExtension,
            FileSize: this.FileSize,
            FileUrl: this.FileUrl,
            Id: this.Id,
            ImageSize: {
                X: parseInt(this.ImageSize?.X?.toString() || "0"),
                Y: parseInt(this.ImageSize?.Y?.toString() || "0")
            },
            ImageUpdatedBy: this.ImageUpdatedBy,
            ImageUpdatedDateTime: convertDate(this.ImageUpdatedDateTime),
            Languages: this.Languages,
            Local: this.Local,
            Locked: this.Locked,
            License: this.License,
            OriginalFilename: this.OriginalFilename,
            PhysicalSizeX: this.PhysicalSizeX || parseInt(this.ImageSize?.X?.toString() || "0")*0.026458333*0.01,
            PhysicalSizeY: this.PhysicalSizeY || parseInt(this.ImageSize?.Y?.toString() || "0")*0.026458333*0.01,
            ProjectId: this.ProjectId,
            SpecifySize: this.SpecifySize,
            Statistics: {
                Date: convertDate(this.Statistics?.Date),
                ThisMonth: this.Statistics?.ThisMonth,
                Today: this.Statistics?.Today,
                Total: this.Statistics?.Total,
            },
            StorageLocation: this.StorageLocation,
            ThumbnailPath: this.ThumbnailPath,
            Vuforia: this.Vuforia,
        }) as IMarker;
    }


    /**
     * Set marker data
     * @param {string} data - the marker Id
     * @return {Marker} The marker object
     */
    set = (data: any) : Marker => {
        // Convert dates
        Object.keys(data).forEach(key => {
            if (key.indexOf("DateTime") >= 0){
                data[key] = convertDate(data[key]) || new Date();
            }
        });

        // Assign data
        Object.assign(this, data);

        // Check data
        this.Archived = data.Archived === true;
        this.CreatedBy = data.CreatedBy || UserModule.uid;
        this.DocumentUpdatedBy = data.DocumentUpdatedBy || UserModule.uid;
        this.ElementsUpdatedBy = data.ElementsUpdatedBy || UserModule.uid;
        this.FileExtension = data.FileExtension || "jpg";
        this.ImageSize = {
            X: parseInt(data.ImageSize?.X?.toString() || "0"),
            Y: parseInt(data.ImageSize?.Y?.toString() || "0")
        };
        this.ImageUpdatedBy = data.ImageUpdatedBy || UserModule.uid;
        this.PhysicalSizeX = data.PhysicalSizeX || parseInt(data.ImageSize?.X?.toString() || "0")*0.026458333*0.01;
        this.PhysicalSizeY = data.PhysicalSizeY || parseInt(data.ImageSize?.Y?.toString() || "0")*0.026458333*0.01;
        this.Statistics = {
            Date: convertDate(data.Statistics?.Date) || new Date(),
            ThisMonth: data.Statistics?.ThisMonth || {
                Clicks: 0,
                CloudScans: 0,
                Events: 0,
                Scans: 0,
            },
            Today: data.Statistics?.Today || {
                Clicks: 0,
                CloudScans: 0,
                Events: 0,
                Scans: 0,
            },
            Total: data.Statistics?.Total || {
                Clicks: 0,
                CloudScans: 0,
                Events: 0,
                Scans: 0,
            },
        };
        if (this.Languages.length === 0){
            this.Languages.push(this.DefaultLanguage)
        } else if (this.Languages.indexOf(this.DefaultLanguage) < 0){
            this.DefaultLanguage = this.Languages[0];
        }

        // Return marker object
        return this;
    }


    /**
     * Get the marker
     * @param {string} licenseId - the license Id
     * @param {string} id - the marker Id
     * @return {Marker} The marker object
     */
    get = async (licenseId?: string, id?: string) : Promise<Marker> => {
        // Set ID
        if (id){
            this.Id = id;
        }
        if (licenseId){
            this._licenseId = licenseId;
        }

        // Get data
        const {data} = await getMarker(this._licenseId, this.Id);

        // Set data
        this.set(data);

        // File url is needed
        if (!this.FileUrl) {
            await this.getFileUrl();
            if (!this.FileUrl) {
                throw Error("Marker file is not defined");
            }
        }

        // Image size is needed
        if (!this.ImageSize?.X || !this.ImageSize?.Y){
            this.ImageSize = await getImageSize(this.FileUrl);
            this.PhysicalSizeX = this.ImageSize.X*0.026458333*0.01;
            this.PhysicalSizeY = this.ImageSize.X*0.026458333*0.01;
        }

        // Get elements
        this.Elements = new MarkerElementList(this);
        this.Elements.setMarkerInfo(this);
        await this.Elements.get(this.Id);

        // Retrieved
        this._new = false;
        this._retrieved = true;
        this._retrieved = true;
        this._changed = 0;

        // Return marker object
        return this;
    }

    /**
     * Update the marker
     * @param {string} licenseId - the license Id
     * @param {any} data - the marker data
     * @return {Marker} The marker object
     */
     public update =  async (licenseId?: string, data?: any) : Promise<any> => {
        if (licenseId){
            this._licenseId = licenseId;
        }

        // Update elements
        const elementsResult = await this.Elements.update(this.Id);

        // Get data
        const markerResult = await updateMarker(this._licenseId, this.Id, {
            ...this.toJSON(),
            ...data
        });

        // Assign object
        if (markerResult){
            Object.assign(this, markerResult.data);
        }

        // No changes
        this._changed = 0;

        // Return results
        return {
            marker: markerResult,
            elements: elementsResult
        };
    }

    /**
     * Delete marker
     * @return {boolean} Success
     */
    public async delete(markerId?: string) : Promise<boolean | any> {
        // Get result
        const result = await deleteMarker(this._licenseId, markerId || this.Id);

        return (result as any).status === "success" ? true : result;
    }


    /**
     * Get the marker
     * @param {string} licenseId - the license Id
     * @return {Promise<IVuforiaData>} The vuforia data object
     */
    getVuforia = async (licenseId?: string) : Promise<IVuforiaData> => {
        if (licenseId){
            this._licenseId = licenseId;
        }

        // Get data
        const {data} = await getVuforiaData(this._licenseId, this.Id);

        // Set data
        if (!this.Vuforia){
            this.Vuforia = data;
        } else {
            Object.assign(this.Vuforia, data);
        }

        // Changed
        this._changed = Date.now();
        this._new = false;

        // Return marker object
        return data;
    }


    /**
     * Get the marker's file url
     * @param {string} licenseId - the license Id
     * @return {Promise<Marker>} The marker object
     */
    getFileUrl = async (licenseId?: string) : Promise<Marker> => {
        if (licenseId){
            this._licenseId = licenseId;
        }

        if (this.StorageLocation){
            // Get data
            const fileUrl = await getFileUrl(this.StorageLocation);

            if (fileUrl){
                this.FileUrl = fileUrl;

                // Update
                await updateMarker(this._licenseId, this.Id, this.toJSON());
            }
        }

        // Return marker object
        return this;
    }

}
