
import { Component, Vue } from 'vue-property-decorator'
import VideoDialog from "@/components/VideoDialog/index.vue";
import AssistanceDialog from "@/components/AssistanceDialog/index.vue";
import IntroDialog from "@/components/IntroDialog/index.vue";
import Tour from "@/components/Tour/index.vue";
import TestMarkerDialog from "@/components/TestMarkerDialog/index.vue";

@Component({
  name: 'App',
  components: {
    TestMarkerDialog,
    Tour,
    IntroDialog,
    AssistanceDialog,
    VideoDialog,
  }
})
export default class extends Vue {
}
