import request from '@/utils/request'

export const getProducts = (params?: any) =>
    request({
        url: 'product',
        method: 'get',
        params
    })

export const getProduct = (productId: string, params?: any) =>
    request({
        url: 'product/' + productId,
        method: 'get',
        params
    })

export const refreshProducts = () =>
    request({
        url: 'refresh-products',
        method: 'get'
    })

export const createProduct = (data: any) =>
    request({
        url: 'product',
        method: 'post',
        data: data
    })

export const updateProduct = (productId: string, data: any) =>
    request({
        url: 'product/' + productId,
        method: 'put',
        data
    })

export const deleteProduct = (productId: string) =>
    request({
        url: 'product/' + productId,
        method: 'delete'
    })
