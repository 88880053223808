import {convertDate, createRandomToken, filterUndefined} from "@/globalFunctions";
import {createBugReport, deleteBugReport, getBugReport, getBugReports, updateBugReport} from "@/data/api/bug-report";

/**
 * The BugReport list class
 */
export class BugReportList extends Array implements Array<BugReport> {
    public _changed = 0;
    public _retrieved = false;
    public _retrieving = false;

    constructor() {
        super();
    }

    public new = () : BugReport => {
        // Create bugReport
        const bugReport = new BugReport();

        // Push to list
        this.push(bugReport);

        // return
        return bugReport;
    }

    public empty = () : void => {
        while(this.length > 0){
            this.pop();
        }
    }

    public get = async () : Promise<BugReportList> => {
        // Prevent double loading
        if (this._retrieving){
            return this;
        }
        this._retrieving = true;

        // Empty array
        this.empty();

        // Get data
        const {data} = await getBugReports();

        // Convert to bugReports
        for (const bugReportData of data){
            // Push data
            const bugReport = new BugReport(bugReportData);
            bugReport._new = false;
            this.push(bugReport);
        }

        // Retrieved
        this._changed = 0;
        this._retrieved = true;
        this._retrieving = false;

        // Return bugReports
        return this;
    }

    public set = (bugReport: BugReport) => {
        // Find index
        const index = this.findIndex((listElement) => listElement.Id === bugReport.Id);
        if (index >= 0){
            Object.assign(this[index], bugReport);
        } else {
            this.push(bugReport);
        }
    }

    public update = async () => {
        // Collect bugReports
        const bugReports = this.filter((bugReport) => bugReport._changed > 0) as BugReport[];

        // Update bugReports
        const promises = [];
        for (const bugReport of bugReports){
            promises.push(bugReport.update());
        }

        // Changed
        this._changed = 0;

        // Return bugReports
        return await Promise.all(promises);
    }

    public archive = async (id: string) => {
        // Collect bugReports
        const index = this.findIndex((bugReport) => bugReport.Id === id);
        const bugReport = this[index] as BugReport;
        if (bugReport){
            const result = await bugReport.archive() as any;
            if (result.status === "success" || result.data.status === "success"){
                // Delete locally
                this.splice(index, 1);

                return true;
            }
        }

        // Return bugReports
        return false;
    }

    public hasChanged = () => {
        this._changed = Date.now();
    }

    public toList = () => {
        return this.map((bugReport) => bugReport);
    }
}

export class BugReport implements IBugReport {
    _new = true;
    _changed = 0;

    Archived = false;
    ArchivedBy?: string;
    ArchivedDateTime?: Date;
    Browser = {
        AppVersion: navigator.appVersion,
        AppName: navigator.appName,
        AppCodeName: navigator.appCodeName,
        CookieEnabled: navigator.cookieEnabled,
        UserAgent: navigator.userAgent,
        Connection: {
            DownLink: (navigator as any).connection.downlink,
            Type: (navigator as any).connection.effectiveType
        },
        Platform: navigator.platform
    }
    CompanyName: string;
    CreatedBy?: string;
    CreatedDateTime = new Date();
    Description = "";
    DocumentUpdatedBy?: string;
    DocumentUpdatedDateTime = new Date();
    Email: string;
    Environment: string;
    FullName: string;
    Id = createRandomToken();
    Index?: number;
    Phone: string;
    UserLocale: string;

    /**
     * Constructs the class
     * @param {any} obj - Object data
     */
    constructor(obj?: any) {
        if (obj) {
            this.set(obj);
        }
    }

    /**
     * Get bug report
     * @return {IBugReport} JSON data
     */
    public async get(id: string) : Promise<BugReport> {
        // Get data
        const {data}  = await getBugReport(id);

        // Set data
        return this.set(data);
    }

    /**
     * Set bug report data
     * @return {BugReport} Instance
     */
    public set(data: any) : BugReport {
        // Assign data
        Object.assign(this, data);

        // Return instance
        return this;
    }

    /**
     * Update bug report data
     * @return {BugReport} Instance
     */
    public async update(){
        // Update or create
        const data = this.toJSON();
        const result = this._new ? await createBugReport(data) : await updateBugReport(this.Id, data);

        // Assign resulting data
        Object.assign(this, result.data);

        // No changes
        this._changed = 0;
        this._new = false;

        // Return instance
        return result;
    }


    /**
     * Archive the bug report
     * @return {Promise<any>} Archiving result
     */
    public async archive() : Promise<any> {
        // Archive
        const result = await deleteBugReport(this.Id);

        // Return result
        return result;
    }


    /**
     * Convert to JSON
     * @return {IBugReport} JSON data
     */
    public toJSON() : IBugReport {
        return filterUndefined({
            Archived: this.Archived,
            ArchivedBy: this.ArchivedBy,
            ArchivedDateTime: convertDate(this.ArchivedDateTime),
            Browser: this.Browser,
            CreatedBy: this.CreatedBy,
            CreatedDateTime: convertDate(this.CreatedDateTime),
            CompanyName: this.CompanyName,
            Description: this.Description,
            DocumentUpdatedBy: this.DocumentUpdatedBy,
            DocumentUpdatedDateTime: convertDate(this.DocumentUpdatedDateTime),
            Email: this.Email,
            Environment: this.Environment,
            FullName: this.FullName,
            Id: this.Id,
            Phone: this.Phone,
            UserLocale: this.UserLocale,
        }) as IBugReport;
    }
}
