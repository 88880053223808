
import { Component, Vue } from 'vue-property-decorator'
import UpperRightMenu from "./components/UpperRightMenu.vue";
import {UserModule} from "@/store/modules/user";
import router from "@/router";
import { startCustomerPortalSession } from '@/data/api/stripe';
import { auth } from '@/firebase/config';
import {refreshProducts} from "@/data/api/product";

@Component({
  name: 'Navbar',
  components: {UpperRightMenu},
  mixins: [],
})
export default class extends Vue {

  get navbarButtons() {
    const buttons = (this.$route.meta.navbar ? this.$route.meta.navbar.buttons : []);
    return buttons.filter(({roles}: {roles: string[] | undefined}) => roles ? UserModule.user.hasAccess(roles) : true);
  }

  get impersonatingLicense(){
    return UserModule.impersonating;
  }

  private handleCommand(command: string){
    switch (command) {
      case "newLibraryItem":
        window.GlobalEvents.$emit("new-library-item");
        break;
      case "newLibraryCategory":
        window.GlobalEvents.$emit("new-library-category");
        break;
      case "newLicense":
        router.push("/admin/licenses/new");
        break;
      case "newProduct":
        router.push("/ecommerce/products/new");
        break;
      case "newCoupon":
        router.push("/ecommerce/coupons/new");
        break;
      case "newTutorial":
        router.push("/admin/tutorials/new");
        break;
      case "newTutorialSection":
        router.push("/admin/tutorials/" + router.currentRoute.params.tutorialId + "/sections/new");
        break;
      case "newCustomer":
        router.push("/customers/edit/new");
        break;
      case "backToLicenses":
        router.push("/user/licenses");
        break;
      case "manageSubscriptions":
        const loading = this.$loading({
          target: "body",
          lock: true
        });

        startCustomerPortalSession(auth.currentUser?.uid as string).then((result) => {
          window.open(result.data.url, "_blank");
        }).finally(() => {
          loading.close();
        });

        break;
      case "toCatalogus":
        router.push("/catalogus");
        break;
      case "refreshProducts":
          const load = this.$loading({
            target: "body",
            lock: true
          });

          refreshProducts().finally(() => {
            load.close();
          });
        break;
      case "stopImpersonating":
        UserModule.StopImpersonating();
        break;
      default:
        console.warn(command, "not implemented");
    }
  }

}
