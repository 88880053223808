/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'object_3d_element': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M28.125 9.898a.996.996 0 00-.01-.144c-.01-.077-.054-.125-.102-.099-.032.016-.061.029-.086.045-3.798 2.192-7.594 4.387-11.395 6.576-.176.099-.234.214-.234.413.006 4.339.006 8.682.006 13.021 0 .054.003.106.01.166.006.093.054.144.106.118.038-.019.067-.032.096-.051 3.798-2.192 7.597-4.39 11.398-6.576.182-.102.224-.221.224-.413-.013-4.355-.013-8.707-.013-13.056zm-12.72 6.323c-3.456-1.981-6.902-3.978-10.352-5.968-.189-.109-.381-.218-.573-.33-.32-.182-.579-.278-.586-.211-.003.045-.006.08-.006.118 0 4.384.003 8.768-.01 13.152 0 .234.118.32.282.413l10.998 6.349c.09.051.179.099.278.157.154.083.278.061.278-.051v-.205c0-4.285-.003-8.573.01-12.854-.003-.278-.08-.429-.32-.57zm11.053-6.656c.749-.435 1.322-.81 1.28-.838-.032-.022-.054-.038-.077-.051-3.811-2.202-7.619-4.397-11.424-6.602-.179-.102-.304-.083-.474.013-2.883 1.67-5.766 3.334-8.653 5.002l-1.459.842c-.806.464-1.376.89-1.274.95l.186.109c3.725 2.15 7.45 4.298 11.168 6.454.205.118.346.128.55.006 2.938-1.706 5.878-3.402 8.822-5.101.445-.262.896-.522 1.354-.784z"/>'
  }
})
