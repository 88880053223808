/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'north_west': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5 15h2V8.41L18.59 20 20 18.59 8.41 7H15V5H5v10z"/>'
  }
})
