/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'element_video': {
    width: 1303,
    height: 1024,
    viewBox: '0 0 1303 1024',
    data: '<path pid="0" d="M438.55 512.753h426.29L651.695 280.036 438.55 512.753zM634.919 745.04h35.703c47.748 0 47.748 0 47.748-47.318v-184.97H587.171v184.97c0 47.318 0 47.318 47.748 47.318zM0 950.227V75.493C0 34.197 33.338.86 74.418.86h1154.339c41.08 0 74.418 33.338 74.418 74.418v874.734c0 41.08-33.338 74.418-74.418 74.418H74.418C33.338 1024.645 0 991.308 0 950.227zm92.485-20.217h92.485v-92.485H92.485v92.485zm0-184.97h92.485v-92.485H92.485v92.485zm0-187.12h92.485v-92.485H92.485v92.485zm0-184.97h92.485v-92.485H92.485v92.485zm0-187.12h92.485V93.345H92.485v92.485zM1118.42 930.01h92.485v-92.485h-92.485v92.485zm0-184.97h92.485v-92.485h-92.485v92.485zm0-187.12h92.485v-92.485h-92.485v92.485zm0-184.97h92.485v-92.485h-92.485v92.485zm0-187.12h92.485V93.345h-92.485v92.485z"/><path pid="1" d="M354.883 185.83h600.076v637.93H354.883V185.83z"/><path pid="2" _fill="#fff" d="M654.921 280.547c-47.748 0-95.496 18.067-131.845 54.415-30.972 30.972-48.608 69.901-53.34 110.121-75.924 6.883-135.286 70.547-135.286 148.406 0 82.376 66.675 149.051 149.051 149.051h365.207c70.116 0 126.683-56.566 126.683-126.683s-56.566-126.683-126.683-126.683h-8.818c6.668-54.846-11.184-112.057-53.34-154.213-36.564-36.349-84.097-54.415-131.629-54.415zm0 156.579c3.871 0 6.668.645 10.109 3.656l81.946 74.633c5.807 5.162 6.883 15.486 1.075 21.293-5.592 5.592-15.486 6.237-21.293.86l-56.996-51.834v160.235c0 8.173-6.668 14.841-14.841 14.841s-14.841-6.668-14.841-14.841V485.734l-56.996 51.834c-5.807 5.377-15.916 4.947-21.293-.86-5.162-5.807-4.947-15.916 1.075-21.293l81.946-74.633c2.151-1.936 6.022-3.656 10.109-3.656z"/>'
  }
})
