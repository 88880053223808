/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'back_hand': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13 24c-3.26 0-6.19-1.99-7.4-5.02l-3.03-7.61a1 1 0 011.24-1.32l.79.26c.56.18 1.02.61 1.24 1.16L7.25 15H8V3.25a1.25 1.25 0 012.5 0V12h1V1.25a1.25 1.25 0 012.5 0V12h1V2.75a1.25 1.25 0 012.5 0V12h1V5.75a1.25 1.25 0 012.5 0V16c0 4.42-3.58 8-8 8z"/>'
  }
})
