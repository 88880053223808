/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'vr_image_element': {
    width: 46,
    height: 32,
    viewBox: '0 0 46 32',
    data: '<path pid="0" d="M27.228 11.136a4.023 4.023 0 11-8.046 0 4.023 4.023 0 018.046 0z"/><path pid="1" d="M14.462 19.168h17.512a3.944 3.944 0 003.944-3.944V7.046a3.944 3.944 0 00-3.944-3.944h-.907a3.951 3.951 0 00-3.852-3.103h-8.02a3.946 3.946 0 00-3.847 3.09l-.005.026h-.881a3.944 3.944 0 00-3.944 3.944v8.191a3.945 3.945 0 003.944 3.918zm8.743-13.765a5.732 5.732 0 11-5.732 5.732 5.732 5.732 0 015.732-5.732zm-9.834.658h1.972a1.065 1.065 0 010 2.13h-1.972a1.065 1.065 0 010-2.13z"/><path pid="2" d="M37.522 7.757s11.596 6.153 4.246 12.713c-4.059 2.918-9.056 4.778-14.468 5.085l-.073.003v3.287c4.72-.513 12.135-2.038 16.329-6.574 8.953-9.479-6.035-14.514-6.035-14.514zM23.809 26.36l-5.403-3.668c-.697-.473-1.315-.171-1.315.671v1.959c-4.752-.572-9.014-2.305-12.608-4.905l.079.054C-2.787 13.897 8.809 7.758 8.809 7.758S-6.1 12.793 2.853 22.351c3.642 3.944 9.703 5.548 14.317 6.258v2.524c0 .841.578 1.144 1.315.671l5.364-3.668a1 1 0 00-.033-1.772l-.006-.003z"/>'
  }
})
