import Vue from 'vue'
import Component from 'vue-class-component'
import {SettingsModule} from "@/store/modules/settings";


@Component
export default class SettingsMixin extends Vue {

    get settings(){
        return SettingsModule;
    }

}
