
import { Component, Prop } from 'vue-property-decorator'
import InputMixin from "../mixins/input";

@Component({
  name: 'DateRangeInput',
  components: {},
  mixins: [InputMixin]
})
export default class DateRangeInput extends InputMixin {
  @Prop({default: () => true }) disableDate!: (input: any) => any;
  @Prop({default: () => true }) dateShortcuts!: Record<string, string | ((input: any) => any)>[];
}
