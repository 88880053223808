/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'taxi_alert': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23 8A7 7 0 009.68 5H7v2H4.5a1.5 1.5 0 00-1.42 1.01L1 14v8a1 1 0 001 1h1a1 1 0 001-1v-1h12v1a1 1 0 001 1h1a1 1 0 001-1v-7.68A7.01 7.01 0 0023 8zm-18.5.5h4.53a6.93 6.93 0 002.08 4.5H3l1.5-4.5zm0 9.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm11 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm2.93-5.63l-.21.11-.18.09a4.97 4.97 0 01-.42.16l-.22.07-.23.06-.2.05a5 5 0 01-5.94-4.41A4.07 4.07 0 0111 8l.02-.47.02-.17.04-.28.04-.21.05-.21.07-.24.05-.13a4.99 4.99 0 019.69 1.7 4.96 4.96 0 01-2.55 4.38zM15 4h2v5h-2zm0 6h2v2h-2z"/>'
  }
})
