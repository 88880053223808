/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'button_element': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M29.19 15.776V3.997c0-.806-.653-1.462-1.462-1.462H4.173c-.806 0-1.462.653-1.462 1.462v23.558c0 .806.653 1.462 1.462 1.462h11.779v-2.65H5.35c.006 0 .01.003.01.01V5.174c0 .006-.003.01-.01.01h21.2c-.006 0-.01-.003-.01-.01v10.602h2.65z"/><path pid="1" d="M25.11 13.552a2.566 2.566 0 00-.403-.749c-.368-.464-1.088-.579-1.581-.298-.598.339-.8.867-.57 1.507.288.81.592 1.616.877 2.429.032.086.051.173.067.259.026.144.01.282-.042.304s-.163-.064-.243-.192a1.214 1.214 0 01-.131-.243c-.426-1.142-.826-2.294-1.258-3.434a1.31 1.31 0 00-1.699-.746c-.675.272-.906.851-.637 1.59.301.829.608 1.645.896 2.48a.85.85 0 01.038.259.582.582 0 01-.013.154c-.019.083-.157.09-.224.006a.768.768 0 01-.096-.17 5.745 5.745 0 01-.134-.304c-.41-1.072-.771-2.166-1.226-3.216-.32-.733-1.046-.982-1.709-.704-.675.285-.957.998-.682 1.766.352.982.717 1.952 1.062 2.934.029.083.042.17.051.256.013.144-.026.272-.08.288-.058.013-.17-.106-.246-.272-.045-.099-.096-.202-.131-.301-.627-1.715-1.248-3.434-1.875-5.152-.141-.371-.278-.746-.419-1.12-.358-.976-1.21-1.414-2.042-1.107-.845.314-1.152 1.149-.784 2.157l.403 1.107c1.005 2.765 2.013 5.533 3.014 8.301.032.086.038.179.045.275a.277.277 0 01-.275.282.906.906 0 01-.282-.035c-.944-.326-1.872-.682-2.813-1.018-.528-.186-1.037-.173-1.504.176-.525.387-.765.902-.682 1.552.083.669.506 1.043 1.104 1.264 2.07.765 4.154 1.51 6.218 2.294.282.109.56.326.742.563.698.909 1.37 1.837 2.022 2.781.208.294.406.339.73.221a749.885 749.885 0 017.053-2.563c.368-.131.467-.336.422-.691a33.532 33.532 0 01-.198-1.869c-.029-.352-.122-.746-.006-1.056.336-.906.224-1.773-.08-2.63-.89-2.458-1.782-4.902-2.682-7.338z"/>'
  }
})
