import request from '@/utils/request'

export const getProspects = async (params?: any) =>
    request({
        url: 'prospect',
        method: 'get',
        params
    });


export const getProspect = (id: string) =>
    request({
        url: 'prospect/' + id,
        method: 'get'
    })


export const createProspect = (data: any) =>
    request({
        url: 'prospect',
        method: 'post',
        data: data
    })

export const updateProspect = (id: string, data: any) =>
    request({
        url: 'prospect/' + id,
        method: 'put',
        data
    })

export const deleteProspect = (id: string) =>
    request({
        url: 'prospect/' + id,
        method: 'delete'
    })
