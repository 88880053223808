/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'double_arrow': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.5 5H11l5 7-5 7h4.5l5-7z"/><path pid="1" d="M8.5 5H4l5 7-5 7h4.5l5-7z"/>'
  }
})
