import {convertDate, filterUndefined} from "@/globalFunctions";
import {getCustomers} from "@/data/api/customer";
import {createCustomer, deleteCustomer, getCustomer, updateCustomer} from "@/data/api/customer";


/**
 * The Customer list class
 */
export class CustomerList extends Array implements Array<Customer> {
    public _changed = 0;
    public _retrieved = false;
    public _retrieving = false;
    public _parentLicenseId = "";

    constructor() {
        super();
    }

    public new = () : Customer => {
        // Create customer
        const customer = new Customer();

        // Push to list
        this.push(customer);

        // return
        return customer;
    }

    public empty = () : void => {
        while(this.length > 0){
            this.pop();
        }
    }

    public get = async (licenseId: string) : Promise<CustomerList> => {
        // Prevent double loading
        if (this._retrieving){
            return this;
        }
        this._retrieving = true;

        // Empty array
        this.empty();

        // Set license ID
        this._parentLicenseId = licenseId;
        if (!this._parentLicenseId){
            return this;
        }

        // Get data
        const {data} = await getCustomers(this._parentLicenseId);

        // Convert to customers
        for (const customerData of data){
            // Push data
            const customer = new Customer(customerData);
            customer._new = false;
            this.push(customer);
        }

        // Retrieved
        this._changed = 0;
        this._retrieved = true;
        this._retrieving = false;

        // Return customers
        return this;
    }

    public set = (customer: Customer) => {
        // Find index
        const index = this.findIndex((listElement) => listElement.Id === customer.Id);
        if (index >= 0){
            Object.assign(this[index], customer);
        } else {
            this.push(customer);
        }
    }

    public update = async () => {
        // Collect customers
        const customers = this.filter((customer) => customer._changed > 0) as Customer[];

        // Update customers
        const promises = [];
        for (const customer of customers){
            promises.push(customer.update());
        }

        // Changed
        this._changed = 0;

        // Return customers
        return await Promise.all(promises);
    }

    public archive = async (id: string) => {
        // Collect customers
        const index = this.findIndex((customer) => customer.Id === id);
        const customer = this[index] as Customer;
        if (customer){
            const result = await customer.archive() as any;
            if (result.status === "success" || result.data.status === "success"){
                // Delete locally
                this.splice(index, 1);

                return true;
            }
        }

        // Return customers
        return false;
    }

    public hasChanged = () => {
        this._changed = Date.now();
    }

    public toList = () => {
        return this.map((customer) => customer);
    }
}

/**
 * The Customer class
 */
export class Customer implements ICustomer {
    _new = true;
    _changed = 0;
    _parentLicenseId = "";
    _retrieved = false;

    AccessToken = "";
    Active = true;
    Archived = false;
    ArchivedBy?: string;
    ArchivedDateTime = new Date();
    Apps: string[] = ["veewar"];
    Billing: IBillingInfo = {};
    Company?: ICompanyInfo;
    Contact: IContactInfo = {
        DifferentFromLicenseOwner: false,
    };
    CreatedBy?: string;
    CreatedDateTime = new Date();
    CustomerId?: string;
    DocumentUpdatedBy?: string;
    DocumentUpdatedDateTime = new Date();
    Id = "new";
    CustomerName = "Customer";
    LicenseOwner: ILicenseOwnerInfo = {};
    Notifications?: Record<string, boolean>;
    Order?: {
        Confirmed: boolean;
        Id: string;
        OrderId: string;
        ProductName: string;
    };
    ParentLicense?: string;
    PlatformId = "veewar";
    ReadOnlyCustomers: string[] = [];
    Scans: IScansInfo = {
        AllowLimitExceed: false,
        Cloud: 0,
        GeneralMaximum: 100,
        LimitExceeded: false,
        Local: 0,
        Maximum: 100,
        Notify: true,
        OnLimitExceed: "nothing",
        Thresholds: {},
        Total: 0,
        Type: "monthly",
    };
    Subscription: ISubscriptionInfo = {
        CostPerScan: 0.01,
        DateRange: [new Date(), new Date()],
        EndDate: new Date(),
        LocalDatabase: false,
        PaymentType: "monthly",
        RestrictOn: "nothing",
        Seats: 1,
        StartDate: new Date(),
        Type: "monthly",
        Viewers: 10,
    };
    Statistics?: {
        Date: Date | string;
        ThisMonth: IStatisticObject;
        Today: IStatisticObject;
        Total: IStatisticObject;
    };
    Users: string[] = [];



    constructor(obj?: any){
        if (obj){
            // Assign other data
            this.set(obj);
        }
    }

    /**
     * Get customer
     * @return {Customer} JSON data
     */
    public async get(id: string, licenseId?: string) : Promise<Customer> {
        if (licenseId){
            this._parentLicenseId = licenseId;
        }

        // Get data
        const {data} = await getCustomer(this._parentLicenseId, id);

        // Item is not new
        this._new = false;

        // Set data
        return this.set(data);
    }

    /**
     * Set customer data
     * @return {Customer} Instance
     */
    public set(data: any) : Customer {
        // Convert dates
        Object.keys(data).forEach(key => {
            if (key.indexOf("DateTime") >= 0){
                data[key] = convertDate(data[key]);
            }
        });

        // Assign data
        Object.assign(this, data);

        // Return instance
        return this;
    }


    /**
     * Archive the customer
     * @return {Promise<any>} Archiving result
     */
    public async archive() : Promise<any> {
        // Archive
        const result = await deleteCustomer(this._parentLicenseId, this.Id);

        // Return result
        return result;
    }

    /**
     * Update or create the customer
     * @return {Customer} Customer object
     */
    public async update() : Promise<Customer> {
        // Update or create
        const data = this.toJSON();
        const result = this._new ? await createCustomer(this._parentLicenseId, data) : await updateCustomer(this._parentLicenseId, this.Id, data);

        // Assign object
        Object.assign(this, result.data);

        // No changes
        this._changed = 0;
        this._new = false;

        return this;
    }

    /**
     * Convert to JSON
     * @return {ICustomer} JSON data
     */
    public toJSON() : ICustomer {
        return filterUndefined({
            AccessToken: this.AccessToken,
            Active: this.Active,
            Apps: this.Apps,
            Billing: this.Billing,
            Company: this.Company,
            Contact: this.Contact,
            CreatedBy: this.CreatedBy,
            CreatedDateTime: convertDate(this.CreatedDateTime),
            CustomerId: this.CustomerId,
            DocumentUpdatedBy: this.DocumentUpdatedBy,
            DocumentUpdatedDateTime: convertDate(this.DocumentUpdatedDateTime),
            Id: this.Id,
            CustomerName: this.CustomerName,
            LicenseOwner: this.LicenseOwner,
            Notifications: this.Notifications,
            Order: this.Order,
            ParentLicense: this.ParentLicense,
            PlatformId: this.PlatformId,
            ReadOnlyCustomers: this.ReadOnlyCustomers,
            Scans: this.Scans,
            Subscription: this.Subscription,
            Statistics: {
                Date: convertDate(this.Statistics?.Date),
                ThisMonth: this.Statistics?.ThisMonth,
                Today: this.Statistics?.Today,
                Total: this.Statistics?.Total,
            },
            Users: this.Users,
        }) as ICustomer;
    }

}
