
import {Component, Prop} from 'vue-property-decorator'
import InputMixin from "../mixins/input";

@Component({
  name: 'LanguageSelect',
  components: {},
  mixins: [InputMixin]
})
export default class LanguageSelect extends InputMixin {
  @Prop({ default: false }) private multiple! : boolean;

  private options = [{
    key: "de",
    value: "DE",
    name: "langauge_select_de",
    label: "languages.de",
  },{
    key: "en",
    value: "EN",
    name: "langauge_select_en",
    label: "languages.en",
  },{
    key: "fr",
    value: "FR",
    name: "langauge_select_fr",
    label: "languages.fr",
  },{
    key: "nl",
    value: "NL",
    name: "langauge_select_nl",
    label: "languages.nl",
  }]

}
