import {convertDate, createRandomToken, filterUndefined} from "@/globalFunctions";
import {
    createLicense,
    deleteLicense,
    getLicense,
    getLicenseInvoices, getLicenseProducts,
    getLicenses,
    updateLicense
} from "@/data/api/license";
import {Product} from "@/data/classes/product";

/**
 * The License list class
 */
export class LicenseList extends Array implements Array<License> {
    _changed = 0;
    _retrieved = false;
    _retrieving = false;
    _first = "";
    _last = "";
    _admin = false

    public new = () : License => {
        // Create license
        const license = new License();

        // Push to list
        this.push(license);

        // return
        return license;
    }

    public empty = () : void => {
        while(this.length > 0){
            this.pop();
        }
    }

    public get = async (admin = false) : Promise<LicenseList> => {
        if (this._retrieving){
            return this;
        }
        this._retrieving = true;

        // Set admin
        this._admin = admin;

        // Empty array
        this.empty();

        // Get data
        const {data} = await getLicenses({
            admin
        });

        // Convert to licenses
        for (const licenseData of data){
            // Push data
            const license = new License(licenseData);
            license._new = false;
            this.push(license);
        }

        // Retrieved
        this._changed = 0;
        this._retrieved = true;
        this._retrieving = false;

        // Return licenses
        return this;
    }

    public set = (license: License) => {
        // Find index
        const index = this.findIndex((listElement) => listElement.Id === license.Id);
        if (index >= 0){
            Object.assign(this[index], license);
        } else {
            this.push(license);
        }
    }

    public update = async () => {
        // Collect licenses
        const licenses = this.filter((license) => license._changed > 0) as License[];

        // Update licenses
        const promises = [];
        for (const license of licenses){
            promises.push(license.update());
        }

        // Changed
        this._changed = 0;

        // Return licenses
        return await Promise.all(promises);
    }

    public archive = async (id: string) => {
        // Collect licenses
        const index = this.findIndex((license) => license.Id === id);
        const license = this[index] as License;
        if (license){
            const result = await license.archive() as any;
            if (result.status === "success" || result.data.status === "success"){
                // Delete locally
                this.splice(index, 1);

                return true;
            }
        }

        // Return licenses
        return false;
    }

    public toList = () => {
        return this.map((license) => license);
    }

    public hasChanged = () => {
        this._changed = Date.now();
    }
}

/**
 * The License class
 */
export class License implements ILicense {
    _changed = 0;
    _new = true;
    _retrieved = false;
    _selected = false;

    AccessToken = "";
    Active = true;
    Archived = false;
    ArchivedBy?: string;
    ArchivedDateTime = new Date();
    Apps: string[] = ["veewar"];
    Billing: IBillingInfo = {
        Address: {}
    };
    CheckOutId?: string;
    Company?: ICompanyInfo;
    Contact: IContactInfo = {
        DifferentFromLicenseOwner: false,
    };
    CreatedBy?: string;
    CreatedDateTime = new Date();
    CustomerId?: string;
    DocumentUpdatedBy?: string;
    DocumentUpdatedDateTime = new Date();
    Id = "new";
    Invoice = {
        StripeId: "",
        Url: ""
    }
    LicenseName = "License";
    LicenseOwner: ILicenseOwnerInfo = {};
    Notifications?: Record<string, boolean>;
    Order?: {
        Confirmed: boolean;
        Id: string;
        OrderId: string;
        ProductName: string;
    };
    ParentLicense?: string;
    PlatformId = "veewar";
    Products = [];
    ReadOnlyLicenses: string[] = [];
    Scans: IScansInfo = {
        AllowLimitExceed: false,
        Cloud: 0,
        GeneralMaximum: 100,
        LimitExceeded: false,
        Local: 0,
        Maximum: 100,
        Notify: true,
        OnLimitExceed: "nothing",
        Thresholds: {},
        Total: 0,
        Type: "monthly",
    };
    Subscription: ISubscriptionInfo = {
        CostPerScan: 0.01,
        DateRange: [new Date(), new Date(new Date().getFullYear()+1, new Date().getMonth(), new Date().getDate())],
        EndDate: new Date(),
        LocalDatabase: false,
        PaymentType: "monthly",
        RestrictOn: "nothing",
        Seats: 1,
        StartDate: new Date(),
        Type: "monthly",
        Viewers: 10,
    };
    Statistics?: {
        Date: Date | string;
        ThisMonth: IStatisticObject;
        Today: IStatisticObject;
        Total: IStatisticObject;
    };
    Users: string[] = [];


    /**
     * Constructor
     * @param {unknown} obj data
     */
    constructor(obj?: unknown) {
        if (obj) {
            this.set(obj);
        }
    }

    /**
     * Get license
     * @return {License} JSON data
     */
    public async get(id: string) : Promise<License> {
        // Get data
        const {data} = await getLicense(id);

        // Item is not new
        this._new = false;

        // Set data
        return this.set(data);
    }

    /**
     * Set license data
     * @return {License} Instance
     */
    public set(data: any) : License {
        // Convert dates
        Object.keys(data).forEach(key => {
            if (key.indexOf("DateTime") >= 0){
                data[key] = convertDate(data[key]);
            }
        });

        // Assign data
        Object.assign(this, data);

        // Return instance
        return this;
    }


    /**
     * Archive the license
     * @return {Promise<any>} Archiving result
     */
    public async archive() : Promise<any> {
        // Archive
        const result = await deleteLicense(this.Id);

        // Return result
        return result;
    }

    /**
     * Update or create the license
     * @return {License} License object
     */
    public async update() : Promise<License> {
        // Update or create
        const data = this.toJSON();
        const result = this._new ? await createLicense(data) : await updateLicense(this.Id, data);

        // Assign object
        Object.assign(this, result.data);

        // No changes
        this._changed = 0;
        this._new = false;

        return this;
    }

    /**
     * Get license invoices
     * @return {Invoice[]} JSON data
     */
    public async getInvoices() : Promise<Invoice[]> {
        // Get data
        const {data} = await getLicenseInvoices(this.Id);

        // Set data
        return data.map((item: any) => {
            return new Invoice(item);
        });
    }

    /**
     * Get license invoices
     * @return {Product[]} JSON data
     */
    public async getProducts() : Promise<Product[]> {
        // Get data
        const {data} = await getLicenseProducts(this.Id);

        // Set data
        return data.map((item: any) => {
            return new Product(item);
        });
    }

    /**
     * Convert to JSON
     * @return {ILicense} JSON data
     */
    public toJSON() : ILicense {
        return filterUndefined({
            AccessToken: this.AccessToken,
            Active: this.Active,
            Apps: this.Apps,
            Billing: this.Billing,
            Company: this.Company,
            Contact: this.Contact,
            CreatedBy: this.CreatedBy,
            CreatedDateTime: convertDate(this.CreatedDateTime),
            CustomerId: this.CustomerId,
            DocumentUpdatedBy: this.DocumentUpdatedBy,
            DocumentUpdatedDateTime: convertDate(this.DocumentUpdatedDateTime),
            Id: this.Id,
            Invoice: this.Invoice,
            LicenseName: this.LicenseName,
            LicenseOwner: this.LicenseOwner,
            Notifications: this.Notifications,
            Order: this.Order,
            ParentLicense: this.ParentLicense,
            PlatformId: this.PlatformId,
            ReadOnlyLicenses: this.ReadOnlyLicenses,
            Scans: this.Scans,
            Subscription: this.Subscription,
            Statistics: {
                Date: convertDate(this.Statistics?.Date),
                ThisMonth: this.Statistics?.ThisMonth,
                Today: this.Statistics?.Today,
                Total: this.Statistics?.Total,
            },
            Users: this.Users,
        }) as ILicense;
    }

}

/**
 * The invoice class
 */
export class Invoice implements IInvoice {
    Archived = false;
    CreatedDateTime = new Date();
    DocumentUpdatedDateTime = new Date();
    FinalizedDateTime = undefined;
    Id = createRandomToken();
    Items = [];
    HostedInvoiceUrl = null;
    MarkedUncollectibleDateTime = undefined;
    PaidDateTime = undefined;
    PdfUrl = null;
    Status =  "open";
    StripeId = "";
    Subscription = {
        StripeId: ""
    };
    Total = 0;
    VoidedDateTime = undefined;



    /**
     * Constructor
     * @param {unknown} obj data
     */
    constructor(obj?: unknown) {
        if (obj) {
            Object.assign(this, obj as IInvoice);
        }
    }

    /**
     * Convert to JSON
     * @return {ILicense} JSON data
     */
    public toJSON() : IInvoice {
        return filterUndefined({
            FinalizedDateTime: convertDate(this.FinalizedDateTime),
            Id: this.Id,
            Items: this.Items,
            MarkedUncollectibleDateTime: convertDate(this.MarkedUncollectibleDateTime),
            PaidDateTime: convertDate(this.PaidDateTime),
            Status: this.Status,
            StripeId: this.StripeId,
            Subscription: this.Subscription,
            Total: this.Total,
            VoidedDateTime: convertDate(this.VoidedDateTime)
        }) as IInvoice;
    }

    /**
     * Convert to FireStore document
     * @return {DocumentData} FireStore document
     */
    public fromStripe(invoice: any) : Invoice {
        // Convert from invoice
        this.FinalizedDateTime = invoice.status_transitions.finalized_at;
        this.MarkedUncollectibleDateTime = invoice.status_transitions.marked_uncollectible_at;
        this.PaidDateTime = invoice.status_transitions.paid_at;
        this.VoidedDateTime = invoice.status_transitions.voided_at;

        this.Items = invoice.lines.data.map((item: any) => {
            return {
                StripeId: item.id,
                Price: {
                    StripeId: item.price.id,
                    Product: {
                        StripeId: item.price.product,
                    }
                },
                Quantity: item.quantity,
            }
        });
        this.HostedInvoiceUrl = invoice.hosted_invoice_url;
        this.PdfUrl = invoice.invoice_pdf;
        this.Status = invoice.status;
        this.StripeId = invoice.id;
        this.Subscription.StripeId = invoice.subscription;
        this.Total = invoice.total;

        // Return data
        return this
    }
}
