/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'curtains_closed': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20 19V3H4v16H2v2h20v-2h-2zM11 5h2v14h-2V5z"/>'
  }
})
