
import { Component, Vue } from 'vue-property-decorator'
import {auth, sendEvent} from "@/firebase/config";
import { UserModule } from '@/store/modules/user';
import {AppModule} from "@/store/modules/app";
import {sendSlackMessage} from "@/data/api/slack";


@Component({
  name: 'IntroDialog',
  components: {},
  mixins: []
})
export default class IntroDialog extends Vue {

  private visible  = false;
  private enableButtons  = true;
  private activeTab  = 0;
  private appInstalled = false;
  private videoWatched = false;

  private email = "";

  get appUrl(){
    return window.origin + "/#/app?app=veewar&prospectId=" + window.UserScoring.hashedEmail;
  }


  created(){
    this.setEventListeners();
  }

  // METHODS
  public next(value: boolean){
    this.enableButtons = false;
    if (this.activeTab === 0 && value){
      // App is installed
      this.appInstalled = true;
    } else if (this.activeTab === 0 && !value){
      // App is not installed
      this.appInstalled = false;
      this.activeTab = 1;
    }

    // Next tab
    this.activeTab++;
    const email = auth?.currentUser?.email;
    if (this.activeTab === 2 && email !== "demo-licentie@veewar.com"){
      this.activeTab = 3;
    }
    if (this.activeTab === 4){
      this.close();
    }

    setTimeout(() => {
      this.enableButtons = true;
    }, 500);
  }
  public prev(){
    if (this.activeTab === 2 && !this.appInstalled){
      this.activeTab = 0;
    } else {
      this.activeTab--;
      const email = auth?.currentUser?.email;
      if (this.activeTab === 2 && email !== "demo-licentie@veewar.com"){
        this.activeTab = 1;
      }
    }
  }
  public preventClose(){
    if (this.activeTab < 4){
      return false;
    }
  }
  public close(openTour=true){
    // Hide
    this.visible = false;

    // Analytics
    window.GlobalEvents.$emit("intro-dialog-closed");
    sendEvent("intro_modal_closed", {
      app_installed: this.appInstalled,
      video_watched: this.videoWatched,
    });

    // Open tour
    if (openTour){
      window.GlobalEvents.$emit("start-tour", "create-marker");
    }

    // Lead generated
    if (UserModule.user.Email === "demo-licentie@veewar.com"){
      window.GlobalEvents.$emit("open-chat");
    }

    // Update user
    return UserModule.user.finishedIntro();
  }
  public async open(closeIfKnown=false){
    window.GlobalEvents.$emit("intro-dialog-opened");

    if (AppModule.device === 0 || window.location.pathname.includes("mobile")){
      return false;
    }

    // Open intro-form
    this.visible = true;
    this.activeTab = 0;

    // Get email
    const email = localStorage.getItem("email");
    if (email){
      this.email = email;
      if (closeIfKnown){
        return this.close(false);
      }
    }

    // Analytics
    sendEvent("intro_modal_opened", {
      app_installed: this.appInstalled,
      video_watched: this.videoWatched,
    });

    // Send slack to confirm intro screen open
    if (!sessionStorage.getItem("introScreensViewed")){
      setTimeout(() => {
        const demographic = window.UserScoring.demographic;
        sendSlackMessage({
          title: "Demo visit",
          text: "A new user just started viewing the intro-screens",
          variables: {
            Browser: demographic.browser,
            Company: demographic.company,
            Country: demographic.country,
            Device: demographic.device,
            IpAddress: demographic.ipAddress,
            Region: demographic.region
          }
        });
        sessionStorage.setItem("introScreensViewed", "true");
      }, 3000);
    }

    // Close assistance
    window.GlobalEvents.$emit("close-assistance-dialog");
  }

  validateEmail(email: string){
    return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  submitEmail(){
    this.enableButtons = false;

    const valid = this.validateEmail(this.email);
    if (!valid){
      this.$notify({
        title: this.$t('notifications.invalid-email.title').toString(),
        message: this.$t('notifications.invalid-email.message').toString(),
        type: 'error'
      });
    } else {
      // Set email
      window.UserScoring.setEmail(this.email);

      // Next
      this.next(true);
    }

    setTimeout(() => {
      this.enableButtons = true;
    }, 500);
  }

  private setEventListeners(){
    window.GlobalEvents.$on("open-intro-dialog", this.open);
    window.GlobalEvents.$on("close-intro-dialog", this.close);
    window.GlobalEvents.$on("markers-loaded", () => {
      if (UserModule.user.Email === "demo-licentie@veewar.com" || !UserModule.user.IntroFinished){
        this.open(true);
      } else {
        window.GlobalEvents.$emit("intro-dialog-closed");
      }
    });
  }
}
