/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'download': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5 20h14v-2H5v2zM19 9h-4V3H9v6H5l7 7 7-7z"/>'
  }
})
