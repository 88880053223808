/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'shower': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="8" cy="17" r="1"/><circle pid="1" cx="12" cy="17" r="1"/><circle pid="2" cx="16" cy="17" r="1"/><path pid="3" d="M13 5.08V3h-2v2.08C7.61 5.57 5 8.47 5 12v2h14v-2c0-3.53-2.61-6.43-6-6.92z"/><circle pid="4" cx="8" cy="20" r="1"/><circle pid="5" cx="12" cy="20" r="1"/><circle pid="6" cx="16" cy="20" r="1"/>'
  }
})
