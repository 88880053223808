/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'keyboard_double_arrow_left': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.59 18L19 16.59 14.42 12 19 7.41 17.59 6l-6 6z"/><path pid="1" d="M11 18l1.41-1.41L7.83 12l4.58-4.59L11 6l-6 6z"/>'
  }
})
