/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'contact_element': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M19.587 11.325c-2.47-.003-4.483 2.109-4.48 4.701.006 2.55 2.026 4.656 4.474 4.653 2.454-.006 4.464-2.106 4.464-4.672-.003-2.573-2.003-4.675-4.458-4.682zm2.586 6.934c-.579.682-1.299 1.107-2.166 1.203-1.19.134-2.182-.288-2.986-1.222-.061-.07-.042-.122-.013-.189a2.97 2.97 0 011.517-1.523c.054-.022.106-.042.173-.074-.95-.64-1.072-1.667-.8-2.403a1.832 1.832 0 011.795-1.213c.765.032 1.456.595 1.667 1.382a1.976 1.976 0 01-.861 2.211c.144.074.291.134.426.211.57.323.995.794 1.264 1.411.038.083.038.134-.016.205zM5.926 4.093v2.25c.624 0 1.229-.01 1.83.003 1.741.032 2.938 1.619 2.512 3.322-.288 1.146-1.283 1.955-2.432 1.971-.637.01-1.27 0-1.92 0v2.182c.627 0 1.242-.01 1.856.003 1.338.026 2.39.966 2.56 2.272.166 1.306-.576 2.534-1.798 2.896-.362.109-.758.106-1.142.122-.49.022-.982.006-1.469.006v1.869c.653 0 1.29-.022 1.926.006 1.037.038 1.824.547 2.262 1.526.432.97.32 1.917-.314 2.765-.525.698-1.242 1.002-2.08.998-.592-.003-1.187 0-1.786 0v1.862s.95 1.862 2.122 1.862h19.229c1.174 0 2.122-.938 2.122-2.093V4.091c0-1.155-.95-2.093-2.122-2.093H8.05c-1.178.003-2.125.938-2.125 2.093zM19.597 21.67c-3.03.01-5.44-2.528-5.44-5.709.003-3.098 2.438-5.629 5.421-5.629 2.992.01 5.398 2.509 5.414 5.635.019 3.181-2.413 5.696-5.395 5.702zM3.67 17.558c.659.006 1.322 0 1.981 0 .682 0 1.366.01 2.048-.003.576-.01.95-.381 1.002-.973.061-.682-.442-1.203-1.184-1.21-1.277-.006-2.557-.003-3.834-.003-.65 0-1.085.422-1.098 1.062-.01.656.432 1.12 1.085 1.126zm4.138 7.162c.515-.01.87-.371.902-.902.029-.48-.346-.963-.81-1.014-.179-.019-.368.045-.55.048-1.254.006-2.512 0-3.766.003-.605.003-.998.384-.995.95.006.544.4.915.989.918.694.003 1.389 0 2.08 0 .717-.003 1.434.003 2.15-.003zM3.584 9.766c.694.003 1.386 0 2.08 0 .704 0 1.408.006 2.115-.003.554-.006.941-.397.941-.931a.935.935 0 00-.944-.941c-1.398-.003-2.797 0-4.195.006-.592.003-.982.371-.989.918-.01.566.387.944.992.95z"/>'
  }
})
