/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'flourescent': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5 9h14v6H5zm6-7h2v3h-2zm6.286 4.399l1.79-1.803 1.42 1.41-1.79 1.802zM11 19h2v3h-2zm6.29-1.29l1.79 1.8 1.42-1.42-1.8-1.79zM3.495 6.01l1.407-1.408L6.69 6.391 5.284 7.798zm-.003 12.066l1.803-1.79 1.409 1.42-1.803 1.79z"/>'
  }
})
