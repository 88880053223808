import request from '@/utils/request'
import {auth} from '@/firebase/config'
import {signInWithEmailAndPassword, createUserWithEmailAndPassword} from "firebase/auth";
import {UserModule} from "@/store/modules/user";
import {MarkerModule} from "@/store/modules/marker";

export const getUsers = () =>
  request({
    url: 'user',
    method: 'get'
  })

export const getUser = () =>
    request({
        url: 'user/' + auth.currentUser?.uid,
        method: 'get',
    })

export const updateUser = (uid: string, data: any) =>
    request({
        url: `user/` + uid,
        method: 'put',
        data
    })

export const getUserProfile = (uid : string) =>
    request({
        url: `/user/${uid}/profile`,
        method: 'get',
    })

export const verifyUser = (uid : string, verificationCode: string) =>
    request({
        url: `/user/${uid}/verify/${verificationCode}`,
        method: 'get',
    })

export const getUserByEmail = (email: string) =>
  request({
    url: `/user/${email}`,
    method: 'get'
  })

export const updateUserProfile = (uid: string, data: any) =>
    request({
        url: `user/` + uid + "/profile",
        method: 'put',
        data
    })
export const addUserProfile = (uid: string, data: any) =>
    request({
        url: `user/` + uid + "/profile",
        method: 'post',
        data
    })

export const deleteUser = (uid: string) =>
  request({
    url: `/user/${uid}`,
    method: 'delete'
  })

export const getUserRoles = async () => {
    // Get available roles
    const roles = ["admin", "user", "trial"];

    // Get user claims

    const claims : any = await auth.currentUser?.getIdTokenResult(true).then((idTokenResult) => {
        return idTokenResult.claims;
    });

    // Trial is not an active role if user is given
    if (!claims.trial) {
        claims.user = true;
    }

    if (claims.user){
        roles.splice(roles.indexOf("trial"), 1);
    }

    // Return user rp;es
    return Object.keys(claims).filter((key:string) => claims[key] && roles.indexOf(key) >= 0);
}

export const registerUser = (userId: string, licenseId?: string, accessToken?: string, productId?:string, data?: any) =>
    request({
        url: `user/` + userId + (!productId ? '/license/' + licenseId + '/' + accessToken : "/product/" + productId),
        method: 'post',
        data
    })


export const resetPassword = (email: string) =>
    request({
        url: `password-reset/` + email,
        method: 'post'
    })

export const login = (data: any) => signInWithEmailAndPassword(auth, data.email, data.password)

export const logout = async (path?: string) => {
    window.GlobalEvents.$emit("logout");
    await UserModule.RemoveUserData();
    await MarkerModule.RemoveMarkerData();
    await auth.signOut();
    if (!path){
        window.location.reload();
    } else {
        window.location.href = window.origin + "/#" + path
    }
}

export const register = (data: any) => createUserWithEmailAndPassword(auth, data.email, data.password)

export const getUsersWithSubscription = () =>
    request({
        url: `/user-with-subscription`,
        method: 'get'
    })
