/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'sentiment_satisfied': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="15.5" cy="9.5" r="1.5"/><circle pid="1" cx="8.5" cy="9.5" r="1.5"/><path pid="2" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-4c-.73 0-1.38-.18-1.96-.52-.12.14-.86.98-1.01 1.15a5.49 5.49 0 005.95-.01c-.97-1.09-.01-.02-1.01-1.15-.59.35-1.24.53-1.97.53z"/>'
  }
})
