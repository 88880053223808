/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'video_element': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M0 29.29V2.704h32V29.29H0zm11.907-18.157v9.552c2.998-1.613 5.875-3.155 8.899-4.781l-8.899-4.771zM3.885 4.797v2.512h6.352V4.797H3.885zM28.192 7.36V4.835h-6.374V7.36h6.374zM3.84 27.171h6.413v-2.499H3.84v2.499zm24.378-2.518h-6.371v2.531h6.371v-2.531zM12.822 7.347h6.32V4.819h-6.32v2.528zm.02 19.818h6.323V24.65h-6.323v2.515z"/>'
  }
})
