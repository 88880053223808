
import {getModelAnimations} from '@/globalFunctions';
import {Component, Prop} from 'vue-property-decorator'
import InputMixin from "../mixins/input";

@Component({
  name: 'ModelAnimationInput',
  components: {},
  mixins: [InputMixin]
})
export default class ModelAnimationInput extends InputMixin {
  @Prop({ default: "text" }) private type! : string; // textarea, text, email, password,

  animations: any[] = [];

  get order(){
    return this.valueModel;
  }
  set order(value: any[]){
    this.valueModel = value;
  }

  addAnimation(){
    this.order.push({
      name: null,
      uuid: null,
      duration: 0
    });
  }

  async getAnimations(){
    const markerElementGroupId = this.formValues?.Action?.Animation?.ElementGroupId;
    if (markerElementGroupId){
      // Get element group
      const markerElementGroup = this.$store.state.editor.marker.Elements.getGroup(markerElementGroupId);

      // Get animations
      let animations = markerElementGroup.getProperty("ModelAnimations")?.List;

      // Get model animations
      if (!animations){
        const fileUrl = markerElementGroup.getProperty("FileUrl")
        animations = await getModelAnimations(fileUrl);
      }


      // Map to options
      this.animations = animations.map((animation: any) => {
        return {
          key: animation.uuid,
          value: animation.name,
          label: animation.name,
          ...animation
        }
      });
    } else if (this.formValues?.ModelAnimations?.List){
      this.animations = this.formValues?.ModelAnimations.List.map((animation: any) => {
        return {
          key: animation.uuid,
          value: animation.name,
          label: animation.name,
          ...animation
        }
      });
    }
  }

  removeAnimation(index: number){
    this.order.splice(index, 1);
  }

  setAnimation(index: number){
    // uuid
    const name = this.order[index].name;

    // Set animation
    const animationIndex = this.animations.findIndex((x) => x.name === name);
    if (animationIndex >= 0){
      const {uuid, duration} = this.animations[animationIndex];
      this.order[index].uuid = uuid;
      this.order[index].duration = duration;
    }

    this.valueModel = this.order;
  }

  created(){
    this.getAnimations();
  }
}
