
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import ObjectViewer from "@/components/ObjectViewer/index.vue";
import AudioViewer from "@/components/AudioViewer/index.vue";
import {getLocalDataUrl} from "@/globalFunctions";

@Component({
  name: 'FileViewer',
  components: {ObjectViewer, AudioViewer}
})
export default class extends Vue {
  @Prop({default: true}) private autoRotate: boolean;
  @Prop({default: false}) private autoPlay: boolean;
  @Prop({default: true}) private controls: boolean;
  @Prop({default: () => { return {};}}) private metadata: Record<string, unknown>;
  @Prop({default: false}) private plain: boolean;
  @Prop({default: false}) private advancedSettings: boolean;
  @Prop({default: "image"}) private type: string;
  @Prop({default: ""}) private url: string | null;

  public loading = false;

  private fileUrl = "";

  public width = 0;
  public height = 0;

  // Watchers
  @Watch("url")
  onUrlChange(){
    this.onLoad();
  }

  created(){
    this.onLoad();
  }

  // Methods
  private getLocalDataUrl = getLocalDataUrl;

  private async onLoad(){
    if (this.url === null){
      return;
    }

    // Start loading
    this.loading = true;

    // Get local url
    const localUrl = await this.getLocalDataUrl(this.url);

    if (localUrl){
      this.fileUrl = localUrl;
    } else {
      // Set file url
      this.fileUrl = this.url;
    }

    if (this.type === "audio" || this.type === "youtube"){
      return this.onFileLoad();
    }
  }

  private async onFileLoad(){
    // Stop loading
    this.loading = false;

    // Set width and height
    const ref = this.$refs[this.type];
    const element = ref instanceof Vue ? (ref as Vue).$el : (ref as Element);
    if (element){
      const {clientWidth, clientHeight} = element;
      this.width = clientWidth;
      this.height = clientHeight;
    }

    // Loaded
    this.$emit("loaded");
  }

  private onError(){
    // Start loading
    this.loading = true;

    // Change url to external
    if (this.url && this.fileUrl !== this.url){
      this.fileUrl = this.url;
    } else {
      console.error("Error loading file");
    }

    // End loading
    this.loading = false;
  }
}
