/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'recommend': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2a10 10 0 1010 10A10 10 0 0012 2zm6 9.8a.9.9 0 01-.1.5l-2.1 4.9a1.34 1.34 0 01-1.3.8H9a2 2 0 01-2-2v-5a1.28 1.28 0 01.4-1L12 5l.69.69a1.08 1.08 0 01.3.7v.2L12.41 10H17a1 1 0 011 1z"/>'
  }
})
