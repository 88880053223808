import Vue, { DirectiveOptions } from 'vue'

import {resolveProvider} from "./provider";
import "./prototypes";


import 'normalize.css'
import ElementUI from 'element-ui'
import SvgIcon from 'vue-svgicon'

import '@/styles/element-variables.scss'


import App from '@/App.vue'
import store from '@/store'
import { AppModule } from '@/store/modules/app'
import router from '@/router'
import i18n from '@/lang'
import '@/icons/components'
import '@/permission'
import '@/pwa/register-service-worker'
import * as directives from '@/directives'
import * as filters from '@/filters'

// Initial modules
import {UserModule} from "@/store/modules/user";
import {DatabaseModule} from "@/store/modules/database";
import information from "../package.json";

// Firebase
import {auth, setUserProperty} from "@/firebase/config";

// Spinner
// @ts-ignore
import Spinner from 'vue-simple-spinner'
Vue.component("VueSimpleSpinner", Spinner); // TODO: Remove this spinner
// @ts-ignore
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent);


Vue.use(ElementUI, {
  size: AppModule.size, // Set element-ui default size
  i18n: (key: string, value: string) => i18n.t(key, value)
})

Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em'
})

// Register global directives
Object.keys(directives).forEach(key => {
  Vue.directive(key, (directives as { [key: string ]: DirectiveOptions })[key])
})

// Register global filter functions
Object.keys(filters).forEach(key => {
  Vue.filter(key, (filters as { [key: string ]: (params?: any) => any })[key])
});

Vue.config.productionTip = false

// Globally accessible
import * as functions from "@/globalFunctions";
Vue.prototype.$functions = functions;
Vue.prototype.$user = {
  isAdmin: false
};

// Mixins
import SettingsMixin from '@/mixins/settings';
Vue.mixin(SettingsMixin);

// Mobile
if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
  document.body.classList.add("mobile")
} else {
  document.body.classList.add("desktop")
}

// DEMO reroute
if (window.location.href.indexOf("#") > window.location.href.indexOf("demo") && window.location.href.indexOf("demo") !== -1){
  window.location.href = window.location.origin + "/#/demo";
}

// Authentication state changed
setUserProperty("user_type", "prospect");
auth.onAuthStateChanged(async (user : any) => {
  // User is logged in
  if (user && router.currentRoute.path !== "/register"){
    // Initiate
    await UserModule.Initialize(user);

    // Update database
    await DatabaseModule.Update()
  }

  let counter = 0;
  window.addEventListener("keydown", (event) => {
    if (event.key === "v"){
      counter++;
      setTimeout(() => {
        counter--;
        if (counter < 0){
          counter = 0;
        }
      }, 1000);

      if (counter >= 3){
        alert("CMS Version: " + information.version);
        counter = 0;
      }
    }
  })
});



resolveProvider().finally(() => {
    //Initialize app
    new Vue({
      router,
      store,
      i18n,
      render: (h) => h(App)
    }).$mount('#app');
})


