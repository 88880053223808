// Import the functions you need from the SDKs you need
import { storage } from "./config";
import {ref, uploadBytesResumable, getDownloadURL, deleteObject, getBlob, getMetadata } from "firebase/storage";
import {DatabaseModule} from "@/store/modules/database";
import {FullMetadata} from "@firebase/storage-types";

const uploadFile = async (filepath: string, file: File, metadata?: any, progress?: (percent: number) => void) : Promise<string> => {
    return new Promise((resolve, reject) => {
        // Get storage ref
        const storageRef = ref(storage, filepath);

        // Create task
        const uploadTask = uploadBytesResumable(storageRef, file, metadata);

        // Listen for state changes
        uploadTask.on('state_changed',
            (snapshot: any) => {
                // Return progress
                if (progress){
                    progress(snapshot.bytesTransferred / snapshot.totalBytes);
                }
            },
            (error: any) => {
                reject(error);
            },
            async () => {
                // Save to database
                await DatabaseModule.Download({filepath, file, metadata});

                // Upload completed successfully, return the download url
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    resolve(downloadURL);
                });
            }
        );
    })
}

const removeFile = async (filepath: string) => {
    return new Promise((resolve, reject) => {
        // Get storage ref
        const storageRef = ref(storage, filepath);

        // Delete the file
        deleteObject(storageRef).then(() => {
            resolve(true);
        }).catch((error) => {
            console.error(error);
            reject(error);
        });
    });
};

const downloadFile = async (filepath: string) => {
    return new Promise((resolve, reject) => {
        // Get storage ref
        const storageRef = ref(storage, filepath);

        // Delete the file
        getBlob(storageRef).then((blob) => {
            resolve(blob);
        }).catch((error) => {
            console.error(error);
            reject(error);
        });
    });
};

const getFileUrl = async (filepath: string) : Promise<string> => {
    return new Promise((resolve, reject) => {
        // Get storage ref
        const storageRef = ref(storage, filepath);

        // Delete the file
        getDownloadURL(storageRef).then((downloadURL) => {
            resolve(downloadURL);
        }).catch(reject);
    });
};

const getFileLastModified = async (filepath: string) : Promise<number> => {
    return new Promise((resolve, reject) => {
        // Get storage ref
        const storageRef = ref(storage, filepath);

        // Delete the file
        getMetadata(storageRef).then((metadata: FullMetadata) => {
            resolve(new Date(metadata.updated).getTime());
        }).catch((error) => {
            reject(error.code);
        });
    });
};

export {
    uploadFile,
    removeFile,
    downloadFile,
    getFileLastModified,
    getFileUrl
}
