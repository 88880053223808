
import { Component, Vue } from 'vue-property-decorator'
import { sendEvent } from "@/firebase/config";


@Component({
  name: 'VideoDialog',
  components: {},
  mixins: []
})
export default class VideoDialog extends Vue {

  private visible  = false;

  created(){
    this.setEventListeners();
  }

  // METHODS
  public close(){
    // Hide
    this.visible = false;

    // Start tour
    window.GlobalEvents.$emit("start-tour", "create-marker");

    // Analytics
    window.GlobalEvents.$emit("video-dialog-closed");
    sendEvent("video_modal_closed", {});
  }
  public async open(){
    window.GlobalEvents.$emit("video-dialog-opened");

    // Open intro-form
    this.visible = true;

    // Analytics
    sendEvent("video_modal_opened", {});

    // Close assistance
    window.GlobalEvents.$emit("close-assistance-dialog");
  }

  private setEventListeners(){
    window.GlobalEvents.$on("open-video-dialog", this.open);
  }
}
