/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'youtube_element': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M31.997 13.731h-.054c0-.314.006-.624-.003-.938-.01-.307-.032-.614-.054-.922-.029-.381-.067-.762-.102-1.142s-.042-.768-.118-1.139a17.984 17.984 0 00-.426-1.658c-.15-.49-.374-.954-.714-1.344-.746-.861-1.706-1.318-2.829-1.437a40.775 40.775 0 00-1.786-.134 55.899 55.899 0 00-1.645-.08c-.826-.032-1.651-.061-2.477-.083-.81-.022-1.619-.045-2.426-.051-1.587-.01-3.174-.026-4.762-.013-1.61.013-3.216.054-4.826.086-.678.013-1.357.035-2.035.054a19.75 19.75 0 00-.643.029c-.704.042-1.408.074-2.109.134-.47.042-.95.083-1.408.195-1.008.243-1.821.797-2.381 1.677-.406.646-.63 1.37-.768 2.112a23.34 23.34 0 00-.243 1.834c-.064.621-.099 1.245-.134 1.866A24.335 24.335 0 000 14.303c0 1.443.01 2.89.026 4.333.003.362.029.723.054 1.082.038.515.083 1.03.128 1.542.032.381.054.765.115 1.142.102.65.275 1.28.509 1.894.429 1.136 1.232 1.882 2.374 2.266.726.243 1.485.31 2.237.381.608.058 1.219.077 1.83.106.502.022 1.008.042 1.514.054.79.022 1.581.042 2.371.054.733.013 1.466.029 2.198.035 1.286.013 2.576.035 3.859.026 1.613-.013 3.226-.054 4.838-.086.662-.013 1.322-.035 1.984-.054.218-.006.438-.016.656-.029.726-.042 1.453-.077 2.176-.134.454-.035.915-.067 1.36-.163 1.411-.304 2.41-1.117 2.938-2.483.362-.931.531-1.901.611-2.89.045-.56.099-1.12.128-1.677.038-.717.074-1.437.08-2.154.019-1.27.01-2.544.01-3.818zm-19.306 6.426v-8.982c2.883 1.501 5.75 2.995 8.643 4.506-2.886 1.494-5.754 2.979-8.643 4.477z"/>'
  }
})
