/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'transit_enterexit': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16 18H6V8h3v4.77L15.98 6 18 8.03 11.15 15H16v3z"/>'
  }
})
