/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'keyboard_double_arrow_up': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 17.59L7.41 19 12 14.42 16.59 19 18 17.59l-6-6z"/><path pid="1" d="M6 11l1.41 1.41L12 7.83l4.59 4.58L18 11l-6-6z"/>'
  }
})
