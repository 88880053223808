
import { Component } from 'vue-property-decorator'
import InputMixin from "../mixins/input";

@Component({
  name: 'SwitchInput',
  components: {},
  mixins: [InputMixin]
})
export default class SwitchInput extends InputMixin {}
