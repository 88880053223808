import Vue from 'vue'
import Vuex from 'vuex'
import { IAppState } from './modules/app'
import { IUserState } from './modules/user'
import { IPermissionState } from './modules/permission'
import { ISettingsState } from './modules/settings'
import { IEditorState } from "@/store/modules/editor";
import {IMarkerModuleState} from "@/store/modules/marker";
import {ICouponModuleState} from "@/store/modules/coupon";
import {ICustomerModuleState} from "@/store/modules/customer";
import {IDatabaseModuleState} from "@/store/modules/database";
import {IProductModuleState} from "@/store/modules/product";


Vue.use(Vuex)

export interface IRootState {
  app: IAppState
  coupon: ICouponModuleState,
  customer: ICustomerModuleState,
  database: IDatabaseModuleState,
  editor: IEditorState
  marker: IMarkerModuleState,
  permission: IPermissionState,
  product: IProductModuleState,
  provider: IProvider,
  settings: ISettingsState,
  user: IUserState,
}

// Declare empty store first, dynamically register all modules later.
export default new Vuex.Store<IRootState>({})
