import request from '@/utils/request'

export const getUserLicenses = async (userId: string, params?: any) =>
    request({
        url: 'user/' + userId + '/licenses',
        method: 'get',
        params
    });

export const getTrialLicense = (params?: any) =>
    request({
        url: 'trial-license',
        method: 'get',
        params
    })

export const getLicenses = (params?: any) =>
    request({
        url: 'license',
        method: 'get',
        params
    })

export const getLicense = (licenseId: string, params?: any) =>
    request({
        url: 'license/' + licenseId,
        method: 'get',
        params
    })

export const getLicenseInvoices = (licenseId: string, params?: any) =>
    request({
        url: 'license/' + licenseId + '/invoice',
        method: 'get',
        params
    })

export const getLicenseProducts = (licenseId: string, params?: any) =>
    request({
        url: 'license/' + licenseId + '/product',
        method: 'get',
        params
    })

export const createLicense = (data: any) =>
    request({
        url: 'license',
        method: 'post',
        data: data
    })

export const updateLicense = (licenseId: string, data: any) =>
    request({
        url: 'license/' + licenseId,
        method: 'put',
        data
    })

export const deleteLicense = (licenseId: string) =>
    request({
        url: 'license/' + licenseId,
        method: 'delete'
    })
