// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {getFirestore, collection, doc, setDoc, onSnapshot, QuerySnapshot} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";
import firebase from "firebase/compat";
import DocumentData = firebase.firestore.DocumentData;
import request from "@/utils/request";

const firebaseConfigs: { [key: string] : any } = {
    acceptance: {
        apiKey: "AIzaSyBWfiWa_u3C8oP-05LAaCHFe9TaRHfIxt8",
        authDomain: "acceptance-veewar.firebaseapp.com",
        databaseURL: "https://acceptance-veewar-default-rtdb.firebaseio.com",
        projectId: "acceptance-veewar",
        storageBucket: "acceptance-veewar.appspot.com",
        messagingSenderId: "269778125831",
        appId: "1:269778125831:web:e53ee66899091f9212d6a2",
        measurementId: "G-SHGNSBYQ08"
    },
    "veewar-cms.web.app": {
        apiKey: "AIzaSyCqW_VRz80-ZqyVkkxvEEic6Jwgm8x7_JA",
        authDomain: "veewar-cms.firebaseapp.com",
        databaseURL: "https://veewar-cms.firebaseio.com",
        projectId: "veewar-cms",
        storageBucket: "veewar-cms.appspot.com",
        messagingSenderId: "223640053698",
        appId: "1:223640053698:web:86fe43adb0877a4229d85b",
        measurementId: "G-F2WBRN3DXG"
    },
    cms: {
        apiKey: "AIzaSyCqW_VRz80-ZqyVkkxvEEic6Jwgm8x7_JA",
        authDomain: "veewar-cms.firebaseapp.com",
        databaseURL: "https://veewar-cms.firebaseio.com",
        projectId: "veewar-cms",
        storageBucket: "veewar-cms.appspot.com",
        messagingSenderId: "223640053698",
        appId: "1:223640053698:web:86fe43adb0877a4229d85b",
        measurementId: "G-F2WBRN3DXG"
    },
    dev: {
        apiKey: "AIzaSyByjFsGsuX0txTTG86bpejcj7dEF-skEIQ",
        authDomain: "dev-veewar.firebaseapp.com",
        projectId: "dev-veewar",
        storageBucket: "dev-veewar.appspot.com",
        messagingSenderId: "1083038143948",
        appId: "1:1083038143948:web:8c166ba0083df45f793969",
        measurementId: "G-GSE34TZEGZ"
    },
    localhost: {
        apiKey: "AIzaSyByjFsGsuX0txTTG86bpejcj7dEF-skEIQ",
        authDomain: "dev-veewar.firebaseapp.com",
        projectId: "dev-veewar",
        storageBucket: "dev-veewar.appspot.com",
        messagingSenderId: "1083038143948",
        appId: "1:1083038143948:web:8c166ba0083df45f793969",
        measurementId: "G-GSE34TZEGZ"
    },
    test: {
        apiKey: "AIzaSyBs5tplG0eHh_daM12hC7VWfeRrKJBkZ1c",
        authDomain: "test-veewar.firebaseapp.com",
        projectId: "test-veewar",
        storageBucket: "test-veewar.appspot.com",
        messagingSenderId: "492265870612",
        appId: "1:492265870612:web:9dea0761a2805fdc8c9e01",
        measurementId: "G-B9XXDJ9TJB"
    },
}
// Your web app's Firebase configuration
const firebaseConfig = firebaseConfigs[window.environment || "cms"];

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const refreshToken = async () : Promise<string> => {
    return auth.currentUser?.getIdToken(true).then((accessToken) => {
        return accessToken;
    }) || "";
};

// Initialize firestore
const db = getFirestore(app);
const saveDoc = async (collection: string, id: string, data: any, options?: any) => {
    const ref = doc(db, collection, id);
    return setDoc(ref, data, options);
};
const listenToDoc = async (collection: string, id: string, callback: (doc?: DocumentData) => void) => {
    const unsubscribe = onSnapshot(doc(db, collection, id), (doc) => {
        callback(doc.data());
    });

    return {
        unsubscribe
    };
}
const listenToCollection = async (col: string, callback: (docs?: QuerySnapshot<DocumentData>) => void) => {
    const unsubscribe = onSnapshot(collection(db, col), (snapshots) => {
        callback(snapshots);
    });

    return {
        unsubscribe
    };
}

// Initialize storage
const storage = getStorage(app);

// Initialize analytics
const analytics = getAnalytics(app);
const setUserProperty = (key: string, value: string) => setUserProperties(analytics, { [key]: value });
const sendEvent = (event: string, params?: any) => {
    // Log event to UserScoring
    window.UserScoring.send(event, params);

    // Log event to analytics
    logEvent(analytics, event, params);
}

// Initialize functions
const functions = getFunctions();
const testFunction = (data: any) => request({
    url: 'test',
    method: 'post',
    data: data
});
const cleanFunction = httpsCallable(functions, 'clean');

export {
    db,
    saveDoc,
    listenToDoc,
    listenToCollection,
    auth,
    storage,
    sendEvent,
    setUserProperty,
    testFunction,
    cleanFunction,
    refreshToken
}

export class getFileUrl {
}
