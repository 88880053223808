import {convertDate, createRandomToken, filterUndefined} from "@/globalFunctions";
import {
    createTutorial,
    deleteTutorial,
    getTutorial,
    getTutorials,
    updateTutorial,
    createTutorialSection,
    deleteTutorialSection,
    getTutorialSections,
    getTutorialSection,
    updateTutorialSection, getTutorialByLink
} from "@/data/api/tutorial";
import {UserModule} from "@/store/modules/user";

export class TutorialList extends Array implements Array<Tutorial> {
    public _changed = 0;
    public _retrieved = false;

    constructor() {
        super();
    }

    public new = () : Tutorial => {
        // Create tutorial
        const tutorial = new Tutorial();

        // Push to list
        this.push(tutorial);

        // return
        return tutorial;
    }

    public empty = () : void => {
        while(this.length > 0){
            this.pop();
        }
    }

    public get = async (params?: any) : Promise<TutorialList> => {
        // Empty array
        this.empty();

        // Get data
        const {data} = await getTutorials(params);

        // Convert to tutorials
        for (const tutorialData of data){
            // Push data
            const tutorial = new Tutorial(tutorialData);
            tutorial._new = false;
            this.push(tutorial);
        }

        // Retrieved
        this._changed = 0;
        this._retrieved = true;

        // Return tutorials
        return this;
    }

    public set = (tutorial: Tutorial) => {
        // Find index
        const index = this.findIndex((listElement) => listElement.Id === tutorial.Id);
        if (index >= 0){
            Object.assign(this[index], tutorial);
        } else {
            this.push(tutorial);
        }
    }

    public update = async () => {
        // Collect tutorials
        const tutorials = this.filter((tutorial) => tutorial._changed > 0) as Tutorial[];

        // Update tutorials
        const promises = [];
        for (const tutorial of tutorials){
            promises.push(tutorial.update());
        }

        // Changed
        this._changed = 0;

        // Return tutorials
        return await Promise.all(promises);
    }

    public archive = async (id: string) => {
        // Collect tutorials
        const index = this.findIndex((tutorial) => tutorial.Id === id);
        const tutorial = this[index] as Tutorial;
        if (tutorial){
            const result = await tutorial.archive() as any;
            if (result.status === "success" || result.data.status === "success"){
                // Delete locally
                this.splice(index, 1);

                return true;
            }
        }

        // Return tutorials
        return false;
    }

    public hasChanged = () => {
        this._changed = Date.now();
    }

    public toList = () => {
        return this.map((tutorial) => tutorial);
    }
}

export class Tutorial implements ITutorial {
    _new? = true;
    _changed? = 0;


    Archived = false;
    ArchivedBy?: string;
    ArchivedDateTime?: Date;
    CreatedBy = UserModule.uid;
    CreatedDateTime = new Date();
    DocumentUpdatedBy = UserModule.uid;
    DocumentUpdatedDateTime = new Date();
    Id = createRandomToken();
    ImageUrl?: string;
    IntroShort?: string;
    Language = "EN";
    Published = false;
    SortIndex = 0;
    Sections: TutorialSectionList = new TutorialSectionList();
    SubTitle?: string;
    Tags?: string[];
    TipText?: string;
    TipTitle?: string
    Title?: string;
    TitleShort?: string;
    Type: "regular" | "video";

    constructor(obj?: any){
        if (obj){
            // Assign other data
            this.set(obj);
        }
    }

    /**
     * Get tutorial
     * @return {Tutorial} JSON data
     */
    public async get(id: string) : Promise<Tutorial> {
        // Get data
        const {data} = await getTutorial(id);

        // Item is not new
        this._new = false;

        // Set data
        return this.set(data);
    }

    /**
     * Get tutorial by link
     * @param {string} link The link to the tutorial
     * @param {string} language The language to get
     * @return {Tutorial} JSON data
     */
    public async getByLink(link: string, language: string) : Promise<Tutorial> {
        // Get data
        const {data} = await getTutorialByLink(link, language);

        // Item is not new
        this._new = false;

        // Set data
        return this.set(data);
    }

    /**
     * Set tutorial data
     * @return {Tutorial} Instance
     */
    public set(data: any) : Tutorial {
        if (data.Sections){
            for (const section of data.Sections){
                this.Sections.push(
                    new TutorialSection(section)
                );
            }

            // Disable re-assign
            delete data.Sections;
        }

        // Convert dates
        Object.keys(data).forEach(key => {
            if (key.indexOf("DateTime") >= 0){
                data[key] = convertDate(data[key]);
            }
        });

        // Assign data
        Object.assign(this, data);

        // Return instance
        return this;
    }


    /**
     * Archive the tutorial
     * @return {Promise<any>} Archiving result
     */
    public async archive() : Promise<any> {
        // Archive
        const result = await deleteTutorial(this.Id);

        // Return result
        return result;
    }

    /**
     * Update or create the tutorial
     * @return {Tutorial} Tutorial object
     */
    public async update() : Promise<Tutorial> {
        // Update or create
        const data = this.toJSON();
        const result = this._new ? await createTutorial(data) : await updateTutorial(this.Id, data);

        // Assign object
        Object.assign(this, result.data);

        // No changes
        this._changed = 0;
        this._new = false;

        return this;
    }

    /**
     * Convert to JSON
     * @return {ITutorial} JSON data
     */
    public toJSON() : ITutorial {
        return filterUndefined({
            Archived: this.Archived,
            ArchivedBy: this.ArchivedBy,
            ArchivedDateTime: convertDate(this.ArchivedDateTime),
            CreatedBy: this.CreatedBy,
            CreatedDateTime: convertDate(this.CreatedDateTime),
            DocumentUpdatedBy: this.DocumentUpdatedBy,
            DocumentUpdatedDateTime: convertDate(this.DocumentUpdatedDateTime),
            Id: this.Id,
            ImageUrl: this.ImageUrl,
            IntroShort: this.IntroShort,
            Language: this.Language,
            Published: this.Published,
            SortIndex: this.SortIndex,
            Sections: this.Sections.map((section) => section.toJSON ? section.toJSON() : section),
            SubTitle: this.SubTitle,
            Tags: this.Tags,
            TipText: this.TipText,
            TipTitle: this.TipTitle,
            Title: this.Title,
            TitleShort: this.TitleShort,
            Type: this.Type,
        }) as ITutorial;
    }

}

export class TutorialSectionList extends Array implements Array<TutorialSection> {
    public _changed = 0;
    public _language = "EN"
    public _retrieved = false;

    constructor() {
        super();
    }

    public new = () : TutorialSection => {
        // Create tutorial
        const tutorialSection = new TutorialSection();

        // Push to list
        this.push(tutorialSection);

        // return
        return tutorialSection;
    }

    public empty = () : void => {
        while(this.length > 0){
            this.pop();
        }
    }

    public get = async (tutorialId: string, language?: string) : Promise<TutorialSectionList> => {
        // Empty array
        this.empty();

        // Save language
        if (language){
            this._language = language;
        } else {
            const result = await getTutorial(tutorialId);
            this._language = result.data.Language;
        }

        // Get data
        const {data} = await getTutorialSections(tutorialId);

        // Convert to tutorials
        for (const tutorialData of data){
            // Push data
            const tutorialSection = new TutorialSection(tutorialData);
            tutorialSection._new = false;
            tutorialSection.Language = this._language;
            this.push(tutorialSection);
        }

        // Retrieved
        this._changed = 0;
        this._retrieved = true;

        // Return tutorials
        return this;
    }

    public set = (tutorialSection: TutorialSection) => {
        // Find index
        const index = this.findIndex((listElement) => listElement.Id === tutorialSection.Id);
        if (index >= 0){
            Object.assign(this[index], tutorialSection);
        } else {
            this.push(tutorialSection);
        }
    }

    public update = async () => {
        // Collect tutorials
        const tutorialSections = this.filter((tutorialSection) => tutorialSection._changed > 0) as TutorialSection[];

        // Update tutorials
        const promises = [];
        for (const tutorialSection of tutorialSections){
            promises.push(tutorialSection.update());
        }

        // Changed
        this._changed = 0;

        // Return tutorials
        return await Promise.all(promises);
    }

    public archive = async (id: string) => {
        // Collect tutorials
        const index = this.findIndex((tutorialSection) => tutorialSection.Id === id);
        const tutorialSection = this[index] as TutorialSection;
        if (tutorialSection){
            const result = await tutorialSection.archive() as any;
            if (result.status === "success" || result.data.status === "success"){
                // Delete locally
                this.splice(index, 1);

                return true;
            }
        }

        // Return tutorials
        return false;
    }

    public hasChanged = () => {
        this._changed = Date.now();
    }

    public toList = () => {
        return this.map((tutorialSection) => tutorialSection);
    }
}

export class TutorialSection implements ITutorialSection {
    _new? = true;
    _changed? = 0;


    Archived = false;
    ArchivedBy?: string;
    ArchivedDateTime?: Date;
    ButtonLinkTutorialId?: string;
    ButtonText?: string;
    CreatedBy = UserModule.uid;
    CreatedDateTime = new Date();
    DocumentUpdatedBy = UserModule.uid;
    DocumentUpdatedDateTime = new Date();
    Icon?: string;
    Id = createRandomToken();
    ImageUrl?: string;
    Language = "EN";
    Link: string[] = [];
    Outlining: "right" | "left";
    Published = false;
    SortIndex = 0;
    SubTitle?: string;
    Tags?: string[];
    Text?: string;
    Title?: string;
    TutorialId: string;
    Type = "text";

    constructor(obj?: any){
        if (obj){
            // Assign other data
            this.set(obj);
        }
    }

    /**
     * Get tutorial section
     * @return {Tutorial} JSON data
     */
    public async get(id: string, tutorialId?: string) : Promise<TutorialSection> {
        // Set tutorial ID
        this.TutorialId = tutorialId || this.TutorialId;

        // Get data
        const {data} = await getTutorialSection(this.TutorialId, id);

        // Item is not new
        this._new = false;

        // Set data
        return this.set(data);
    }

    /**
     * Set tutorial data
     * @return {Tutorial} Instance
     */
    public set(data: any) : TutorialSection {
        // Convert dates
        Object.keys(data).forEach(key => {
            if (key.indexOf("DateTime") >= 0){
                data[key] = convertDate(data[key]);
            }
        });

        // Assign data
        Object.assign(this, data);

        // Return instance
        return this;
    }


    /**
     * Archive the tutorial
     * @return {Promise<any>} Archiving result
     */
    public async archive() : Promise<any> {
        // Archive
        const result = await deleteTutorialSection(this.TutorialId, this.Id);

        // Return result
        return result;
    }

    /**
     * Update or create the tutorial
     * @return {Tutorial} Tutorial object
     */
    public async update() : Promise<TutorialSection> {
        // Update or create
        const data = this.toJSON();
        const result = this._new ? await createTutorialSection(this.TutorialId, data) : await updateTutorialSection(this.TutorialId, this.Id, data);

        // Assign object
        Object.assign(this, result.data);

        // No changes
        this._changed = 0;
        this._new = false;

        return this;
    }

    /**
     * Convert to JSON
     * @return {ITutorial} JSON data
     */
    public toJSON() : ITutorialSection {
        return filterUndefined({
            Archived: this.Archived,
            ArchivedBy: this.ArchivedBy,
            ArchivedDateTime: convertDate(this.ArchivedDateTime),
            ButtonLinkTutorialId: this.ButtonLinkTutorialId,
            ButtonText: this.ButtonText,
            CreatedBy: this.CreatedBy,
            CreatedDateTime: convertDate(this.CreatedDateTime),
            DocumentUpdatedBy: this.DocumentUpdatedBy,
            DocumentUpdatedDateTime: convertDate(this.DocumentUpdatedDateTime),
            Icon: this.Icon,
            Id: this.Id,
            ImageUrl: this.ImageUrl,
            Link: this.Link,
            Language: this.Language,
            Outlining: this.Outlining,
            Published: this.Published,
            SortIndex: this.SortIndex,
            SubTitle: this.SubTitle,
            Tags: this.Tags,
            Text: this.Text,
            Title: this.Title,
        }) as ITutorialSection;
    }

}


