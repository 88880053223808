/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow_back_ios_new': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.77 3.77L16 2 6 12l10 10 1.77-1.77L9.54 12z"/>'
  }
})
