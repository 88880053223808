
import { Component, Vue } from 'vue-property-decorator'
import * as configuration from "./configuration"
import {ElLoadingComponent} from "element-ui/types/loading";
import { MessageBox } from 'element-ui'
import GeneralForm from "@/components/GeneralForm/index.vue";
import {BugReport} from "@/data/classes/bug-report";

@Component({
  name: 'BugReportForm',
  components: {
    GeneralForm
  },
  mixins: []
})
export default class LibraryItemForm extends Vue {

  private loading: | ElLoadingComponent;
  private ready  = false;

  private visible  = false;
  private title  = "";

  private formValues : Record<string, any> = {};

  private bugReport = new BugReport();

  private tabs: Record<string, any>[] = [];
  private activeTab = "content";

  // COMPUTED


  created(){
    this.setEventListeners();
  }

  beforeDestroy(){
    this.removeEventListeners();
  }

  // METHODS
  public nextTab(){
    (this.$refs.generalForm as any).nextTab();
  }
  public prevTab(){
    (this.$refs.generalForm as any).prevTab();
  }
  public submit(){
    (this.$refs.generalForm as any).submit();
  }
  public beforeClose(confirm: () => void, cancel: () => void){
    // No changes were made
    if (this.formValues._changed === 0){
      return confirm();
    }

    MessageBox({
      title: this.$t('confirmations.close-bug-report-form.title').toString(),
      message: this.$t('confirmations.close-bug-report-form.text').toString(),
      showCancelButton: true,
      confirmButtonClass: "el-button--info",
      cancelButtonClass: "el-button--danger",
      confirmButtonText: this.$t('confirmations.default.confirmation-button').toString(),
      cancelButtonText: this.$t('confirmations.default.cancel-button').toString(),
    }).then(() => {
      confirm();
    }).catch(() => {
      cancel();
    })
  }
  public close(ready?: boolean){
    if (!ready){
      this.beforeClose(() => {
        // Hide
        this.visible = false;

        // Reset form values
        this.tabs = [];
        this.formValues = {};

        // Unsubscribe
        this.removeEventListeners();
      }, () => {});
    } else {
      // Hide
      this.visible = false;

      // Reset form values
      this.tabs = [];
      this.formValues = {};

      // Unsubscribe
      this.removeEventListeners();
    }

    // Event
    window.GlobalEvents.$emit("close-bug-report-dialog");
  }
  public async open(id?: string){
    // Run
    await this.beforeOpen(id);

    // Open bug-report-form
    this.visible = true;
  }
  private async beforeOpen(id?: string){
    // Start loading
    this.loading = this.$loading({
      lock: true,
      target: ".bug-report-form .el-dialog"
    });
    this.ready = false;

    // Load
    if (id){
      await this.bugReport.get(id)
    } else {
      this.bugReport = new BugReport();
    }

    // Get configuration
    const config = configuration;

    // Set config
    this.tabs = config.tabs;

    // Set active tab
    this.activeTab = this.tabs[0].key;

    // Create form values
    const formValues = this.bugReport.toJSON() as any;
    Object.keys(formValues).forEach(key => {
      this.$set(this.formValues, key, formValues[key]);
    });

    // Done loading
    this.loading?.close();
    this.ready = true;
  }

  private async onSubmit(formValues: any){
    // Start loading
    this.loading = this.$loading({
      lock: true,
      target: '.bug-report-form .el-dialog'
    });

    const valid = await (this.$refs.generalForm as any).validate();
    if (valid){
      // Write form values
      this.bugReport.set(formValues);

      // Update
      await this.bugReport.update()
          .then(() => {
            this.$notify({
              title: this.$t('notifications.bug-report-saved.success.title').toString(),
              message: this.$t('notifications.bug-report-saved.success.message').toString(),
              type: 'success'
            });
          }).catch((error) => {
            // Notify user
            const errorCode = error.response.message.toLowerCase();
            this.$notify({
              title: this.$t('notifications.bug-report-saved.' + errorCode + '.title').toString(),
              message: this.$t('notifications.bug-report-saved.' + errorCode + '.message').toString(),
              type: 'error'
            });
          }).finally(() => {
            // Close bug-report-form
            this.close(true);

            // Stop loading
            this.loading?.close()
          });
    } else {
      this.loading?.close();
    }
  }

  private setEventListeners(){
    window.FormEvents.$on("open-bug-report", this.open);
  }

  private removeEventListeners(){
    window.FormEvents.$off("open-bug-report", this.open);
  }
}
