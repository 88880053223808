
import {Component, Prop} from 'vue-property-decorator'
import InputMixin from "../mixins/input";

@Component({
  name: 'MultipleChoice',
  components: {},
  mixins: [InputMixin]
})
export default class MultipleChoice extends InputMixin {
  @Prop({ default: () => [] }) private options! : Record<string, unknown>[];

}
