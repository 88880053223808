/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'install_desktop': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20 17H4V5h8V3H4c-1.11 0-2 .89-2 2v12a2 2 0 002 2h4v2h8v-2h4c1.1 0 2-.9 2-2v-3h-2v3z"/><path pid="1" d="M17 14l5-5-1.41-1.41L18 10.17V3h-2v7.17l-2.59-2.58L12 9z"/>'
  }
})
