/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'bubble_chart': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="7.2" cy="14.4" r="3.2"/><circle pid="1" cx="14.8" cy="18" r="2"/><circle pid="2" cx="15.2" cy="8.8" r="4.8"/>'
  }
})
