
import { Component, Vue } from 'vue-property-decorator'
import store from "@/store";
import router from "@/router";
import {MessageBox} from "element-ui";
import i18n from "@/lang";
import {sendEvent} from "@/firebase/config";

@Component({
  name: 'Tour',
  components: {},
  mixins: []
})
export default class Tour extends Vue {

  private id  = "create-marker";
  private step = 0;
  private visible  = false;
  private dialogVisible  = false;
  private active  = false;

  private animateNext : any;
  private nextVisible  = true;

  private focus: any[] = [];
  private configurations: any = {
    "create-marker": {
      beforeReady: () => {
        if (router.currentRoute.path !== "/dashboard/grid"){
          return router.push("/dashboard/grid");
        }
        return;
      },
      nextTour: "create-elements",
      tour: [{
          beforeNext: () => {
            setTimeout(() => {
              const btn = document.querySelector("#editor .left-menu .el-collapse-item:nth-child(1) [role='button']") as HTMLElement;
              if (btn && !btn.className.includes("is-active")){
                btn.click();
              }
            }, 1000);
          },
          performElementAction: true,
          element: "[name='button_create_marker']",
          padding: { top: 10, left: 10, bottom: 10, right: 10 },
          placement: "bottom",
          hideNext: true,
          finishedEvent: "route-change-/marker/editor"
        },
        {
          focusEvent: "editor-loaded",
          element: "[name='file_upload_marker']",
          padding: { top: 10, left: 10, bottom: 10, right: 10 },
          placement: "right",
          hideNext: true,
          finishedEvent: "upload-file-selected"
        },
        {
          element: "[name='file_upload_marker']",
          padding: { top: 10, left: 10, bottom: 10, right: 10 },
          hideNext: true,
          finishedEvent: "marker-created",
        },
        {
          allowReturn: true,
          focusEvent: "left-menu-rendered",
          element: "#editor .el-collapse-item:nth-child(2) .input-field:nth-child(1)",
          padding: { top: 20, left: 20, bottom: 20, right: 20 },
          placement: "right"
        },
        {
          allowReturn: true,
          element: "#editor .el-collapse-item:nth-child(2) .input-field:nth-child(2)",
          padding: { top: 20, left: 20, bottom: 20, right: 20 },
          placement: "right"
        },
        {
          allowReturn: true,
          element: "#editor .el-collapse-item:nth-child(2) .language-fields",
          padding: { top: 20, left: 20, bottom: 20, right: 20 },
          placement: "right"
        },
        {
          allowReturn: true,
          element: "#editor .left-menu-footer .input-field",
          padding: { top: 10, left: 20, bottom: 10, right: 20 },
          placement: "top"
        },
        {
          allowReturn: true,
          element: "#editor .left-menu-footer .input-field:nth-child(2)",
          padding: { top: 10, left: 20, bottom: 10, right: 20 },
          placement: "top"
        },
        {
          element: "#editor .left-menu button",
          overlay: true,
          padding: { top: 10, left: 10, bottom: 10, right: 10 },
          placement: "right",
        },
        {
          placement: "center"
        }
      ]
    },
    "create-elements": {
      beforeReady: async () => {
        if (router.currentRoute.path.indexOf("/marker/editor") < 0 || !router.currentRoute.params.markerId){
          if (store.state.marker.list.length > 0 && store.state.marker.list[0].Id !== "new"){
            return router.push("/marker/editor/" + store.state.marker.list[0].Id);
          } else {
            if (store.state.editor){
              store.state.editor.activeMarkerElementGroupId = "";
            }
            return MessageBox({
              title: i18n.t('confirmations.no-marker-created.title').toString(),
              message: i18n.t('confirmations.no-marker-created.text').toString(),
              showCancelButton: true,
              confirmButtonClass: "el-button--info",
              cancelButtonClass: "el-button--danger",
              confirmButtonText: i18n.t('confirmations.default.confirmation-button').toString(),
              cancelButtonText: i18n.t('confirmations.default.cancel-button').toString(),
            }).then(() => {
              window.GlobalEvents.$emit("start-tour", "create-marker");
              return false;
            }).catch(() => {
              window.GlobalEvents.$emit("stop-tour");
            });
          }
        } else {
          setTimeout(() => {
            window.GlobalEvents.$emit("editor-loaded");
          }, 1000);
        }
        return;
      },
      nextTour: "test-activate-marker",
      tour: [
        {
          focusEvent: "editor-loaded",
          allowReturn: true,
          element: "#editor .right-menu .marker-element-section",
          placement: "left",
          overlay: true
        },
        {
          element: "#editor .right-menu .marker-element-section .el-button",
          padding: { top: 5, left: 5, bottom: 5, right: 5 },
          placement: "left",
          hideNext: true,
          finishedEvent: "open-creator"
        },
        {
          focusEvent: "opened-creator",
          element: ".creator .creator-canvas",
          padding: { top: 10, left: 10, bottom: 10, right: 10 },
          placement: "right",
          hideNext: true,
          finishedEvent: "upload-file-selected",
        },
        {
          element: ".creator .creator-canvas",
          padding: { top: 10, left: 10, bottom: 10, right: 10 },
          placement: "right",
          hideNext: true,
          finishedEvent: "upload-file-uploaded",
        },
        {
          element: ".creator-actions-form .next-button",
          padding: { top: 10, left: 10, bottom: 10, right: 10 },
          placement: "top",
          hideNext: true,
          finishedEvent: "general-form-next-tab",
        },
        {
          element: "#pane-action",
          padding: { top: 10, left: 10, bottom: 90, right: 10 },
          placement: "left",
          hideNext: true,
          backEvent: "general-form-prev-tab",
          finishedEvent: "general-form-next-tab"
        },
        {
          allowReturn: true,
          element: "#pane-animation .general-form-input:nth-child(1)",
          padding: { top: 10, left: 10, bottom: 10, right: 10 },
          placement: "left"
        },
        {
          allowReturn: true,
          element: "#pane-animation .general-form-input:nth-child(2)",
          padding: { top: 10, left: 10, bottom: 10, right: 10 },
          placement: "left"
        },
        {
          element: ".creator-actions-form .finish-button",
          padding: { top: 10, left: 10, bottom: 10, right: 10 },
          placement: "top",
          hideNext: true,
          finishedEvent: "element-created"
        },
        {
          element: "#editor .canvas-elements .vdr",
          padding: { top: 10, left: 10, bottom: 10, right: 10 },
          placement: "left",
          overlay: true
        },
        {
          element: "#editor .right-menu-footer button",
          padding: { top: 10, left: 10, bottom: 10, right: 10 },
          placement: "top",
          hideNext: true,
          finishedEvent: "marker-saved"
        },
        {
          element: "#editor .right-menu button",
          padding: { top: 10, left: 10, bottom: 10, right: 10 },
          placement: "left",
        },
        {
          placement: "center"
        }
      ]
    },
    "editor": {
      beforeReady: async () => {
        if (router.currentRoute.path.indexOf("/marker/editor") < 0 || !router.currentRoute.params.markerId){
          if (store.state.marker.list.length > 0){
            // Go to marker
            await router.push("/marker/editor/" + store.state.marker.list[0].Id);
          } else {
            return MessageBox({
              title: i18n.t('confirmations.no-marker-created.title').toString(),
              message: i18n.t('confirmations.no-marker-created.text').toString(),
              showCancelButton: true,
              confirmButtonClass: "el-button--info",
              cancelButtonClass: "el-button--danger",
              confirmButtonText: i18n.t('confirmations.default.confirmation-button').toString(),
              cancelButtonText: i18n.t('confirmations.default.cancel-button').toString(),
            }).then(() => {
              window.GlobalEvents.$emit("start-tour", "create-marker");
              return false;
            }).catch(() => {
              window.GlobalEvents.$emit("stop-tour");
            });
          }
        } else {
          setTimeout(() => {
            store.state.editor.activeMarkerElementGroupId = "";
            window.EditorEvents.$emit("set-canvas", "2D");
            window.GlobalEvents.$emit("editor-loaded");
          }, 1000);
        }

        // Create elements first
        if (store.state.editor.marker.Elements.length === 0){
          return MessageBox({
            title: i18n.t('confirmations.no-elements-created.title').toString(),
            message: i18n.t('confirmations.no-elements-created.text').toString(),
            showCancelButton: true,
            confirmButtonClass: "el-button--info",
            cancelButtonClass: "el-button--danger",
            confirmButtonText: i18n.t('confirmations.default.confirmation-button').toString(),
            cancelButtonText: i18n.t('confirmations.default.cancel-button').toString(),
          }).then(() => {
            window.GlobalEvents.$emit("start-tour", "create-elements");
          }).catch(() => {
            window.GlobalEvents.$emit("stop-tour");
          })
        }

        return;
      },
      tour: [
        {
          beforeNext: () => {
            const btn = document.querySelector("#editor .right-menu .el-collapse-item:nth-child(1)  [role='button']") as HTMLElement;
            if (btn && !btn.className.includes("is-active")){
              btn.click();
            }
          },
          focusEvent: "editor-loaded",
          element: "#canvas-wrapper",
          placement: "left",
          hideNext: true,
          finishedEvent: "element-activated"
        },
        {
          beforeNext: () => {
            const btn = document.querySelector("#editor .right-menu .el-collapse-item:nth-child(2)  [role='button']") as HTMLElement;
            if (btn && !btn.className.includes("is-active")){
              btn.click();
            }
          },
          element: "#editor .right-menu .el-collapse-item:nth-child(1)",
          placement: "left",
          overlay: true,
        },
        {
          element: "#editor .right-menu .el-collapse-item:nth-child(2)",
          placement: "left",
          overlay: true,
        },
        {
          element: "#editor .canvas-elements .vdr",
          placement: "left",
          padding: { top: 10, left: 10, bottom: 10, right: 10 },
          hideNext: true,
          finishedEvent: "context-menu-opened"
        },
        {
          element: ".element-dropdown-menu",
          padding: { top: 10, left: 10, bottom: 10, right: 10 },
          placement: "left",
          overlay: true,
          beforeNext: () => {
            window.GlobalEvents.$emit("hide-context-menu");
          }
        },
        {
          allowReturn: true,
          element: ".transform-switch-buttons",
          padding: { top: 10, left: 10, bottom: 10, right: 10 },
          placement: "bottom",
          overlay: true
        },
        {
          allowReturn: true,
          element: ".language-switch-buttons",
          padding: { top: 10, left: 10, bottom: 10, right: 10 },
          placement: "top",
          overlay: true
        },
        {
          allowReturn: true,
          element: ".canvas-switch-buttons",
          padding: { top: 10, left: 10, bottom: 10, right: 10 },
          placement: "top",
          hideNext: true,
          finishedEvent: "canvas-3d-opened"
        },
        {
          element: "#canvas-wrapper",
          placement: "left",
          hideNext: true,
          finishedEvent: "element-activated"
        },
        {
          element: ".transform-switch-buttons",
          padding: { top: 0, left: 0, bottom: 50, right: 250 },
          finishedEvent: "transform-control-mode-change",
          placement: "bottom"
        },
        {
          allowReturn: true,
          element: "#canvas-wrapper",
          placement: "left",
        },
        {
          allowReturn: true,
          element: ".tools-wrapper button:nth-child(1)",
          placement: "bottom",
          overlay: true
        },
        {
          allowReturn: true,
          element: ".tools-wrapper button:nth-child(2)",
          placement: "bottom",
          overlay: true
        },
        {
          allowReturn: true,
          element: ".tools-wrapper .el-button-group:nth-child(2) button",
          padding: {top: 0, left: 0, bottom: 0, right: 42},
          placement: "bottom",
          overlay: true
        },
        {
          allowReturn: true,
          element: ".tools-wrapper .el-button-group:nth-child(3) button:nth-child(1)",
          placement: "bottom",
          overlay: true
        },
        {
          allowReturn: true,
          element: ".tools-wrapper .el-button-group:nth-child(3) button:nth-child(2)",
          placement: "bottom",
          overlay: true
        },
        {
          element: ".canvas-help-buttons button",
          padding: { top: 10, left: 10, bottom: 10, right: 10 },
          placement: "bottom",
        },
        {
          placement: "center"
        }
      ]
    },
    "test-activate-marker": {
      beforeReady: async () => {
        if (router.currentRoute.path.indexOf("/marker/editor") < 0 || !router.currentRoute.params.markerId){
          if (store.state.marker.list.length > 0){
            return router.push("/marker/editor/" + store.state.marker.list[0].Id);
          } else {
            return MessageBox({
              title: i18n.t('confirmations.no-marker-created.title').toString(),
              message: i18n.t('confirmations.no-marker-created.text').toString(),
              showCancelButton: true,
              confirmButtonClass: "el-button--info",
              cancelButtonClass: "el-button--danger",
              confirmButtonText: i18n.t('confirmations.default.confirmation-button').toString(),
              cancelButtonText: i18n.t('confirmations.default.cancel-button').toString(),
            }).then(() => {
              window.GlobalEvents.$emit("start-tour", "create-marker");
              return false;
            }).catch(() => {
              window.GlobalEvents.$emit("stop-tour");
            });
          }
        } else {
          setTimeout(() => {
            window.GlobalEvents.$emit("editor-loaded");
          }, 1000);
        }
        return;
      },
      tour: [
        {
          focusEvent: "editor-loaded",
          element: "#editor .right-menu-footer button:nth-child(2)",
          padding: { top: 5, left: 5, bottom: 5, right: 5 },
          placement: "top",
          finishedEvent: "test-marker",
          hideNext: true
        },
        {
          allowReturn: true,
          padding: {top: 0, left: 0, bottom: 0, right: 0},
          element: ".test-marker-mask .download-app-stamp",
          placement: "left"
        },
        {
          allowReturn: true,
          padding: {top: 20, left: 20, bottom: 20, right: 20},
          overlay: true,
          element: ".test-marker-dialog .test-instructions",
          placement: "left",
        },
        {
          allowReturn: true,
          element: ".test-marker-image",
          placement: "right",
        },
        {
          beforeNext: () => {
            const btn = document.querySelector(".test-marker-dialog .close-button") as HTMLElement;
            if (btn){
              btn.click();
            }
          },
          element: ".test-marker-dialog .close-button",
          padding: { top: 10, left: 10, bottom: 10, right: 10 },
          placement: "left",
          overlay: true,
        },
        {
          beforeNext: () => {
            return router.push("/dashboard/grid");
          },
          element: "#editor .right-menu-footer button:nth-child(3)",
          padding: { top: 5, left: 5, bottom: 5, right: 5 },
          placement: "top",
          finishedEvent: "marker-activation-toggle",
          hideNext: true,
        },
        {
          focusEvent: "markers-loaded",
          element: ".marker-card-footer .el-dropdown",
          padding: { top: 5, left: 5, bottom: 5, right: 5 },
          placement: "bottom",
          finishedEvent: "marker-menu-opened",
          hideNext: true,
        },
        {
          element: ".marker-dropdown-menu .el-dropdown-menu__item:nth-child(2)",
          placement: "top",
          finishedEvent: "test-marker",
          hideNext: true,
        },
        {
          beforeNext: () => {
            const btn = document.querySelector(".test-marker-dialog .close-button") as HTMLElement;
            if (btn){
              btn.click();
            }
          },
          element: ".test-marker-image",
          placement: "right",
        },
        {
          allowReturn: true,
          element: ".marker-card-footer .el-switch",
          padding: { top: 10, left: 10, bottom: 10, right: 10 },
          placement: "bottom",
          finishedEvent: "marker-activation-toggle",
        },
        {
          placement: "center",
        }
      ]
    },
    "bug-report": {
      beforeReady: async () => {
        return;
      },
      tour: [
        {
          element: ".right-menu-item div",
          placement: "bottom",
          finishedEvent: "dropdown-menu-opened",
          hideNext: true
        },
        {
          focusEvent: "dropdown-menu-opened",
          element: ".el-dropdown-menu li",
          placement: "bottom",
          finishedEvent: "open-bug-report-dialog",
          hideNext: true
        },
        {
          allowReturn: true,
          element: "#bugReportForm .general-form-input:nth-child(1)",
          placement: "right",
        },
        {
          allowReturn: true,
          element: "#bugReportForm .general-form-input:nth-child(2)",
          placement: "left",
        },
        {
          allowReturn: true,
          element: "#bugReportForm .general-form-input:nth-child(3)",
          placement: "right",
        },
        {
          allowReturn: true,
          element: "#bugReportForm .general-form-input:nth-child(4)",
          placement: "left",
        },
        {
          allowReturn: true,
          element: "#bugReportForm .general-form-input:nth-child(5)",
          placement: "top",
        },
        {
          allowReturn: true,
          element: ".bug-report-form-actions button",
          padding: { top: 10, left: 10, bottom: 10, right: 10 },
          placement: "top",
          overlay: true,
        },
        {
          element: ".el-dialog__header button",
          padding: { top: 5, left: 5, bottom: 5, right: 5 },
          placement: "left",
          overlay: true,
        },
        {
          placement: "center",
        }
      ]
    },
  }
  private configuration: any;
  private tour: any;
  private tourMask: any;
  private element: any;

  get prevState(){
    return this.step > 0 ? this.tour[this.step-1] : undefined;
  }

  get currentState(){
    return this.configurations[this.id].tour[this.step];
  }

  get nextState(){
    return this.configurations[this.id].tour[this.step+1];
  }

  created(){
    this.setEventListeners();
  }

  mounted() {
    this.tourMask = document.getElementById("tour-mask");
  }

  // METHODS
  public stop(complete = false){
    // Hide
    this.visible = false;
    this.dialogVisible = false;
    this.active = false;

    // Remove focus
    this.removeFocus();

    // Hide dialog
    const dialog = document.querySelector(".tour-dialog") as HTMLElement;
    dialog.style.top = "-600px";

    // Tour stopped
    window.GlobalEvents.$emit("tour-stopped");

    window.GlobalEvents.$off(this.tour[this.step].focusEvent);
    window.GlobalEvents.$off(this.tour[this.step].finishedEvent);
    window.GlobalEvents.$off(this.tour[this.step].backEvent);


    if (complete){
      // Analytics
      sendEvent("tutorial_complete", {
        "tutorial": this.id
      });
    }
  }
  public async start(tourId: string){
    if (this.id && this.id !== tourId){
      // Analytics
      sendEvent("tutorial_complete", {
        "tutorial": this.id
      });
    }

    // Select tour
    this.id = tourId;
    this.step = 0;
    this.configuration = this.configurations[this.id];
    this.tour = this.configuration.tour;
    this.active = true;

    // Before ready
    const ready = await this.configuration.beforeReady();

    if (ready !== false){
      // Focus on element
      this.removeFocus();
      if (this.tour[0].focusEvent){
        window.GlobalEvents.$on(this.tour[0].focusEvent, () => {
          window.GlobalEvents.$off(this.tour[0].focusEvent);
          this.startFocus();
        });
      } else {
        this.startFocus();
      }

      // Next step
      if (this.tour[0].finishedEvent){
        window.GlobalEvents.$on(this.tour[0].finishedEvent, () => {
          window.GlobalEvents.$off(this.tour[0].finishedEvent);
          this.nextStep(this.tour[0].finishedEvent === (this.tour[1] ? this.tour[1].focusEvent : null));
        });
      }

      // Tour started
      window.GlobalEvents.$emit("tour-started");

      // Set interval
      if (this.animateNext){
        window.clearInterval(this.animateNext);
      }
      setTimeout(() => {
        this.animateNext = window.setInterval(() => {
          this.nextVisible = !this.nextVisible;
        }, 1500);
      }, 3000);

      // Analytics
      sendEvent("tutorial_begin", {
        "tutorial": tourId
      });
    }
  }

  startFocus(){
    // Empty focus
    this.focus = [];

    // Create focus
    if (!this.tour){
      return
    }

    const selector= this.tour[this.step].element;
    const padding = this.tour[this.step].padding || { top: 0, left: 0, bottom: 0, right: 0 };
    if (selector){
      this.element = document.querySelector(selector) as HTMLElement;
      if (this.element){
        let rect = this.element.getBoundingClientRect();

        const overlay = document.createElement("div");
        overlay.style.position = "absolute";
        overlay.style.top = rect.top + "px";
        overlay.style.left = rect.left + "px";
        overlay.style.width = rect.width + "px";
        overlay.style.height = rect.height + "px";
        overlay.classList.add("focus-box");

        const topBox = document.createElement("div");
        topBox.style.position = "absolute";
        topBox.style.top = "0px";
        topBox.style.left = (rect.left - padding.left) + "px";
        topBox.style.width = (rect.width + padding.left + padding.right) + "px";
        topBox.style.height = (rect.top - padding.top) + "px";
        topBox.classList.add("focus-box");
        topBox.classList.add("dark");

        const leftBox = document.createElement("div");
        leftBox.style.position = "absolute";
        leftBox.style.top = "0px";
        leftBox.style.left = "0px";
        leftBox.style.width = (rect.left - padding.left) + "px";
        leftBox.style.height = "100vh";
        leftBox.classList.add("focus-box");
        leftBox.classList.add("dark");

        const bottomBox = document.createElement("div");
        bottomBox.style.position = "absolute";
        bottomBox.style.bottom = "0px";
        bottomBox.style.left = (rect.left - padding.left) + "px";
        bottomBox.style.width = (rect.width + padding.left + padding.right) + "px";
        bottomBox.style.height = "calc(100vh - " + (rect.top + rect.height - padding.bottom) + "px)";
        bottomBox.classList.add("focus-box");
        bottomBox.classList.add("dark");

        const rightBox = document.createElement("div");
        rightBox.style.position = "absolute";
        rightBox.style.top = "0px";
        rightBox.style.right = "0px";
        rightBox.style.width = "calc(100vw - " + (rect.left + rect.width - padding.right) + "px)";
        rightBox.style.height = "100vh";
        rightBox.classList.add("focus-box");
        rightBox.classList.add("dark");

        document.body.appendChild(topBox);
        document.body.appendChild(leftBox);
        document.body.appendChild(bottomBox);
        document.body.appendChild(rightBox);
        if (this.tour[this.step].overlay){
          document.body.appendChild(overlay);
        }

        let done = 0;
        const transition = setInterval(() => {
          if (this.element){
            const newRect = this.element.getBoundingClientRect();
            if (newRect.top !== rect.top
                || newRect.left !== rect.left
                || newRect.bottom !== rect.bottom
                || newRect.right !== rect.right
                || newRect.width !== rect.width
                || newRect.height !== rect.height
            ){
              done = 0;
            }

            if (done === 0){
              topBox.style.left = (rect.left - padding.left) + "px";
              topBox.style.width = (rect.width + padding.left + padding.right) + "px";
              topBox.style.height = (rect.top - padding.top) + "px";
              leftBox.style.width = (rect.left - padding.left) + "px";
              bottomBox.style.left = (rect.left - padding.left) + "px";
              bottomBox.style.width = (rect.width + padding.left + padding.right) + "px";
              bottomBox.style.height = "calc(100vh - " + (rect.top + rect.height + padding.bottom) + "px)";
              rightBox.style.width = "calc(100vw - " + (rect.left + rect.width + padding.right) + "px)";
            }

            // Set rect
            rect = newRect;
          }


          if (done > 100){
            clearInterval(transition);
          }
          done++;

          // Position dialog
          this.positionDialog();
        }, 10)
      } else {
        setTimeout(this.startFocus, 100);
      }
    } else {
      this.element = null;
      this.positionDialog();

      const focusBox = document.createElement("div");
      focusBox.style.position = "absolute";
      focusBox.style.top = "0px";
      focusBox.style.left =  "0px";
      focusBox.style.width = "100vw";
      focusBox.style.height = "100vh";
      focusBox.classList.add("focus-box");
      focusBox.classList.add("dark");

      document.body.appendChild(focusBox);
    }

    setTimeout(() => {
      this.dialogVisible = true;
    }, 500);
    this.visible = true;
  }

  positionDialog(){
    const dialog = document.querySelector(".tour-dialog") as HTMLElement;
    if (dialog){
      const padding = (this.tour ? (this.tour[this.step] ? this.tour[this.step].padding : { top: 0, left: 0, bottom: 0, right: 0 }) : undefined) || { top: 0, left: 0, bottom: 0, right: 0 };
      const rect = dialog.getBoundingClientRect();

      if (this.element){
        const placement = this.tour[this.step].placement;
        let {top, left, width, height} = this.element.getBoundingClientRect();

        let margin = 30;
        if (placement === "top"){
          dialog.style.top = Math.max((top - padding.top - margin - rect.height), margin) + "px";
          let dialogLeft = Math.max((left + (width/2) - (rect.width/2)), margin);
          if (dialogLeft > window.innerWidth - rect.width - margin){
            dialogLeft = window.innerWidth - rect.width - margin;
          }
          dialog.style.left = dialogLeft + "px";
        } else if (placement === "left"){
          let dialogTop = Math.max((top + (height/2) - (rect.height/2)), margin);
          if (dialogTop > window.innerHeight - rect.height - margin){
            dialogTop = window.innerHeight - rect.height - margin;
          }
          dialog.style.top = dialogTop + "px";
          dialog.style.left = Math.max((left - padding.left - margin - rect.width), margin) + "px";
        } else if (placement === "bottom"){
          dialog.style.top = Math.max((top + padding.bottom + height + margin), margin) + "px";
          let dialogLeft = Math.max((left + (width/2) - (rect.width/2)), margin);
          if (dialogLeft > window.innerWidth - rect.width - margin){
            dialogLeft = window.innerWidth - rect.width - margin;
          }
          dialog.style.left = dialogLeft + "px";
        } else if (placement === "right"){
          let dialogTop = Math.max((top + (height/2) - (rect.height/2)), margin);
          if (dialogTop > window.innerHeight - rect.height - margin){
            dialogTop = window.innerHeight - rect.height - margin;
          }
          dialog.style.top = dialogTop + "px";
          let dialogLeft = Math.max((left + padding.right + width + margin), margin);
          if (dialogLeft > window.innerWidth - padding.right - rect.width - margin){
            dialogLeft = window.innerWidth - padding.right - rect.width - margin;
          }
          dialog.style.left = dialogLeft + "px";
        }
      } else {
        dialog.style.top = (window.innerHeight/2) - (rect.height/2) + "px";
        dialog.style.left = (window.innerWidth/2) - (rect.width/2) + "px";
      }
    }
  }

  previousStep(){

    // Hide dialog
    this.dialogVisible = false;

    // Previous step
    this.step--;

    // Remove clones
    this.removeFocus();

    // Refocus
    this.startFocus();

    // Remove listeners
    window.GlobalEvents.$off(this.tour[this.step+1].focusEvent);
    window.GlobalEvents.$off(this.tour[this.step+1].finishedEvent);

    // Set up new listeners
    if (this.tour[this.step].finishedEvent){
      window.GlobalEvents.$on(this.tour[this.step].finishedEvent, () => {
        window.GlobalEvents.$off(this.tour[this.step].finishedEvent);
        this.nextStep(this.tour[this.step].finishedEvent === (this.tour[this.step+1] ? this.tour[this.step+1].focusEvent : null));
      });
    }
  }

  nextStep(focus = false){
    // Hide dialog
    this.dialogVisible = false;

    // Execute before next step
    if (this.tour[this.step].beforeNext){
      this.tour[this.step].beforeNext();
    }

    // Next step
    this.step++;

    // Remove clones
    this.removeFocus();

    // Refocus when next step is ready
    if (this.tour[this.step].focusEvent && !focus){
      window.GlobalEvents.$on(this.tour[this.step].focusEvent, () => {
        window.GlobalEvents.$off(this.tour[this.step].focusEvent);
        this.startFocus();
      });
    } else {
      this.startFocus();
    }

    // Remove listeners
    window.GlobalEvents.$off(this.tour[this.step-1].focusEvent);
    window.GlobalEvents.$off(this.tour[this.step-1].finishedEvent);

    // Set up new listeners
    // Next step
    if (this.tour[this.step].finishedEvent){
      window.GlobalEvents.$on(this.tour[this.step].finishedEvent, () => {
        window.GlobalEvents.$off(this.tour[this.step].finishedEvent);
        this.nextStep(this.tour[this.step].finishedEvent === (this.tour[this.step+1] ? this.tour[this.step+1].focusEvent : null));
      });
    }

    // Previous step
    if (this.tour[this.step].backEvent){
      window.GlobalEvents.$on(this.tour[this.step].backEvent, () => {
        window.GlobalEvents.$off(this.tour[this.step].backEvent);
        this.previousStep();
      });
    }
  }

  removeFocus(){
    document.querySelectorAll(".focus-box").forEach((element: any) => {
      try {
        document.body.removeChild(element);
      }
      catch (e) {
        console.error(e);
      }
    });

    this.visible = false;
  }

  loading: any;
  onWindowResizeStart(){
    this.removeFocus();
    this.dialogVisible = false;
    // this.loading = this.$loading({
    //   target: "body",
    //   lock: true
    // });
  }

  onWindowResizeEnd(){
    setTimeout( () => {
      if (this.active){
        this.startFocus();
        this.positionDialog();
      }
      // this.loading?.close();
    }, 500);
  }

  private setEventListeners(){
    window.GlobalEvents.$on("start-tour", this.start);
    window.GlobalEvents.$on("stop-tour", this.stop);
    window.GlobalEvents.$on("tour-next-step", this.nextStep);
    window.GlobalEvents.$on('window-resize-start', this.onWindowResizeStart);
    window.GlobalEvents.$on('window-resize-end', this.onWindowResizeEnd);
  }
}
