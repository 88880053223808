/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'screen_search_desktop': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20 18c1.1 0 1.99-.9 1.99-2L22 6a2 2 0 00-2-2H4c-1.11 0-2 .89-2 2v10a2 2 0 002 2H0v2h24v-2h-4zM4 16V6h16v10.01L4 16zm5.097-6.047c0-1.027.836-1.864 1.864-1.864 1.027 0 1.864.837 1.864 1.864a1.867 1.867 0 01-1.864 1.864 1.867 1.867 0 01-1.864-1.864zm7.032 4.236l-2.482-2.482a3.19 3.19 0 00.527-1.754A3.216 3.216 0 0010.96 6.74a3.217 3.217 0 00-3.214 3.213 3.218 3.218 0 003.214 3.214 3.19 3.19 0 001.724-.51l2.489 2.487.955-.955z"/>'
  }
})
