import request from '@/utils/request'
import {UserModule} from "@/store/modules/user";

export const getMarkerElements = async (markerId: string, params?: any) =>
    request({
        url: 'license/' + UserModule.license + '/marker/' + markerId + '/element',
        method: 'get',
        params
    });

export const createMarkerElement = async (markerId: string, data: any) =>
    request({
        url: 'license/' + UserModule.license + '/marker/' + markerId + '/element',
        method: 'post',
        data: data
    })

export const updateMarkerElement = async (markerId: string, elementId: string, data: any) =>
    request({
        url: 'license/' + UserModule.license + '/marker/' + markerId + '/element/' + elementId,
        method: 'put',
        data
    })

export const deleteMarkerElement = async (markerId: string, elementId: string) =>
    request({
        url: 'license/' + UserModule.license + '/marker/' + markerId + '/element/' + elementId,
        method: 'delete'
    })
