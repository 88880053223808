
import { Component, Watch } from 'vue-property-decorator'
import InputMixin from "../mixins/input";

@Component({
  name: 'Input',
  components: {},
  mixins: [InputMixin]
})
export default class Input extends InputMixin {
  private variables = [];

  @Watch("valueModel")
  onModelChange(value: IMarkerElementTemplateVariable[]){
    if (JSON.stringify(this.variables) !== JSON.stringify(value)){
      this.variables = JSON.parse(JSON.stringify(value));
    }
  }

  @Watch("variables", { deep: true })
  onVariablesChange(value: IMarkerElementTemplateVariable[]){
    this.valueModel = value;
  }

  created(){
    if (this.valueModel){
      this.variables = JSON.parse(JSON.stringify(this.valueModel));
    }
  }
}
