
import { Component, Prop } from 'vue-property-decorator'
import InputMixin from "../mixins/input";

@Component({
  name: 'Input',
  components: {},
  mixins: [InputMixin]
})
export default class Input extends InputMixin {
  @Prop({ default: null }) private checkboxGroups?: Record<string, unknown>[];

}
