import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import {login, logout, getUserProfile, getUser, getUserRoles} from '@/data/api/users'
import { getToken, setToken, removeToken } from '@/utils/cookies'
import router, { resetRouter } from '@/router'
import { PermissionModule } from './permission'
import store from '@/store'
import Vue from "vue";
import {User} from "@/data/classes/user";
import {MarkerModule} from "@/store/modules/marker";
import {CustomerModule} from "@/store/modules/customer";
import {refreshToken, setUserProperty} from "@/firebase/config";
import {setLocale} from "@/globalFunctions";
import {SettingsModule} from "@/store/modules/settings";

export interface IUserState {
  token: string
  name: string
  avatar: string
  introduction: string
  roles: string[]
  email: string
  language: string
  license?: string
  licenses: string[]
  uid: string
  user: User
}

@Module({ dynamic: true, store, name: 'user' })
class UserModuleClass extends VuexModule implements IUserState {
  public token = getToken() || ''
  public name = '';
  public avatar = '';
  public introduction = '';
  public impersonating = false;
  public roles: string[] = [];
  public email = '';
  public language = 'NL';
  public languageCode = 'NL';
  public license = "";
  public originalLicense = "";
  public licenses = [];
  public uid = ''
  public user = new User();

  @Mutation
  private SET_TOKEN(token: string) {
    this.token = token
    setToken(token)
    sessionStorage.setItem("token", token);
  }

  @Mutation
  private SET_LICENSE(licenseId: string) {
    this.license = licenseId
    this.originalLicense = licenseId;
    Vue.prototype.$user.license = licenseId;
  }

  @Mutation
  private SET_LANGUAGE(language: string){
    // Set language
    this.language = language;
    SettingsModule.SetDefaultLanguage(language);
  }

  @Mutation
  private START_IMPERSONATING(licenseId: string) {
    this.license = licenseId
    Vue.prototype.$user.license = licenseId;
    this.impersonating = this.originalLicense !== this.license;
    localStorage.setItem("impersonating", licenseId);
  }

  @Mutation
  private STOP_IMPERSONATING() {
    this.license = this.originalLicense;
    Vue.prototype.$user.license = this.license;
    this.impersonating = this.originalLicense !== this.license;
    localStorage.removeItem("impersonating");
  }

  @Mutation
  private SET_ROLES(roles: string[]) {
    this.roles = roles
    this.user.set({Roles: roles});

    // Set global variables
    Vue.prototype.$user.roles = roles;
    Vue.prototype.$user.isAdmin = roles.includes("admin");
  }

  @Mutation
  private SET_UID(uid: string) {
    this.uid = uid
    this.user.Id = uid;
  }

  @Action
  public async Initialize(userInfo : any) {
    // Unpack
    const { accessToken, uid } = userInfo;

    // Set access token
    this.SET_TOKEN(accessToken);

    // Refresh token every half hour
    setInterval(async () => {
      const newToken = await refreshToken();
      this.SET_TOKEN(newToken);
    }, 30*60*1000);

    // Get roles
    const roles : any = await getUserRoles();

    // Get user data
    await this.user.get(uid);

    // Set data
    this.SET_UID(uid);

    // Set license
    if (this.user.DefaultLicense || this.user.Licenses && this.user.Licenses.length > 0) {
      this.SET_LICENSE(this.user.DefaultLicense || this.user.Licenses[0]);
    }

    // Set roles
    this.SET_ROLES(roles)

    // Set user type
    if (this.user.Email === "demo-licentie@veewar.com"){
      setUserProperty("user_type", "demo");
      window.isDemo = true;
      window.GlobalEvents.$emit("start-demo");
      this.SET_LANGUAGE(navigator.language)
    } else if (roles.includes("trial")){
      setUserProperty("user_type", "trial");
      window.isTrial = true;
      window.GlobalEvents.$emit("start-trial");
      this.SET_LANGUAGE(navigator.language)
    } else {
      setUserProperty("user_type", "regular");
      window.GlobalEvents.$emit("start");
      this.SET_LANGUAGE(this.user.DefaultLocale)
    }

    if (localStorage.getItem("localePreference")){
      // Set preferred language
      setLocale(localStorage.getItem("localePreference") || navigator.language);
    }


    // If user was impersonating
    const impersonatingLicense = localStorage.getItem("impersonating");
    if (impersonatingLicense){
      this.Impersonate(impersonatingLicense);
    }

    // Reset router
    resetRouter()
    // Generate dynamic accessible routes based on roles
    PermissionModule.GenerateRoutes(this.roles)
    // Add generated routes
    PermissionModule.dynamicRoutes.forEach(route => {
      router.addRoute(route)
    });

    // Redirect
    const verificationCode = sessionStorage.getItem("verificationCode");
    if (verificationCode){
      sessionStorage.removeItem("redirect");
      router.push("/verify?code=" + verificationCode);
    } else {
      const redirect = sessionStorage.getItem("redirect");
      if (redirect){
        router.push(redirect).then(() => {
          sessionStorage.removeItem("redirect");
        });
      }
    }

    // User is logged in
    window.GlobalEvents.$emit("user-logged-in");
  }

  @Action
  public async Login(userInfo: { email: string, password: string}) {
    return login(userInfo);
  }

  @Action
  public async GetUser() {
    // Get user
    const {data} = await getUser()
    if (!data) {
      throw Error('Verification failed, please Login again.')
    }

    // Set data
    this.user.set(data.data);

    // Get roles
    const roles : any = await getUserRoles();

    // Set data
    this.SET_ROLES(roles);
    this.SET_UID(this.user.Id);
  }

  @Action
  public async Impersonate(licenseId: string) {
    this.START_IMPERSONATING(licenseId);

    router.push("/dashboard");
  }

  @Action
  public async SetDefaultLicense(licenseId: string) {
    // Set license
    this.SET_LICENSE(licenseId);
    if (this.user.DefaultLicense !== licenseId){
      this.user.DefaultLicense = licenseId;
      await this.user.update();

      // Refresh other modules
      CustomerModule.list._retrieved = false;
      MarkerModule.list._retrieved = false;
    }

    return router.push("/dashboard");
  }

  @Action
  public async StopImpersonating() {
    this.STOP_IMPERSONATING();

    window.location.reload();
  }

  @Action
  public async SetAccessToken(accessToken: string) {
    this.SET_TOKEN(accessToken);
  }

  @Action
  public async GetUserProfile(uid : string) {
    // Get user profile
    const {data} = await getUserProfile(uid)

    // Set data
    this.user.set(data.data);

    // Return data
    return this.user;
  }

  @Action
  public async UpdateRoutes() {
    // Dynamically modify permissions
    await this.GetUser()

    resetRouter()
    // Generate dynamic accessible routes based on roles
    PermissionModule.GenerateRoutes(this.roles)
    // Add generated routes
    PermissionModule.dynamicRoutes.forEach(route => {
      router.addRoute(route)
    })
  }

  @Action
  public async LogOut() {
    await logout()
    if (this.token) {
      removeToken()
      resetRouter()
    }

    // Reset visited views and cached views
    this.SET_TOKEN('')
    this.SET_ROLES([])
    this.SET_UID('');
  }

  @Action
  public async RemoveUserData() {
    if (this.token) {
      removeToken()
      resetRouter()
    }

    // Reset visited views and cached views
    this.SET_TOKEN('')
    this.SET_ROLES([])
    this.SET_UID('');
  }
}


export const UserModule = getModule(UserModuleClass)
