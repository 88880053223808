import {convertDate, filterUndefined} from "@/globalFunctions";
import {createProduct, deleteProduct, getProduct, getProducts, updateProduct} from "@/data/api/product";

/**
 * The Product list class
 */
export class ProductList extends Array implements Array<Product> {
    _changed = 0;
    _retrieved = false;
    _retrieving = false;
    _first = "";
    _last = "";
    _admin = false

    public new = () : Product => {
        // Create product
        const product = new Product();

        // Push to list
        this.push(product);

        // return
        return product;
    }

    public empty = () : void => {
        while(this.length > 0){
            this.pop();
        }
    }

    public get = async (admin = false) : Promise<ProductList> => {
        if (this._retrieving){
            return this;
        }
        this._retrieving = true;

        // Set admin
        this._admin = admin;

        // Empty array
        this.empty();

        // Get data
        const {data} = await getProducts({
            admin
        });

        // Convert to products
        for (const productData of data){
            // Push data
            const product = new Product(productData);
            product._new = false;
            this.push(product);
        }

        // Retrieved
        this._changed = 0;
        this._retrieved = true;
        this._retrieving = false;

        // Return products
        return this;
    }

    public set = (product: Product) => {
        // Find index
        const index = this.findIndex((listElement) => listElement.Id === product.Id);
        if (index >= 0){
            Object.assign(this[index], product);
        } else {
            this.push(product);
        }
    }

    public update = async () => {
        // Collect products
        const products = this.filter((product) => product._changed > 0) as Product[];

        // Update products
        const promises = [];
        for (const product of products){
            promises.push(product.update());
        }

        // Changed
        this._changed = 0;

        // Return products
        return await Promise.all(promises);
    }

    public archive = async (id: string) => {
        // Collect products
        const index = this.findIndex((product) => product.Id === id);
        const product = this[index] as Product;
        if (product){
            const result = await product.archive() as any;
            if (result.status === "success" || result.data.status === "success"){
                // Delete locally
                this.splice(index, 1);

                return true;
            }
        }

        // Return products
        return false;
    }

    public toList = () => {
        return this.map((product) => product);
    }

    public hasChanged = () => {
        this._changed = Date.now();
    }
}

/**
 * The Product class
 */
export class Product implements IProduct {
    _changed = 0;
    _new = true;
    _retrieved = false;
    _selected = false;

    Active = true;
    Archived = false;
    ArchivedBy?: string;
    ArchivedDateTime = new Date();
    BulletPoints = [];
    CreatedBy?: string;
    CreatedDateTime = new Date();
    DocumentUpdatedBy?: string;
    DocumentUpdatedDateTime = new Date();
    Description = {
        DE: "",
        EN: "",
        FR: "",
        NL: ""
    };
    Id = "new";
    Sku: string;
    Info = {
        Type: "subscription",
        ScanType: "monthly",
        ScanAmount: 1000,
        AllowLimitExceed: true
    };
    Nickname = "";
    Price = {
        Amount: null,
        BaseTier: {
            UpTo: 1000,
            FlatAmount: 3900,
            UnitAmount: 0,
            StripeId: ""
        },
        BillingScheme: "tiered",
        Currency: "EUR",
        ExtraTier: {
            UpTo: null,
            FlatAmount: 3900,
            UnitAmount: (3100/1000),
            StripeId: ""
        },
        Interval: "month",
        StripeId: "",
        TaxIncluded: false,
        Type: "recurring",
        TierMode: "volume",
        UsageType: "metered",
    };
    StripeId: string;
    RibbonText = {
        DE: "",
        EN: "",
        FR: "",
        NL: ""
    }
    Title = {
        DE: "",
        EN: "",
        FR: "",
        NL: ""
    }
    TitleShort = {
        DE: "",
        EN: "",
        FR: "",
        NL: ""
    }
    TranslateFrom = "NL"


    /**
     * Constructor
     * @param {unknown} obj data
     */
    constructor(obj?: unknown) {
        if (obj) {
            this.set(obj);
        }
    }

    /**
     * Get product
     * @return {Product} JSON data
     */
    public async get(id: string) : Promise<Product> {
        // Get data
        const {data} = await getProduct(id);

        // Item is not new
        this._new = false;

        // Set data
        return this.set(data);
    }

    /**
     * Set product data
     * @return {Product} Instance
     */
    public set(data: any) : Product {
        // Convert dates
        Object.keys(data).forEach(key => {
            if (key.indexOf("DateTime") >= 0){
                data[key] = convertDate(data[key]);
            }
        });

        // Assign data
        Object.assign(this, data);

        // Return instance
        return this;
    }

    /**
     * Archive the product
     * @return {Promise<any>} Archiving result
     */
    public async archive() : Promise<any> {
        // Archive
        const result = await deleteProduct(this.Id);

        // Return result
        return result;
    }

    /**
     * Update or create the product
     * @return {Product} Product object
     */
    public async update() : Promise<Product> {
        // Update or create
        const data = this.toJSON();
        const result = this._new ? await createProduct(data) : await updateProduct(this.Id, data);

        // Assign object
        Object.assign(this, result.data);

        // No changes
        this._changed = 0;
        this._new = false;

        return this;
    }

    /**
     * Convert to JSON
     * @return {IProduct} JSON data
     */
    public toJSON() : IProduct {
        return filterUndefined({
            Active: this.Active,
            Archived: this.Archived,
            ArchivedBy: this.ArchivedBy,
            ArchivedDateTime: convertDate(this.ArchivedDateTime),
            BulletPoints: this.BulletPoints,
            CreatedBy: this.CreatedBy,
            CreatedDateTime: convertDate(this.CreatedDateTime),
            DocumentUpdatedBy: this.DocumentUpdatedBy,
            DocumentUpdatedDateTime: convertDate(this.DocumentUpdatedDateTime),
            Description: this.Description,
            Id: this.Id,
            Info: this.Info,
            Nickname: this.Nickname,
            Price: this.Price,
            Sku: this.Sku,
            StripeId: this.StripeId,
            RibbonText: this.RibbonText,
            Title: this.Title,
            TitleShort: this.TitleShort,
            TranslateFrom: this.TranslateFrom,
        }) as IProduct;
    }

}
