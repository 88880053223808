import request from '@/utils/request'

export const startCheckout = (userId: string, productId: string,  couponId?: string, params?: any) =>
    request({
        url: 'stripe/checkout/' + userId + '/' + productId + (couponId ? '?couponId=' + couponId : ""),
        method: 'get',
        params
    })

export const continueCheckout = (licenseId: string, params?: any) =>
    request({
        url: 'stripe/checkout/' + licenseId,
        method: 'get',
        params
    })

export const startCustomerPortalSession = (userId: string, params?: any) =>
    request({
        url: 'stripe/portal/' + userId,
        method: 'get',
        params
    })

export const advanceTestClock = (userId: string, clockId: string, date: number, params?: any) =>
    request({
        url: 'stripe/test-clock/' + userId + '/' + clockId + '/' + date,
        method: 'get',
        params
    })
