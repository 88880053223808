
import { Component, Watch } from 'vue-property-decorator'
import InputMixin from "../mixins/input";
import SizeInput from "@/components/GeneralInput/components/SizeInput.vue";
import ColorInput from "@/components/GeneralInput/components/ColorInput.vue";

@Component({
  name: 'Input',
  components: {ColorInput, SizeInput},
  mixins: [InputMixin]
})
export default class Input extends InputMixin {
  private style = {};

  @Watch("valueModel")
  onModelChange(value: Record<string, IMarkerElementTemplateStyle>){
    if (JSON.stringify(this.style) !== JSON.stringify(value)){
      this.style = JSON.parse(JSON.stringify(value));
    }
  }

  @Watch("style", { deep: true })
  onVariablesChange(value: IMarkerElementTemplateStyle[]){
    this.valueModel = value;
  }

  private inputs = {
    size: [
      "padding", "margin", "width", "height", "border-width",
      "padding-left", "padding-right", "padding-top", "padding-bottom",
      "margin-left", "margin-right", "margin-top", "margin-bottom"
    ],
    color: [
      "color", "background-color", "background", "font-color", "border-color"
    ],
  }
  getInputType(styleKey: string){
    let type = "text";
    Object.keys(this.inputs).forEach((key: string) => {
      if ((this.inputs as any)[key].indexOf(styleKey) >= 0){
        type = key;
      }
    });
    return type;
  }

  getLabel(obj: IMarkerElementTemplateStyle){
    if (this.$t("labels.name."+obj.TagName) !== this.$t("labels.name."+obj.TagName)){
      return this.$t("labels.style-type."+obj.Key) + " " + this.$t("labels.type."+obj.TagType) + ' "' + this.$t("labels.name."+obj.TagName) + '"';
    } else {
      return this.$t("labels.style-type."+obj.Key) + " " + this.$t("labels.type."+obj.TagType)
    }
  }

  created(){
    if (this.valueModel){
      this.style = JSON.parse(JSON.stringify(this.valueModel));
    }
  }
}
