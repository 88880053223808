
import { Component } from 'vue-property-decorator'
import UpperRightMenu from "./components/UpperRightMenu.vue";
import {MarkerMixin} from "@/mixins/marker";
import {MarkerModule} from "@/store/modules/marker";
import {Marker} from "@/data/classes/marker";
import {UserModule} from "@/store/modules/user";

@Component({
  name: 'Navbar',
  components: {UpperRightMenu},
  mixins: [MarkerMixin],
})
export default class DashboardNavbar extends MarkerMixin {

  private loading: any;
  private rowSize = 5;

  private updateRowSize(value: number){
    window.GlobalEvents.$emit("marker-overview-row-size", value);
  }

  get markerFileUrls(){
    return MarkerModule.list.filter((marker: Marker) => marker._selected).map((marker: Marker) => marker.FileUrl);
  }

  get impersonatingLicense(){
    return UserModule.impersonating;
  }

  private async handleCommand(command: string){

    // Handle command
    switch (command) {
      case "deselectMarkers":
        this.markers.deselect();
        break;
      case "activateMarkers":
        // Start loading
        this.loading = this.$loading({
          lock: true,
          target: '#dashboard',
        });

        await this.markers.activate().then(({success, error}) => {
          error.forEach(({name, code}: {name:string, code: string}, index) => {
            setTimeout(() => {
              this.$notify({
                title: this.$t('notifications.markers-activated.' + code + '.title').toString(),
                message: this.$t('notifications.markers-activated.' + code + '.message').toString() + " " + name,
                type: 'error'
              });
            }, (index + 1) * 1500);
          });

          if (success.length > 0) {
            this.$notify({
              title: this.$t('notifications.markers-activated.success.title').toString(),
              message: this.$t('notifications.markers-activated.success.message').toString() + " " + success.join(", "),
              type: 'success'
            });
          }
        });
        break;
      case "deactivateMarkers":
        // Start loading
        this.loading = this.$loading({
          lock: true,
          target: '#dashboard',
        });

        await this.markers.deactivate().then(({success, error}) => {
          error.forEach(({name, code}: {name:string, code: string}, index) => {
            setTimeout(() => {
              this.$notify({
                title: this.$t('notifications.markers-activated.' + code + '.title').toString(),
                message: this.$t('notifications.markers-activated.' + code + '.message').toString() + " " + name,
                type: 'error'
              });
            }, (index + 1) * 1500);
          });

          if (success.length > 0) {
            this.$notify({
              title: this.$t('notifications.markers-activated.success.title').toString(),
              message: this.$t('notifications.markers-activated.success.message').toString() + " " + success.join(", "),
              type: 'success'
            });
          }
        });
        break;
      case "testMarkers":
        const marker = MarkerModule.list.filter((marker: Marker) => marker._selected)[0];
        window.GlobalEvents.$emit("open-test-marker-dialog", marker);

        // Listen for scans
        try {
          // Add scan listener
          window.UserScoring.addScanListener(marker.Id);

          // Remove after close
          window.GlobalEvents.$on("test-marker-dialog-closed", () => {
            window.UserScoring.removeScanListener(marker.Id);
            window.GlobalEvents.$off("test-marker-dialog-closed");
          })
        }
        catch (e) {
          console.error(e);
        }

        break;
      case "stopImpersonating":
        UserModule.StopImpersonating();
        break;
      default:
        console.warn("Unknown command: " + command);
    }

    // End loading
    this.loading?.close();
  }
}
