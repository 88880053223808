/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'deblur': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 3v18a9 9 0 000-18z"/><circle pid="1" cx="6" cy="14" r="1"/><circle pid="2" cx="6" cy="18" r="1"/><circle pid="3" cx="6" cy="10" r="1"/><circle pid="4" cx="3" cy="10" r=".5"/><circle pid="5" cx="6" cy="6" r="1"/><circle pid="6" cx="3" cy="14" r=".5"/><circle pid="7" cx="10" cy="21" r=".5"/><circle pid="8" cx="10" cy="3" r=".5"/><circle pid="9" cx="10" cy="6" r="1"/><circle pid="10" cx="10" cy="14" r="1.5"/><circle pid="11" cx="10" cy="10" r="1.5"/><circle pid="12" cx="10" cy="18" r="1"/>'
  }
})
